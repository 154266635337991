import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import { message } from 'antd';
import {
  authSuccessRedirection,
  convertfoxIdentifycall,
  deletePromise,
  fetchProjectSecretKey,
  getBlackFridayQueryParams,
  getPromise,
  getQueryParamsValue,
  patchPromise,
  postPromise,
  setProjectSecretKeyInLocal,
  unAuthorisedRedirection,
  unProcessableEntity,
  updateBrowserHistory,
  updateAppConfig,
  validateProjectSecretKey,
} from '../../helpers/utility';
import {
  discountPlans,
  gistPlans,
  gistShopifyPlans,
  OnBoardingUserState,
  publicRoutes,
  serverErrorCodes,
  unAuthorizedErrorCode,
  whiteLabelPlans,
} from '../constants';
import axios from 'axios';
import { store } from '../store';
import { push } from 'connected-react-router';
import { moduleAllSetUpCompleted } from '../../components/GettingStarted/helpers';
import { invalidContent } from '../../components/constants';
import contactActions from '../../redux/contacts/actions';
import chatActions from '../../redux/chat/actions';
import appActions from '../../redux/apps/actions';
import * as queryString from 'query-string';
import { analyticsScripts } from '../../containers/App/helper';

export function* checkAuthorization() {
  const referrerPaths = document.location.pathname.split('/');
  let referrer = getQueryParamsValue('referrer');
  if (
    referrerPaths[1] !== 'invites' &&
    referrerPaths[2] !== 'password' &&
    referrerPaths[2] !== 'rating' &&
    referrerPaths[2] !== 'email-confirmation'
  ) {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
      try {
        const referrerPaths = document.location.pathname.split('/');
        let queryParams = '';
        const { secret_key } = queryString.parse(document.location.search);
        if (document.location.pathname.includes('/oauth/authorize')) {
          const { workspace_id } = queryString.parse(document.location.search);
          if (workspace_id) {
            queryParams = `?project_secret_key=${workspace_id}`;
          }
        } else if (
          referrerPaths.length > 2 &&
          referrerPaths[1] === 'projects'
        ) {
          if (referrerPaths[2] === 'oauth2callback') {
            queryParams = `?project_secret_key=${localStorage.getItem(
              'projectSecretKey',
            )}`;
          } else {
            queryParams = `?project_secret_key=${referrerPaths[2]}`;
          }
        } else if (
          discountPlans.includes(referrerPaths[1]) &&
          !invalidContent.includes(
            secret_key || localStorage.getItem('projectSecretKey'),
          )
        ) {
          queryParams = `?project_secret_key=${
            secret_key || localStorage.getItem('projectSecretKey')
          }`;
        }
        const url = `${settings.ROOT_URL}authenticate.json${queryParams}`;
        const data = yield call(() => getPromise(url));
        if (data.data.charge_confirmation_url) {
          updateBrowserHistory('settings/subscription');
        }
        // Changes for white label domains integrations
        const whiteLabelHost = data.data.wl_host || null;
        if (whiteLabelHost) {
          window.location.hostname = whiteLabelHost;
        }
        yield put({
          type: actions.AUTHORIZATION_SUCCESS,
          payload: data,
        });
        yield analyticsScripts();
        yield put({
          type: chatActions.UPDATE_ENABLED_INBOX_WIDGETS_SUCCESS,
          payload: data.data.user_preference,
        });
        localStorage.setItem('projectSecretKey', data.data.project_secret_key);
        authSuccessRedirection(data.data.charge_confirmation_url);
        convertfoxIdentifycall();
        updateAppConfig();
      } catch (error) {
        yield put({
          type: actions.UPDATE_PRODUCT_DETAIL,
          payload: error.response && error.response.data,
        });
        updateAppConfig();
        if (error.response && error.response.status === 404) {
          yield put(push('/404'));
        }
        if (error.response && error.response.status === unAuthorizedErrorCode) {
          const plan = getQueryParamsValue('plan');
          const email = getQueryParamsValue('email');
          const grsf = getQueryParamsValue('grsf');
          let signUp = ['signup', 'sign-up', 'sign_up'];
          if (publicRoutes.includes(referrerPaths[1])) {
            if (
              !['null', '', null, undefined].includes(plan) &&
              signUp.includes(referrerPaths[1])
            ) {
              yield put(push(`/signup?plan=${plan}`));
            } else if (
              !['null', '', null, undefined].includes(email) &&
              signUp.includes(referrerPaths[1])
            ) {
              yield put(push(`/signup?email=${email}`));
            } else if (
              !['null', '', null, undefined].includes(grsf) &&
              signUp.includes(referrerPaths[1])
            ) {
              yield put(push(`/signup?grsf=${grsf}`));
            } else {
              yield put(push(`/${referrerPaths[1]}`));
            }
          } else if (referrerPaths[1] === 'users') {
            if (signUp.includes(referrerPaths[2])) {
              if (!['null', '', null, undefined].includes(plan)) {
                yield put(push(`/signup?plan=${plan}`));
              } else if (!['null', '', null, undefined].includes(email)) {
                yield put(push(`/signup?email=${email}`));
              } else if (!['null', '', null, undefined].includes(grsf)) {
                yield put(push(`/signup?grsf=${grsf}`));
              } else {
                yield put(push('/signup'));
              }
            } else {
              yield put(push('/login'));
            }
          } else if (referrerPaths[1] === 'onboarding') {
            yield put(push('/signup'));
          } else if (discountPlans.includes(referrerPaths[1])) {
            if (referrerPaths[1] === 'blackfriday') {
              yield put(push(getBlackFridayQueryParams(referrerPaths[1])));
            } else if (referrerPaths[1] === 'exclusive-offer') {
              yield put(push(getBlackFridayQueryParams(referrerPaths[1])));
            } else if (referrerPaths[1] === 'prime') {
              let planType = getQueryParamsValue('plan_name');
              let secretKey = getQueryParamsValue('secret_key');
              let licenses = getQueryParamsValue('licenses');
              let location = '/prime/checkout';
              let params = new URLSearchParams('');

              if (!invalidContent.includes(planType)) {
                params.set('plan_name', planType);
              }
              if (!invalidContent.includes(licenses)) {
                params.set('licenses', licenses);
              }
              if (!invalidContent.includes(secretKey)) {
                params.set('secret_key', secretKey);
              }
              if (params.toString()) {
                location += `?${params.toString()}`;
              }
              yield put(push(location));
            } else {
              yield put(push('/woocommerce-offer/checkout'));
            }
          } else {
            if (
              !referrerPaths.includes('login') &&
              referrerPaths[1] !== '' &&
              !referrerPaths.includes('onboarding') &&
              !referrerPaths.includes('choose-plan') &&
              !referrerPaths.includes('start-trial') &&
              !document.location.pathname.includes('oauth/authorize')
            ) {
              yield put(
                push(
                  `/login?referrer=${encodeURIComponent(
                    document.location.pathname,
                  )}${document.location.search}`,
                ),
              );
            } else {
              if (!referrer) {
                yield put(push('/login'));
              }
            }
          }
        }
        if (error.response && error.response.status !== 403) {
          yield put({
            type: actions.AUTHORIZATION_ERROR,
          });
        }
      }
    });
  }
}

export function* agentStatusChange() {
  yield takeEvery(actions.AGENT_AWAY_STATUS, function* (params) {
    yield put({
      type: actions.SET_AGENT_STATUS,
      payload: params.agentAwayStatus.status,
    });
    try {
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/agent-status/update.json`;
      yield call(() => postPromise(url, params.agentAwayStatus));
    } catch (error) {
      unAuthorisedRedirection(error);
      yield put({
        type: actions.AGENT_AWAY_STATUS_FAILURE,
        payload: params.agentAwayStatus.status,
      });
      if (error.response && error.response.status !== 403) {
        message.error('Agent status update failed');
      }
    }
  });
}

export function* pingAgentStatus() {
  yield takeEvery(actions.PING_AGENT_STATUS, function* () {
    try {
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/agent-status/ping.json`;
      yield call(() => postPromise(url));
    } catch (error) {
      if (error.response && serverErrorCodes.includes(error.response.status)) {
        window.bugsnagClient.notify(error);
      }
    }
  });
}

export function* authenticateUser(params) {
  try {
    let url = `${settings.ROOT_URL}users/sign_in`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.AUTHENTICATE_USER_SUCCESS,
      payload: data,
    });
    yield analyticsScripts();
    yield put({
      type: chatActions.UPDATE_ENABLED_INBOX_WIDGETS_SUCCESS,
      payload: data.data.user_preference,
    });
    yield authSuccessRedirection(data.data.charge_confirmation_url);
    if (document.location.pathname.split('/')[1] !== 'signup') {
      validateProjectSecretKey();
    }
    convertfoxIdentifycall();
    localStorage.removeItem('plan_detail');
    setProjectSecretKeyInLocal(data);
    updateAppConfig();
  } catch (error) {
    yield put({
      type: actions.UPDATE_PRODUCT_DETAIL,
      payload: error.response && error.response.data,
    });
    updateAppConfig();
    if (error.response && serverErrorCodes.includes(error.response.status)) {
      window.bugsnagClient.notify(error);
    }
    if (error.response && error.response.status === unAuthorizedErrorCode) {
      yield put({
        type: actions.AUTHENTICATE_USER_FAILURE,
        payload: error.response.data.errors,
      });
    } else if (error.response && error.response.status === 422) {
      yield put({
        type: actions.AUTHENTICATE_USER_FAILURE,
        payload: error.response.data.errors,
      });
    } else {
      yield put({
        type: actions.AUTHENTICATE_USER_FAILURE,
        payload: 'Something went wrong',
      });
    }
  }
}

export function* createUserAccount(params) {
  try {
    const url = `${settings.ROOT_URL}users/sign_up`;
    const data = yield call(() => postPromise(url, params.payload));
    let planType = getQueryParamsValue('plan');
    if (!['null', '', null, undefined].includes(planType)) {
      planType === 'startup'
        ? localStorage.setItem('plan_detail', 'startup_annual')
        : localStorage.setItem('plan_detail', planType);
    }
    yield put({
      type: actions.CREATE_USER_ACCOUNT_SUCCESS,
      payload: data,
    });
    yield analyticsScripts();
    yield put({
      type: chatActions.UPDATE_ENABLED_INBOX_WIDGETS_SUCCESS,
      payload: data.data.user_preference,
    });
    yield put({
      type: actions.DISCOUNT_PAGE_LOADER,
      payload: false,
    });
    if (params.payload.all_in_one_upgrade) {
      convertfoxIdentifycall();
      yield put(push(`/projects/${fetchProjectSecretKey()}/getting-started`));
    } else if (
      store.getState().Auth.onBoardingState ===
      OnBoardingUserState.companyDetails
    ) {
      yield put(push(`/projects/${fetchProjectSecretKey()}/contacts`));
    } else if (
      store.getState().Auth.onBoardingState ===
      OnBoardingUserState.gettingStarted
    ) {
      yield put(push(`/projects/${fetchProjectSecretKey()}/contacts`));
    } else {
      yield put(push('/onboarding'));
    }
    setProjectSecretKeyInLocal(data);
    updateAppConfig();
  } catch (error) {
    yield put({
      type: actions.UPDATE_PRODUCT_DETAIL,
      payload: error.response && error.response.data,
    });
    updateAppConfig();
    if (error.response && serverErrorCodes.includes(error.response.status)) {
      window.bugsnagClient.notify(error);
    }
    yield put({
      type: actions.CREATE_USER_ACCOUNT_FAILURE,
    });
    yield put({
      type: actions.DISCOUNT_PAGE_LOADER,
      payload: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors, 5);
    } else {
      message.error('Create account failed');
    }
  }
}

export function* createCompanyDetails(params) {
  try {
    const url = `${settings.ROOT_URL}projects`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_COMPANY_DETAILS_SUCCESS,
      payload: data,
    });
    yield put({
      type: chatActions.UPDATE_ENABLED_INBOX_WIDGETS_SUCCESS,
      payload: data.data.user_preference,
    });
    convertfoxIdentifycall(data.data.analytics_data);
    if (data.data.is_prime_coupon_user) {
      yield put(push(`/projects/${fetchProjectSecretKey()}/getting-started`));
    } else {
      const planDetail = localStorage.getItem('plan_detail');
      if (!['null', '', null, undefined].includes(planDetail)) {
        if (
            gistPlans.includes(planDetail) ||
            whiteLabelPlans.includes(planDetail) ||
            gistShopifyPlans.includes(planDetail)
        ) {
          // Set the plan type
          yield put({
            type: actions.SET_PLAN_TYPE,
            payload: planDetail,
          });
          yield put(push('/start-trial'));
        } else {
          yield put(push('/choose-plan'));
        }
      } else {
        yield put(push('/choose-plan'));
      }
    }
    setProjectSecretKeyInLocal(data);
    updateAppConfig();
  } catch (error) {
    yield put({
      type: actions.UPDATE_PRODUCT_DETAIL,
      payload: error.response && error.response.data,
    });
    updateAppConfig();
    yield put({
      type: actions.CREATE_COMPANY_DETAILS_FAILURE,
    });
    if (error.response && serverErrorCodes.includes(error.response.status)) {
      window.bugsnagClient.notify(error);
    }
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors, 5);
    } else {
      message.error('Create company details failed');
    }
  }
}

//Get company details from Clearbit API
export function* getCompanyDetails(params) {
  try {
    const url = `https://autocomplete.clearbit.com/v1/companies/suggest?query=${params.payload}`;
    const data = yield call(() =>
      axios.get(url).then((response) => response.data),
    );
    if (data.length) {
      yield put({
        type: actions.GET_COMPANY_DETAILS_FROM_CLEAR_BIT_SUCCESS,
        payload: data[0],
      });
    }
  } catch (error) {
    if (error) {
      window.bugsnagClient.notify(error);
    }
  }
}

export function* getInviteUserDetails() {
  try {
    let inviteUserToken = window.location.href.split('/').reverse()[0];
    const url = `${settings.ROOT_URL}invites/${inviteUserToken}/validate`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_INVITE_USER_DETAILS_SUCCESS,
      payload: data,
    });
    if (store.getState().Auth.userExist === true) {
      window.location.href = `/login?email=${encodeURIComponent(
        store.getState().Auth.invitedEmail,
      )}&token=${inviteUserToken}`;
    } else {
      window.location.href = `/signup?email=${encodeURIComponent(
        store.getState().Auth.invitedEmail,
      )}&token=${inviteUserToken}`;
    }
  } catch (error) {
    window.location.href = '/signup';
    if (error.response && serverErrorCodes.includes(error.response.status)) {
      window.bugsnagClient.notify(error);
    }
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error(
        'This is not a valid invite link. Please check and try again',
      );
    }
  }
}

export function* requestForgotPasswordLink(params) {
  try {
    const url = `${settings.ROOT_URL}users/reset_password`;
    yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.REQUEST_FORGOT_PASSWORD_LINK_SUCCESS,
    });
    yield put(push('/login'));
    message.success(
      'You will receive an email with instructions on how to reset your password in a few minutes.',
      5,
    );
  } catch (error) {
    yield put({
      type: actions.REQUEST_FORGOT_PASSWORD_LINK_FAILURE,
    });
    if (error.response && serverErrorCodes.includes(error.response.status)) {
      window.bugsnagClient.notify(error);
    }
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Something went wrong');
    }
  }
}

export function* changePassword(params) {
  try {
    const url = `${settings.ROOT_URL}users/reset_password`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.CHANGE_PASSWORD_SUCCESS,
      payload: data,
    });
    yield analyticsScripts();
    yield put({
      type: chatActions.UPDATE_ENABLED_INBOX_WIDGETS_SUCCESS,
      payload: data.data.user_preference,
    });
    if (fetchProjectSecretKey() !== null) {
      yield put(push(`/projects/${fetchProjectSecretKey()}/contacts`));
      message.success('Password updated.', 2);
    } else {
      yield put(push('/workspaces'));
    }
    convertfoxIdentifycall();
  } catch (error) {
    yield put({
      type: actions.CHANGE_PASSWORD_FAILURE,
    });
    if (error.response && serverErrorCodes.includes(error.response.status)) {
      window.bugsnagClient.notify(error);
    }
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors, 3);
    } else {
      message.error('Password cannot be changed');
    }
  }
}

export function* signOut() {
  const { fireBaseToken } = store.getState().Chat;
  try {
    const url = `${settings.ROOT_URL}users/sign_out?device_token=${fireBaseToken}`;
    yield call(() => deletePromise(url));
    localStorage.removeItem('referrerPath');
    localStorage.removeItem('plan_detail');
    window.location.href = '/login';
  } catch (error) {
    if (error.response && serverErrorCodes.includes(error.response.status)) {
      window.bugsnagClient.notify(error);
    }
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors, 3);
    } else {
      message.error('Something went wrong');
    }
  }
}

export function* getRecentProjects() {
  try {
    const url = `${settings.ROOT_URL}projects`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_RECENT_PROJECTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors, 3);
    } else {
      message.error('Something went wrong');
    }
  }
}

export function* getAllProjects(params) {
  try {
    const url = `${settings.ROOT_URL}projects?all_projects=true`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_ALL_PROJECTS_SUCCESS,
      payload: data.data.projects,
    });
    if (
      data &&
      data.data &&
      data.data.projects &&
      data.data.projects.length === 1
    ) {
      yield put({
        type: actions.SET_AUTHORIZE_APP_PROJECT_SECRET_KEY,
        payload: data.data.projects[0].secret_key,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors, 3);
    } else {
      message.error('Something went wrong');
    }
  }
}

export function* createWorkSpace(params) {
  try {
    const url = `${settings.ROOT_URL}projects`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_WORKSPACE_SUCCESS,
      payload: data,
    });
    yield put({
      type: chatActions.UPDATE_ENABLED_INBOX_WIDGETS_SUCCESS,
      payload: data.data.user_preference,
    });
    window.location.href = '/';
  } catch (error) {
    yield put({
      type: actions.CREATE_WORKSPACE_FAILURE,
    });
    if (error.response && serverErrorCodes.includes(error.response.status)) {
      window.bugsnagClient.notify(error);
    }
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors, 5);
    } else {
      message.error('Create workspace failed');
    }
  }
}

export function* fetchGettingStartedData(params) {
  try {
    const { getStartedData } = store.getState().Auth;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/getting_started`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_GET_STARTED_DATA_SUCCESS,
      payload: data,
    });
    if (params && params.fromOnboarding) {
      if (
        moduleAllSetUpCompleted(getStartedData.settings, [
          'tracking_script_installed_at',
        ])
      ) {
        updateBrowserHistory('conversations');
      } else {
        message.error('Tracking code has not been installed yet.');
      }
    }
  } catch (error) {
    yield put({ type: actions.FETCH_GET_STARTED_DATA_FAILURE });
    if (error.response && error.response.status === 404) {
      yield put(push('/404'));
    } else {
      message.error('Get started fetch failed');
    }
  }
}

export function* updateGettingStartedData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/update_setup_guide`;
    yield call(() => patchPromise(url, params.data));
    yield put({
      type: actions.COMPLETE_ONBOARDING_TASK,
      payload: params.data,
    });
  } catch (error) {
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update setup guide details failed');
    }
    unAuthorisedRedirection(error);
  }
}

export function* fetchEligiblePlanDetails(params) {
  let queryParams = '';
  if (params.includeShopify) {
    queryParams = '?shopify_plans=true';
  }
  const projectSecretKey = params.projectSecretKey || fetchProjectSecretKey();
  try {
    const url = `${settings.ROOT_URL}projects/${projectSecretKey}/settings/billings/project_subscription_plans/eligible_plans${queryParams}`;
    const payload = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ELIGIBLE_PLAN_DETAIL_SUCCESS,
      payload: payload.data,
    });
    if (params.includeShopify) {
      yield put({
        type: actions.SHOPIFY_PLAN_DRAWER_STATUS,
        payload: true,
      });
    }
  } catch (error) {
    yield put({ type: actions.FETCH_ELIGIBLE_PLAN_DETAIL_FAILURE });
    if (error.response && error.response.status === 404) {
      yield put(push('/404'));
    } else {
      message.error('Fetch plan detail failed');
    }
  }
}

export function* startTrial(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/billings/cards`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.AUTHORIZATION_SUCCESS,
      payload: data,
    });
    store.dispatch({
      type: actions.SET_BUTTON_LOADER,
      payload: false,
    });
    yield put(push(`/projects/${fetchProjectSecretKey()}/getting-started`));
  } catch (error) {
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Start trial failed');
    }
    unAuthorisedRedirection(error);
    store.dispatch({
      type: actions.SET_BUTTON_LOADER,
      payload: false,
    });
  }
}

export function* updateOnboardingState(params) {
  try {
    const data = {
      user: {
        onboarding_state: 'plan_details',
      },
    };
    const url = `${settings.ROOT_URL}/users/onboarding_state`;
    yield call(() => patchPromise(url, data));
    store.dispatch({
      type: actions.SET_LOADER,
      payload: false,
    });
    yield put(push(`/projects/${fetchProjectSecretKey()}/getting-started`));
  } catch (error) {
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Continue with free plan failed');
    }
    unAuthorisedRedirection(error);
    store.dispatch({
      type: actions.SET_LOADER,
      payload: false,
    });
  }
}

export function* enableEmailSendingDomain(params) {
  const object = { project: params.payload };
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/enable_email_sending`;
    const data = yield call(() => postPromise(url, object));
    yield put({
      type: actions.EMAIL_SENDING_DOMAIN_SUCCESS,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.EMAIL_SENDING_DOMAIN_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Email sending domain failed');
    }
  }
}

export function* updateSatisfactionRating(object) {
  yield put({ type: actions.SET_SATISFACTION_RATING_LOADER, payload: true });
  try {
    const url = `${
      settings.API_DOMAIN
    }/conversations/update_satisfaction_rating`;
    let data = yield call(() => patchPromise(url, object.payload, false));
    yield put({
      type: actions.UPDATE_SATISFACTION_RATING_SUCCESS,
      payload: data.data,
    });

    yield put({ type: actions.SET_SATISFACTION_RATING_LOADER, payload: false });
    yield put({
      type: actions.SET_SATISFACTION_RATING_COMMENT_LOADER,
      payload: false,
    });
  } catch (error) {
    yield put({ type: actions.SET_SATISFACTION_RATING_LOADER, payload: false });
    yield put({
      type: actions.SET_SATISFACTION_RATING_COMMENT_LOADER,
      payload: false,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Post feedback failed');
    }
  }
}

export function* discountPageAddCard(params) {
  // discounted_all_in_one
  try {
    let url = `${settings.ROOT_URL}`;
    if (params.isForPrimeCheckout) {
      url += 'prime_checkout';
    } else {
      url += 'discounted_all_in_one';
    }
    let data = yield call(() => postPromise(url, params.payload));
    if (params.isForPrimeCheckout) {
      yield put({
        type: actions.AUTHORIZATION_SUCCESS,
        payload: data,
      });
      yield put(push('/onboarding'));
    } else {
      params.history.push({
        pathname: `/projects/${data.data.project_secret_key}/settings/subscription`,
      });
    }
    yield put({
      type: actions.DISCOUNT_PAGE_LOADER,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: actions.DISCOUNT_PAGE_LOADER,
      payload: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('discounted plan change failed');
    }
    unAuthorisedRedirection(error);
  }
}

export function* updateConfirmationSettings(params) {
  try {
    const { key, extra_queries } = params;
    const url = `${
      settings.FORMS_CONFIRMATION_API
    }form_data/${key}/confirmation?${extra_queries ?? ''}`;
    let data = yield call(() => postPromise(url));
    let isRedirection = data.data.redirect_url;
    if (isRedirection) {
      window.location.href = data.data.redirect_url;
    } else {
      yield put({
        type: actions.UPDATE_CONFIRMATION_SETTINGS_REDIRECTION_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.UPDATE_CONFIRMATION_SETTINGS_REDIRECTION_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Confirmation settings failed');
    }
    if (error.response && error.response.status !== 403) {
      unAuthorisedRedirection(error);
    }
  }
}

export function* connectShopifyFromApp(params) {
  try {
    const url = `${settings.ROOT_URL}projects/${params.secretKey}/settings/integration/shopify/connect_store`;
    let data = yield call(() => patchPromise(url, params.payload));
    localStorage.removeItem('shopify_store');
    if (data.data.confirmation_url) {
      window.location.href = data.data.confirmation_url;
    } else {
      message.success(data.data);
      yield put({
        type: actions.CONNECT_SHOPIFY_FROM_APP_SUCCESS,
      });
      if (params.isRedirect) {
        yield put(
          push(
            `/projects/${params.secretKey}/apps/?app_id=shopify_integration`,
          ),
        );
        window.location.reload();
      } else {
        yield put({
          type: appActions.GET_SHOPIFY_LISTS,
        });
      }
    }
  } catch (error) {
    yield put({
      type: actions.CONNECT_SHOPIFY_FROM_APP_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Connect shopify store failed');
    }
    unAuthorisedRedirection(error);
  }
}

export function* updatePropertyPreference(params) {
  try {
    let requestObject = null;
    if (params.isFromTableView || params.isFromSearchView) {
      requestObject = {
        users_profile: {
          inbox_preference: params.payload.toString(),
        },
      };
    } else {
      requestObject = {
        users_profile: {
          property_preference: params.payload.toString(),
        },
      };
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users_profiles/update_property_preference`;
    const updatePropertyPreferenceResponse = yield call(() =>
      patchPromise(url, requestObject),
    );
    yield put({
      type: actions.UPDATE_PROPERTY_PREFERENCE_SUCCESS,
      payload:
        !(params.isFromTableView || params.isFromSearchView) &&
        updatePropertyPreferenceResponse.data,
      inboxPreference:
        (params.isFromTableView || params.isFromSearchView) &&
        updatePropertyPreferenceResponse.data,
    });
    if (params.requestFrom !== 'contacts_reducer') {
      if (!(params.isFromTableView || params.isFromSearchView)) {
        yield put({
          type: contactActions.UPDATE_CONTACT_COLUMNS,
          propertyPreference: updatePropertyPreferenceResponse.data,
        });
      }
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Contacts table columns change failed');
    }
  }
}

export function* getUnsubscriptionPerson(params) {
  try {
    const url = `${settings.API_DOMAIN}/unsubscribe_person?token=${params.token}`;
    let data = yield call(() => getPromise(url, false));
    yield put({
      type: actions.GET_UNSUBSCRIPTION_PERSON_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    let message;
    if (error.response && error.response.data) {
      message = error.response.data.error.message;
    } else {
      message = 'Unsubscription fetch failed';
    }
    yield put({
      type: actions.GET_UNSUBSCRIPTION_PERSON_DATA_FAILURE,
      message,
    });
  }
}

export function* updateUnsubscriptionPerson(params) {
  try {
    const url = `${settings.API_DOMAIN}/projects/${params.secretKey}/unsubscribe_person`;
    let data = yield call(() => postPromise(url, params.payload, false));
    yield put({
      type: actions.UPDATE_UNSUBSCRIPTION_PERSON_DATA_SUCCESS,
      message: data.message,
      payload: params.payload,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.UPDATE_UNSUBSCRIPTION_PERSON_DATA_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('update Unsubscription person Details failed');
    }
  }
}

export function* resubscribeEmailPreferences(params) {
  try {
    const url = `${settings.API_DOMAIN}/projects/${params.secretKey}/subscribe_person`;
    let data = yield call(() => postPromise(url, params.payload, false));
    yield put({
      type: actions.RESUBSCRIBE_EMAIL_PREFERENCES_SUCCESS,
      message: data.message,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Resubscribe email preference failed');
    }
  }
}

export function* findAppByClientId(params) {
  try {
    const url = `${settings.ROOT_URL}developer_apps/app_details/${params.clientId}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FIND_APP_BY_CLIENT_ID_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('App fetch failed');
    }
    yield put({
      type: actions.FIND_APP_BY_CLIENT_ID_FAILURE,
    });
  }
}

export function* authorizeApp(params) {
  try {
    const query = new URLSearchParams(params.payload).toString();
    const url = `${settings.API_DOMAIN}/oauth/authorize?${query}`;
    const data = yield call(() => postPromise(url));
    window.location.replace(data.redirect_uri);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('App authorize failed');
    }
  }
}

export function* hideConversationStatusMessages(params) {
  try {
    const { payload } = params;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users_profiles/hide_conversation_status_messages`;
    const data = yield call(() => patchPromise(url, payload));
    yield put({
      type: actions.HIDE_CONVERSATION_STATUS_MESSAGES_SUCCESS,
      payload: data.data.hide_conversation_status_messages,
    });
    message.success('Preferences saved');
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Saving preferences failed');
    }
  }
}

export function* denyApp(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/developer_apps/${
      params.payload
    }/denied`;
    const data = yield call(() => deletePromise(url));
    message.error(data.data);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('App denied failed');
    }
  }
}

export function* fetchAddons(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/billings/project_subscription_plans/add_ons?key=${
      params.key
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ADDON_DETAILS_SUCCESS,
      payload: data.add_on_details,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Addon fetch failed');
  }
}

export function* updateAddonDetails(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/billings/project_subscription_plans/update_add_on`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.ADDON_LOADER,
      payload: false,
    });
    yield put({
      type: actions.UPDATE_ADDON_DETAILS_SUCCESS,
      payload: params.payload.add_on_name,
    });
    message.success(data.data);
    params.callback();
    window.location.reload();
  } catch (error) {
    yield put({
      type: actions.ADDON_LOADER,
      payload: false,
    });
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Add on update failed');
  }
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(agentStatusChange),
    fork(pingAgentStatus),
    takeEvery(actions.AUTHENTICATE_USER, authenticateUser),
    takeEvery(actions.CREATE_USER_ACCOUNT, createUserAccount),
    takeEvery(actions.CREATE_COMPANY_DETAILS, createCompanyDetails),
    takeEvery(actions.GET_COMPANY_DETAILS_FROM_CLEAR_BIT, getCompanyDetails),
    takeEvery(actions.GET_INVITE_USER_DETAILS, getInviteUserDetails),
    takeEvery(actions.REQUEST_FORGOT_PASSWORD_LINK, requestForgotPasswordLink),
    takeEvery(actions.CHANGE_PASSWORD, changePassword),
    takeEvery(actions.SIGN_OUT, signOut),
    takeEvery(actions.GET_RECENT_PROJECTS, getRecentProjects),
    takeEvery(actions.GET_ALL_PROJECTS, getAllProjects),
    takeEvery(actions.CREATE_WORKSPACE, createWorkSpace),
    takeEvery(actions.FETCH_GET_STARTED_DATA, fetchGettingStartedData),
    takeEvery(actions.UPDATE_GET_STARTED_DATA, updateGettingStartedData),
    takeEvery(actions.FETCH_ELIGIBLE_PLAN_DETAIL, fetchEligiblePlanDetails),
    takeEvery(actions.UPGRATE_PLAN_WITH_TRIAL, startTrial),
    takeEvery(actions.UPDATE_ONBOARD_STATE, updateOnboardingState),
    takeEvery(actions.ENABLE_EMAIL_SENDING_DOMAIN, enableEmailSendingDomain),
    takeEvery(actions.UPDATE_SATISFACTION_RATING, updateSatisfactionRating),
    takeEvery(actions.DISCOUNT_PAGE_ADD_CARD, discountPageAddCard),
    takeEvery(
      actions.UPDATE_CONFIRMATION_SETTINGS_REDIRECTION,
      updateConfirmationSettings,
    ),
    takeEvery(actions.CONNECT_SHOPIFY_FROM_APP, connectShopifyFromApp),
    takeEvery(actions.UPDATE_PROPERTY_PREFERENCE, updatePropertyPreference),
    takeEvery(actions.GET_UNSUBSCRIPTION_PERSON_DATA, getUnsubscriptionPerson),
    takeEvery(
      actions.UPDATE_UNSUBSCRIPTION_PERSON_DATA,
      updateUnsubscriptionPerson,
    ),
    takeEvery(
      actions.RESUBSCRIBE_EMAIL_PREFERENCES,
      resubscribeEmailPreferences,
    ),
    takeEvery(actions.FIND_APP_BY_CLIENT_ID, findAppByClientId),
    takeEvery(actions.AUTHORIZE_APP, authorizeApp),
    takeEvery(
      actions.HIDE_CONVERSATION_STATUS_MESSAGES,
      hideConversationStatusMessages,
    ),
    takeEvery(actions.DENY_APP, denyApp),
    takeEvery(actions.FETCH_ADDON_DETAILS, fetchAddons),
    takeEvery(actions.UPDATE_ADDON_DETAILS, updateAddonDetails),
  ]);
}
