import React, { useState, useMemo, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  canWeAccess,
  fetchProjectSecretKey,
  isRestricted,
  fetchRestrictedFeatures,
  isPrimeCouponUser,
} from '../../../helpers/utility';
import actions from '../../../redux/settings/actions';
import newsActions from '../../../redux/newscenter/actions';
import {
  menu,
  restrictedPage,
  userRestriction,
} from '../../TopNavBar/constants';
import settings from '../../../settings/index';
const { Sider } = Layout;
const { SubMenu } = Menu;

const SideBar = ({ sideBarActiveKey, plan }) => {
  const dispatch = useDispatch();
  const { owner } = useSelector((state) => state.Auth);

  const [openKeys, setOpenKeys] = useState([]);
  const newsCenters = useSelector((state) => state.NewsCenter.newsCenters);

  useEffect(() => {
    dispatch({
      type: newsActions.FETCH_NEWS_CENTERS,
    });
  }, []);

  // Define menu options
  const workspaceSetup = useMemo(
    () => [
      {
        title: 'General',
        subMenu: [
          { label: 'General settings', url: 'workspace-defaults' },
          { label: 'Teammates', url: 'users' },
          { label: 'Teams & Roles', url: 'teams' },
        ],
        key: 'General',
      },

      {
        title: 'Your preferences',
        subMenu: [
          { label: 'General', url: 'general' },
          { label: 'Profile & calendar', url: 'profile' },
          { label: 'Notifications', url: 'notifications' },
        ],
        key: 'Profile',
      },
      {
        title: 'API & webhooks',
        subMenu: [
          { label: 'API key', url: 'api-key' },
          {
            label: 'Webhooks',
            url: 'webhooks',
          },
        ],
        key: 'Integrations',
      },
      {
        title: 'Billing',
        subMenu: [
          { label: 'Subscription', url: 'subscription' },
          { label: 'Invoice', url: 'invoice' },
          ...(isPrimeCouponUser && ['free', 'prime_lifetime'].includes(plan)
            ? [{ label: 'Redeem coupon', url: 'redeem-coupon' }]
            : []),
        ],
        key: 'Billing',
      },
    ],
    [plan],
  );

  const dataManagementSettings = useMemo(
    () => [
      {
        title: 'Properties',
        subMenu: [
          { label: 'Contact properties', url: 'contact-properties' },
          { label: 'Conversation properties', url: 'conversation-properties' },
          { label: 'Deal properties', url: 'deal-properties' },
          { label: 'Contact segments', url: 'contact-segments' },
          { label: 'Qualification data', url: 'qualification-data' },
        ],
        key: 'Properties',
      },
      {
        title: 'Tags',
        subMenu: [
          { label: 'Contact tags', url: 'contact-tags' },
          !isRestricted(restrictedPage.conversation) && {
            label: 'Conversation tags',
            url: 'conversation-tags',
          },
          !isRestricted(restrictedPage.conversation) && {
            label: 'Message tags',
            url: 'message-tags',
          },
        ],
        key: 'Tags',
      },
      {
        title: 'Tracking & events',
        subMenu: [
          { label: 'Tracking code', url: 'tracking-code' },
          { label: 'Events', url: 'events' },
          { label: 'Mobile App', url: 'mobile-app' },
          ...(!fetchRestrictedFeatures().includes(menu.identityVerification)
            ? [{ label: 'Security', url: 'security' }]
            : []),
        ],
        key: 'Tracking',
      },
      {
        title: 'Import & export',
        subMenu: [
          { label: 'Import history', url: 'imports' },
          { label: 'Export history', url: 'exports' },
        ],
        key: 'ImportsAndExports',
      },
    ],
    [],
  );

  const MAX_LABEL_LENGTH = 23;

  const truncateLabel = (label) =>
    label.length > MAX_LABEL_LENGTH
      ? `${label.slice(0, MAX_LABEL_LENGTH)}…`
      : label;

  const newsCenterSubMenu = useMemo(() => {
    const newsCenterSubMenu = [];
    if (!canWeAccess(userRestriction.messages).no_access) {
      newsCenters.forEach((newsCenter) => {
        newsCenterSubMenu.push({
          label: newsCenter.name,
          url: `newscenter/${newsCenter.id}`,
        });
      });
    }
    newsCenterSubMenu.push({
      label: 'All News Centers',
      url: 'newscenter/all',
    });
    return newsCenterSubMenu;
  }, [newsCenters]);

  const toolSettings = useMemo(
    () => [
      {
        title: 'Messenger',
        subMenu: [
          { label: 'Appearance', url: 'appearance' },
          { label: 'Availability', url: 'availability' },
          { label: 'Visibility', url: 'visibility' },
          { label: 'Languages', url: 'language' },
          { label: 'Data and Privacy', url: 'data-and-privacy' },
          { label: 'Advanced', url: 'advanced' },
          { label: 'Apps', url: 'messenger-apps' },
        ],
        key: 'Messenger',
      },
      {
        title: 'Inbox',
        subMenu: [
          {
            label: 'Email forwarding',
            url: 'shared-team-email-address',
          },
          {
            label: 'Conversation snippets',
            url: 'snippets',
          },
          { label: 'Spam settings', url: 'spam' },
        ],
        key: 'Inbox',
      },
      {
        title: 'Bots',
        subMenu: [
          { label: 'Custom bot', url: 'bot' },
          { label: 'Qualification bot', url: 'qualification-bot' },
        ],
        key: 'Bots',
      },
      {
        title: 'Knowledge base',
        subMenu: [
          { label: 'General', url: 'knowledge-base' },
          { label: 'Themes', url: 'kb-themes' },
        ],
        key: 'KnowledgeBase',
      },
      {
        title: 'Email',
        subMenu: [
          { label: 'General', url: 'email' },
          { label: 'Email sending addresses', url: 'email-sending-domain' },
          { label: 'Custom email templates', url: 'email-templates' },
          { label: 'Subscription types', url: 'subscription-types' },
          { label: 'Forms', url: 'forms' },
        ],
        key: 'Email',
      },
      {
        title: 'CRM',
        subMenu: [
          { label: 'Pipelines & stages', url: 'pipelines-and-stages' },
          { label: 'Companies', url: 'company-settings' },
        ],
        key: 'CRM',
      },
      {
        title: 'News Center',
        subMenu: newsCenterSubMenu,
        key: 'NewsCenter',
      },
    ],
    [newsCenterSubMenu],
  );

  const findKey = (menuList, activeKey) => {
    for (const data of menuList) {
      if (data.subMenu) {
        for (const el of data.subMenu) {
          if (el.url === activeKey[0]) {
            return data.key;
          }
        }
      }
    }
    return '';
  };

  useEffect(() => {
    const key =
      findKey(workspaceSetup, sideBarActiveKey) ||
      findKey(dataManagementSettings, sideBarActiveKey) ||
      findKey(toolSettings, sideBarActiveKey);
    setOpenKeys(key ? [key] : []);
  }, [sideBarActiveKey, workspaceSetup, dataManagementSettings, toolSettings]);

  // Filter workspaceSetup based on permissions
  const filteredWorkspaceSetup = useMemo(
    () =>
      workspaceSetup.filter(
        (data) =>
          data.title !== 'Billing' ||
          owner ||
          canWeAccess(userRestriction.billingSettings),
      ),
    [workspaceSetup, owner],
  );

  const menuClick = (e) => {
    dispatch({
      type: actions.SET_SIDEBAR_ACTIVE,
      key: e.keyPath,
    });
    localStorage.setItem('currentSettingsUrl', e.key);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const getMenuList = (menu) => {
    const { subMenu, title, key, url } = menu;
    return subMenu ? (
      <SubMenu key={key} title={<span>{title}</span>}>
        {subMenu.map((data) =>
          data.label ? (
            <Menu.Item key={data.url} onClick={menuClick}>
              <NavLink
                to={`/projects/${fetchProjectSecretKey()}/settings/${data.url}`}
              >
                {title === 'News Center'
                  ? truncateLabel(data.label)
                  : data.label}
              </NavLink>
            </Menu.Item>
          ) : null,
        )}
      </SubMenu>
    ) : (
      <Menu.Item key={url} onClick={menuClick}>
        <NavLink to={`/projects/${fetchProjectSecretKey()}/settings/${url}`}>
          {title}
        </NavLink>
      </Menu.Item>
    );
  };

  return (
    <Sider className="settings-sider">
      <div className="heading">Settings</div>
      <Menu
        mode="inline"
        selectedKeys={sideBarActiveKey}
        style={{ borderRight: 0 }}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        <p className="sub-menu-title">Workspace Setup</p>
        {filteredWorkspaceSetup.map((data) => getMenuList(data))}
        <p className="sub-menu-title">Data Management</p>
        {dataManagementSettings.map((data) => getMenuList(data))}
        <p className="sub-menu-title">Tools</p>
        {toolSettings.map((data) => getMenuList(data))}
      </Menu>
    </Sider>
  );
};

export default SideBar;
