import './style.scss';
import React, { Component } from 'react';
import { Button, Drawer, Form, Input } from 'antd';
import { store } from '../../redux/store';
import actions from '../../redux/auth/actions';

class WorkSpaceCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClose = () => {
    store.dispatch({
      type: actions.CLOSE_WORKSPACE_DRAWER,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = this.props.form;
    form.validateFields((err, values) => {
      if (!err) {
        store.dispatch({
          type: actions.CREATE_WORKSPACE,
          payload: { ...values },
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { openWorkSpaceDrawer, createWorkSpaceLoader } = this.props;
    return (
      <Drawer
        placement="right"
        closable={false}
        maskClosable={true}
        onClose={() => this.onClose()}
        visible={openWorkSpaceDrawer}
        className="workspace-drawer"
        destroyOnClose={true}
        width={500}
      >
        <div className="drawer-heading">
          <h2>Create a new workspace</h2>
          <p>Typically, you should setup one workspace per website or product.
            This keeps everything cleanly organized.</p>
        </div>
        <div className="drawer-content">
          <Form onSubmit={this.handleSubmit} colon={false}>
            <Form.Item label="Workspace name">
              {getFieldDecorator('project_name', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter your workspace name',
                    whitespace: true,
                  },
                  {
                    max: 48,
                    message: 'Input should be less than 48 characters',
                  },
                ],
              })(
                <Input
                  className="form-item input-md"
                  placeholder="My Company"
                />,
              )}
              <div className="ant-form-extra-desc">
                You can always change the name later.
              </div>
            </Form.Item>
            <Form.Item label="Domain">
              {getFieldDecorator('domain', {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please enter domain name',
                  },
                ],
              })(
                <Input
                  className="form-item input-md"
                  placeholder="myawesomewebsite.com"
                />,
              )}
            </Form.Item>
            <Form.Item>
              <Button
                className="btn btn-primary btn-sm submit"
                htmlType="submit"
                loading={createWorkSpaceLoader}
              >
                Create Workspace
              </Button>
              <Button
                className="btn btn-secondary-outline btn-sm cancel"
                onClick={() => this.onClose()}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    );
  }
}

export default Form.create()(WorkSpaceCreate);
