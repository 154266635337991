import React from 'react';

const ThatsHelped = ({ t }) => (
  <>
    <h2>{t.feedbackThankYouSubject}</h2>
    <div className={'divider'}></div>
    <p>{t.feedbackAcknowledgment}</p>
    <h1>{t.pageClosureMessage}</h1>
  </>
);
export default ThatsHelped;
