import React, { useMemo, useEffect } from 'react';
import { Progress } from 'antd';
import authActions from '../../../redux/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { updateBrowserHistory } from '../../../helpers/utility';

const StepList = (props) => {
  const { card, isFromTaskWidget, fireConfetti } = props;
  const dispatch = useDispatch();
  const completedOnboardingTasks = useSelector(
    (state) => state.Auth.completedOnboardingTasks,
  );

  const getStepStatus = (stepKey) =>
    completedOnboardingTasks?.includes(stepKey);

  const handleStepItemAction = (step, stepKey) => {
    dispatch({
      type: authActions.SET_CURRENT_TASK_WIDGET_DATA,
      payload: {
        step: step,
        product: stepKey,
      },
    });
    updateBrowserHistory(step.action.url);
  };

  const completedStepsCount = useMemo(
    () => card.steps.filter((step) => getStepStatus(step.key)).length,
    [card.key, completedOnboardingTasks],
  );

  const progressPercentage = (completedStepsCount / card.steps.length) * 100;

  useEffect(() => {
    if (progressPercentage === 100 && isFromTaskWidget) {
      fireConfetti();
    }
  }, [progressPercentage]);

  return (
    <div className="p-2">
      <div className="p-4 pt-0 pb-2 flex items-center gap-2">
        <div className="text-sm text-muted block">
          {completedStepsCount}/{card.steps.length}
        </div>
        <Progress
          percent={progressPercentage}
          showInfo={false}
          strokeColor={card.color_class}
          className="mb-1"
        />
      </div>


      {card.steps.map((step, itemIndex) => {
        const isStepCompleted = getStepStatus(step.key);
        let pointerEvents = '',
          textMuted = '',
          completed = '';
        if (isStepCompleted) {
          pointerEvents = 'pointer-events-none';
          textMuted = 'text-muted';
          completed = 'completed';
        }
        return (
          <React.Fragment key={itemIndex}>
            <div
              className={`getting-started-step-item ${pointerEvents} ${
                step.key === props.selectedStepKey ? 'selected' : ''
              }`}
              onClick={() => handleStepItemAction(step, card.key)}
            >
              <div className="step-item-section">
                <div className={`${card.color_class} ${completed} step-icon`}>
                  <i
                    className={
                      isStepCompleted ? 'fas fa-check' : step.iconClass
                    }
                  />
                </div>
              </div>
              <div className="step-item-description">
                <h4 className={`text-base mb-1 ${textMuted}`}>{step.title}</h4>
                <p className={`text-sm text-muted mb-0 ${textMuted}`}>
                  {step.description}
                </p>
              </div>
            </div>

            {itemIndex < card.steps.length - 1 && (
              <div className="separate-line my-2" />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default StepList;
