const actions = {
  SET_CHAT_ONGOING_SORT_VALUES_IN_POST: 'SET_CHAT_ONGOING_SORT_VALUES_IN_POST',
  SET_CHAT_ONGOING_SEARCH_VALUE_IN_POST:
    'SET_CHAT_ONGOING_SEARCH_VALUE_IN_POST',
  SET_CHAT_ONGOING_STATUS_IN_POST: 'SET_CHAT_ONGOING_STATUS_IN_POST',
  SET_CREATE_CHAT_ONGOING_IN_POST: 'SET_CREATE_CHAT_ONGOING_IN_POST',
  GET_CHAT_PROMPT_DETAILS_IN_POST: 'GET_CHAT_PROMPT_DETAILS_IN_POST',
  GET_CHAT_PROMPT_DETAILS_SUCCESS_IN_POST:
    'GET_CHAT_PROMPT_DETAILS_SUCCESS_IN_POST',
  GET_CHAT_PROMPT_DETAILS_FAILURE_IN_POST:
    'GET_CHAT_PROMPT_DETAILS_FAILURE_IN_POST',
  UPDATE_CHAT_PROMPT_DETAILS_IN_POST: 'UPDATE_CHAT_PROMPT_DETAILS_IN_POST',
  SET_CHAT_ONGOING_TAB_KEY_IN_POST: 'SET_CHAT_ONGOING_TAB_KEY_IN_POST',
  SET_CHAT_ONGOING_TAG_ID_IN_POST: 'SET_CHAT_ONGOING_TAG_ID_IN_POST',
  SET_CHAT_ONGOING_SCHEDULE_TIME_IN_POST:
    'SET_CHAT_ONGOING_SCHEDULE_TIME_IN_POST',
  CHAT_ONGOING_SCHEDULE_DETAILS_IN_POST:
    'CHAT_ONGOING_SCHEDULE_DETAILS_IN_POST',
  UPDATE_MESSAGE_CHAT_ONGOING_IN_POST: 'UPDATE_MESSAGE_CHAT_ONGOING_IN_POST',
  UPDATE_MESSAGE_CHAT_ONGOING_SCHEDULE_DETAILS_IN_POST:
    'UPDATE_MESSAGE_CHAT_ONGOING_SCHEDULE_DETAILS_IN_POST',
  REORDER_MESSAGE_CHATS_IN_POST: 'REORDER_MESSAGE_CHATS_IN_POST',
  CHANGE_MESSAGE_CHAT_POSITION_IN_POST: 'CHANGE_MESSAGE_CHAT_POSITION_IN_POST',
  SET_MESSAGE_CHAT_LOADER_IN_POST: 'SET_MESSAGE_CHAT_LOADER_IN_POST',
};

export default actions;
