const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  AUTHORIZATION_SUCCESS: 'AUTHORIZATION_SUCCESS',
  AUTHORIZATION_ERROR: 'AUTHORIZATION_ERROR',
  AGENT_AWAY_STATUS: 'AGENT_AWAY_STATUS',
  AGENT_AWAY_STATUS_FAILURE: 'AGENT_AWAY_STATUS_FAILURE',
  SET_AGENT_STATUS: 'SET_AGENT_STATUS',
  PING_AGENT_STATUS: 'PING_AGENT_STATUS',
  AUTHENTICATE_USER: 'AUTHENTICATE_USER',
  AUTHENTICATE_USER_SUCCESS: 'AUTHENTICATE_USER_SUCCESS',
  AUTHENTICATE_USER_FAILURE: 'AUTHENTICATE_USER_FAILURE',
  CREATE_USER_ACCOUNT: 'CREATE_USER_ACCOUNT',
  CREATE_USER_ACCOUNT_SUCCESS: 'CREATE_USER_ACCOUNT_SUCCESS',
  CREATE_USER_ACCOUNT_FAILURE: 'CREATE_USER_ACCOUNT_FAILURE',
  CREATE_COMPANY_DETAILS: 'CREATE_COMPANY_DETAILS',
  CREATE_COMPANY_DETAILS_SUCCESS: 'CREATE_COMPANY_DETAILS_SUCCESS',
  CREATE_COMPANY_DETAILS_FAILURE: 'CREATE_COMPANY_DETAILS_FAILURE',
  GET_COMPANY_DETAILS_FROM_CLEAR_BIT: 'GET_COMPANY_DETAILS_FROM_CLEAR_BIT',
  GET_COMPANY_DETAILS_FROM_CLEAR_BIT_SUCCESS:
    'GET_COMPANY_DETAILS_FROM_CLEAR_BIT_SUCCESS',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  GET_INVITE_USER_DETAILS: 'GET_INVITE_USER_DETAILS',
  GET_INVITE_USER_DETAILS_SUCCESS: 'GET_INVITE_USER_DETAILS_SUCCESS',
  REQUEST_FORGOT_PASSWORD_LINK: 'REQUEST_FORGOT_PASSWORD_LINK',
  REQUEST_FORGOT_PASSWORD_LINK_SUCCESS: 'REQUEST_FORGOT_PASSWORD_LINK_SUCCESS',
  REQUEST_FORGOT_PASSWORD_LINK_FAILURE: 'REQUEST_FORGOT_PASSWORD_LINK_FAILURE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  CLEAR_ERROR: 'CLEAR_ERROR',
  SIGN_OUT: 'SIGN_OUT',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  GET_RECENT_PROJECTS: 'GET_RECENT_PROJECTS',
  GET_RECENT_PROJECTS_SUCCESS: 'GET_RECENT_PROJECTS_SUCCESS',
  GET_ALL_PROJECTS: 'GET_ALL_PROJECTS',
  GET_ALL_PROJECTS_SUCCESS: 'GET_ALL_PROJECTS_SUCCESS',
  OPEN_WORKSPACE_DRAWER: 'OPEN_WORKSPACE_DRAWER',
  CLOSE_WORKSPACE_DRAWER: 'CLOSE_WORKSPACE_DRAWER',
  CREATE_WORKSPACE: 'CREATE_WORKSPACE',
  CREATE_WORKSPACE_SUCCESS: 'CREATE_WORKSPACE_SUCCESS',
  CREATE_WORKSPACE_FAILURE: 'CREATE_WORKSPACE_FAILURE',
  UPDATE_CHANGED_PROFILE_DETAILS: 'UPDATE_CHANGED_PROFILE_DETAILS',
  REMOVE_PROJECT_SECRET_KEY: 'REMOVE_PROJECT_SECRET_KEY',
  AUTH_LOADER: 'AUTH_LOADER',
  UPDATE_WORKSPACE_NAME: 'UPDATE_WORKSPACE_NAME',
  FETCH_GET_STARTED_DATA: 'FETCH_GET_STARTED_DATA',
  FETCH_GET_STARTED_DATA_SUCCESS: 'FETCH_GET_STARTED_DATA_SUCCESS',
  FETCH_GET_STARTED_DATA_FAILURE: 'FETCH_GET_STARTED_DATA_FAILURE',
  FETCH_ELIGIBLE_PLAN_DETAIL: 'FETCH_ELIGIBLE_PLAN_DETAIL',
  FETCH_ELIGIBLE_PLAN_DETAIL_SUCCESS: 'FETCH_ELIGIBLE_PLAN_DETAIL_SUCCESS',
  FETCH_ELIGIBLE_PLAN_DETAIL_FAILURE: 'FETCH_ELIGIBLE_PLAN_DETAIL_FAILURE',
  SET_PLAN_TYPE: 'SET_PLAN_TYPE',
  UPGRATE_PLAN_WITH_TRIAL: 'UPGRATE_PLAN_WITH_TRIAL',
  SET_BUTTON_LOADER: 'SET_BUTTON_LOADER',
  UPDATE_ONBOARD_STATE: 'UPDATE_ONBOARD_STATE',
  SET_LOADER: 'SET_LOADER',
  UPDATE_NOTIFICATION_SETTINGS: 'UPDATE_NOTIFICATION_SETTINGS',
  EMAIL_SENDING_DOMAIN_SUCCESS: 'EMAIL_SENDING_DOMAIN_SUCCESS',
  ENABLE_EMAIL_SENDING_DOMAIN: 'ENABLE_EMAIL_SENDING_DOMAIN',
  EMAIL_SENDING_DOMAIN_FAILURE: 'EMAIL_SENDING_DOMAIN_FAILURE',
  EMAIL_SENDING_DOMAIN_MODEL_TRIGGER: 'EMAIL_SENDING_DOMAIN_MODEL_TRIGGER',
  UPDATE_SATISFACTION_RATING: 'UPDATE_SATISFACTION_RATING',
  UPDATE_SATISFACTION_RATING_SUCCESS: 'UPDATE_SATISFACTION_RATING_SUCCESS',
  SET_SATISFACTION_RATING_LOADER: 'SET_SATISFACTION_RATING_LOADER',
  SET_SATISFACTION_RATING_COMMENT_LOADER:
    'SET_SATISFACTION_RATING_COMMENT_LOADER',
  DISCOUNT_PAGE_ADD_CARD: 'DISCOUNT_PAGE_ADD_CARD',
  DISCOUNT_PAGE_LOADER: 'DISCOUNT_PAGE_LOADER',
  UPDATE_CONFIRMATION_SETTINGS_REDIRECTION:
    'UPDATE_CONFIRMATION_SETTINGS_REDIRECTION',
  UPDATE_CONFIRMATION_SETTINGS_REDIRECTION_SUCCESS:
    'UPDATE_CONFIRMATION_SETTINGS_REDIRECTION_SUCCESS',
  UPDATE_CONFIRMATION_SETTINGS_REDIRECTION_FAILURE:
    'UPDATE_CONFIRMATION_SETTINGS_REDIRECTION_FAILURE',
  CONNECT_SHOPIFY_FROM_APP: 'CONNECT_SHOPIFY_FROM_APP',
  UPDATE_OPEN_CONVERSATION_COUNT: 'UPDATE_OPEN_CONVERSATION_COUNT',
  SET_INBOX_AUDIO_NOTIFICATION: 'SET_INBOX_AUDIO_NOTIFICATION',
  UPDATE_PROPERTY_PREFERENCE: 'UPDATE_PROPERTY_PREFERENCE',
  UPDATE_PROPERTY_PREFERENCE_SUCCESS: 'UPDATE_PROPERTY_PREFERENCE_SUCCESS',
  UPDATE_PRODUCT_DETAIL: 'UPDATE_PRODUCT_DETAIL',
  SET_NOT_AUTHORIZED_MODAL_STATUS: 'SET_NOT_AUTHORIZED_MODAL_STATUS',
  UPDATE_DISPLAY_PREFERENCE: 'UPDATE_DISPLAY_PREFERENCE',
  SET_CURRENT_USER_THEME: 'SET_CURRENT_USER_THEME',
  UPDATE_USER_PERMISSION_DATA: 'UPDATE_USER_PERMISSION_DATA',
  GET_UNSUBSCRIPTION_PERSON_DATA: 'GET_UNSUBSCRIPTION_PERSON_DATA',
  GET_UNSUBSCRIPTION_PERSON_DATA_SUCCESS:
    'GET_UNSUBSCRIPTION_PERSON_DATA_SUCCESS',
  GET_UNSUBSCRIPTION_PERSON_DATA_FAILURE:
    'GET_UNSUBSCRIPTION_PERSON_DATA_FAILURE',
  UPDATE_UNSUBSCRIPTION_PERSON_DATA: 'UPDATE_UNSUBSCRIPTION_PERSON_DATA',
  UPDATE_UNSUBSCRIPTION_PERSON_DATA_SUCCESS:
    'UPDATE_UNSUBSCRIPTION_PERSON_DATA_SUCCESS',
  UPDATE_UNSUBSCRIPTION_PERSON_DATA_FAILURE:
    'UPDATE_UNSUBSCRIPTION_PERSON_DATA_FAILURE',
  UPDATE_UNSUBSCRIPTION_STATUS: 'UPDATE_UNSUBSCRIPTION_STATUS',
  RESUBSCRIBE_EMAIL_PREFERENCES: 'RESUBSCRIBE_EMAIL_PREFERENCES',
  RESUBSCRIBE_EMAIL_PREFERENCES_SUCCESS:
    'RESUBSCRIBE_EMAIL_PREFERENCES_SUCCESS',
  CONNECT_SHOPIFY_FROM_APP_SUCCESS: 'CONNECT_SHOPIFY_FROM_APP_SUCCESS',
  CONNECT_SHOPIFY_FROM_APP_FAILURE: 'CONNECT_SHOPIFY_FROM_APP_FAILURE',
  SHOPIFY_PLAN_DRAWER_STATUS: 'SHOPIFY_PLAN_DRAWER_STATUS',
  FIND_APP_BY_CLIENT_ID: 'FIND_APP_BY_CLIENT_ID',
  FIND_APP_BY_CLIENT_ID_SUCCESS: 'FIND_APP_BY_CLIENT_ID_SUCCESS',
  FIND_APP_BY_CLIENT_ID_FAILURE: 'FIND_APP_BY_CLIENT_ID_FAILURE',
  AUTHORIZE_APP: 'AUTHORIZE_APP',
  HIDE_CONVERSATION_STATUS_MESSAGES: 'HIDE_CONVERSATION_STATUS_MESSAGES',
  HIDE_CONVERSATION_STATUS_MESSAGES_SUCCESS:
    'HIDE_CONVERSATION_STATUS_MESSAGES_SUCCESS',
  SET_AUTHORIZE_APP_PROJECT_SECRET_KEY: 'SET_AUTHORIZE_APP_PROJECT_SECRET_KEY',
  DENY_APP: 'DENY_APP',
  UPDATE_ANALYTICS_TRACK_SCRIPTS: 'UPDATE_ANALYTICS_TRACK_SCRIPTS',
  UPDATE_PRIME_COUPONS: 'UPDATE_PRIME_COUPONS',
  UPDATE_PRIME_PLAN_ID: 'UPDATE_PRIME_PLAN_ID',
  SET_PRIME_WORKSPACE_ENABLED: 'SET_PRIME_WORKSPACE_ENABLED',
  FETCH_ADDON_DETAILS: 'FETCH_ADDON_DETAILS',
  FETCH_ADDON_DETAILS_SUCCESS: 'FETCH_ADDON_DETAILS_SUCCESS',
  UPDATE_ADDON_DETAILS: 'UPDATE_ADDON_DETAILS',
  UPDATE_ADDON_DETAILS_SUCCESS: 'UPDATE_ADDON_DETAILS_SUCCESS',
  ADDON_LOADER: 'ADDON_LOADER',
  UPDATE_GET_STARTED_DATA: 'UPDATE_GET_STARTED_DATA',
  SET_INFO_BANNER_VISIBILITY: 'SET_INFO_BANNER_VISIBILITY',
  SET_CURRENT_TASK_WIDGET_DATA: 'SET_CURRENT_TASK_WIDGET_DATA',
  COMPLETE_ONBOARDING_TASK: 'COMPLETE_ONBOARDING_TASK',
};

export default actions;
