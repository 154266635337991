import React, { Component } from 'react';
import { Button, Card, Form, Icon, Input, Modal, Radio, Tooltip } from 'antd';
import './style.scss';
import { store } from '../../../redux/store';
import actions from '../../../redux/messages/actions';
import broadcastActions from '../../../redux/email/broadcast/actions';
import postActions from '../../../redux/post/actions';
import EmailTemplateContainer from './EmailTemplateContainer';
import { BROADCAST_MAIL_TYPE } from '../../../redux/constants';
import {
  fetchProjectSecretKey,
  fetchRestrictedFeatures, getAppDetail, isRestricted,
} from '../../../helpers/utility';
import {menu, restrictedPage} from '../../TopNavBar/constants';
import { NavLink } from 'react-router-dom';
const { Meta } = Card;

class SelectChannel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      step: 'selectChannel',
      values: { name: null },
    };
  }

  onChange = (e) => {
    this.setState({
      type: e.target.value,
    });
  };

  onCancel = () => {
    store.dispatch({
      type: actions.SET_SELECT_CHANNEL_MODAL_STATUS,
      payload: false,
    });
    this.setState({ step: 'selectChannel' });
  };

  handleSubmit = () => {
    const form = this.props.form;
    form.validateFields((err, values) => {
      if (!err) {
        if (this.state.type === 'email') {
          // One off email
          if (this.state.step === 'selectTemplate') {
            this.createEmail();
          } else {
            this.setState({ step: 'selectTemplate', values: values });
          }
        } else if (this.state.type === 'message') {
          // One off messages
          values.display_conditions = this.getFilterData();
          store.dispatch({
            type: actions.CREATE_MESSAGES_CHAT,
            data: {
              triggered_chat: { ...values, message_type: 'one_off_chat' },
            },
            isFromContact: true,
          });
        } else {
          // One off post
          values.display_conditions = this.getFilterData();
          store.dispatch({
            type: postActions.CREATE_MESSAGES_CHAT_IN_POST,
            data: {
              triggered_chat: {
                ...values,
                message_type: 'one_off_announcement',
              },
            },
            isFromContact: true,
          });
        }
      }
    });
  };

  getFilterData = () => {
    const { selectedSegment } = this.props;
    if (selectedSegment) {
      const isAllUsers =
        String(selectedSegment.name).toLowerCase() === 'all users';
      const isAddFilter =
        (selectedSegment.new_filters &&
          selectedSegment.new_filters.length === 0) ||
        (selectedSegment.new_filters &&
          selectedSegment.new_filters.length === 1 &&
          selectedSegment.new_filters[0].criteria.length === 0);
      if (isAllUsers && isAddFilter) {
        // If all user set email has any value
        return [{ criteria: [{ key: 'email', condition: 'has_any_value' }] }];
      } else {
        return selectedSegment.new_filters;
      }
    }
  };

  createEmail = () => {
    const { values } = this.state,
      { builderType } = this.child.state;
    const object = {
      title: values.name,
      composer_type: builderType,
      mail_type: BROADCAST_MAIL_TYPE,
    };
    if (builderType === 'visual_builder') {
      object.visual_builder_template_name = this.props.selectedTemplateId;
      object.visual_builder_json = this.props.templateJson;
    } else {
      object.custom_template_id = this.props.selectedTemplateId;
    }
    object.new_filters = this.getFilterData();
    store.dispatch({
      type: broadcastActions.CREATE_BROADCAST,
      object,
    });
  };

  getText = () => {
    if (this.state.type === 'email' && this.state.step === 'selectChannel') {
      return 'Continue';
    } else {
      return 'Create';
    }
  };

  onBack = () => {
    this.setState({ step: 'selectChannel' });
  };

  render() {
    const {
        visible,
        createChatLoader,
        createBroadcastLoader,
        createPostLoader,
        // isOneOffOnly,
      } = this.props,
      { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={
          this.state.step === 'selectChannel'
            ? 'Select your channel'
            : 'Choose a template'
        }
        visible={visible}
        centered
        className={`chat-modal ${
          this.state.step === 'selectTemplate' && 'scroll'
        }`}
        onCancel={this.onCancel}
        destroyOnClose={true}
        closable={false}
        width={800}
        footer={[
          <Button
            key={'cancel'}
            className="btn btn-secondary-outline btn-sm"
            onClick={this.onCancel}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            onClick={this.handleSubmit}
            className="btn btn-primary btn-sm"
            disabled={
              createChatLoader ||
              createBroadcastLoader ||
              createPostLoader ||
              this.state.type === null
            }
            loading={
              createChatLoader || createBroadcastLoader || createPostLoader
            }
          >
            {this.getText()}
          </Button>,
        ]}
      >
        <Form>
          {this.state.step === 'selectChannel' ? (
            <>
              <Radio.Group
                className={'radio-card-button grid grid-cols-3 gap-4 mb-4'}
                onChange={this.onChange}
                value={this.state.type}
              >
                <Radio value="email">
                  <Card
                    hoverable
                    cover={<i className="fas fa-envelope-open-text" />}
                  >
                    <Meta
                      title="Email"
                      description="Send a one-off email to the selected contacts"
                    />
                  </Card>
                </Radio>
                {!isRestricted(restrictedPage.conversation) && (
                  <Tooltip
                  title={
                    fetchRestrictedFeatures().includes(
                      menu.chatOneOffMessage,
                    ) ? (
                      <>
                        Please upgrade your plan{' '}
                        <NavLink
                          to={`/projects/${fetchProjectSecretKey()}/settings/subscription`}
                          target="_blank"
                        >
                          here
                        </NavLink>{' '}
                        to access this feature.
                      </>
                    ) : (
                      ''
                    )
                  }
                  placement="top"
                >
                  <Radio
                    value="message"
                    disabled={fetchRestrictedFeatures().includes(
                      menu.chatOneOffMessage,
                    )}
                  >
                    <Card
                      hoverable
                      cover={<i className="fas fa-comment-alt-lines" />}
                    >
                      <Meta
                        title="Chat"
                        description={`Start a conversation in the ${getAppDetail().name} Messenger`}
                      />
                    </Card>
                  </Radio>
                </Tooltip>
                )}
                {!isRestricted(restrictedPage.conversation) && (
                  <Tooltip
                  title={
                    fetchRestrictedFeatures().includes(menu.postOneOff) ? (
                      <>
                        Please upgrade your plan{' '}
                        <NavLink
                          to={`/projects/${fetchProjectSecretKey()}/settings/subscription`}
                          target="_blank"
                        >
                          here
                        </NavLink>{' '}
                        to access this feature.
                      </>
                    ) : (
                      ''
                    )
                  }
                  placement="top"
                >
                  <Radio
                    value="post"
                    disabled={fetchRestrictedFeatures().includes(
                      menu.postOneOff,
                    )}
                  >
                    <Card
                      hoverable
                      cover={<i className="fas fa-address-card" />}
                    >
                      <Meta
                        title="Post"
                        description="Make an announcement to your contacts"
                      />
                    </Card>
                  </Radio>
                </Tooltip>)
                }
              </Radio.Group>

              {/*{!isOneOffOnly && (*/}
              {/*  <p>This is where the ongoing vs one-off switch goes</p>*/}
              {/*)}*/}

              {this.state.type && (
                <Form.Item
                  label={`What should we call your ${this.state.type}?`}
                >
                  {getFieldDecorator('name', {
                    initialValue: this.state.values.name,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please enter the name!',
                      },
                    ],
                  })(
                    <Input
                      ref={(input) => (this.input = input) && input.focus()}
                      className={'form-item input-lg'}
                    />,
                  )}
                </Form.Item>
              )}
            </>
          ) : (
            <>
              <div className={'back'} onClick={this.onBack}>
                <Icon type={'left'} /> Back
              </div>
              <EmailTemplateContainer
                {...this.props}
                ref={(ref) => (this.child = ref)}
              />
            </>
          )}
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(SelectChannel);
