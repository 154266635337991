import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { supportCardData, marketingCardData } from '../Constants';
import StepList from '../StepList/StepList';
import './TaskWidget.scss';
import authActions from '../../../redux/auth/actions';
import Confetti from 'react-confetti';

const TaskWidget = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const currentStep = useSelector(({ Auth }) => Auth.currentStep);
  const currentProduct = useSelector(({ Auth }) => Auth.currentProduct);
  const dispatch = useDispatch();

  const getCurrentProductData = () => {
    const allCardData = [...supportCardData, ...marketingCardData];
    return allCardData.find((card) => card.key === currentProduct);
  };
  const style = { position: 'fixed', top: 0, left: 0, zIndex: 9999 };
  const [confettiStatus, setConfettiStatus] = useState(false);
  const currentProductData = getCurrentProductData();
  const currentSteps = currentProductData ? currentProductData.steps : [];
  const currentProductTitle = currentProductData
    ? currentProductData.title
    : '';

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClose = () => {
    dispatch({
      type: authActions.SET_CURRENT_TASK_WIDGET_DATA,
      payload: {
        step: null,
        product: null,
      },
    });
  };

  if (!currentStep || currentSteps.length === 0) return null;

  return (
    <div className="task-widget">
      <button
        className="task-widget__close-button"
        onClick={handleClose}
        aria-label="Close task widget"
      >
        Close
      </button>
      <div className="task-widget__container">
        <button
          className="task-widget__header"
          onClick={toggleExpand}
          aria-expanded={isExpanded}
          aria-controls="task-widget-content"
        >
          <span>{currentStep.title}</span>
          <i
            className={`fas ${
              isExpanded ? 'fa-chevron-down' : 'fa-chevron-up'
            }`}
          />
        </button>

        <div
          id="task-widget-content"
          className={`task-widget__content ${
            isExpanded ? 'task-widget__content--expanded' : ''
          }`}
        >
          <div className="task-widget__content-container">
            <h3 className="task-widget__title">{currentProductTitle}</h3>
            <StepList
              card={currentProductData}
              selectedStepKey={currentStep.key}
              isFromTaskWidget
              fireConfetti={() => setConfettiStatus(true)}
            />
          </div>
        </div>
      </div>
      {confettiStatus && (
        <Confetti
          recycle={false}
          numberOfPieces={200}
          height={window.innerHeight}
          widht={window.innerWidth}
          gravity={0.4}
          style={style}
          onConfettiComplete={() => setConfettiStatus(false)}
        />
      )}
    </div>
  );
};

export default TaskWidget;
