import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  unAuthorisedRedirection,
} from '../../../helpers/utility';
import settings from '../../../settings';
import actions from './actions';
import { message } from 'antd';
import { getTableValues } from '../../../components/Messages/Shared/Helper';
import { store } from '../../store';
import filterActions from '../../filters/actions';
import { filterVariables } from '../../constants';
import postActions from '../actions';
import proactiveMessageActions from '../../proactiveMessage/actions';
import chatTemplateAction from '../../automation/messageTemplate/actions';
import displayActions from '../../displayRules/actions';

export function* getChatPromptDetails(params) {
  const promptApi =
    params.isFromTemplate === true ? 'message_templates' : 'triggered_chats';
  try {
    yield put({ type: actions.SET_MESSAGE_CHAT_LOADER_IN_POST, payload: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/${promptApi}/${params.id}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_CHAT_PROMPT_DETAILS_SUCCESS_IN_POST,
      payload: params.isFromTemplate
        ? data.data.message_template.triggered_chat
        : data.data.triggered_chat,
    });
    if (params.isFromTemplate) {
      yield put({
        type: chatTemplateAction.UPDATE_DRAWER_MESSAGE_TEMPLATE,
        payload: data.data.message_template,
      });
    }
    yield put({
      type: actions.SET_MESSAGE_CHAT_LOADER_IN_POST,
      payload: false,
    });
    yield put({
      type: postActions.SET_CHAT_NAME_IN_POST,
      payload: params.isFromTemplate
        ? data.data.message_template.triggered_chat.name
        : data.data.triggered_chat.name,
    });
    const typeOfOneOffPost = params.isFromTemplate
      ? data.data.message_template.triggered_chat.message_type ===
        'one_off_announcement'
      : data.data.triggered_chat.message_type === 'one_off_announcement';
    if (typeOfOneOffPost) {
      yield put({
        type: filterActions.SET_FILTERS,
        payload: params.isFromTemplate
          ? data.data.message_template.triggered_chat.display_conditions
          : data.data.triggered_chat.display_conditions,
        filterName: filterVariables.oneOffChatKey,
      });
    } else {
      yield put({
        type: displayActions.SET_DISPLAY_RULES,
        payload: data.data.triggered_chat.display_conditions,
      });
    }
    const messageGoalFilter = params.isFromTemplate
      ? data.data.message_template.triggered_chat.message_goal
      : data.data.triggered_chat.message_goal;
    if (messageGoalFilter) {
      yield put({
        type: filterActions.SET_FILTERS,
        payload: messageGoalFilter.goal_filter,
        filterName: filterVariables.oneOffPostKeyGoal,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SET_MESSAGE_CHAT_LOADER_IN_POST,
      payload: false,
    });
    message.error(`Fetch ${getTableValues().tableState} chats failed`);
  }
}

export function* updateScheduleDetails(params) {
  try {
    let triggeredChat = store.getState().chatOngoingPost.chatPromptDetails;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/triggered_chats/${params.id}`;
    let data = {
      triggered_message: {
        name: triggeredChat.name,
        message: params.messageDetails.message_content,
        display_conditions: triggeredChat.display_conditions,
        triggered_user_id: params.messageDetails.triggered_user_id,
        assign_to: params.messageDetails.assign_to,
        template: params.messageDetails.message_template,
        deadline_details: triggeredChat.deadline_details,
        schedule_details: store.getState().chatOngoingPost.scheduleDetails,
        delivery_time_details: triggeredChat.delivery_time_details,
      },
    };
    if (!store.getState().chatOngoingPost.scheduleDetails) {
      data = {
        ...data,
        triggered_message: {
          ...data.triggered_message,
          new_status:
            triggeredChat.message_type === 'on_going_announcement'
              ? 'live'
              : 'sent',
        },
      };
    }
    const responseData = yield call(() =>
      patchPromise(url, data.triggered_message),
    );
    yield put({
      type: actions.GET_CHAT_PROMPT_DETAILS_SUCCESS_IN_POST,
      payload: responseData.data.triggered_chat,
    });
    yield put({
      type: proactiveMessageActions.CLEAR_PROACTIVE_MESSAGE_DETAILS,
    });
    message.success(responseData.message);
    params.history.push({
      pathname: `/projects/${fetchProjectSecretKey()}/messages/post`,
    });
  } catch (error) {
    yield put({
      type: actions.GET_CHAT_PROMPT_DETAILS_FAILURE_IN_POST,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Chat message update failed');
    }
  }
}

export function* changeMessageChatPosition(params) {
  try {
    yield put({
      type: postActions.SET_TABLE_LISTS_IN_POST,
      payload: params.data,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/triggered_chats/${
      params.id
    }/update_priority`;
    const changePositionResponse = yield call(() =>
      patchPromise(url, params.payload),
    );
    message.success(changePositionResponse.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Message priority update failed');
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CHAT_PROMPT_DETAILS_IN_POST, getChatPromptDetails),
    takeEvery(
      actions.UPDATE_MESSAGE_CHAT_ONGOING_SCHEDULE_DETAILS_IN_POST,
      updateScheduleDetails,
    ),
    takeEvery(
      actions.CHANGE_MESSAGE_CHAT_POSITION_IN_POST,
      changeMessageChatPosition,
    ),
  ]);
}
