import { delay } from 'redux-saga';
import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects';
import {
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  deletePromise,
  postPromise,
  updateBrowserHistory,
  extractCurrentSegmentId,
  extractContactSearchValue,
  extractContactSorterValue,
  exportFilterData,
  unAuthorisedRedirection,
  findIndexOfAllUsersSegment,
  checkCurrentSegment,
  findAllUsersSegmentInList,
  setSelectPropertyImports,
  getContactURL,
  unProcessableEntity,
} from '../../helpers/utility';
import settings from '../../settings/index';
import actions from './actions';
import { message, notification } from 'antd';
import { store } from '../../redux/store';
import { push } from 'connected-react-router';
import _ from 'lodash';
import filterActions from '../filters/actions';
import { filterVariables } from '../constants';
import { filterApplied } from '../../components/Filters/helper';
import chatActions from '../chat/actions';
import { addContactActivity } from '../../components/Contacts/ProfileView/helper';
import { updateOwnerInContactsList } from '../../components/Contacts/helper';

export function* fetchSegments(params) {
  const { segments, selectedSegment } = store.getState().Contacts;
  if (segments.length === 0 || (params && params.loadSegments)) {
    try {
      let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/segments.json?project_secret_key=${fetchProjectSecretKey()}`;
      let data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_SEGMENTS_SUCCESS,
        payload: data,
      });
      if (params.segmentId) {
        yield put({
          type: actions.SET_SEGMENT_FROM_SETTINGS,
          id: params.segmentId,
        });
      }
      if (params.tagsValue) {
        let filters = [
          {
            criteria: [
              { key: 'tags', value: params.tagsValue, condition: 'equals' },
            ],
          },
        ];
        yield put({
          type: actions.SET_SEGMENT_FROM_SETTINGS,
          id: undefined,
        });
        yield put({ type: actions.APPLY_FILTERS, filters });
        yield put({
          type: filterActions.SET_FILTERS,
          payload: filters,
          filterName: filterVariables.contactsKey,
        });
      }
      if (params.eventId) {
        let filters = [
          {
            criteria: [
              {
                event_id: params.eventId,
                key: 'performed_event',
                condition: 'no_less_than',
                value: 1,
                time_window: { condition: 'within', value: 7 },
              },
            ],
          },
        ];
        yield put({
          type: actions.SET_SEGMENT_FROM_SETTINGS,
          id: undefined,
        });
        yield put({ type: actions.APPLY_FILTERS, filters });
        yield put({
          type: filterActions.SET_FILTERS,
          payload: filters,
          filterName: filterVariables.contactsKey,
        });
      }
      if (_.find(data.data, selectedSegment) === undefined) {
        let index = findIndexOfAllUsersSegment(data.data);
        yield put({
          type: actions.SET_SEGMENT_FROM_SETTINGS,
          id: undefined,
        });
        yield put({
          type: actions.FETCH_CONTACTS,
          segmentId: data.data[index].id,
        });
      }
    } catch (error) {
      unAuthorisedRedirection(error);
      message.error('Segments fetch failed');
    }
  }
  if (params && params.payload) {
    const { fetchProperties, fetchContactProfile } = params.payload;
    if (fetchProperties && fetchContactProfile) {
      yield put({
        type: actions.FETCH_PROPERTIES,
        payload: { fetchContactProfile: true, contactID: params.contactID },
      });
    }
  }
}

export function* fetchContacts(params) {
  let filterData = exportFilterData(),
    filters =
      filterData && filterData.property && filterData.property.new_filters;
  let { segments, takeFilterParams } = store.getState().Contacts;
  if(params.payload && params.payload.applyFilter !== undefined){
    yield put({
      type: actions.UPDATE_FILTER_OR_SEGMENT,
      takeFilterParams: params.payload.applyFilter,
    });
    takeFilterParams = params.payload.applyFilter;
  }
  try {
    const searchValue = extractContactSearchValue(),
      { sortOrder, sortKey } = extractContactSorterValue();
    let segmentId =
        params.payload && params.payload.selectedSegmentId
          ? params.payload.selectedSegmentId
          : params.id
            ? params.id
            : filters && filters.length !== 0
              ? ''
              : extractCurrentSegmentId();
    if(takeFilterParams){
      segmentId= '';
    }
    let url = '';
    if (params.tagsValue) {
      filters = [
        {
          criteria: [
            { key: 'tags', value: params.tagsValue, condition: 'equals' },
          ],
        },
      ];
      let stringifyFilters = JSON.stringify(filters);
      if (segments.length !== 0) {
        store.dispatch({
          type: actions.SET_SEGMENT_FROM_SETTINGS,
          id: undefined,
        });
        store.dispatch({ type: actions.APPLY_FILTERS, filters });
        yield put({
          type: filterActions.SET_FILTERS,
          payload: filters,
          filterName: filterVariables.contactsKey,
        });
      }
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/${getContactURL()}?project_secret_key=${fetchProjectSecretKey()}&search_value=${encodeURIComponent(
        searchValue,
      )}&sort_type=${sortOrder}&sort_key=${sortKey}&filter_data=${encodeURIComponent(
        stringifyFilters,
      )}`;
    } else if (params.segmentId && !takeFilterParams) {
      // TODO:Need to check logic
      // filters = [];
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/${getContactURL()}?project_secret_key=${fetchProjectSecretKey()}&search_value=${encodeURIComponent(
        searchValue,
      )}&segment_id=${
        params.segmentId
      }&sort_type=${sortOrder}&sort_key=${sortKey}`;
    } else if (params.eventId) {
      filters = [
        {
          criteria: [
            {
              event_id: params.eventId,
              key: 'performed_event',
              condition: 'no_less_than',
              value: 1,
              time_window: { condition: 'within', value: 7 },
            },
          ],
        },
      ];
      let stringifyFilters = JSON.stringify(filters);
      if (segments.length !== 0) {
        store.dispatch({
          type: actions.SET_SEGMENT_FROM_SETTINGS,
          id: undefined,
        });
        store.dispatch({ type: actions.APPLY_FILTERS, filters });
        yield put({
          type: filterActions.SET_FILTERS,
          payload: filters,
          filterName: filterVariables.contactsKey,
        });
      }
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/${getContactURL()}?project_secret_key=${fetchProjectSecretKey()}&search_value=${encodeURIComponent(
        searchValue,
      )}&sort_type=${sortOrder}&sort_key=${sortKey}&filter_data=${encodeURIComponent(
        stringifyFilters,
      )}`;
    } else {
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/${getContactURL()}?project_secret_key=${fetchProjectSecretKey()}&search_value=${encodeURIComponent(
        searchValue,
      )}&segment_id=${segmentId}&sort_type=${sortOrder}&sort_key=${sortKey}`;
    }
    if (segmentId || params.segmentId) {
      yield put({
        type: filterActions.RESET_CONTACTS_AUDIENCE_FILTERS,
      });
    }
    if (
      filters &&
      filterApplied(filterData) &&
      filters.length !== 0 &&
      !segmentId &&
      !params.tagsValue &&
      !params.eventId
    ) {
      let stringifyFilters = JSON.stringify(filters);
      url = `${url}&filter_data=${encodeURIComponent(stringifyFilters)}`;
    }
    let data = yield call(() => getPromise(url));
    //To reset page count after search,sort functions
    store.dispatch({ type: actions.RESET_PAGE_COUNT });
    if (
      params.segmentId &&
      segments.length !== 0 &&
      !takeFilterParams
    ) {
      yield put({
        type: actions.SET_SEGMENT_FROM_SETTINGS,
        id: params.segmentId,
      });
    }
    if (params.payload && params.payload.applyFilter) {
      yield put({ type: actions.APPLY_FILTERS, filters });
    }
    if (checkCurrentSegment(data)) {
      yield put({
        type: actions.FETCH_CONTACTS_SUCCESS,
        payload: {
          ...data,
          data: updateOwnerInContactsList(data.data),
        },
        searchValue: searchValue,
        propertyPreference: store.getState().Auth.propertyPreference,
      });
      yield put({
        type: actions.SET_SELECTED_SEGMENT_COUNT,
        matchedContactsCount: data.meta_data.matched_people_count,
      });
    }
    if (data.meta_data.segment_processing) {
      //Delay time for display the notification after the API success message is closed.
      if (params.isFromSegment) {
        yield delay(3000);
      }
      notification.warn({
        message: 'Segment is processing.',
        description:
          'We are adding matched contacts into this segment. It will be completed in few mins.',
        placement: 'topRight',
        duration: 6,
        className: 'p-5',
      });
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      message.error(error.response.data.error.message);
    } else {
      unAuthorisedRedirection(error);
      message.error('Contacts fetch failed');
    }
    yield put({ type: actions.DISABLE_CONTACTS_LOADER });
  }
}

export function* appendContacts(params) {
  let filterData = exportFilterData(),
    filters =
      filterData && filterData.property && filterData.property.new_filters;
  let { takeFilterParams } = store.getState().Contacts;
  try {
    const { payload } = params,
      { sortOrder, sortKey } = extractContactSorterValue();
    let lastContactId = '';
    let segmentId, url;
    if (payload.data && payload.data.segmentId) {
      segmentId = payload.data.segmentId;
    } else {
      segmentId =
        params.payload && params.payload.selectedSegmentId
          ? params.payload.selectedSegmentId
          : filters && filters.length !== 0
          ? ''
          : extractCurrentSegmentId();
    }
    if(takeFilterParams){
      segmentId='';
    }
    let searchValue = extractContactSearchValue();
    if (payload && payload.data.lastContactId) {
      lastContactId = payload.data.lastContactId;
    }
    if (payload.data && payload.data.tagsValue) {
      filters = [
        {
          criteria: [
            { key: 'tags', value: payload.data.tagsValue, condition: 'equals' },
          ],
        },
      ];
      let stringifyFilters = JSON.stringify(filters);
      yield put({ type: actions.APPLY_FILTERS, filters });
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/${getContactURL()}?project_secret_key=${fetchProjectSecretKey()}}&last_person_id=${lastContactId}&search_value=${encodeURIComponent(
        searchValue,
      )}&sort_type=${sortOrder}&sort_key=${sortKey}&filter_data=${encodeURIComponent(
        stringifyFilters,
      )}&page=${payload.data.pageCount}`;
    } else {
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/${getContactURL()}?project_secret_key=${fetchProjectSecretKey()}&last_person_id=${lastContactId}&segment_id=${findAllUsersSegmentInList(
        segmentId,
      )}&search_value=${encodeURIComponent(
        searchValue,
      )}&sort_type=${sortOrder}&sort_key=${sortKey}&page=${
        payload.data.pageCount
      }`;
    }
    if (
      filters &&
      filterApplied(filterData) &&
      filters.length !== 0 &&
      !params.tagsValue
    ) {
      let stringifyFilters = JSON.stringify(filters);
      url = `${url}&filter_data=${encodeURIComponent(stringifyFilters)}`;
    }
    let data = yield call(() => getPromise(url));
    if (checkCurrentSegment(data)) {
      yield put({
        type: actions.APPEND_CONTACTS_SUCCESS,
        payload: {
          ...data,
          data: updateOwnerInContactsList(data.data),
        },
        propertyPreference: store.getState().Auth.propertyPreference,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Contacts fetch failed');
  }
}

export function* fetchProperties(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/properties_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_PROPERTIES_SUCCESS,
      payload: data,
      propertyPreference: store.getState().Auth.propertyPreference,
    });
    if (params && params.payload) {
      const { fetchContactProfile } = params.payload;
      if (fetchContactProfile) {
        yield put({
          type: actions.FETCH_CONTACT_PROFILE,
          contactID: params.contactID,
        });
      }
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Properties fetch failed');
  }
}

export function* fetchContactProfile(params) {
  try {
    let contactID = null;
    if (params.contactID) {
      contactID = params.contactID;
    } else {
      contactID = store.getState().Contacts.personID;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/people/${contactID}/person_details`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CONTACT_PROFILE_SUCCESS,
      payload: data.data,
      userId: data.data.profile.person_id,
      property: 'tags',
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Profile fetch failed');
  }
}

export function* updateContactProfileProperties(params) {
  try {
    let contactID = store.getState().Contacts.personID,
      userList = store.getState().Settings.userList,
      ownerData,
      payload,
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/people/${contactID}/update_person.json`;
    let data = yield call(() => patchPromise(url, params.object));
    if (params.object.person.contact_owner) {
      ownerData = yield userList.find(
        (user) => user.id.toString() === params.object.person.contact_owner,
      );
    }
    payload = Object.keys(params.object.person).includes('contact_owner')
      ? {
          ...params.object,
          owner: ownerData || null,
        }
      : params.object;
    yield put({
      type: actions.UPDATE_CONTACT_PROFILE_SUCCESS,
      payload,
      profile: data.data.profile ? data.data.profile : '',
    });
    if (params.isFromUserProfile) {
      yield put({
        type: chatActions.UPDATE_SELECTED_CHAT_ROOM_CONTACT_OWNER,
        payload,
      });
    }
    message.success('Contact updated successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error(error.response.data.errors);
  }
}

export function* deleteContactProfile(params) {
  try {
    let contactID = store.getState().Contacts.personID,
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/people.json`,
      contactIds = {
        person_ids: params.personIds ? params.personIds : [parseInt(contactID)],
      };
    yield call(() => deletePromise(url, contactIds));
    updateBrowserHistory('contacts');
    yield put({
      type: actions.FETCH_SEGMENTS,
      loadSegments: true,
    });
    yield put({
      type: actions.DELETE_CONTACT_PROFILE_SUCCESS,
      payload: contactIds,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Profile delete failed');
  }
}

export function* unsubscribeContactProfile(params) {
  const { payload, isFromConversation, contactPersonID } = params;
  try {
    let contactID = store.getState().Contacts.personID,
      action = actions.UNSUBSCRIBE_CONTACT_PROFILE_SUCCESS;
    if (contactPersonID) {
      contactID = contactPersonID;
    }
    if (isFromConversation) {
      action = chatActions.UNSUBSCRIBE_CURRENT_PERSON_SUCCESS;
    }
    let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/subscription_status/${contactID}.json`,
      subscribeDetails = {
        status: payload.data,
      };
    yield call(() => patchPromise(url, subscribeDetails));
    // Converts the unsubscribe check to a string for payload consistency with the backend
    yield put({
      type: action,
      payload: String(payload.data === 'unsubscribe'),
    });
    message.success(`Contact ${payload.data} succeeded`);
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error(`Contact ${payload.data} failed`);
  }
}

export function* editContactProperties(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/people.json`;
    yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.EDIT_CONTACT_PROPERTIES_SUCCESS,
      payload: params.payload,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Update contact(s) failed');
  }
}

export function* createContact(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/people.json`;
    yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.FETCH_CONTACTS,
    });
    yield put({
      type: actions.CREATE_CONTACT_SUCCESS,
    });
    if (params.emptyPage) {
      yield put({
        type: actions.FETCH_SEGMENTS,
        loadSegments: true,
      });
    }
    if (params.onModalClose) {
      params.onModalClose();
    }
    message.success('Contact created successfully');
    store.dispatch({ type: actions.CONTACT_CREATE_MODAL, payload: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CREATE_CONTACT_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors.join());
    } else {
      message.error('Contact creation failed');
    }
  }
}

export function* fetchContactActivities(params) {
  try {
    let lastActivityId = '',
      activityTypes = '';
    if (params.payload && params.payload.lastActivityId) {
      lastActivityId = params.payload.lastActivityId;
    } else {
      yield put({ type: actions.CLEAR_CONTACT_ACTIVITIES });
    }
    if (params.payload && params.payload.activityTypes) {
      activityTypes = params.payload.activityTypes.map((activityType) =>
        activityType.toUpperCase().replace(' ', '_'),
      );
    }
    let contactID = null;
    if (params.contactID) {
      contactID = params.contactID;
    } else {
      contactID = store.getState().Contacts.personID;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/people/${contactID}/activities.json?last_activity_id=${lastActivityId}&activity_types=${activityTypes}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CONTACT_ACTIVITIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Activities fetch failed');
  }
}

export function* fetchTags() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tags.json`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_TAGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Tags fetch failed');
  }
}

export function* applyTag(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/people_tags.json`;
    yield call(() => patchPromise(url, params.payload.data));
    yield put({
      type: actions.APPLY_TAG_SUCCESS,
      payload: params.payload,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Apply tag failed');
  }
}

export function* createSegment(params) {
  let segmentContent = {
    segment: {
      ...params.payload,
      new_filters: store.getState().Filter.contactsAudienceFilters.new_filters,
    },
  };
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/segments`;
    let data = yield call(() => postPromise(url, segmentContent));
    yield put({
      type: actions.CREATE_SEGMENT_SUCCESS,
      payload: data,
    });
    yield put({
      type: filterActions.SET_FILTERS,
      payload: [],
      filterName: filterVariables.contactsKey,
    });
    yield put({
      type: actions.SET_SELECT_SEGMENT,
      payload: data.data,
      isFromSegment: true,
    });
    yield put({
      type: actions.FETCH_CONTACTS,
      payload: { selectedSegmentId: data.data.id },
      isFromSegment: true,
    });
    if (data.message) {
      message.success(data.message);
    }
  } catch (error) {
    yield put({
      type: actions.CREATE_SEGMENT_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors[0]);
    } else {
      message.error('Segment creation failed');
    }
  }
}

export function* updateSegment(params) {
  let segmentContent = {
    segment: {
      ...params.payload,
      new_filters: store.getState().Filter.contactsAudienceFilters.new_filters,
    },
  };
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/segments/${params.payload.id}`;
    let data = yield call(() => patchPromise(url, segmentContent));
    yield put({
      type: actions.UPDATE_SEGMENT_SUCCESS,
      payload: data,
    });
    yield put({ type: actions.SET_SEGMENT_SELECTION, payload: data.data });
    yield put({
      type: actions.SET_SELECT_SEGMENT,
      payload: data.data,
      isFromSegment: true,
    });
    yield put({
      type: actions.FETCH_CONTACTS,
      payload: { selectedSegmentId: data.data.id },
      isFromSegment: true,
    });
    if (data.message) {
      message.success(data.message);
    }
  } catch (error) {
    yield put({
      type: actions.UPDATE_SEGMENT_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors[0]);
    } else {
      message.error('Segment update failed');
    }
  }
}

export function* validateCSV(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/imports/validate`;
    let data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.VALIDATE_IMPORTED_CSV_SUCCESS,
      payload: data,
    });
    yield put({
      type: actions.SET_IMPORT_CSV_CURRENT_STEP_KEY,
      payload: 1,
    });
  } catch (error) {
    yield put({
      type: actions.VALIDATE_IMPORTED_CSV_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('CSV validation failed');
    }
  }
}

export function* importCSV(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/imports`;
    yield call(() => postPromise(url, params.payload));
    yield put({ type: actions.IMPORT_CSV_SUCCESS });
    yield put(push(`/projects/${fetchProjectSecretKey()}/progress`));
  } catch (error) {
    yield put({
      type: actions.IMPORT_CSV_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('CSV upload failed');
    }
  }
}

export function* createContactProperty(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_properties`;
    let data = yield call(() => postPromise(url, params.data));
    message.success(data.message);
    yield put({
      type: actions.CREATE_CONTACT_PROPERTIES_FOR_CSV_SUCCESS,
      payload: data,
    });
    yield put({
      type: actions.CREATE_PROPERTY_DRAWER_VISIBLE,
      payload: false,
    });
    const { name, type } = data.data.contact_property,
      { index, header } = params.newPropertyDetails,
      constructedOptions = { props: { id: type } };
    setSelectPropertyImports(name, constructedOptions, header, index);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('create contact properties failed');
    }
  }
}

export function* exportContacts(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/exports`;
    let data = yield call(() => postPromise(url, params.payload));
    message.success(data.message, 5);
    yield put({
      type: actions.EXPORT_CONTACTS_SUCCESS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.EXPORT_CONTACTS_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Export contacts failed');
    }
  }
}

export function* fetchTeamMatesList() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/teams-users-list?include_current_user=true&sort_by=email`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_TEAM_MATES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch assignee failed');
  }
}

export function* fetchContactNotes(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/people/${
      params.personID
    }/people_notes`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CONTACT_NOTES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_CONTACT_NOTES_FAILURE });
    unAuthorisedRedirection(error);
    message.error('Fetch notes failed');
  }
}

export function* createContactNotes(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/people/${
      params.personID
    }/people_notes`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_CONTACT_NOTES_SUCCESS,
      payload: data.data,
      params,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Create note failed');
  }
}

export function* editContactNotes(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/people/${
      params.personID
    }/people_notes/${params.noteID}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.EDIT_CONTACT_NOTES_SUCCESS,
      payload: data.data.people_note,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Update note failed');
  }
}

export function* deleteContactNotes(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/people/${
      params.personID
    }/people_notes/${params.noteID}`;
    const data = yield call(() => deletePromise(url));
    yield put({
      type: actions.DELETE_CONTACT_NOTES_SUCCESS,
      payload: data.data,
      params,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Delete note failed');
  }
}

export function* fbCustomAudience(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/fb_audience_bulk_action`;
    const data = yield call(() => patchPromise(url, params.object));
    yield put({
      type: actions.FB_CUSTOM_AUDIENCE_SUCCESS,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FB_CUSTOM_AUDIENCE_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export function* fetchWooCommerceProducts(params) {
  const { type, id, store_id, store_type } = params.payload;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/people/ecommerce/product_details?${type}=${id}&store_id=${store_id}${
      store_type ? `&store_type=${store_type}` : ''
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WOOCOMMERCE_PRODUCTS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_WOOCOMMERCE_PRODUCTS_DETAILS_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}
export function* fetchContactId(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/contact?email=${encodeURIComponent(
      params.mailId,
    )}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.SET_PERSON_ID,
      payload: data.data.contact_id,
    });
    yield put({
      type: actions.FETCH_CONTACT_ACTIVITIES,
      contactID: data.data.contact_id,
    });
    yield put({
      type: actions.FETCH_SEGMENTS,
      payload: {
        fetchProperties: true,
        fetchContactProfile: true,
        contactID: data.data.contact_id,
      },
    });
    yield put({ type: actions.ACTIVATE_NOTES_LOADER });
    yield put({
      type: actions.FETCH_CONTACT_NOTES,
      personID: data.data.contact_id,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error(error.response.data.errors);
  }
}

export function* getMeetingList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/meetings_list?contact_id=${
      params.personID
    }`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_CONTACT_MEETINGS_SUCCESS,
      payload: data.data.scheduled_meetings,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch meetings failed');
    }
    yield put({ type: actions.GET_CONTACT_MEETINGS_FAILURE });
  }
}

export function* addContactMeeting(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/scheduled_meetings`;
    let data = yield call(() => postPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.ADD_CONTACT_MEETING_SUCCESS,
      payload: data.data.scheduled_meeting,
    });
    addContactActivity(data.data.scheduled_meeting.activities, 'Meetings');
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Add meeting failed');
    }
    yield put({ type: actions.ADD_CONTACT_MEETING_FAILURE });
  }
}

export function* removeContactMeeting(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/scheduled_meetings/${
      params.meetingID
    }/cancel_scheduled_meeting`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.CANCEL_CONTACT_MEETING_SUCCESS,
      meetingID: params.meetingID,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Cancel meeting failed');
    }
    yield put({ type: actions.CANCEL_CONTACT_MEETING_FAILURE });
  }
}

function* fetchEmailList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/crm_emails?contact_id=${
      params.personID
    }`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_CONTACT_EMAIL_LIST_SUCCESS,
      payload: data.data.crm_emails,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Email list fetch failed');
  }
}

function* updateContactCompany(params) {
  try {
    let url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/people/${
      params.personID
    }/update_company`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success('Company updated successfully');
    yield put({
      type: actions.UPDATE_CONTACT_COMPANY_SUCCESS,
      payload: data.data.company,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Company updating failed');
    }
    yield put({
      type: actions.UPDATE_CONTACT_COMPANY_FAILURE,
    });
  }
}

function* createContactEmail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/crm_emails`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_CONTACT_EMAIL_SUCCESS,
      payload: data.data.crm_email,
    });
    addContactActivity(data.data.crm_email.activities, 'Emails');
    message.success(data.message);
  } catch (error) {
    yield put({
      type: actions.CREATE_CONTACT_EMAIL_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Email send failed');
    }
  }
}

function* updateContactOwner(params) {
  try {
    let url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/people/${
      params.personID
    }/update_owner`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.UPDATE_CONTACT_OWNER_SUCCESS,
      payload: data.data.owner,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Owner updating failed');
    }
    yield put({
      type: actions.UPDATE_CONTACT_OWNER_FAILURE,
    });
  }
}

function* addFavouriteSegment(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/favourite_segments/${
      params.payload.segmentId
    }`;
    let data = yield call(() => patchPromise(url));
    message.success(data.message);

    yield put({
      type: actions.ADD_FAVOURITE_SEGMENT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Add favourite failed');
    }
  }
}

function* removeFavouriteSegment(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/favourite_segments/${
      params.payload.segmentId
    }`;
    let data = yield call(() => deletePromise(url));
    message.success(data.message);

    yield put({
      type: actions.REMOVE_FAVOURITE_SEGMENT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Add favourite failed');
    }
  }
}

export function* deleteContactSegment(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_segments/${
      params.payload.segmentId
    }`;
    let data = yield call(() => deletePromise(url));
    message.success(data.message);

    yield put({
      type: actions.FETCH_SEGMENTS,
      loadSegments: true,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Delete contact segments failed');
    }
  }
}

export function* renameContactSegment(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_segments/${
      params.segmentId
    }`;
    let payload = yield call(() => patchPromise(url, params.data));
    message.success(payload.message);

    yield put({
      type: actions.FETCH_SEGMENTS,
      loadSegments: true,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Delete contact segments failed');
    }
  }
}

export function* getPersonMailSubscription(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_subscriptions?person_id=${
      params.personID
    }`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_PERSON_MAIL_SUBSCRIPTION_SUCCESS,
      payload: payload.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.ACTIVE_MAIL_SUBSCRIPTIONS_LOADER,
      payload: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch person subscription types failed');
    }
  }
}

export function* updatePersonMailSubscription(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_subscription_person`;
    let payload = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_PERSON_MAIL_SUBSCRIPTION_SUCCESS,
      payload: params.payload.mail_subscription_person,
    });
    yield put({
      type: actions.GET_PERSON_MAIL_SUBSCRIPTION,
      personID: params.payload.mail_subscription_person.person_id,
    });
    message.success(payload.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Update person subscription type failed');
    }
  }
}

export function* fetchContactDeals(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/associated_deals?${
      params.isFromCompany ? 'company_id' : 'contact_id'
    }=${params.contactID}`;
    let response = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CONTACT_DEALS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_CONTACT_DEALS_SUCCESS,
    });
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Deals fetch failed');
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_SEGMENTS, fetchSegments),
    takeLatest(actions.FETCH_CONTACTS, fetchContacts),
    takeEvery(actions.APPEND_CONTACTS, appendContacts),
    takeEvery(actions.FETCH_PROPERTIES, fetchProperties),
    takeEvery(actions.FETCH_CONTACT_PROFILE, fetchContactProfile),
    takeEvery(
      actions.UPDATE_CONTACT_PROFILE_PROPERTIES,
      updateContactProfileProperties,
    ),
    takeEvery(actions.DELETE_CONTACT_PROFILE, deleteContactProfile),
    takeEvery(actions.UNSUBSCRIBE_CONTACT_PROFILE, unsubscribeContactProfile),
    takeEvery(actions.EDIT_CONTACT_PROPERTIES, editContactProperties),
    takeEvery(actions.CREATE_CONTACT, createContact),
    takeEvery(actions.FETCH_CONTACT_ACTIVITIES, fetchContactActivities),
    takeEvery(actions.FETCH_TAGS, fetchTags),
    takeEvery(actions.APPLY_TAG, applyTag),
    takeEvery(actions.CREATE_SEGMENT, createSegment),
    takeEvery(actions.UPDATE_SEGMENT, updateSegment),
    takeEvery(actions.VALIDATE_IMPORTED_CSV, validateCSV),
    takeEvery(actions.IMPORT_CSV, importCSV),
    takeEvery(actions.CREATE_CONTACT_PROPERTIES_FOR_CSV, createContactProperty),
    takeEvery(actions.EXPORT_CONTACTS, exportContacts),
    takeEvery(actions.FETCH_TEAM_MATES, fetchTeamMatesList),
    takeEvery(actions.FETCH_CONTACT_NOTES, fetchContactNotes),
    takeEvery(actions.CREATE_CONTACT_NOTES, createContactNotes),
    takeEvery(actions.EDIT_CONTACT_NOTES, editContactNotes),
    takeEvery(actions.DELETE_CONTACT_NOTES, deleteContactNotes),
    takeEvery(actions.FB_CUSTOM_AUDIENCE, fbCustomAudience),
    takeEvery(
      actions.FETCH_WOOCOMMERCE_PRODUCTS_DETAILS,
      fetchWooCommerceProducts,
    ),
    takeEvery(actions.FETCH_CONTACT_ID, fetchContactId),
    takeEvery(actions.GET_CONTACT_MEETINGS, getMeetingList),
    takeEvery(actions.ADD_CONTACT_MEETING, addContactMeeting),
    takeEvery(actions.CANCEL_CONTACT_MEETING, removeContactMeeting),
    takeEvery(actions.GET_CONTACT_EMAIL_LIST, fetchEmailList),
    takeEvery(actions.CREATE_CONTACT_EMAIL, createContactEmail),
    takeEvery(actions.UPDATE_CONTACT_COMPANY, updateContactCompany),
    takeEvery(actions.UPDATE_CONTACT_OWNER, updateContactOwner),
    takeEvery(actions.ADD_FAVOURITE_SEGMENT, addFavouriteSegment),
    takeEvery(actions.REMOVE_FAVOURITE_SEGMENT, removeFavouriteSegment),
    takeEvery(actions.DELETE_SINGLE_CONTACT_SEGMENT, deleteContactSegment),
    takeEvery(actions.RENAME_SINGLE_CONTACT_SEGMENT, renameContactSegment),
    takeEvery(actions.GET_PERSON_MAIL_SUBSCRIPTION, getPersonMailSubscription),
    takeEvery(
      actions.UPDATE_PERSON_MAIL_SUBSCRIPTION,
      updatePersonMailSubscription,
    ),
    takeEvery(actions.FETCH_CONTACT_DEALS, fetchContactDeals),
  ]);
}
