import { all, put, takeEvery, call } from 'redux-saga/effects';
import actions from './actions';
import {
  unAuthorisedRedirection,
  fetchProjectSecretKey,
  getPromise,
  postPromise,
  patchPromise,
  deletePromise,
  updateBrowserHistory,
} from '../../helpers/utility';
import { message } from 'antd';
import settings from '../../settings';

export function* fetchNewsCenters(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_NEWS_CENTERS_SUCCESS,
      payload: data.news_centers,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch list failed');
    yield put({ type: actions.FETCH_NEWS_CENTERS_FAILURE });
  }
}

export function* createNewsCenter(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_NEWS_CENTER_SUCCESS,
      payload: data.news_center,
    });
    message.success('News center created successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Create news center failed');
    yield put({ type: actions.CREATE_NEWS_CENTER_FAILURE });
  }
  if (params.callback) {
    params.callback();
  }
}

export function* fetchNewsCenter(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers/${params.payload}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_NEWS_CENTER_SUCCESS,
      payload: data.news_center,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch news center failed');
  }
}

export function* updateNewsCenter(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers/${params.id}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_NEWS_CENTER_SUCCESS,
      payload: data.news_center,
    });
    yield put({
      type: actions.SET_CONNECT_DOMAIN_TAB_KEY,
      payload: 1,
    });
    message.success('News center updated successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update news center failed');
    }
    yield put({ type: actions.UPDATE_NEWS_CENTER_FAILURE });
  }
  if (params.callback) {
    params.callback();
  }
}

export function* deleteNewsCenter(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers/${params.payload}`;
    yield call(() => deletePromise(url));
    yield put({
      type: actions.DELETE_NEWS_CENTER_SUCCESS,
      payload: params.payload,
    });
    message.success('News center deleted successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Delete news center failed');
    }
  }
}

export function* CustomDomainUpdate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers/${
      params.newscenter_id
    }/validate_custom_domain`;
    let data = yield call(() => getPromise(url));
    yield put({ type: actions.CUSTOM_DOMAIN_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      yield put({
        type: actions.CUSTOM_DOMAIN_UPDATE_ERROR_MESSAGE,
        payload: error.response.data.errors,
      });
    } else if (error.response && error.response.status !== 403) {
      message.error('Update custom domain failed');
    }
  }
}

export function* fetchNewsCenterLanguages(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers/${
      params.payload.newscenter_id
    }/supported_languages`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_NEWS_CENTER_LANGUAGES_SUCCESS,
      payload: data.data.supported_languages,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch news center languages failed');
  }
}

export function* fetchNewsItems(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers/${
      params.payload.newscenter_id
    }/news_items`;

    const queryParams = [];
    if (params.payload.status) {
      queryParams.push(`status=${params.payload.status}`);
    }
    if (params.payload.label_id) {
      queryParams.push(`label_id=${params.payload.label_id}`);
    }
    if (params.payload.author_id) {
      queryParams.push(`author_id=${params.payload.author_id}`);
    }
    if (params.payload.search_text) {
      queryParams.push(`search_text=${params.payload.search_text}`);
    }
    if (params.payload.last_item_id) {
      queryParams.push(`last_item_id=${params.payload.last_item_id}`);
    }
    if (params.payload.sort_key && params.payload.sort_type) {
      queryParams.push(`sort_key=${params.payload.sort_key}`);
      queryParams.push(`sort_type=${params.payload.sort_type}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_NEWS_ITEMS_SUCCESS,
      payload: data.news_items,
    });
    yield put({
      type: actions.FETCH_NEWS_ITEMS_STATUS_COUNTS_SUCCESS,
      payload: data.news_items_count,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch list failed');
    yield put({ type: actions.FETCH_NEWS_ITEMS_FAILURE });
  }
}

export function* AppendNewsItems(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers/${
      params.payload.newscenter_id
    }/news_items`;

    const queryParams = [];
    if (params.payload.status) {
      queryParams.push(`status=${params.payload.status}`);
    }
    if (params.payload.label_id) {
      queryParams.push(`label_id=${params.payload.label_id}`);
    }
    if (params.payload.author_id) {
      queryParams.push(`author_id=${params.payload.author_id}`);
    }
    if (params.payload.search_text) {
      queryParams.push(`search_text=${params.payload.search_text}`);
    }
    if (params.payload.last_item_id) {
      queryParams.push(`last_item_id=${params.payload.last_item_id}`);
    }
    if (params.payload.sort_key && params.payload.sort_type) {
      queryParams.push(`sort_key=${params.payload.sort_key}`);
      queryParams.push(`sort_type=${params.payload.sort_type}`);
    }
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_APPEND_NEWS_ITEMS_SUCCESS,
      payload: data.news_items,
    });
    yield put({
      type: actions.FETCH_NEWS_ITEMS_STATUS_COUNTS_SUCCESS,
      payload: data.news_items_count,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch list failed');
    yield put({ type: actions.FETCH_NEWS_ITEMS_FAILURE });
  }
}

export function* fetchNewsItem(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_items/${
      params.payload.newsitem_id
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_NEWS_ITEM_SUCCESS,
      payload: data.news_item,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch news item failed');
  }
}

export function* createNewsItem(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers/${
      params.newscenter_id
    }/news_items`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_NEWS_ITEM_SUCCESS,
      payload: data.news_item,
    });
    message.success('News item created successfully');
    updateBrowserHistory(`news-items/${data.news_item.id}`);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Create news item failed');
    }
    yield put({ type: actions.CREATE_NEWS_ITEM_FAILURE });
  }
}

export function* updateNewsItem(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_items/${params.newsitem_id}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_NEWS_ITEM_SUCCESS,
      payload: data.news_item,
    });
    message.success('News item updated successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update news item failed');
    }
    yield put({ type: actions.UPDATE_NEWS_ITEM_FAILURE });
  }
}

export function* deleteNewsItem(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_items/${params.newsitem_id}`;
    yield call(() => deletePromise(url));
    yield put({
      type: actions.DELETE_NEWS_ITEM_SUCCESS,
      payload: params.newsitem_id,
    });
    message.success('news item deleted successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Delete news item failed');
    yield put({ type: actions.DELETE_NEWS_ITEM_FAILURE });
  }
}

export function* duplicateNewsItem(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_items/${
      params.newsitem_id
    }/duplicate`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.DUPLICATE_NEWS_ITEM_SUCCESS,
      payload: data.news_item,
    });
    updateBrowserHistory(`news-items/${data.news_item.id}`);
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Duplicate news item failed');
    yield put({ type: actions.DUPLICATE_NEWS_ITEM_FAILURE });
  }
}

export function* fetchNewsCenterLabels(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers/${
      params.payload.newscenter_id
    }/labels`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_NEWS_CENTER_LABELS_SUCCESS,
      payload: data.labels,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch news center labels failed');
  }
}

export function* createNewsCenterLabel(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers/${
      params.newscenter_id
    }/labels`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_NEWS_CENTER_LABEL_SUCCESS,
      payload: data.label,
    });
    message.success('Label created successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    const errorData = error.response.data;
    const keys = Object.keys(errorData);
    const errorMessages = keys
      .map((key) => `${key} ${errorData[key].join(', ')}`)
      .join('; ');
    message.error(errorMessages);
    yield put({ type: actions.SAVE_NEWS_CENTER_LABEL_FAILURE });
  }
  if (params.callback) {
    params.callback();
  }
}

export function* updateNewsCenterLabel(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers/${
      params.newscenter_id
    }/labels/${params.label_id}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_NEWS_CENTER_LABEL_SUCCESS,
      payload: data.label,
    });
    message.success('Label updated successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Update news center label failed');
    yield put({ type: actions.SAVE_NEWS_CENTER_LABEL_FAILURE });
  }
  if (params.callback) {
    params.callback();
  }
}

export function* deleteNewsCenterLabel(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers/${
      params.newscenter_id
    }/labels/${params.label_id}`;
    yield call(() => deletePromise(url));
    yield put({
      type: actions.DELETE_NEWS_CENTER_LABEL_SUCCESS,
      payload: params.label_id,
    });
    message.success('Label deleted successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Delete news center label failed');
  }
}

export function* updateNewsCenterLabelsPosition(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers/${
      params.newscenter_id
    }/update_labels_order`;
    yield call(() => patchPromise(url, params.payload));
  } catch (error) {
    unAuthorisedRedirection(error);
    // RESET LABELS POSITION IF ERROR
    yield put({
      type: actions.FETCH_NEWS_CENTER_LABELS_SUCCESS,
      payload: params.old_labels,
    });
    message.error('Update news center labels position failed');
  }
}

export function* fetchAuthors() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/users_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_AUTHORS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch authors failed');
    }
  }
}

export function* importNewsItems(params) {
  try {
    const { newscenter_id, payload } = params;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_centers/${newscenter_id}/news_item_migrations`;
    let data = yield call(() => postPromise(url, payload));
    message.success(data.data);
    yield put({
      type: actions.SET_IMPORT_NEWS_ITEMS_LOADER,
      payload: false,
    });
    yield put({
      type: actions.TOGGLE_IMPORT_MODAL_VISIBILITY,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: actions.SET_IMPORT_NEWS_ITEMS_LOADER,
      payload: false,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response?.data?.errors);
    } else {
      message.error('Import news items failed');
    }
  }
}

export default function* rootSaga() {
  yield all([
    // News Center
    takeEvery(actions.FETCH_NEWS_CENTERS, fetchNewsCenters),
    takeEvery(actions.CREATE_NEWS_CENTER, createNewsCenter),
    takeEvery(actions.FETCH_NEWS_CENTER, fetchNewsCenter),
    takeEvery(actions.UPDATE_NEWS_CENTER, updateNewsCenter),
    takeEvery(actions.DELETE_NEWS_CENTER, deleteNewsCenter),
    takeEvery(actions.FETCH_NEWS_CENTER_LANGUAGES, fetchNewsCenterLanguages),
    takeEvery(actions.CUSTOM_DOMAIN_UPDATE, CustomDomainUpdate),

    // News Items
    takeEvery(actions.FETCH_NEWS_ITEMS, fetchNewsItems),
    takeEvery(actions.APPEND_NEWS_ITEMS, AppendNewsItems),
    takeEvery(actions.FETCH_NEWS_ITEM, fetchNewsItem),
    takeEvery(actions.CREATE_NEWS_ITEM, createNewsItem),
    takeEvery(actions.UPDATE_NEWS_ITEM, updateNewsItem),
    takeEvery(actions.DELETE_NEWS_ITEM, deleteNewsItem),
    takeEvery(actions.DUPLICATE_NEWS_ITEM, duplicateNewsItem),

    // News Center Labels
    takeEvery(actions.FETCH_NEWS_CENTER_LABELS, fetchNewsCenterLabels),
    takeEvery(actions.CREATE_NEWS_CENTER_LABEL, createNewsCenterLabel),
    takeEvery(actions.UPDATE_NEWS_CENTER_LABEL, updateNewsCenterLabel),
    takeEvery(actions.DELETE_NEWS_CENTER_LABEL, deleteNewsCenterLabel),
    takeEvery(
      actions.UPDATE_NEWSCENTER_LABELS_POSITION,
      updateNewsCenterLabelsPosition,
    ),
    takeEvery(actions.FETCH_AUTHORS, fetchAuthors),

    // Import News Items
    takeEvery(actions.IMPORT_NEWS_ITEMS, importNewsItems),
  ]);
}
