import axios from 'axios';
import settings from '../settings';
import { history, store } from '../redux/store';
import actions from '../redux/contacts/actions';
import React from 'react';
import _, {
  cloneDeep,
  capitalize,
  find,
  map,
  isEmpty,
  orderBy,
  lowerCase,
  uniqBy,
  findLast,
} from 'lodash';
import {
  dateFormat,
  emailSendingHours,
  extraSpaceRegex,
  generalTimeInterval,
  emailComposerSavingInterval,
} from '../components/Email/constants';
import * as moment from 'moment';
import { emojify } from 'react-emojione';
import chatActions from '../redux/chat/actions';
import workflowActions from '../redux/automation/workflow/actions';
import campaignActions from '../redux/email/campaign/actions';
import { message, Select, Modal } from 'antd';
import {
  discountPlans,
  gistPlans,
  gistShopifyPlans,
  OnBoardingUserState,
  serverErrorCodes,
  unAuthorizedErrorCode,
  whiteLabelPlans,
} from '../redux/constants';
import authActions from '../redux/auth/actions';
import meetingAction from '../redux/meetings/actions';
import {
  restrictedModule,
  subscriptionAddons,
  supportPermission,
  userRestriction,
} from '../components/TopNavBar/constants';
import {
  getTeamInboxCount,
  getTeammateInboxCount,
} from '../components/Chat/Filters/helper';
import settingsActions from '../redux/settings/actions';
import momentTimeZone from 'moment-timezone';
import { convert } from 'html-to-text';
import linkifyHtml from 'linkifyjs/html';
import { propertyLists, invalidContent } from '../components/constants';
import {
  stableReportsDate,
  momentDateFormat,
} from '../components/Reports/constants';
import {
  agentMessageTypes,
  botStatuses,
  nonBotStatuses,
  satisfactionRatings,
} from '../components/Chat/Messages/constants';
import { Icon, Spin } from 'antd';
import Froalaeditor from 'froala-editor';
import emailActions from '../redux/email/actions';
import sharedActions from '../redux/sharedReducers/actions';
import messagesActions from '../redux/messages/actions';
import juice from 'juice';
import postsActions from '../redux/post/actions';
import socket from './socket';
import ReactMarkdown from 'react-markdown';
import appsActions from '../redux/apps/actions';
import appPlatformActions from '../redux/appPlatform/actions';
import Settings from '../settings';
import { matchPath } from 'react-router-dom';

const Option = Select.Option,
  confirm = Modal.confirm;

export function timeDifference(
  givenTime,
  displayAgo = false,
  longUnits = false,
) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => (number > 1 ? '' : '');
  const description = displayAgo ? ' ago' : '';
  const number = (num) => (num > 9 ? `${num}` : `0${num}`);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const longUnitsForTimes = {
    h: ' hour',
    m: ' minute',
    d: ' day',
    w: ' week',
  };
  const getUnit = (unit) => `${longUnits ? longUnitsForTimes[unit] : unit}`;
  const getTimeText = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = months[givenTime.getMonth()];
      const day = number(givenTime.getDate());
      const year = givenTime.getFullYear();
      return `${month} ${day}, ${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        if (days < 7) {
          return `${days}${getUnit('d')}${numberEnding(days)} ${description}`;
        } else {
          const week = Math.floor(days / 7);
          return `${week}${getUnit('w')}${numberEnding(week)} ${description}`;
        }
      } else {
        const month = months[givenTime.getMonth()];
        const day = number(givenTime.getDate());
        return `${month} ${day}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours}${getUnit('h')}${numberEnding(hours)}${description}`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes}${getUnit('m')}${numberEnding(minutes)}${description}`;
    }
    return 'Just now';
  };
  return getTimeText();
}

export function scrollToBottom(el) {
  if (el) {
    const scrollHeight = el.scrollHeight;
    const height = el.clientHeight;
    const maxScrollTop = scrollHeight - height;
    el.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }
}
export function scrollToRight(el) {
  if (el) {
    const scrollWidth = el.scrollWidth,
      width = el.clientWidth,
      maxScrollLeft = scrollWidth - width,
      scrollToRight = maxScrollLeft > 0 ? maxScrollLeft : 0;
    el.scrollTo({
      left: scrollToRight,
      behavior: 'smooth',
    });
  }
}

export function scrollToBottomToDisplayIndicator() {
  const currentChatBody = extractPreviousConversationIdentifier()
    ? 'previous-conversation-body'
    : 'messageBody';
  const el = document.getElementById(currentChatBody);
  if (el) {
    const scrollHeight = el.scrollHeight;
    const height = el.clientHeight;
    const scrollTop = el.scrollTop;
    const maxScrollTop = scrollHeight - height;
    if (maxScrollTop - scrollTop <= 63) {
      el.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }
}

export function openArticleInMessenger(e) {
  e.preventDefault();
  let articleId = e.target.dataset.id;
  if (window.gist) window.gist.chat('article', articleId, 'sidebar');
}

export function openSurveyTriggerAPI(e, surveyId) {
  e.preventDefault();
  if (window.gist) {
    window.gist.trigger('survey', surveyId);
  }
}

export function scrollToTop(el) {
  if (el) {
    el.scrollTop = 0;
  }
}

export function scrollToPosition(el, oldScrollHeight) {
  if (el) {
    el.scrollTop =
      oldScrollHeight > 0
        ? el.scrollHeight - oldScrollHeight + el.scrollTop
        : 0;
  }
}

export function extractConversationIndex(
  conversations,
  conversation_identifier,
) {
  let conversationIndex = conversations.findIndex(
    (conversation) =>
      conversation.conversation_identifier === conversation_identifier,
  );
  conversationIndex = conversationIndex < 0 ? 0 : conversationIndex;
  return conversationIndex;
}

export function extractConversationId() {
  const chatRooms = store.getState().Chat.chatRooms;
  const conversationIdentifier = extractConversationIdentifier();
  let selectedConversation = chatRooms.find(
    (conversation) =>
      conversation.conversation_identifier === conversationIdentifier,
  );
  return selectedConversation && selectedConversation.id;
}

export function generateRandomId(currentConversationId) {
  let milliseconds = new Date().getTime();
  return `${currentConversationId}-${milliseconds}`;
}

export function extractConversationIdentifier() {
  return store.getState().Chat.currentConversationId;
}

export function extractActiveConversationId() {
  return store.getState().Chat.activeConversationId;
}

export function extractPreviousConversationIdentifier() {
  return store.getState().Chat.selectedPreviousConversationIdentifier;
}

export function extractActiveComposerTab() {
  return store.getState().Chat.activeComposerTab;
}

export function extractPreviousConversationActiveComposerTab() {
  return store.getState().Chat.activePastConversationComposerTab;
}

export function fetchAttachmentQueues(type, cid) {
  if (type === 'note') {
    return store.getState().Chat.noteAttachmentQueues[cid];
  } else {
    return store.getState().Chat.replyAttachmentQueues[cid];
  }
}

export function extractConversationIdentifierFromUrl() {
  let cid = window.location.pathname.split('/').reverse()[0];
  cid = ['', undefined, null, 'null', 'latest'].includes(cid) ? '' : cid;
  return cid;
}

export function extractCurrentPersonId() {
  return store.getState().Chat.currentPersonId;
}

export function extractCurrentUserId() {
  return store.getState().Auth.currentUserId;
}

export function extractUserProfileData() {
  return store.getState().Auth.profile;
}

export function extractCurrentUserSecret() {
  return store.getState().Auth.userSecret;
}

export function extractCurrentUserRole() {
  const userRole = store.getState().Auth.userRole;
  return userRole && userRole.name;
}

export function fetchProjectSecretKey() {
  return store.getState().Auth.currentProjectSecretKey;
}

export function fetchProjectName() {
  return store.getState().Auth.currentProjectName;
}

export function extractCurrentSegmentId() {
  const segmentId = store.getState().Contacts.selectedSegment.id;
  return [null, undefined].includes(segmentId) ? '' : segmentId;
}

export function extractContactSearchValue() {
  return store.getState().Contacts.contactSearchValue;
}

export function extractWorkFlowSearchValue() {
  return store.getState().Workflow.searchValue;
}

export function extractWorkFlowTemplateSearchValue(templateType) {
  return store.getState()[`${templateType}TemplateReducer`].searchValue;
}

export function extractFormSearchValue() {
  return store.getState().Forms.searchedValue;
}

export function extractAssistantSearchValue() {
  return store.getState().Assistants.searchedValue;
}

export function extractSourceSearchValue() {
  return store.getState().Assistants.sourceSearchValue;
}

export function extractRulesSearchValue() {
  return store.getState().Rules.searchValue;
}

export function extractContactSorterValue() {
  return {
    sortOrder: store.getState().Contacts.sortOrder,
    sortKey: store.getState().Contacts.sortKey,
  };
}

export function extractUserAnalyticsData() {
  return store.getState().Auth.analyticsData;
}

const getFetcher = (withCredentials) => {
  const fetcher = axios.create({ withCredentials });

  fetcher.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 503) {
        // server is unavailable or maintenance
        history.push('/maintenance');
        store.dispatch({
          type: authActions.AUTHORIZATION_ERROR,
        });
      } else {
        // Handle other errors or let them bubble up
        return Promise.reject(error);
      }
    },
  );
  return fetcher;
};

export function getPromise(url, withCredentials = settings.ALLOW_CREDENTIALS) {
  return getFetcher(withCredentials)
    .get(url, { headers: settings.headers })
    .then((response) => response.data);
}

export function postPromise(
  url,
  params,
  withCredentials = settings.ALLOW_CREDENTIALS,
) {
  return getFetcher(withCredentials)
    .post(url, params, { headers: settings.headers })
    .then((response) => response.data);
}

export function patchPromise(
  url,
  params,
  withCredentials = settings.ALLOW_CREDENTIALS,
) {
  return getFetcher(withCredentials)
    .patch(url, params, { headers: settings.headers })
    .then((response) => response.data);
}

export function deletePromise(
  url,
  params,
  withCredentials = settings.ALLOW_CREDENTIALS,
) {
  if (params) {
    return getFetcher(withCredentials)
      .delete(url, { data: params }, { headers: settings.headers })
      .then((response) => response.data);
  }
  return getFetcher(withCredentials)
    .delete(url, { headers: settings.headers })
    .then((response) => response.data);
}

export function getMessageStatus(status) {
  if (status === 'seen' || status === 'responded') {
    return getStatusItem('Seen');
  } else if (status === 'sent' || status === 'delivered') {
    return getStatusItem('Not seen');
  }
}

export function getStatusItem(status) {
  return (
    <>
      <span>{status}</span>
      <span className="separator">·</span>
    </>
  );
}

export function extractOldMessageId(messages) {
  return messages.length > 0 ? messages[0].id : '';
}

export function checkUserType(userType, key) {
  return !(userType === 'user' && ['email', 'name'].includes(key));
}

export function getlastMessage(messages) {
  const reversedMessages = _.cloneDeep(messages).reverse();
  const messageIndex = reversedMessages.findIndex((msg) =>
    isEndUserMessage(msg),
  );
  if (messageIndex >= 0) {
    const message = reversedMessages[messageIndex];
    const statusData = {
      id: message.id,
      person_id: message.person_id,
    };
    return { index: messageIndex, statusData: statusData, message: message };
  } else {
    return { index: messageIndex };
  }
}

export function isEndUserMessage(msg) {
  return (
    msg.user_id === null &&
    ['lead_bot_user_response', 'ai_bot_user_response', null].includes(
      msg.message_type,
    )
  );
}

export function isAllowUpdate(message) {
  return (
    message && (message.status === 'sent' || message.status === 'delivered')
  );
}

export function isAllowEditMessage(message) {
  return !message.is_mail_sent && !message.queried_for_sending_email;
}

export function isAllowSaveReply(message) {
  return (
    message &&
    (message.message_type === null || message.message_type === 'note') &&
    message.user_id &&
    ![
      'lead_bot_greeting',
      'lead_bot_question',
      'lead_bot_response',
      'lead_bot_goal_message',
    ].includes(message.message_type)
  );
}

export function agentTypingStatusObject() {
  const profile = extractUserProfileData();
  return {
    conversation_identifier: extractConversationIdentifier(),
    user_id: extractCurrentUserId(),
    user_avatar: profile.avatar_url,
    user_avatar_letter: profile.full_name.substring(0, 2), //  TODO: Change avatar letter
    user_name: profile.full_name,
    person_id: extractCurrentPersonId(),
    room: fetchProjectSecretKey(),
  };
}

export function updateBrowserHistory(urlParam, state = '') {
  history.push({
    pathname: `/projects/${fetchProjectSecretKey()}/${urlParam}`,
    state,
  });
}

export function navigateToNewTab(url) {
  window.open(`/projects/${fetchProjectSecretKey()}/${url}`, '_blank');
}

export function activateChatRoom(params, data) {
  validateConversationIDFromUrl(data.data.conversations);
}

export function assignFilters(key, filters) {
  if (key === 'IB1') {
    filters = '&assigned=me';
  } else if (key === 'IB2') {
    filters = '&mentioned=true';
  } else if (key === 'IB3') {
    filters = '&unassigned=true';
  } else if (key === 'IB4') {
    filters = '';
  } else if (key === 'IB5') {
    filters = '&only_spam=true';
  } else if (key === 'bot') {
    filters = '&assigned=bot';
  } else if (key.includes('team-')) {
    filters = `&team_id=${key.split('team-')[1]}`;
  } else if (key.includes('team_mate-')) {
    filters = `&assigned=${key.split('team_mate-')[1]}`;
  } else if (key.includes('custom-inbox-view-')) {
    filters = `&inbox_view_id=${key.split('custom-inbox-view-')[1]}`;
  }
  return filters;
}

export function assignSorter(params, sort) {
  if (params.sort === 'oldest') {
    sort = '&order=ASC';
  } else if (params.sort === 'oldest_unreplied' || params.sort === 'priority') {
    sort = `&order=${params.sort}`;
  } else {
    sort = '';
  }
  return sort;
}

export function getCaretPosition(ctrl) {
  // IE < 9 Support
  if (document.selection) {
    ctrl.focus();
    let range = document.selection.createRange();
    let rangelen = range.text.length;
    range.moveStart('character', -ctrl.value.length);
    let start = range.text.length - rangelen;
    return { start: start, end: start + rangelen };
  }
  // IE >=9 and other browsers
  else if (ctrl.selectionStart || ctrl.selectionStart === '0') {
    return { start: ctrl.selectionStart, end: ctrl.selectionEnd };
  } else {
    return { start: 0, end: 0 };
  }
}

export function setCaretPosition(ctrl, start, end) {
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    window.setTimeout(function () {
      ctrl.setSelectionRange(start, end);
    }, 100);
  }
}

export function extractAvatarLetter(fullName) {
  if (fullName) {
    return fullName.slice(0, 2).toUpperCase();
  } else {
    return 'SI';
  }
}

export function isImageFile(filename) {
  return /\.(gif|jpg|jpeg|tiff|png)$/i.test(filename);
}

export function validateEmail(email) {
  const regex = /^([A-Za-z0-9_+\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
  return regex.test(String(email).toLowerCase());
}

export function checkSingleEmojiText(messageContent) {
  const emojiRegexPattern =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|\u263A|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  messageContent = emojify(messageContent, { output: 'unicode' });
  if (_.toArray(messageContent.trim()).length === 1) {
    return emojiRegexPattern.test(messageContent);
  } else {
    return false;
  }
}

export function hasText(messageContent) {
  const textRegex = /[A-Za-z0-9]/;
  const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
  return (
    emojify(messageContent, { output: 'unicode' }).match(textRegex) !== null ||
    emojify(messageContent, { output: 'unicode' }).match(specialCharRegex) !=
    null
  );
}

export function removeUnderscore(value) {
  if (value) {
    let text = value.replace(/_/g, ' ');
    text = text.charAt(0).toUpperCase() + text.slice(1);
    // Todo: Need to remove after backend implemented these changes
    if (Object.keys(propertyLists).includes(value)) {
      text = propertyLists[value];
    }
    return text;
  }
}

export function findIndexOfAllUsersSegment(segments) {
  if (segments.length) {
    return segments.findIndex(
      (segment) => String(segment.name).toLowerCase() === 'all users',
    );
  } else {
    return -1;
  }
}

export function findAllUsersInSegment(segmentName) {
  if (String(segmentName).toLowerCase() === 'all users') {
    return 'All Contacts';
  } else {
    return segmentName;
  }
}

export function findAllUsersSegmentInList(segmentId) {
  const { segments } = store.getState().Contacts;
  if (segments.length) {
    const index = segments.findIndex(
      (segment) => String(segment.name).toLowerCase() === 'all users',
    );
    if (index > -1) {
      return segmentId === segments[index].id ? '' : segmentId;
    }
  }
}

export function avatarImage(value) {
  if (
    value.avatar_url === null ||
    value.avatar_url.includes('assets.getgist.com/avatar')
  ) {
    return (
      <div className="no-image-profile">
        {value.avatar_letter
          ? value.avatar_letter
          : extractAvatarLetter(value.full_name ? value.full_name : value.name)}
      </div>
    );
  } else {
    return (
      <img src={value.avatar_url} alt={'user'} className={'image-profile'} />
    );
  }
}

export function isPropertySelected(property, selectedPropertiesList) {
  const selectedProperty = _.find(selectedPropertiesList, {
    value: property,
  });
  return !!selectedProperty;
}
export function exportFilterData() {
  const { contactsAudienceFilters, allPropertiesLists, eventsMetaData } =
    store.getState().Filter;
  return {
    property: contactsAudienceFilters,
    propertyList: allPropertiesLists,
    eventsList: eventsMetaData,
  };
}

export function getDateLocalTimezone(date, format = dateFormat) {
  return moment.utc(date).local().format(format);
}

export function getDateLocalTimeWithCustomFormat(date, format) {
  return moment.utc(date).local().format(format);
}

export function checkIsBeforeDate(date) {
  return moment().isSameOrBefore(moment.utc(date).local());
}
export function disabledDate(current) {
  // Can not select days before today
  return current && current < moment.utc().subtract(1, 'day').endOf('day');
}

export function disabledDateCheck(current) {
  // can not select days after today
  return (
    current &&
    (current.isAfter() || current.isBefore(stableReportsDate, momentDateFormat))
  );
}

export function disabledDateWithTimeZone(current, timeZone) {
  // Can not select days before today
  return (
    current &&
    current.endOf('day') <
    momentTimeZone().tz(timeZone).subtract(1, 'day').endOf('day')
  );
}

export function disabledDateBeforeToday(current) {
  // Can not select days before today
  return current && current < moment().subtract(1, 'day').endOf('day');
}

export function getDisabledHours(date) {
  let hours = [];
  if (date === moment().date()) {
    for (let i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
  }
  return hours;
}

export function getDisabledMinutes(date, selectedHour) {
  let minutes = [];
  if (date === moment().date() && selectedHour === moment().hour()) {
    for (let i = 0; i < moment().minute(); i++) {
      minutes.push(i);
    }
  }
  return minutes;
}

export function disabledDateTime(test) {
  const hours = moment(test).hours();
  const date = moment(test).date();
  return {
    disabledHours: () => getDisabledHours(date),
    disabledMinutes: () => getDisabledMinutes(date, hours),
  };
}

export function agentProfileReconstruct(params) {
  return {
    conversation_identifier: params.conversation_identifier,
    avatar_url: params.user_avatar,
    avatar_letter: params.user_avatar_letter,
    full_name: params.full_name,
  };
}

export function removeConversation(
  conversation_identifier,
  conversation_status,
  isFromPreviousConversation,
  chatRooms = store.getState().Chat.chatRooms,
) {
  const inboxFilter = store.getState().Chat.inboxFilter;
  if (
    extractConversationIdentifier() !== conversation_identifier ||
    inboxFilter === 'IB5'
  ) {
    // updateConversationsCount();
    chatRooms = chatRooms.filter(
      (chatRoom) =>
        chatRoom.conversation_identifier !== conversation_identifier,
    );

    const payload = { data: cloneDeep(chatRooms) };
    store.dispatch({ type: chatActions.UPDATE_CONVERSATIONS, payload });
    if (
      inboxFilter === 'IB5' &&
      extractConversationIdentifier() === conversation_identifier
    ) {
      const chatRoom = chatRooms.length ? chatRooms[0] : '';
      store.dispatch({
        type: chatActions.SET_SELECTED_CHAT_ROOM,
        chatRoom,
      });
    }
  } else {
    if (conversation_status === 'delete') {
      deleteConversation(conversation_identifier);
    } else if (conversation_status === 'assign') {
      store.dispatch({
        type: chatActions.UPDATE_REMOVED_CONVERSATION_IDENTIFIER,
        payload: conversation_identifier,
        closedConversationOverlay: true,
      });
    } else {
      const { statusFilter, isFromPreviousConversation } =
        store.getState().Chat;
      store.dispatch({
        type: chatActions.UPDATE_CHAT_ROOMS_CONVERSATION_STATUS,
        payload: {
          conversation_identifier: conversation_identifier,
          status: conversation_status,
          isFromPreviousConversation: isFromPreviousConversation,
        },
      });

      if (conversation_status !== statusFilter) {
        store.dispatch({
          type: chatActions.UPDATE_REMOVED_CONVERSATION_IDENTIFIER,
          payload: conversation_identifier,
        });
      } else {
        store.dispatch({
          type: chatActions.UPDATE_REMOVED_CONVERSATION_IDENTIFIER,
          payload: null,
        });
      }

      store.dispatch({
        type: chatActions.UPDATE_CONVERSATION_STATUS,
        cid: conversation_identifier,
        status: conversation_status,
      });
    }
  }

  store.dispatch({ type: chatActions.SET_CLEAR_ALL });
}

export function deleteConversation(conversationId) {
  let chatRooms = store.getState().Chat.chatRooms,
    chatRoomLength = chatRooms.length;
  const chatRoomIndex = chatRooms.findIndex(
    (chatRoom) => chatRoom.conversation_identifier === conversationId,
  );
  if (chatRoomIndex >= 0) {
    updateConversationsCount();
    chatRooms = chatRooms.filter(
      (chatRoom) => chatRoom.conversation_identifier !== conversationId,
    );
    const payload = { data: chatRooms };
    store.dispatch({ type: chatActions.UPDATE_CONVERSATIONS, payload });
    let chatRoom;
    if (chatRoomIndex === chatRoomLength - 1) {
      chatRoom = chatRooms[chatRoomIndex - 1]; //Chat room without removed conversation
    } else {
      chatRoom = chatRooms[chatRoomIndex]; //Chat room without removed conversation
    }
    if (chatRoom) {
      const cid = chatRoom.conversation_identifier;
      const pid = chatRoom.person_id;
      store.dispatch({ type: chatActions.SET_SELECTED_CHAT_ROOM, chatRoom });
      store.dispatch({ type: chatActions.FETCH_MESSAGES, cid });
      store.dispatch({ type: chatActions.FETCH_PROFILE, pid });
      updateBrowserHistory(`conversations/${cid}`);
    } else {
      store.dispatch({ type: chatActions.DELETE_CONVERSATION });
    }
  }
}

export function validateConversationIDFromUrl(conversations) {
  let urlParamConversationIdentifier = window.location.href
      .split('/')
      .reverse()[0],
    conversationID = conversations[0].conversation_identifier;
  let conversationIndex = extractConversationIndex(
    conversations,
    urlParamConversationIdentifier,
  ); //Returns conversation index if found ,otherwise 0 returned
  if (conversationIndex === 0) {
    if (
      window.location.href.indexOf('conversations/') !== -1 &&
      window.location.href.indexOf('conversations/rule') === -1
    ) {
      updateBrowserHistory(`conversations/${conversationID}`);
    }
    return setConversationID(conversationID);
  } else {
    return setConversationID(urlParamConversationIdentifier);
  }
}

export function validateConversationID(conversationID) {
  let conversations = store.getState().Chat.chatRooms,
    tempConversationID = conversations[0].conversation_identifier,
    conversationIndex = extractConversationIndex(conversations, conversationID); //Returns conversation index if found ,otherwise 0 returned
  if (conversationIndex === 0) {
    return setConversationID(tempConversationID);
  } else {
    return setConversationID(conversationID);
  }
}

export function setConversationID(conversationID) {
  store.dispatch({
    type: chatActions.SET_CURRENT_CONVERSATION_ID,
    payload: conversationID,
  });
  return conversationID;
}

export function setPersonID(personID) {
  store.dispatch({
    type: actions.SET_PERSON_ID,
    payload: personID,
  });
}

export function disableAutoSave() {
  setTimeout(() => {
    store.dispatch({
      type: campaignActions.SET_AUTO_SAVE_LOADER,
      payload: false,
    });
  }, generalTimeInterval);
}

export function isNumber(value) {
  const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
  return reg.test(value) && value >= 0;
}

export function copyToClipboard(containerId, decision, valueId = '') {
  let copyText;
  if (decision === 'id') {
    copyText = document.getElementById(containerId);
  } else {
    let text = document.getElementsByClassName(containerId);
    copyText = text[0];
  }
  let value = '';
  if (![null, undefined, ''].includes(valueId)) {
    const element = document.getElementById(valueId);
    if (element) {
      value = element.value;
    }
  }
  copyValueToClipboard(copyText.textContent + value);
}

export function copyValueToClipboard(value) {
  let textArea = document.createElement('textarea');
  textArea.value = value;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  textArea.remove();
  message.success('Copied to clipboard');
}

export function resetWorkflowLoader(value) {
  store.dispatch({
    type: workflowActions.RESET_WORKFLOW_CREATE_LOADER_STATUS,
    payload: value,
  });
}

export function authSuccessRedirection(ConformationURl) {
  const searchText =
    store.getState().router.location.state &&
    store.getState().router.location.state.from.search;
  const searchParams = new URLSearchParams(searchText);
  let referrer = getQueryParamsValue('referrer'),
    limitedTimeOffer = getQueryParamsValue('discount') === 'true',
    shopifyStore =
      searchParams.get('shopify_store') ||
      getQueryParamsValue('shopify_store') ||
      localStorage.getItem('shopify_store'),
    slugName = getQueryParamsValue('slug_name');
  const referrerPaths = document.location.pathname.split('/');
  const isShopifyRedirect =
    document.location.pathname.includes('apps') &&
    !invalidContent.includes(getQueryParamsValue('store')) &&
    !invalidContent.includes(getQueryParamsValue('charge_id'));
  if (isShopifyRedirect) {
    return null;
  } else if (ConformationURl) {
    updateBrowserHistory('settings/subscription');
  } else if (!invalidContent.includes(shopifyStore)) {
    const match = matchPath(document.location.pathname, {
      path: '/projects/:projectId/*',
    });

    if (match && match.params.projectId) {
      if (match && !match.params[0].includes('change-plan')) {
        history.push({
          pathname: `/projects/${match.params.projectId}/apps`,
          search: `?app_id=shopify_integration&shopify_store=${shopifyStore}${
            slugName ? `&slug_name=${slugName}` : ''
          }`,
        });
      }
    } else {
      history.push(`/shopify/choose-workspace?shopify_store=${shopifyStore}`);
    }
    if (match && match.params.projectId !== fetchProjectSecretKey()) {
      window.location.reload();
    }
  } else if (limitedTimeOffer || discountPlans.includes(referrerPaths[1])) {
    // restrict redirect on login
    history.push('/limited-time-offer');
    return;
    const { isUpgradePrime, isPaid } = store.getState().Auth;
    const currentOffer = !invalidContent.includes(referrer)
      ? referrer
      : referrerPaths[1];
    const LDTUpgradeDeadline = moment.utc(
      '02/03/2023/12/00',
      'DD/MM/YYYY/hh/mm',
    );
    // if (isUpgradePrime && moment().isBefore(LDTUpgradeDeadline)) {
    if (currentOffer === 'prime' && isUpgradePrime) {
      discountPlanNavigation(
        !invalidContent.includes(referrer) ? referrer : referrerPaths[1],
      );
    } else if (currentOffer === 'blackfriday' && !isPaid) {
      discountPlanNavigation(
        !invalidContent.includes(referrer) ? referrer : referrerPaths[1],
      );
    } else {
      // The discount is not available for already paid user
      history.push('/limited-time-offer');
    }
  } else if (
    store.getState().Auth.onBoardingState === OnBoardingUserState.signUp
  ) {
    let domain = store
      .getState()
      .Auth.profile.email.substring(
        store.getState().Auth.profile.email.lastIndexOf('@') + 1,
      );
    store.dispatch({
      type: authActions.GET_COMPANY_DETAILS_FROM_CLEAR_BIT,
      payload: domain,
    });

    history.push('/onboarding');
  } else if (
    store.getState().Auth.onBoardingState === OnBoardingUserState.companyDetails
  ) {
    let plan = localStorage.getItem('plan_detail');
    if (plan != null) {
      if (
        gistPlans.includes(plan) ||
        whiteLabelPlans.includes(plan) ||
        gistShopifyPlans.includes(plan)
      ) {
        // Set the plan type
        store.dispatch({
          type: authActions.SET_PLAN_TYPE,
          payload: plan,
        });
        if (!gistShopifyPlans.includes(plan)) {
          history.push('/start-trial');
        }
      } else {
        history.push('/choose-plan');
      }
    } else {
      history.push('/choose-plan');
    }
  } else if (store.getState().Auth.currentUserId !== null) {
    if (store.getState().Auth.currentProjectSecretKey === null) {
      history.push('/workspaces');
    } else {
      if (
        ['/signup', '/login', 'forgot-password'].includes(
          history.location.pathname,
        )
      ) {
        if (referrer !== 'null' && referrer !== '') {
          history.push(referrer);
        } else {
          updateBrowserHistory('contacts');
        }
      } else if (history.location.pathname.split('/')[2] === '_') {
        history.push({
          pathname: history.location.pathname.replace(
            history.location.pathname.split('/')[2],
            fetchProjectSecretKey(),
          ),
          search: history.location.search,
        });
      }
    }
  }
}

export function unAuthorisedRedirection(error, isNotDelete = true) {
  if (error.response && serverErrorCodes.includes(error.response.status)) {
    window.bugsnagClient.notify(error);
  }
  if (error.response && error.response.status === 404 && isNotDelete) {
    window.bugsnagClient.notify(error);
  }
  if (error.response && error.response.status === 403) {
    if (error.response.data.error) {
      message.error(error.response.data.error.message);
    } else {
      message.error(error.response.data.errors);
    }
  }
  if (error.response && error.response.status === unAuthorizedErrorCode) {
    store.dispatch({
      type: authActions.AUTHORIZATION_ERROR,
    });
    history.push('/login');
  }
}

//To show error message for unprocessable entity(422) status.
export function unProcessableEntity(error, manualMessage) {
  if (error.response && error.response.status === 422) {
    if (error.response.data.error && error.response.data.error.message) {
      message.error(error.response.data.error.message);
    } else {
      message.error(error.response.data.errors);
    }
  } else {
    message.error(manualMessage);
  }
}

//To check the referer url secret key
export function validateProjectSecretKey() {
  if (
    document.location.pathname.split('/')[2] !==
    store.getState().Auth.currentProjectSecretKey
  ) {
    store.dispatch(authActions.checkAuthorization());
  }
}

export function trimFunction(value) {
  return value ? value.trim() : '';
}

export function rgb2hex(rgb) {
  rgb = rgb.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i,
  );
  return rgb && rgb.length === 4
    ? `#${`0${parseInt(rgb[1], 10).toString(16)}`.slice(-2)}${`0${parseInt(
      rgb[2],
      10,
    ).toString(16)}`.slice(-2)}${`0${parseInt(rgb[3], 10).toString(
      16,
    )}`.slice(-2)}`
    : '';
}

export function convertfoxIdentifycall(data) {
  const userID = extractCurrentUserId();
  const profileData = extractUserProfileData();
  const createdAt = extractUserProfileData().created_at;
  let analyticsData = cloneDeep(extractUserAnalyticsData());
  let onBoardingData = {};
  if (userID) {
    analyticsData.email = profileData.email;
    analyticsData.name = profileData.full_name;
    if (window.gist) {
      onBoardingData.email = profileData.email;
      onBoardingData.name = profileData.full_name;
      onBoardingData.user_hash = analyticsData.user_hash;
      if (data === 'contacts') {
        onBoardingData.created_at = createdAt;
        //GIST analytics
        window.gist.identify(userID, onBoardingData);
      } else if (data) {
        onBoardingData.user_role = data.user_role;
        onBoardingData.user_industry = data.user_industry;
        onBoardingData.user_admin_link = data.user_admin_link;
        onBoardingData.migrated_from = data.migrated_from;
        //GIST analytics
        window.gist.identify(userID, onBoardingData);
      } else {
        //GIST analytics
        window.gist.identify(userID, {
          ...analyticsData,
          sync_gist_properties: true,
        });
      }
    }

    // Beamer config
    if (window.beamer_config) {
      window.beamer_config.user_id = userID;
      window.beamer_config.user_email = profileData.email;
      window.beamer_config.user_firstname = profileData.first_name;
      window.beamer_config.user_lastname = profileData.last_name;
    }

    // UserFeedback Data
    // if (window.Userback) {
    //   window.Userback.email = profileData.email;
    //   window.Userback.custom_data = {
    //     email: profileData.email,
    //     user_id: userID,
    //     workspace_id: fetchProjectSecretKey(),
    //     name: profileData.full_name,
    //   };
    // }

    // ProfitWell
    if (window.profitwell) {
      window.profitwell('start', {
        user_email: profileData.email,
      });
    }

    // PostHog
    if (window.posthog) {
      window.posthog.identify(userID, analyticsData);
    }
  }
}

export function pluralise(value, count) {
  return count > 1 ? `${value}s` : value;
}

export function getHoursFromMinutes(minutes) {
  return Math.floor(minutes / 60);
}

export function getRemainingMinutesFromHours(minutes) {
  let hours = minutes / 60;
  let roundOfHours = Math.floor(hours);
  let roundOfMinutes = (hours - roundOfHours) * 60;
  return Math.round(roundOfMinutes);
}

export function handleMeetingLinkAutoSave(status) {
  store.dispatch({
    type: meetingAction.MEETING_LINK_AUTO_SAVE,
    payload: status,
  });
}

export function closestValue(value, data) {
  let result, lastValue;

  data.forEach(function (object) {
    let newValue = Math.abs(value - object.value);
    if (newValue >= lastValue) {
      return true;
    }
    result = object.value;
    lastValue = newValue;
  });
  return result;
}

// https://stackoverflow.com/a/21648508
export function hexToRgbA(hex, opacity = '1') {
  let c;
  c = hex.substring(1).split('');
  if (c.length === 3) {
    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  }
  c = `0x${c.join('')}`;
  return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
    ',',
  )}, ${opacity})`;
  // throw new Error('Bad Hex');
}

export function colorSelection(color) {
  let rgbValues = color
    .replace('rgba(', '')
    .replace('rgb(', '')
    .replace(')', '')
    .split(',');
  if (rgbValues.length >= 3) {
    let colorContrast = Math.round(
      (parseInt(rgbValues[0]) * 299 +
        parseInt(rgbValues[1]) * 587 +
        parseInt(rgbValues[2]) * 114) /
      1000,
    );
    let textColor = parseInt(colorContrast) > 125 ? '#2D3138' : '#FFFFFF';

    if (rgbValues[3] !== undefined && colorContrast < 125) {
      if (parseFloat(rgbValues[3]) <= 0.5) {
        textColor = '#2D3138';
      }
    }
    return textColor;
  } else {
    let backgroundRgba = hexToRgbA(color);
    rgbValues = backgroundRgba
      .replace('rgba(', '')
      .replace('rgb(', '')
      .replace(')', '')
      .split(',');
    if (rgbValues.length) {
      let colorContrast = Math.round(
        (parseInt(rgbValues[0]) * 299 +
          parseInt(rgbValues[1]) * 587 +
          parseInt(rgbValues[2]) * 114) /
        1000,
      );
      return colorContrast > 125 ? '#2D3138' : '#FFFFFF';
    }
  }
}

export function checkIsKeyPresent(data) {
  return data ? data : null;
}

export function getImageUrl(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function returnFileSize(number) {
  if (number < 1024) {
    return `${number} bytes`;
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} KB`;
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)} MB`;
  }
}

export function leadingZero(num, size = 2) {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
}

export function exportSavedBehaviouralEmailSettings() {
  return store.getState().AutoEmail.savedSettings;
}

export function logicalOperatorSelector(label) {
  if (label === 'exactly') {
    return '=';
  } else if (label === 'no more than') {
    return '<=';
  } else if (label === 'no less than') {
    return '>=';
  } else if (label === 'more than') {
    return '>';
  } else if (label === 'less than') {
    return '<';
  }
}

export function fetchRestrictedFeatures() {
  return store.getState().Auth.restrictedFeatures;
}

export function fetchDomainLevelRestriction() {
  return store.getState().Auth.domainLevelRestriction;
}

export function timeDifferenceInSeconds(time) {
  let duration = moment.duration(moment().utc().diff(moment.utc(time)));
  return Math.floor(duration / 1000);
}

export function fetchCurrentContent(url) {
  let features = restrictedModule.filter((module) =>
    url.split('/').some((data, _, list) => {
      if (data.includes('rule')) {
        return list.includes('conversations')
          ? 'inbox_automation' === module.key
          : 'rules' === module.key;
      } else if (data === 'support' && list.includes('bots')) {
        return 'answers' === module.key;
      } else {
        return data === module.key;
      }
    }),
  );
  return features[0];
}

export function fetchAddonContent(url) {
  let features = subscriptionAddons.filter((module) =>
    url.split('/').some((data, _, list) => {
      if (data === 'support' && list.includes('bot')) {
        return 'support-bot-addon' === module.key;
      }
    }),
  );
  return features[0];
}

export function orderTeamsByName(teams, conversationInboxCount) {
  let Teams = teams.map((value) => {
    value.count = getTeamInboxCount(conversationInboxCount, value.id);
    return value;
  });
  return _.orderBy(
    Teams,
    [
      function (o) {
        return _.lowerCase(o.team_name);
      },
      'count',
    ],
    ['asc', 'desc'],
  );
}

export function orderTeamMatesByName(teamMates, conversationInboxCount) {
  let teammates = teamMates.map((value) => {
    value.count = getTeammateInboxCount(conversationInboxCount, value.id);
    return value;
  });

  return _.orderBy(
    teammates,
    [
      function (o) {
        return _.lowerCase(o.name);
      },
      'count',
    ],
    ['asc', 'desc'],
  );
}

export function minutes() {
  let minute = [];
  for (let i = 0; i < 60; i++) {
    minute.push(
      <Option value={i} key={i}>
        {i < 10 ? `0${i}` : i}
      </Option>,
    );
  }
  return minute;
}

export function hours() {
  let hour = [];
  for (let i = 1; i <= 12; i++) {
    hour.push(
      <Option value={i} key={i}>
        {i < 10 ? `0${i}` : i}
      </Option>,
    );
  }
  return hour;
}

export function receiveDateFormatting(hours, minute) {
  let meridian;
  if (hours >= 12) {
    hours = hours - 12;
    if (hours === 0) {
      hours = 12;
    }
    meridian = 'pm';
  } else {
    if (hours === 0) {
      hours = 12;
    }
    meridian = 'am';
  }
  return { hours, minute, meridian };
}

export function sendDateFormatting(hour, minute, meridian, date) {
  if (meridian === 'pm') {
    hour = hour + 12;
    if (hour === 24) {
      hour = 12;
    }
  } else {
    if (hour === 12) {
      hour = 0;
    }
  }
  return moment(date).set({ hour, minute });
}

export function getQueryParamsValue(searchParam) {
  let urlString = window.location.href;
  let url = new URL(urlString);
  return decodeURIComponent(url.searchParams.get(searchParam));
}

export function registerAddButton() {
  let showAddButtonModal = this.showAddButtonModal;
  Froalaeditor.DefineIcon('insertButton', {
    NAME: 'plus',
    SVG_KEY: 'add',
  });
  Froalaeditor.RegisterCommand('insertButton', {
    title: 'Insert Button',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      this.selection.save();
      showAddButtonModal(this);
    },
  });
}

export function getButtonHTML(buttonProperties) {
  const {
    buttonText,
    link,
    buttonColor,
    fontSize,
    horizontalPadding,
    verticalPadding,
    borderRadius,
    textAlign,
    fontWeight,
    lineHeight,
  } = buttonProperties;
  return `<p><a href="${link}" style="word-break: break-word;${
    textAlign
      ? 'display: flex !important;align-items: center;justify-content: center;width: fit-content;margin: 10px auto'
      : 'display:inline-block !important;'
  };background-color:${buttonColor};color:#ffffff ; font-weight:${fontWeight} !important; font-size:${fontSize}px !important; text-decoration:none !important; padding:${verticalPadding}px ${horizontalPadding}px !important; border-radius:${borderRadius}px !important; -moz-border-radius:${borderRadius}px !important; -webkit-border-radius:${borderRadius}px !important; -khtml-border-radius:${borderRadius}px !important; ${
    lineHeight ? `line-height:${lineHeight}` : ''
  };" target="_blank">${buttonText}</a></p>`;
}

export function changeDirection(dir, align) {
  // Wrap block tags.
  this.selection.save();
  this.html.wrap(true, true, true, true);
  this.selection.restore();

  // Get blocks.
  const elements = this.selection.blocks();

  // Save selection to restore it later.
  this.selection.save();

  for (let i = 0; i < elements.length; i++) {
    let element = elements[i];
    if (element !== this.el) {
      window
        .$(element)
        .css('direction', dir)
        .css('text-align', align)
        .removeClass('fr-temp-div');
    }
  }

  // Unwrap temp divs.
  this.html.unwrap();

  // Restore selection.
  this.selection.restore();
}

export function registerTextDirection() {
  Froalaeditor.DefineIcon('rightToLeft', { NAME: 'long-arrow-left' });
  Froalaeditor.RegisterCommand('rightToLeft', {
    title: 'RTL',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      changeDirection.apply(this, ['rtl', 'right']);
    },
  });

  Froalaeditor.DefineIcon('leftToRight', { NAME: 'long-arrow-right' });
  Froalaeditor.RegisterCommand('leftToRight', {
    title: 'LTR',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      changeDirection.apply(this, ['ltr', 'left']);
    },
  });
}

export function replaceEmptySpace(text) {
  return text.replace(extraSpaceRegex, '').trim();
}

export function registerPersonalize(
  customFields,
  isIconNeeded = false,
  isChangeToContact = false,
) {
  Froalaeditor.DefineIcon('buttonIcon', { NAME: 'tag' });
  let cmdSettings = {
    title: isChangeToContact ? 'Contact' : 'Personalize',
    type: 'dropdown',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    options: { ...customFields },
    callback: function (cmd, val) {
      this.html.insert(val);
    },
  };

  if (isIconNeeded) {
    cmdSettings.icon = 'buttonIcon';
  }

  Froalaeditor.RegisterCommand(
    isChangeToContact ? 'contact' : 'personalize',
    cmdSettings,
  );
}

export function registerConversation(customFields) {
  let cmdSettings = {
    title: 'Conversation',
    type: 'dropdown',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    options: { ...customFields },
    callback: function (cmd, val) {
      this.html.insert(val);
    },
  };

  Froalaeditor.RegisterCommand('conversation', cmdSettings);
}

export function registerSnippetBtn() {
  const { handleSnippetVisibleChange } = this.props;
  Froalaeditor.DefineIcon('snippetIcon', { NAME: 'star' });

  Froalaeditor.RegisterCommand('SnippetBtn', {
    icon: 'snippetIcon',
    title: 'Insert a snippet',
    callback: function () {
      handleSnippetVisibleChange(true);
    },
    undo: true,
  });
}

export function onPlanChange() {
  store.dispatch({
    type: settingsActions.SET_SIDEBAR_ACTIVE,
    key: ['subscription'],
  });
  updateBrowserHistory('settings/change-plan');
}

export function conversationInsertStatus(chat, isChatMessage) {
  const { chatRooms, openCount, statusFilter, closedCount, pendingCount } =
    chat;

  let length = chatRooms.length;
  if (!isChatMessage) length += 1;

  return (
    (length === openCount && statusFilter === 'open') ||
    (length === closedCount && statusFilter === 'closed') ||
    (length === pendingCount && statusFilter === 'pending')
  );
}

export function findHoursIndex(value) {
  const index = emailSendingHours.findIndex((hour) => hour.value === value);
  return index > -1 && index;
}

export function getTimeZone(values, emailComposerData, defaultTimeZone) {
  if (values.stop_time_zone) {
    return values.stop_time_zone;
  } else if (
    emailComposerData.deadline_details &&
    emailComposerData.deadline_details.time_zone
  ) {
    return emailComposerData.deadline_details.time_zone;
  } else {
    return defaultTimeZone;
  }
}

export function getMaintenanceBannerHeight() {
  // return localStorage.getItem('closeMaintenanceBanner') ? 0 : 40;
  // Now there is no maintance banner, So we return height 0
  return 0;
}

export function getPeriod(type) {
  if (type === 'monthly') {
    return 'mo';
  } else {
    return 'y';
  }
}

export function roundOffByTwoDecimal(value) {
  return Math.round(value * 100) / 100;
}

export function findAllUsersIdInSegment(segments) {
  if (segments.length) {
    let findSegmentId = _.find(
      segments,
      (data) => String(data.name).toLowerCase() === 'all users',
    );
    return findSegmentId ? findSegmentId.id : '';
  } else {
    return null;
  }
}

export function getSegmentID() {
  let segments = store.getState().Contacts.segments;
  let segmentId = store.getState().Contacts.selectedSegment.id;
  if (segments.length) {
    let findSegmentId = _.find(
      segments,
      (data) =>
        data.id === segmentId &&
        String(data.name).toLowerCase() === 'all users',
    );
    return findSegmentId ? '' : `?segments=${segmentId}`;
  } else {
    return '';
  }
}

export function checkCurrentSegment(data) {
  const { selectedSegment } = store.getState().Contacts,
    segmentId = selectedSegment.id ? selectedSegment.id : null;
  return !data.meta_data.segment_id
    ? true
    : parseInt(segmentId) === parseInt(data.meta_data.segment_id);
}

export function createCampaignRecipients() {
  createCampaignRecipientsDebounce();
}

let createCampaignRecipientsDebounce = _.debounce(() => {
  const {
    campaignExitFilterValidation,
    campaignExitAudienceFilters,
    campaignEntryFilterValidation,
    campaignEntryAudienceFilters,
  } = store.getState().Filter;
  const entryCondition = campaignEntryAudienceFilters.new_filters.length
      ? campaignEntryFilterValidation
      : true,
    exitCondition = campaignExitAudienceFilters.new_filters.length
      ? campaignExitFilterValidation
      : true;
  if (entryCondition && exitCondition) {
    store.dispatch({
      type: campaignActions.CAMPAIGN_CREATE_RECIPIENTS,
      payload: { isSave: false },
    });
  }
}, emailComposerSavingInterval);

export function convertHtmlToText(text) {
  return convert(text, {
    wordwrap: false,
    hideLinkHrefIfSameAsText: true,
    preserveNewlines: true,
  });
}

export function parseAndLinkify(message) {
  if(message.message_content_type === 'text'){
    return linkify(message.message_content.replace(/</g, '&lt;').replace(/>/g, '&gt;'));
  }
  return linkify(message.message_content);
}

export function linkify(text) {
  return linkifyHtml(text, {
    defaultProtocol: 'https',
  });
}

export function keyNameChange(data) {
  return data.map(({ name: display_name, ...rest }) => ({
    display_name,
    ...rest,
  }));
}

export function enableEmailDomainbutton(value) {
  let paths = ['email', 'forms', 'events', 'workflows', 'rules'];
  let isButttonvisible = false;
  if (value.indexOf('settings') === -1) {
    paths.map((val) => {
      if (value.includes(val)) {
        isButttonvisible = true;
      }
      return paths;
    });
  }
  return isButttonvisible;
}
export function isListContains(list, value) {
  return !value
    ? false
    : list.some((data) => data.id === value) ||
        list.some((data) => data.property_name === value);
}
export function isPropertyToShown(
  category_name,
  removeProperty,
  property_name,
) {
  return category_name === 'Uncategorized'
    ? true
    : !removeProperty.includes(property_name);
}

export function isSurveyTemplateEnabled() {
  return store.getState().Auth.surveyTemplateEnabled;
}

export function findRatingEmoji(rating) {
  const ratingContent = satisfactionRatings.filter(
    (satisfactionRating) => satisfactionRating.rating === parseInt(rating),
  );
  if (ratingContent.length > 0) {
    return `${settings.EMOJI_URL}${ratingContent[0].val}`;
  }
}

export function convertStringToJson(value) {
  return JSON.parse(value);
}

export function isTemplateEnabled() {
  return store.getState().Auth.templateEnabled;
}
export function showBanner(bannerMessages, pathName) {
  if (pathName && pathName.split('/')[3]) {
    if (bannerMessages.hasOwnProperty('general')) {
      return bannerMessages.general;
    } else if (
      pathName.split('/')[3] === 'contacts' &&
      bannerMessages.hasOwnProperty('contacts')
    ) {
      return bannerMessages.contacts;
    } else if (
      ['email', 'forms', 'events', 'workflows', 'rules'].includes(
        pathName.split('/')[3],
      ) &&
      bannerMessages.hasOwnProperty('marketing')
    ) {
      return bannerMessages.marketing;
    } else if (
      pathName.split('/')[3] === 'email' &&
      pathName.split('/')[4] === 'broadcasts' &&
      bannerMessages.hasOwnProperty('broadcasts')
    ) {
      return bannerMessages.broadcasts;
    } else if (
      ['assistants', 'meetings', 'live-view'].includes(
        pathName.split('/')[3],
      ) &&
      bannerMessages.hasOwnProperty('sales')
    ) {
      return bannerMessages.sales;
    } else if (
      pathName.split('/')[3] === 'knowledge-base' &&
      bannerMessages.hasOwnProperty('support')
    ) {
      return bannerMessages.support;
    } else if (
      pathName.split('/')[3] === 'settings' &&
      bannerMessages.hasOwnProperty('settings')
    ) {
      return bannerMessages.settings;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function tableLoadingSpinner(loader) {
  const antIcon = (
    <Icon type="loading" style={{ fontSize: 24, color: '#37a1f6' }} spin />
  );
  return {
    spinning: loader,
    indicator: <Spin indicator={antIcon} />,
  };
}

export function wooCommerceVerify(wooCommercePopup) {
  const { wooCommerceInterval } = store.getState().Apps;
  if (wooCommercePopup.closed) {
    store.dispatch({
      type: appsActions.UPDATE_FB_BUTTON_LOADER,
      loading: { woocommerce_integration: false },
    });
    for (let i of wooCommerceInterval) {
      clearInterval(i);
    }
    store.dispatch({
      type: appsActions.RESET_WOO_COMMERCE_INTERVAL,
    });
    store.dispatch({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
      changeLoaderState: true,
    });
    store.dispatch({
      type: appsActions.CHANGE_MODAL_VISIBILTY,
      payload: false,
    });
  }
}

export function checkECommerceIntegration(appsList) {
  return (
    (appsList &&
      appsList.woocommerce_integrations &&
      appsList.woocommerce_integrations.length > 0) ||
    (appsList &&
      appsList.shopify_integrations &&
      appsList.shopify_integrations.length > 0)
  );
}

export function isConversationExist(data, cid, type = true) {
  if (type === true) {
    return data.some((result) => result.cid === cid && result.isTyping);
  } else {
    return data.some(
      (result) => result.cid === cid && type === result.type && result.isTyping,
    );
  }
}

export function currentConversationTypingDetail(data, cid, type) {
  return data.find(
    (result) => result.cid === cid && type === result.type && result.isTyping,
  );
}
export function checkCidInChatRoom(cid) {
  return store
    .getState()
    .Chat.chatRooms.some((data) => data.conversation_identifier === cid);
}

export function setAssignee(assignTo, currentUserId) {
  let assignee = 'unassigned';
  if (assignTo) {
    if (parseInt(currentUserId) === assignTo.id) {
      assignee = 'myself';
    } else if (assignTo.bot) {
      assignee = 'bot';
    } else {
      assignee = assignTo.id;
    }
  }
  return assignee;
}

export function conversationStatusChangeHandler() {
  const { conversationSearchText, inboxFilter, sortFilter, statusFilter } =
      store.getState().Chat,
    conversationData = {
      chatRoomActivation: true,
      emptyChatRoom: true,
      searchTag: '',
      searchQuery: conversationSearchText,
      filter: inboxFilter,
      sort: sortFilter,
      status: statusFilter,
    };
  setTimeout(function () {
    store.dispatch({
      type: chatActions.FETCH_CONVERSATIONS,
      ...conversationData,
    });
  }, 100);
}

export function refreshConversationPage() {
  //reload the conversations when conversations actions performed in client side and the network has disconnected and connected.
  const isConversationActive =
    window.location.pathname.includes('/conversations');
  if (isConversationActive) {
    conversationStatusChangeHandler();
  }
}

export function updateConversationsCount() {
  const { statusFilter, openCount, closedCount, pendingCount } =
    store.getState().Chat;
  let msg = { open: openCount, closed: closedCount, pending: pendingCount };
  if (statusFilter === 'open' && openCount) {
    msg = {
      ...msg,
      open: openCount - 1,
    };
  } else if (statusFilter === 'closed' && closedCount) {
    msg = {
      ...msg,
      closed: closedCount - 1,
    };
  } else if (statusFilter === 'pending' && pendingCount) {
    msg = {
      ...msg,
      pending: pendingCount - 1,
    };
  }
  store.dispatch({
    type: chatActions.UPDATE_CONVERSATION_STATUS_COUNT,
    payload: msg,
  });
}
export function updateTitle(title, cid) {
  store.dispatch({
    type: chatActions.UPDATE_CONVERSATION_TITLE_SUCCESS,
    payload: title,
    conversationId: cid,
  });
}
export function updateConversationBehaviour(msg, status) {
  const { statusFilter } = store.getState().Chat;
  store.dispatch({
    type: chatActions.UPDATE_CHAT_ROOMS_CONVERSATION_STATUS,
    payload: {
      conversation_identifier: msg.conversation_identifier,
      status: msg.conversation.status,
    },
  });
  store.dispatch({
    type: chatActions.UPDATE_CONVERSATION_STATUS,
    cid: msg.conversation_identifier,
    status: msg.conversation.status,
  });
  if (statusFilter === msg.conversation.status && status !== 'assign') {
    store.dispatch({
      type: chatActions.UPDATE_REMOVED_CONVERSATION_IDENTIFIER,
      payload: null,
    });
  }
}

export function updateParticipants(msg) {
  if (msg.action_type === 'agent_message' && msg.status === 'sent') {
    const payload = { data: msg };

    store.dispatch({
      type: chatActions.UPDATE_PARTICIPANTS_IN_CURRENT_CONVERSATION_DATA,
      payload,
    });

    store.dispatch({
      type: chatActions.UPDATE_PARTICIPANTS_IN_ALL_CONVERSATION_DATA,
      payload,
    });
  }
}

export function setSnoozeCalculation(key) {
  let selectedTime = { time: '', format: '' };
  if (key === 'later' || key.includes('hour')) {
    selectedTime.time = parseInt(key.replace('_hour')) || 4;
    selectedTime.format = 'hour';
  } else if (key.includes('week')) {
    selectedTime.time = parseInt(key.replace('_week')) || 1;
    selectedTime.format = 'week';
  } else if (key.includes('month')) {
    selectedTime.time = parseInt(key.replace('_month')) || 1;
    selectedTime.format = 'month';
  } else if (key.includes('tomorrow')) {
    let currentTime = moment();
    let Tomorrow = moment().add(1, 'day');
    Tomorrow.set({
      hour: 7,
      minute: 1,
      seconds: 0,
    });
    selectedTime.time = Tomorrow.diff(currentTime, 'minute');
    selectedTime.format = 'minute';
  } else if (key === 'monday') {
    let currentTime = moment();
    let upcomingMonday = moment()
      .startOf('isoWeek')
      .add(1, 'week')
      .day('monday')
      .add(7, 'hour')
      .add(1, 'minute');
    selectedTime.time = upcomingMonday.diff(currentTime, 'minute');
    selectedTime.format = 'minute';
  } else if (key.includes('day')) {
    selectedTime.time = parseInt(key.replace('_day')) || 1;
    selectedTime.format = 'day';
  } else {
    console.warn('Invalid Key on Snooze ');
    return 0;
  }
  return selectedTime;
}

export function fetchTeamRestrictedFeatures() {
  return settings.RESTRICTED_PROJECTS_BASED_ON_TEAMS.some(
    (data) =>
      data.projectName === fetchProjectSecretKey() &&
      store.getState().Auth.teamRestrictedIds.includes(data.teamId),
  );
}
export function isSettingsRestrictedTeam() {
  return settings.RESTRICTED_FEATURES_BASED_ON_TEAMS.some(
    (data) =>
      data.projectName === fetchProjectSecretKey() &&
      store.getState().Auth.teamRestrictedIds.includes(data.teamId),
  );
}

export function removeFroalaClass(data) {
  return data.replace(new RegExp(/fr-original-class/g), 'class');
}
export function updateMessage(msg, status) {
  // Pushed conversation status messages into reducer under "internal_display_content" key
  const { removedConversationIdentifier } = store.getState().Chat;

  const internalDisplayContent = getConversationStatusMessage(msg);
  if (internalDisplayContent) {
    msg.internal_display_content = internalDisplayContent;
  }

  if (removedConversationIdentifier === msg.conversation_identifier) {
    updateConversationBehaviour(msg, status);
  }

  const payload = { data: msg };
  if (
    extractConversationIdentifier() === msg.conversation_identifier ||
    extractPreviousConversationIdentifier() === msg.conversation_identifier
  ) {
    store.dispatch({
      type: chatActions.UPDATE_MESSAGE,
      payload,
      currentUserId: extractCurrentUserId(),
    });

    const currentChatBody = extractPreviousConversationIdentifier()
      ? 'previous-conversation-body'
      : 'messageBody';
    scrollToBottom(document.getElementById(currentChatBody));
  }

  if (
    extractPreviousConversationIdentifier() === msg.conversation_identifier &&
    msg.conversation
  ) {
    store.dispatch({
      type: chatActions.UPDATE_SELECTED_PREVIOUS_CHAT_DETAILS,
      data: { status: msg.conversation.status },
    });
  }
  store.dispatch({
    type: chatActions.UPDATE_ALL_CONVERSATION_DATA,
    payload,
  });

  if (msg.status === 'sent' || msg.status === null) {
    store.dispatch({
      type: chatActions.POST_SUCCESS,
      payload: msg.conversation_identifier,
      message: msg,
    });
  }
}

export function filterTags(conversationRooms, tagID, currentConversationId) {
  const chatIndex = conversationRooms.findIndex(
    (room) => room.conversation_identifier === currentConversationId,
  );
  if (chatIndex > -1) {
    conversationRooms[chatIndex].tags = conversationRooms[
      chatIndex
      ].tags.filter((tag) => tag.id !== tagID);
  }
  return conversationRooms;
}

export function agentStatus(status) {
  if (status === 'online') {
    return 'Available';
  } else if (status === 'offline') {
    return 'Away';
  } else {
    return 'Inactive';
  }
}

export function previewClassChange(data) {
  return convertInlineStyle(
    data.replace(new RegExp(/fr-original-class/g), 'class'),
  );
}

export function setSelectPropertyImports(value, options, header, index) {
  store.dispatch({
    type: actions.DISABLE_SELECTED_PROPERTY,
    payload: value,
    id: index,
  });
  if (options.props.id === 'date') {
    store.dispatch({
      type: actions.UPDATE_TABLE_DATA_PROPERTY,
      payload: {
        key: header,
        value: value,
        dataType: 'date',
        dateFormat: null,
      },
    });
    store.dispatch({
      type: actions.SET_MAPPED_DATA,
      key: header,
      value: value,
      dataType: 'date',
      dateFormat: null,
    });
  } else {
    store.dispatch({
      type: actions.UPDATE_TABLE_DATA_PROPERTY,
      payload: {
        key: header,
        value: value,
        dataType: 'string',
      },
    });
    store.dispatch({
      type: actions.SET_MAPPED_DATA,
      key: header,
      value: value,
      dataType: 'string',
    });
  }
}

export function handleNegativeValue(data) {
  return data > 0 ? data : 0;
}

export function mailDisable(mail, subject, recipients) {
  if (['broadcast', 'auto-mail'].includes(mail)) {
    return recipients || !subject;
  } else if (mail === 'campaign') {
    return !subject;
  }
  return false;
}

export function confirmScheduleModal(isBroadcast) {
  if (!canWeAccess(userRestriction.messages).all) {
    handleNotAuthorizedModal(true);
  } else {
    const { broadcastID } = store.getState().BroadcastEmail,
      { autoEmailID } = store.getState().AutoEmail,
      { isCancelSchedule } = store.getState().Email;
    let loading = false,
      emailType = isBroadcast ? 'broadcast' : 'autoEmail';
    confirm({
      title: 'Cancel the scheduled email? It will be moved to draft mode.',
      okText: 'Yes',
      cancelText: 'No',
      okButtonProps: {
        disabled: isCancelSchedule,
      },
      onOk() {
        if (!loading) {
          store.dispatch({
            type: emailActions.CANCEL_SCHEDULE,
            id: isBroadcast ? broadcastID : autoEmailID,
            [emailType]: true,
          });
        }
        loading = false;
      },
    });
  }
}

export function setImageLink(data) {
  let html = document.createElement('div'),
    updatedHtml = document.createElement('div');
  html.innerHTML = data;
  updatedHtml.innerHTML = data;
  [...html.getElementsByTagName('img')].map((data, index) => {
    if (
      data.getAttribute('data-changeurl') &&
      data.parentElement.tagName !== 'A'
    ) {
      let text = `<a href="${data.getAttribute(
        'data-href',
      )}" rel="nofollow noopener noreferrer" target="_blank">${
        data.outerHTML
      }</a>`;
      updatedHtml.innerHTML = _.replace(
        updatedHtml.innerHTML,
        data.outerHTML,
        text,
      );
    }
    return null;
  });
  return updatedHtml.innerHTML;
}
export function fetchActiveUserPropertyTabs(changeTab = false) {
  const defaultUserPropertyTabs = [
      'tags',
      'segments',
      'campaigns',
      'workflows',
    ],
    activeUserPropertyTabs = changeTab
      ? changeTab.filter((data) => defaultUserPropertyTabs.includes(data))
      : localStorage.getItem('activeUserPropertyTabs') &&
      localStorage
        .getItem('activeUserPropertyTabs')
        .split(',')
        .filter((data) => defaultUserPropertyTabs.includes(data));
  return activeUserPropertyTabs
    ? activeUserPropertyTabs.toString()
    : defaultUserPropertyTabs.toString();
}

export function setTableState(tableFrom) {
  localStorage.setItem('currentTableState', tableFrom);
  store.dispatch({
    type: sharedActions.SET_TABLE_STATE,
    payload: tableFrom,
  });
}

export function selectElementPagination(
  event,
  dataList,
  action,
  pageLastId,
  emailTagSearch,
) {
  const { target } = event;
  if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
    const infiniteData = dataList;
    if (infiniteData.length > 0) {
      const lastTagId = infiniteData[infiniteData.length - 1].id;
      if (
        store.getState().MessagesReducer.messageTagsListLastId !== lastTagId
      ) {
        store.dispatch({
          type: messagesActions.SET_MESSAGE_TAGS_LIST_PAGINATION_VALUES,
          lastId: lastTagId,
          page:
            Number(store.getState().MessagesReducer.messageTagsListPage) + 1,
        });
        store.dispatch({
          type: action,
          pageLastId: lastTagId,
          emailTagSearch,
          requestFrom: 'messages',
        });
        return lastTagId;
      }
      return;
    }
  }
}

export function cancelScheduledChat(id, history, requestFrom) {
  if (!canWeAccess(userRestriction.messages).all) {
    handleNotAuthorizedModal(true);
  } else {
    confirm({
      title: 'Cancel the scheduled message? It will be moved to draft mode.',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        if (requestFrom === 'proactive_chat') {
          store.dispatch({
            type: messagesActions.CANCEL_CHAT_SCHEDULE,
            id,
            history: history,
          });
        } else if (requestFrom === 'announcement_template') {
          store.dispatch({
            type: postsActions.CANCEL_CHAT_SCHEDULE_IN_POST,
            id,
            history: history,
          });
        }
      },
    });
  }
}

/**
 * Takes a string and repeats it "n" times. Example: rep = '*'.repeatCharacter(5); returns '*****'
 * @param {number} number - Times to repeat the string
 */
String.prototype.repeatCharacter = function (num) {
  return new Array(num + 1).join(this);
};

/**
 * Removes duplicates from concatenated strings
 */
Array.prototype.getUniqueWords = function () {
  var a, // array
    i, // incremental counter
    j; // next incremental counter

  a = this.concat();
  for (i = 0; i < a.length; ++i) {
    for (j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) {
        a.splice(j, 1);
      }
    }
  }

  return a;
};

/**
 * Default words that are used in the profanityFilter() method
 */
function getDefaultBadWords(language) {
  // Get language codes from Github docs: https://github.com/LDNOOBW/List-of-Dirty-Naughty-Obscene-and-Otherwise-Bad-Words
  const supportedLanguages = [
    'ar',
    'zh',
    'cs',
    'da',
    'nl',
    'en',
    'fil',
    'fi',
    'fr',
    'de',
    'hi',
    'hu',
    'it',
    'ja',
    'ko',
    'no',
    'fa',
    'pl',
    'pt',
    'ru',
    'es',
    'sv',
    'th',
    'tr',
  ];
  if (!supportedLanguages.includes(language)) {
    language = 'en';
  }
  return require(`naughty-words/${language}.json`);
}

/**
 * Replace a word with asterisk characters
 * @param {string} string - String to replace
 * @param {object} JSON - JSON with additional options
 */
export function profanityFilter(
  message,
  options = {
    replaceWith: '*',
    language: 'en',
    customBadWords: null,
    filter: true,
  },
) {
  if (options.filter) {
    let regex,
      badWordsList = getDefaultBadWords(options.language);
    if (options.customBadWords !== null) {
      badWordsList = badWordsList
        .concat(options.customBadWords)
        .getUniqueWords();
    }

    // We've got an array of bad words, let's proceed with removing them from the message.
    for (let i = 0; i < badWordsList.length; i += 1) {
      regex = new RegExp(`\\b${badWordsList[i]}\\b`, 'gi');
      let replacedWord = options.replaceWith.repeatCharacter(
        badWordsList[i].length,
      );

      if (regex.test(message)) {
        message = message.replace(regex, replacedWord);
      }
    }
  }

  return message;
}

const froalaStyle = `<style>
img {
  position: relative;
  max-width: 100%
}

img.fr-dib {
  margin: 5px auto;
  display: block;
  float: none;
  vertical-align: top
}

img.fr-dib.fr-fil {
  margin-left: 0!important;
  margin-right: auto!important;
  text-align: left
}

img.fr-dib.fr-fir {
  margin-right: 0!important;
  margin-left: auto!important;
  text-align: right
}

img.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px))
}

img.fr-dii.fr-fil {
  float: left;
  margin: 5px 5px 5px 0;
  max-width: calc(100% - 5px)
}

img.fr-dii.fr-fir {
  float: right;
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px)
}

span.fr-emoticon {
  font-weight: normal;
  font-family: "Apple Color Emoji", "Segoe UI Emoji", "NotoColorEmoji", "Segoe UI Symbol", "Android Emoji", "EmojiSymbols";
  display: inline;
  line-height: 0
}

span.fr-emoticon.fr-emoticon-img {
  background-repeat: no-repeat !important;
  font-size: inherit;
  height: 1em;
  width: 1em;
  min-height: 20px;
  min-width: 20px;
  display: inline-block;
  margin: -.1em .1em .1em;
  line-height: 1;
  vertical-align: middle
}</style>`;

export function convertInlineStyle(html) {
  return juice(html + froalaStyle);
}

export function isAllUserSegment(segments, id) {
  return _.find(
    segments,
    (segment) =>
      segment.id === Number(id) && segment.name.toLowerCase() === 'all users',
  );
}

export function setTableSort(tableColumns, isSetPriority) {
  let columns = tableColumns.map((column) => {
    if (column.dataIndex !== 'action') {
      column.sorter = !isSetPriority;
    }
    return column;
  });
  return columns;
}

export function discountPlanNavigation(referrerPath) {
  if (referrerPath === 'blackfriday') {
    history.push(getBlackFridayQueryParams(referrerPath));
  } else if (referrerPath === 'exclusive-offer') {
    history.push(getBlackFridayQueryParams(referrerPath));
  } else if (referrerPath === 'prime') {
    let planType = getQueryParamsValue('plan_name');
    let secretKey = getQueryParamsValue('secret_key');
    let licenses = getQueryParamsValue('licenses');
    let location = '/prime/checkout';
    let params = new URLSearchParams('');

    if (!invalidContent.includes(planType)) {
      params.set('plan_name', planType);
    }
    if (!invalidContent.includes(licenses)) {
      params.set('licenses', licenses);
    }
    if (!invalidContent.includes(secretKey)) {
      params.set('secret_key', secretKey);
    }
    if (params.toString()) {
      location += `?${params.toString()}`;
    }
    history.push(location);
  } else {
    history.push('/woocommerce-offer/checkout');
  }
}
export function setProjectSecretKeyInLocal(data) {
  if (data && data.data && data.data.project_secret_key) {
    localStorage.setItem('projectSecretKey', data.data.project_secret_key);
  }
}
export function updatePropertyPreference(data, requestFrom) {
  setTimeout(() => {
    store.dispatch({
      type: authActions.UPDATE_PROPERTY_PREFERENCE,
      payload: data,
      requestFrom,
    });
  });
}
export function addGistContactAction(items) {
  if (!items.includes('gist_contact_action')) {
    items.splice(1, 0, 'gist_contact_action');
  }
  return items;
}
export function applyHeaderTags() {
  let isActive = function (cmd) {
    let blocks = this.selection.blocks();
    let tag = 'N';
    if (blocks.length) {
      let blk = blocks[0];
      let default_tag = this.html.defaultTag();
      if (blk.tagName.toLowerCase() !== default_tag && blk !== this.el) {
        tag = blk.tagName;
      }
    }

    if (['LI', 'TD', 'TH'].indexOf(tag) >= 0) {
      tag = 'N';
    }

    return tag.toLowerCase() === cmd;
  };
  // Define custom buttons.
  Froalaeditor.DefineIcon('h1', {
    NAME: '<strong>H1</strong>',
    template: 'text',
  });
  Froalaeditor.DefineIcon('h2', {
    NAME: '<strong>H2</strong>',
    template: 'text',
  });
  Froalaeditor.RegisterCommand('h1', {
    title: 'Heading 1',
    callback: function (cmd, val, params) {
      if (isActive.apply(this, [cmd])) {
        this.paragraphFormat.apply('N');
      } else {
        this.paragraphFormat.apply(cmd);
      }
    },
    refresh: function ($btn) {
      $btn.toggleClass('fr-active', isActive.apply(this, [$btn.data('cmd')]));
    },
  });

  Froalaeditor.RegisterCommand('h2', {
    title: 'Heading 2',
    callback: function (cmd, val, params) {
      if (isActive.apply(this, [cmd])) {
        this.paragraphFormat.apply('N');
      } else {
        this.paragraphFormat.apply(cmd);
      }
    },
    refresh: function ($btn) {
      $btn.toggleClass('fr-active', isActive.apply(this, [$btn.data('cmd')]));
    },
  });
}

export function getOrigin() {
  const { location } = window;
  if (location.origin) {
    return location.origin;
  } else {
    return `${location.protocol}//${location.host}`;
  }
}

export function getHostname() {
  const { location } = window;
  return location.hostname;
}

export function isGistDomain() {
  const originName = getOrigin();
  return [
    'https://web-testing.getgist.com',
    'https://app.getgist.com',
  ].includes(originName);
}

export function getAppDetail() {
  let product = {
    fav_icon: `${Settings.STATIC_HOST}/white-label/gist/favicon.ico`,
    logo: `${Settings.STATIC_HOST}/white-label/gist/logo.png`,
    name: 'Gist',
    primary_colour: '#1890ff',
  };
  if (store) {
    product = store.getState().Auth.product;
  }
  const { logo, name, fav_icon, primary_colour } = product;
  return { logo, name, fav_icon, primary_colour };
}

export function updateFavIcon() {
  let link =
    document.getElementById('gist-favicon') || document.createElement('link');
  link.rel = 'shortcut icon';
  link.href = getAppDetail().fav_icon;
  document.getElementsByTagName('head')[0].appendChild(link);
}

export function updateAppColor() {
  document.documentElement.style.setProperty('--primary', '#D3886F');
}

export function updateAppConfig() {
  updateFavIcon();
  updateAppColor();
}

export function isRestricted(page) {
  return fetchDomainLevelRestriction().includes(page);
}

export function fetchUserPermissions() {
  return store.getState().Auth.userPermissions;
}

export function canWeAccess(route) {
  const permissions = fetchUserPermissions() || {};
  if (
    [
      'can_manage_automations',
      'can_manage_bots',
      'can_manage_articles',
      'can_manage_news_items',
      'can_manage_messages',
    ].includes(route)
  ) {
    // permissions - all, read, write and no_access
    switch (permissions[route]) {
      case 'all':
        return {
          all: true,
          read: true,
          write: true,
          no_access: false,
        };
      case 'write':
        return {
          all: false,
          read: true,
          write: true,
          no_access: false,
        };
      case 'read':
        return {
          all: false,
          read: true,
          write: false,
          no_access: false,
        };
      default:
        return {
          all: false,
          read: false,
          write: false,
          no_access: true,
        };
    }
  } else if (['can_manage_deals'].includes(route)) {
    // permissions - all, assigned_only  and no_access
    switch (permissions[route]) {
      case 'all':
        return {
          all: true,
          assigned_only: false,
          no_access: false,
        };
      case 'assigned_only':
        return {
          all: false,
          assigned_only: true,
          no_access: false,
        };
      default:
        return {
          all: false,
          assigned_only: false,
          no_access: true,
        };
    }
  } else if (['can_manage_inbox'].includes(route)) {
    // permissions - all, assigned_only, assigned only and teams  ,and no_access
    switch (permissions[route]) {
      case 'all':
        return {
          all: true,
          assigned_only: true,
          assigned_teams_only: true,
          no_access: false,
        };
      case 'assigned_only':
        return {
          all: false,
          assigned_only: true,
          assigned_teams_only: false,
          no_access: false,
        };
      case 'assigned_teams_only':
        return {
          all: false,
          assigned_only: true,
          assigned_teams_only: true,
          no_access: false,
        };
      default:
        return {
          all: false,
          assigned_only: false,
          assigned_teams_only: false,
          no_access: true,
        };
    }
  } else if (['can_manage_emma_custom_answers'].includes(route)) {
    // permissions - all, write, no_access
    switch (permissions[route]) {
      case 'all':
        return {
          all: true,
          write: false,
          no_access: false,
        };
      case 'write':
        return {
          all: false,
          write: true,
          no_access: false,
        };
      default:
        return {
          all: false,
          write: false,
          no_access: true,
        };
    }
  } else if (['can_manage_emma_content'].includes(route)) {
    // permissions - all, read, no_access
    switch (permissions[route]) {
      case 'all':
        return {
          all: true,
          read: false,
          no_access: false,
        };
      case 'read':
        return {
          all: false,
          read: true,
          no_access: false,
        };
      default:
        return {
          all: false,
          read: false,
          no_access: true,
        };
    }
  } else if (['can_manage_inbox_apps'].includes(route)) {
    // permissions - all, view_only
    switch (permissions[route]) {
      case 'all':
        return {
          all: true,
          custom: false,
          no_access: false,
        };
      case 'custom':
        return {
          all: false,
          custom: true,
          no_access: false,
        };
      default:
        return {
          all: false,
          custom: false,
          no_access: true,
        };
    }
  } else if (['can_manage_custom_apps'].includes(route)) {
    return permissions[route];
  } else {
    return permissions[route] === 'all' || permissions[route] === true
      ? true
      : false;
  }
}

export function handleNotAuthorizedModal(value) {
  store.dispatch({
    type: authActions.SET_NOT_AUTHORIZED_MODAL_STATUS,
    payload: value,
  });
}

export function handleRoleData(data) {
  // Set values for essential keys if they are missing due to "no_access"
  if (!data.can_access_inbox) {
    data.can_manage_inbox = 'no_access';
  }
  if (!data.can_access_emma_custom_answers) {
    data.can_manage_emma_custom_answers = 'no_access';
  }
  if (!data.can_access_emma_content) {
    data.can_manage_emma_content = 'no_access';
  }
  if (!data.can_access_inbox_apps) {
    data.can_manage_inbox_apps = 'no_access';
  }
  if (!data.can_access_articles) {
    data.can_manage_articles = 'no_access';
  }
  if (!data.can_access_news_items) {
    data.can_manage_news_items = 'no_access';
  }
  if (!data.can_access_messages) {
    data.can_manage_messages = 'no_access';
  }
  if (!data.can_access_bots) {
    data.can_manage_bots = 'no_access';
  }
  if (!data.can_access_automations) {
    data.can_manage_automations = 'no_access';
  }
  if (!data.can_access_deals) {
    data.can_manage_deals = 'no_access';
  }

  // Delete unnecessary keys
  delete data.can_access_inbox;
  delete data.can_access_articles;
  delete data.can_access_messages;
  delete data.can_access_bots;
  delete data.can_access_automations;
  delete data.can_access_deals;
  delete data.can_access_emma_custom_answers;
  delete data.can_access_emma_content;
  delete data.can_access_inbox_apps;

  data.can_manage_teammates = false;
  data.can_manage_teams = false;
  data.can_manage_billing_settings = false;

  return data;
}

export function inboxAutomationTriggerKeyToText(key) {
  let triggerText = '';
  switch (key) {
    case 'inbound_conversation_started':
      triggerText = 'A customer starts a new conversation';
      break;
    case 'reply_to_outbound':
      triggerText = 'A customer replies to an in-app message';
      break;
    case 'any_end_user_message':
      triggerText = 'A customer sends any reply';
      break;
    case 'awaiting_teammate_reply':
      triggerText = 'A new conversation hasn’t received a reply';
      break;
    case 'awaiting_end_user_reply':
      triggerText = 'A customer has been unresponsive';
      break;
    case 'snooze_expired':
      triggerText = 'A snooze has expired';
      break;
    case 'assignee_changed':
      triggerText = 'An assignee has been changed';
      break;
    case 'tag_added':
      triggerText = 'A conversation tag has been added';
      break;
    case 'tag_removed':
      triggerText = 'A conversation tag has been removed';
      break;
    case 'left_satisfaction_rating':
      triggerText = 'A customer gives a satisfaction rating';
      break;
    case 'qualification_property_updated':
      triggerText = 'A customer has submitted a qualification property';
      break;
    default:
      break;
  }
  return triggerText;
}

export function inboxAutomationActionKeyToIconClass(key) {
  let actionIcon = '';
  switch (key) {
    case 'assign_conversation':
      actionIcon = 'fas fa-bolt';
      break;
    case 'tag_conversation':
      actionIcon = 'fas fa-tag';
      break;
    case 'untag_conversation':
      actionIcon = 'fas fa-tag';
      break;
    case 'reopen_conversation':
      actionIcon = 'fas fa-inbox-full';
      break;
    case 'snooze_conversation':
      actionIcon = 'fas fa-clock';
      break;
    case 'close_conversation':
      actionIcon = 'fas fa-check';
      break;
    case 'delete_conversation':
      actionIcon = 'fal fa-trash';
      break;
    case 'reply_with_message':
      actionIcon = 'fal fa-comment-lines';
      break;
    case 'add_internal_note':
      actionIcon = 'fal fa-pencil';
      break;
    case 'tag_contact':
      actionIcon = 'fas fa-tag';
      break;
    case 'untag_contact':
      actionIcon = 'fas fa-tag';
      break;
    case 'trigger_webhook':
      actionIcon = 'fas fa-link';
      break;
    default:
      break;
  }

  return actionIcon;
}

export function inboxAutomationActionKeyText(action) {
  let actionText = '';
  switch (action.action_name) {
    case 'assign_conversation':
      actionText = 'Assign to';
      break;
    case 'tag_conversation':
      actionText = 'Add a conversation tag';
      break;
    case 'untag_conversation':
      actionText = 'Remove a conversation tag';
      break;
    case 'reopen_conversation':
      actionText = 'Reopen conversation';
      break;
    case 'snooze_conversation':
      actionText = 'Snooze conversation';
      break;
    case 'close_conversation':
      actionText = 'Close conversation';
      break;
    case 'delete_conversation':
      actionText = 'Delete conversation';
      break;
    case 'mark_conversation_priority':
      actionText = 'Mark as priority';
      break;
    case 'remove_conversation_priority':
      actionText = 'Remove priority';
      break;
    case 'reply_with_message':
      actionText = 'Reply with message';
      break;
    case 'add_internal_note':
      actionText = 'Add an internal note';
      break;
    case 'tag_contact':
      actionText = 'Add a contact tag';
      break;
    case 'untag_contact':
      actionText = 'Remove a contact tag';
      break;
    case 'trigger_webhook':
      actionText = 'Trigger a webhook';
      break;
    default:
      break;
  }

  return actionText;
}

export function getUserData(data) {
  if (data.id && !data.id.toString().split('-')[1]) {
    if (data.action_type === 'member') {
      data.id = `m-${data.id}`;
    } else if (data.action_type === 'team') {
      data.id = `t-${data.id}`;
    }
  }
  return data;
}

export function getTeamData(data) {
  if (!data.id.toString().split('-')[1]) {
    if (data.availability_type === 'member') {
      data.id = `m-${data.id}`;
    } else if (data.availability_type === 'team') {
      data.id = `t-${data.id}`;
    }
  }
  return data;
}

export function addHyphenToTeamsTeammates(payload) {
  const teams =
    payload.teams.length &&
    map(payload.teams, (team) => {
      team.id = `t-${team.id}`;
      return team;
    });
  const teamMates =
    payload.team_mates.length &&
    map(payload.team_mates, (member) => {
      member.id = `m-${member.id}`;
      return member;
    });

  return { teams, teamMates };
}

export function setInitialAssigneeData(value) {
  let updatedValue = 'unassign';

  if (value.assignee_type === 'teammate') {
    updatedValue = `m-${value.assignee_id}`;
  } else if (value.assignee_type === 'team') {
    updatedValue = `t-${value.assignee_id}`;
  } else if (value.assignee_type === 'contact_owner') {
    updatedValue = 'contact_owner';
  }

  return updatedValue;
}

export function getMessageJSON(messageData) {
  delete messageData.person_total_unread_count;
  delete messageData.open;
  delete messageData.closed;
  delete messageData.pending;
  delete messageData.last_user;
  delete messageData.project_details;
  delete messageData.new_conversation;
  delete messageData.conversation_count;

  return messageData;
}

export function getPersonStatus(pinged_at) {
  const now = Math.round(new Date().getTime() / 1000);
  if (now - pinged_at < 45) {
    return 'online';
  } else if (now - pinged_at <= 300) {
    return 'away';
  } else {
    return 'offline';
  }
}

export function joinLivePersonRoom(person_id, project_secret) {
  const livePersonRoom = `${project_secret}/contacts/${person_id}`;

  // TODO: Need to remove the leave room emitting by node server check
  socket.emit('leave_room', livePersonRoom);
  socket.emit('room', livePersonRoom);
}

export function leaveLivePersonRoom(person_id, project_secret) {
  const livePersonRoom = `${project_secret}/contacts/${person_id}`;
  socket.emit('leave_room', livePersonRoom);
}

export function updateURLWithoutRerender(data = null, title = null, path) {
  window.history.replaceState(data, null, path);
}

export function isLiveViewRestricted() {
  const { restrictedFeatures } = store.getState().Auth;
  return restrictedFeatures.includes('live_view');
}

function setTheme(theme) {
  const oldTheme = theme === 'light' ? 'dark' : 'light';

  store.dispatch({
    type: authActions.SET_CURRENT_USER_THEME,
    payload: theme,
  });

  document.body.classList.remove(`${oldTheme}-theme`);
  document.body.classList.add(`${theme}-theme`);
}

function updateTheme(e) {
  // change profileTheme to use Store data
  const profileTheme = store.getState().Auth.userPreferences.theme || 'auto';
  if (profileTheme === 'auto') {
    if (e.matches) {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  }
}

export function initiatePreferColorScheduleListeners(theme = 'auto') {
  let mediaQueryObject = window.matchMedia('(prefers-color-scheme: dark)');
  if (theme === 'auto') {
    // Set Dark Mode
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      setTheme('dark');
    } else {
      setTheme('light');
    }

    try {
      // Chrome & Firefox
      mediaQueryObject.addEventListener('change', (e) => {
        updateTheme(e);
      });
    } catch (e1) {
      try {
        // Safari older version
        mediaQueryObject.addEventListener((e) => {
          updateTheme(e);
        });
      } catch (e2) {
        console.error(e2);
      }
    }
  } else {
    setTheme(theme);
  }
}

export function isAllowAllUser(users) {
  if (
    users &&
    users.length &&
    canWeAccess(userRestriction.deals).assigned_only
  ) {
    const currentUser = find(users, { id: extractCurrentUserId() });
    if (currentUser) {
      return [currentUser];
    }
    return [];
  }
  return users;
}

export function handleSupportPermission(permissions) {
  // handle support permission
  if (permissions && permissions.can_manage_support === false) {
    permissions = { ...permissions, ...supportPermission };
  }
  return permissions;
}

// Message.js Code
function startCase(string) {
  return string && string.replace(/(^|\s)\w/g, (data) => data.toUpperCase());
}

function getPersonName(person) {
  return person.person_chat_name !== undefined &&
  person.person_chat_name !== null &&
  person.person_chat_name !== ''
    ? validateEmail(person.person_chat_name)
      ? person.person_chat_name
      : startCase(person.person_chat_name)
    : person.email;
}

function getHumanReadableSnoozeTime(snooze_time) {
  let human_readable_time;
  if (
    getDateLocalTimeWithCustomFormat(snooze_time, 'YYYY') ===
    moment().format('YYYY')
  ) {
    human_readable_time = getDateLocalTimeWithCustomFormat(
      snooze_time,
      'MMMM DD',
    );
  } else {
    human_readable_time = getDateLocalTimeWithCustomFormat(
      snooze_time,
      'MMMM DD YYYY',
    );
  }

  return human_readable_time;
}

function getBotConversationStatusMessage(message) {
  let message_template = '';
  try {
    // check if action is about updating a contact property
    if (
      ['qualification_property', 'lead_bot_attribute_mapping'].includes(
        message.message_type,
      )
    ) {
      let { key, value } = JSON.parse(message.message_content);
      message_template = `${getPersonName(
        message.person,
      )} left their ${capitalize(key)}: ${value}`;
    } else {
      message_template = message.message_display_content;
    }
  } catch (e) {}

  return message_template;
}

function getNonBotConversationStatusMessage(message) {
  const { action_type, performed_by, meta_data } = message;

  // entity1 indicates the one who performed the action
  // entity2 only applies to "conversation assignment" at the moment and as such indicates who the conversation is assigned to
  var entity1_name = '',
    entity2_name = '';

  // ========= SETTING ENTITY 1 =========

  // Action has been performed by Teammate
  if (performed_by === 'teammate') {
    // If entity1 object exists - check if entity1.id is current logged-in user
    if (meta_data.entity1) {
      if (meta_data.entity1.id === extractCurrentUserId()) {
        entity1_name = 'You';
      } else {
        entity1_name = startCase(meta_data.entity1.name);
      }
    }
    // Action has been performed by Contact
  } else if (performed_by === 'contact') {
    entity1_name =
      startCase(meta_data.entity1.name) || message.person.person_chat_name;
    // Action has been performed by Bot
  } else if (performed_by === 'bot') {
    entity1_name = startCase(meta_data.entity1.name) || 'Bot';
    // Action has been performed by Support Bot
  } else if (performed_by === 'support_bot') {
    entity1_name = startCase(meta_data.entity1.name) || 'Support Bot';
    // Action has been performed by Inbox Automation Rules
  } else if (performed_by === 'assignment_rule') {
    entity1_name = startCase(meta_data.entity1.name) || 'Inbox Automation';
  } else if (performed_by === 'bot_through_api') {
    entity1_name = 'Bot(API)';
  }

  // ========= SETTING ENTITY 2 (APPLIES TO ASSIGNMENT ACTION ALONE) =========

  // check if action is Assignment
  if (['conversation_assignment', 'assignment_rule'].includes(action_type)) {
    let assigner_id = meta_data.entity1.id,
      assignee_id = meta_data.entity2.id;

    // If Assignee is logged-in user, then value will be You or Yourself depending on assigner
    if (assignee_id === extractCurrentUserId()) {
      if (assignee_id === assigner_id) {
        entity2_name = 'yourself';
      } else {
        entity2_name = 'you';
      }
      // If assignee is not logged-in user, but both assignee and assigner are same, then value will be themselves. Else, will be assignee's name
    } else if (assignee_id && assignee_id === assigner_id) {
      entity2_name = 'themselves';
    } else if (entity1_name === 'Bot' && meta_data.entity2.name === 'Bot') {
      entity2_name = 'itself';
    } else {
      entity2_name = startCase(meta_data.entity2.name);
    }
  }

  // ========= SETTING CONVERSATION STATUS TEXT =========

  var conversation_status = ''; // used exclusively to show "reopened", "closed", "snoozed" text
  let snooze_prepend = ''; // used exclusively to show "Snoozed until X time" text

  // check if action is Conversation Status Change
  if (action_type === 'conversation_status_update') {
    if (message.conversation_status === 'open') {
      conversation_status = 'reopened';
    } else if (message.conversation_status === 'closed') {
      conversation_status = 'closed';
    } else if (message.conversation_status === 'pending') {
      conversation_status = 'snoozed';
      snooze_prepend = ` until ${getDateLocalTimeWithCustomFormat(
        message.snooze_time,
        'LT',
      )} on ${getHumanReadableSnoozeTime(message.snooze_time)}`;
    }
  }

  // ========= FINALLY SETTING MESSAGE TEMPLATE =========

  let message_template = '';

  switch (action_type) {
    case 'conversation_status_update':
      if (message.reopened_from_snooze) {
        message_template = 'Snooze ended and this conversation was reopened';
      } else {
        message_template = `${entity1_name} ${conversation_status} this conversation${snooze_prepend}`;
      }
      break;
    case 'conversation_assignment':
    case 'assignment_rule':
      if (
        performed_by === 'assignment_rule' &&
        meta_data.entity1.name === 'Default'
      ) {
        message_template = `Assigned to ${entity2_name} as the default assignee for your workspace`;
      } else {
        message_template = `${entity1_name} assigned this conversation to ${entity2_name}`;
      }
      break;
    case 'conversation_unassignment':
      message_template = `${entity1_name} unassigned this conversation`;
      break;
    case 'mark_conversation_priority':
      message_template = `${entity1_name} marked this conversation as priority`;
      break;
    case 'remove_conversation_priority':
      message_template = `${entity1_name} removed priority from this conversation`;
      break;
    default:
      break;
  }

  return message_template;
}

export function getConversationStatusMessage(message) {
  if (botStatuses.includes(message.message_type)) {
    return getBotConversationStatusMessage(message);
  } else if (nonBotStatuses.includes(message.message_type)) {
    return getNonBotConversationStatusMessage(message);
  } else {
    return '';
  }
}

export function getContactURL() {
  return isElasticSearch() ? 'contacts-es.json' : 'people.json';
}

export function isElasticSearch() {
  return store.getState().Auth.elasticSearchEnabled;
}

export const getRoundedToHundred = (a, e = 0) =>
  a.map((x) => {
    let r = Math.round(x + e);
    e += x - r;
    return r;
  });

export function subscriptionStatus(subscriptionTypes, personMailSubscription) {
  let subscriptionData = [];
  for (let item of personMailSubscription) {
    for (let subscription of subscriptionTypes) {
      if (subscription.id === item.mail_subscription_id) {
        if (JSON.parse(item.subscribed_status)) {
          subscriptionData.push(subscription.id);
        }
        break;
      }
    }
  }
  return subscriptionData;
}

export function subscriptionStatusUpdate(data, status, subscriptionID) {
  let updateData = [];
  if (status) {
    updateData = [...data, subscriptionID];
  } else {
    updateData = data.filter((id) => id !== subscriptionID);
  }
  return updateData;
}
export const loadScriptByURL = (id, url, callback) => {
  const isScriptExist = document.getElementById(id);
  if (!isScriptExist) {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }
  if (isScriptExist && callback) callback();
};
export const trimHtmlContent = (htmlContent) => {
  let editedHtmlContent = htmlContent;
  [
    ...(editedHtmlContent.match(/<br>/g) || []),
    ...(editedHtmlContent.match(/<p><br><\/p>/g) || []),
  ].forEach(() => {
    editedHtmlContent = editedHtmlContent.replace(/^<br>+|<br>+$/gm, '');
    editedHtmlContent = editedHtmlContent.replace(
      /^<p><br><\/p>+|<p><br><\/p>+$/gm,
      '',
    );
  });

  return editedHtmlContent;
};

export function parseMarkdown(text) {
  return (
    <ReactMarkdown
      components={{
        p: React.Fragment,
        // eslint-disable-next-line
        a: ({ node, ...props }) => <a className="link-text" {...props} />,
      }}
      linkTarget={'_target'}
    >
      {text}
    </ReactMarkdown>
  );
}

export const modifyConversationObject = (conversation) => {
  let modifiedConversation = conversation;
  modifiedConversation['last_conversation_message'] =
    modifiedConversation.last_message;
  delete modifiedConversation.last_message;
  return modifiedConversation;
};

export function userList(
  msg,
  currentUserId,
  inboxRoles,
  canManageUnassignedInbox,
  assignedTeams,
) {
  let all = [],
    unAssigned = [],
    teamCount = [],
    teammate = [],
    userMentioned = [],
    bot = [],
    inboxView = {},
    mentioned = {},
    teams = {},
    teammates = {},
    inboxViews = {},
    spam = [],
    {
      inboxFilter: key,
      tempInboxViewCount,
      conversationInboxCount,
      inboxViewCount,
    } = store.getState().Chat,
    userId = extractCurrentUserId(),
    count = [{ open: 0, closed: 0, pending: 0 }];
  msg &&
  msg.map((data) => {
    if (data.all) {
      all.push(data);
    } else if (data.bot) {
      bot.push(data);
    } else if (data.spam) {
      spam.push(data);
    } else if (data.user_id && !data.mentioned) {
      teammate.push(data);
    } else if (data.user_id && data.mentioned) {
      userMentioned.push(data);
    } else if (data.team_id) {
      teamCount.push(data);
    } else if (data.inbox_views) {
      inboxView = data.inbox_views;
    } else if (
      !data.all &&
      !data.mentioned &&
      !data.user_id &&
      !data.team_id
    ) {
      unAssigned.push(data);
    }
    return null;
  });
  userMentioned &&
  userMentioned.map((e) => {
    mentioned = { ...mentioned, [e.user_id]: e.open };
    return null;
  });
  teamCount &&
  teamCount.map((e) => {
    teams = { ...teams, [e.team_id]: e.open };
    return null;
  });
  teammate &&
  teammate.map((e) => {
    teammates = { ...teammates, [e.user_id]: e.open };
    return null;
  });
  if (!isEmpty(inboxView)) {
    Object.keys(inboxView).forEach((key) => {
      inboxViews = { ...inboxViews, [key]: inboxView[key].open };
    });
  } else {
    inboxViews = conversationInboxCount.inbox_views;
    inboxView = inboxViewCount;
  }
  if (key === 'IB1') {
    count = teammate.filter((data) => data.user_id === userId);
  } else if (key === 'IB2') {
    count = userMentioned.filter((data) => data.user_id === userId);
  } else if (key === 'IB3') {
    count = unAssigned;
  } else if (key === 'IB5') {
    count = spam;
  } else if (key === 'bot') {
    count = bot;
  } else if (key.includes('team-')) {
    count = teamCount.filter(
      (data) => data.team_id.toString() === key.split('-')[1],
    );
  } else if (key.includes('team_mate-')) {
    count = teammate.filter(
      (data) => data.user_id.toString() === key.split('-')[1],
    );
  } else if (key.includes('custom-inbox-view-')) {
    count = [inboxView[key.split('custom-inbox-view-')[1]]];
  } else {
    let openCount, closeCount, pendingCount;

    if (!inboxRoles.all) {
      teammate.forEach((teammate) => {
        if (teammate.user_id === currentUserId) {
          openCount = teammate.open;
          closeCount = teammate.closed;
          pendingCount = teammate.pending;
        }
      });
      if (inboxRoles.assigned_teams_only) {
        assignedTeams.forEach((assignedTeam) => {
          teamCount.forEach((team) => {
            if (assignedTeam.id === team.team_id) {
              openCount += team.open;
              closeCount += team.closed;
              pendingCount += team.pending;
            }
          });
        });
      }
      if (canManageUnassignedInbox) {
        unAssigned.forEach((item) => {
          openCount += item.open;
          closeCount += item.closed;
          pendingCount += item.pending;
        });
      }
      count = all;
      count[0].open = openCount;
      count[0].closed = closeCount;
      count[0].pending = pendingCount;
    } else {
      count = all;
    }
  }
  return {
    open: count && count[0] ? count[0].open : 0,
    closed: count && count[0] ? count[0].closed : 0,
    pending: count && count[0] ? count[0].pending : 0,
    conversationCount: {
      all: all && all[0] && all[0].open,
      mentioned,
      unassigned: unAssigned && unAssigned[0] && unAssigned[0].open,
      bot: bot && bot[0] && bot[0].open,
      assigned: {
        teammates,
        teams,
      },
      inbox_views: { ...tempInboxViewCount, ...inboxViews },
      spam: spam && spam[0] && spam[0].open,
    },
    inboxViewCount: inboxView,
  };
}

export function getTeamsCount(teams) {
  let teamCount = 0;
  teams.forEach((item) => {
    teamCount += item.count;
  });
  return teamCount;
}

export function updateCountBasedOnRoles(
  conversationInboxCount,
  assignedTeams,
  currentUserId,
  inboxRoles,
  canManageUnassignedInbox,
) {
  let allCount = getTeammateInboxCount(conversationInboxCount, currentUserId);
  if (!inboxRoles.all) {
    if (inboxRoles.assigned_teams_only)
      allCount += getTeamsCount(assignedTeams);
    if (canManageUnassignedInbox) allCount += conversationInboxCount.unassigned;
    return allCount;
  }
  return null;
}

export const getSortedSnippet = (sortOrder, list) => {
  switch (sortOrder) {
    case 'recently_updated': {
      return orderBy(
        list,
        [({ updated_at }) => new Date(updated_at).getTime()],
        ['desc'],
      );
    }
    case 'most_used': {
      return orderBy(
        list,
        [
          ({ usage_count }) => usage_count || 0,
          ({ title }) => lowerCase(title),
        ],
        ['desc', 'asc'],
      );
    }
    case 'least_used': {
      return orderBy(
        list,
        [
          ({ usage_count }) => usage_count || 0,
          ({ title }) => lowerCase(title),
        ],
        ['asc', 'asc'],
      );
    }
    case 'newest': {
      return orderBy(
        list,
        [({ created_at }) => new Date(created_at).getTime()],
        ['desc'],
      );
    }
    case 'oldest': {
      return orderBy(
        list,
        [({ created_at }) => new Date(created_at).getTime()],
        ['asc'],
      );
    }
    default:
      return orderBy(list, [({ title }) => lowerCase(title)], ['asc']);
  }
};
export function openLinkInNewTab(url) {
  let URL = url.match(/^http[s]?:\/\//) ? url : `https://${url}`;
  const win = window.open(URL, '_blank');
  win && win.focus();
}

export function removeDuplicateChats(chatRooms) {
  return uniqBy(chatRooms, function (e) {
    return e.conversation_identifier;
  });
}

export function handleImageAttachment() {
  const uploadImageElement = document.querySelector(
    `${
      extractPreviousConversationIdentifier() ? '.conversation-drawer' : ''
    } .upload-image .fa-image`,
  );
  if (uploadImageElement) uploadImageElement.click();
}

export function handleUploadAttachment() {
  let uploadAttachmentElement = document.querySelector(
    `${
      extractPreviousConversationIdentifier() ? '.conversation-drawer' : ''
    } .upload-attachment .fa-paperclip`,
  );
  if (uploadAttachmentElement) uploadAttachmentElement.click();
}

// 👇️ if you need to capitalize first and lowercase rest
export function capitalizeFirstLowercaseRest(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function getLastPersonOrAgentMessage(selectedChatRoomMessages) {
  return (
    selectedChatRoomMessages &&
    selectedChatRoomMessages.messages &&
    (findLast(
        selectedChatRoomMessages.messages,
        (message) => message.message_display_type === 'person_message',
      ) ||
      findLast(
        selectedChatRoomMessages.messages,
        (message) =>
          message.message_display_type === 'agent_message' &&
          agentMessageTypes.includes(message.message_type) &&
          !message.is_attachment &&
          !message.is_gif,
      ) ||
      [])
  );
}

export function convertNumberToReadable(number, precision = 2) {
  return number.toLocaleString(undefined, {
    maximumFractionDigits: precision,
  });
}

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function trackEvent(name, properties = {}) {
  if (window.gist) {
    window.gist.track(name, properties);
  }
  if (window.posthog) {
    window.posthog.capture(name, properties);
  }
}

export function trackPageview() {
  if (window.posthog) {
    window.posthog.capture('$pageview');
  }
  if (window.gist) {
    window.gist.trackPageView();
  }
}

export function openNewConversation(message = '') {
  if (window.gist) {
    window.gist.chat('openNewConversation', message);
  }
}

export function insertContentAtCursor(
  originalContent,
  contentToBoInserted,
  cursorPosition,
) {
  let textBeforeCursorPosition = '',
    textAfterCursorPosition = '';

  if (cursorPosition) {
    if (originalContent) {
      textBeforeCursorPosition = originalContent.substring(0, cursorPosition);
      textAfterCursorPosition = originalContent.substring(
        cursorPosition,
        originalContent.length,
      );
    }
  } else {
    textBeforeCursorPosition = originalContent;
  }

  return (
    textBeforeCursorPosition + contentToBoInserted + textAfterCursorPosition
  );
}

export function openInNewTab(url) {
  const win = window.open(url, '_blank');
  win.focus();
}

export function getJsTriggerContent(triggerType, triggerId) {
  return `gist.trigger('${triggerType}', ${triggerId});`;
}

export function registerTeammate(customFields) {
  let cmdSettings = {
    title: 'Teammate',
    type: 'dropdown',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    options: { ...customFields },
    callback: function (cmd, val) {
      this.html.insert(val);
    },
  };

  Froalaeditor.RegisterCommand('teammate', cmdSettings);
}

export function prependNonPriorityConversation(chatRooms, conversation, state) {
  const indexOfFirstNonPriority = chatRooms.findIndex(
    (chatRoom) => chatRoom.priority !== true,
  );
  if (indexOfFirstNonPriority >= 0) {
    chatRooms.splice(indexOfFirstNonPriority, 0, conversation);
  } else if (conversationInsertStatus(state)) {
    chatRooms.push(conversation);
  }
  return chatRooms;
}
export function getBlackFridayQueryParams(referrerPath) {
  let plan = getQueryParamsValue('plan'),
    planInterval = getQueryParamsValue('interval');
  let location = `/${referrerPath}/checkout`;
  if (!invalidContent.includes(plan)) {
    location += `?plan=${plan}`;
  }
  if (!invalidContent.includes(planInterval)) {
    if (!invalidContent.includes(plan)) {
      location += `&interval=${planInterval}`;
    } else {
      location += `?interval=${planInterval}`;
    }
  }
  return location;
}
export function isPrimeCouponUser() {
  return store.getState().Auth.isPrimeCouponUser;
}

export function isPrimeCouponWorkspace() {
  return store.getState().Auth.plan === 'prime_lifetime';
}
export function isPrimePremiumEquivalent() {
  return store.getState().Auth.planEquivalent === 'all_in_one_premium';
}

export function getCouponsCount(plan) {
  let coupons = store.getState().Auth.couponsData;
  if (plan) {
    return (plan in coupons && coupons[plan]) || 0;
  }
  return (
    (('aio_prime' in coupons && coupons.aio_prime) || 0) +
    (('marketing_prime' in coupons && coupons.marketing_prime) || 0) +
    (('support_prime' in coupons && coupons.support_prime) || 0)
  );
}

export function getTrackingCodeSnippet() {
  return `<!-- start Gist JS code-->
<script>
    (function(d,h,w){var gist=w.gist=w.gist||[];gist.methods=['trackPageView','identify','track','setAppId','init'];gist.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);gist.push(e);return gist;}};for(var i=0;i<gist.methods.length;i++){var c=gist.methods[i];gist[c]=gist.factory(c)}s=d.createElement('script'),s.src="${
    settings.GIST_SNIPPET_URL
  }",s.async=!0,e=d.getElementsByTagName(h)[0],e.appendChild(s),s.addEventListener('load',function(e){},!1),gist.init("${fetchProjectSecretKey()}"),gist.trackPageView()})(document,'head',window);
</script>
<!-- end Gist JS code-->`;
}

export function checkAndCorrectBackgroundImage(value, isFromNoise = false) {
  if (isFromNoise) {
    return `url(${settings.STATIC_HOST}/images/noises/noise${value}.png)`;
  } else {
    if (value.startsWith('url(')) {
      return value; // Already has url() syntax, return as-is
    } else if (value.startsWith('http://') || value.startsWith('https://')) {
      return `url(${value})`; // Add url() syntax to standalone HTTP/HTTPS string
    } else {
      // Not a URL, likely a gradient
      return value;
    }
  }
}

export function displayRestrictedFeatureNotification(currentContent) {
  return (
    // !fetchUserRestrictedFeatures().includes(this.state.restrictedMenu) && TODO: Need to check this restriction
    currentContent &&
    Object.keys(currentContent).length > 0 &&
    fetchRestrictedFeatures().includes(currentContent.restriction)
  );
}

export const checkUploadedImageSize=(size,file)=>{
  let sizeInMB;
  if (file && file.file) {
    sizeInMB = (file.file.size / (1024 * 1024)).toFixed(2);
  }
  if (sizeInMB < size) {
    return true
  } else {
    if (!file.name) {
      message.error(`Image must smaller than ${size}MB!`);
      return false
    }
  }
};
export function roundItemsUsingLargestRemainder(items) {
  let itemsCountObject = [];

  _.forEach(items, (item, key) => {
    itemsCountObject.push({ id: key, count: item });
  });

  const totalCount = _.sumBy(itemsCountObject, 'count');

  let itemsObject = itemsCountObject.map((itemCount, i) => {
    const percentage = totalCount ? (100 * itemCount.count) / totalCount : 0;
    const roundedPercentage = Math.floor(percentage);

    return {
      itemCount,
      percentage,
      roundedPercentage,
      remainder: percentage - roundedPercentage,
    };
  });

  /*
    Distribute the margin of error according to the largest remainder,
    falling back to the earliest vote time when needed
  */
  if (totalCount > 0) {
    const roundedTotalPercentage = _.sumBy(itemsObject, 'roundedPercentage');
    const errorMargin = 100 - roundedTotalPercentage;

    const sortedByRemainder = _.orderBy(
      itemsObject,
      ['remainder', 'itemCount.count'],
      ['desc', 'desc'],
    );
    for (var i = 0; i < errorMargin; i++) {
      const item = _.find(itemsObject, [
        'itemCount.id',
        sortedByRemainder[i].itemCount.id,
      ]);
      item.roundedPercentage += 1;
    }
  }

  const itemsRounded = [];

  _.forEach(itemsObject, (item, key) => {
    itemsRounded.push({
      count: item.itemCount.count,
      percentage: item.roundedPercentage,
    });
  });

  return { itemsRounded, totalCount };
}

// Converts JSON to URL Params
function isObj(a) {
  if (!!a && a.constructor === Object) {
    return true;
  }
  return false;
}

function _st(z, g) {
  return '' + (g !== '' ? '[' : '') + z + (g !== '' ? ']' : '');
}

export function fromObject(params, skipobjects, prefix) {
  if (skipobjects === void 0) {
    skipobjects = false;
  }
  if (prefix === void 0) {
    prefix = '';
  }
  var result = '';
  if (typeof params != 'object') {
    return prefix + '=' + encodeURIComponent(params) + '&';
  }
  for (var param in params) {
    var c = '' + prefix + _st(param, prefix);
    if (isObj(params[param]) && !skipobjects) {
      result += fromObject(params[param], false, '' + c);
    } else if (Array.isArray(params[param]) && !skipobjects) {
      // eslint-disable-next-line
      params[param].forEach((item, ind) => {
        result += fromObject(item, false, c + '[' + ind + ']');
      });
    } else {
      result += c + '=' + encodeURIComponent(params[param]) + '&';
    }
  }
  return result;
}

export const getBackgroundColor = (appearanceData, colorValues) => {
  if (appearanceData.background_type === 'gradient') {
    return map(colorValues.gradient, (data, key) => {
      if (key === appearanceData.background_value) return data;
    }).find(Boolean);
  } else if (appearanceData.background_type === 'color') {
    return map(colorValues.solid, (data, key) => {
      if (key === appearanceData.background_value) return data;
    }).find(Boolean);
  }
};

export const getIconBackgroundColor = (appearanceData, colorValues) => {
  if (appearanceData.icon_background_type === 'gradient') {
    return map(colorValues.gradient, (data, key) => {
      if (key === appearanceData.icon_background_value) return data;
    }).find(Boolean);
  } else if (appearanceData.icon_background_type === 'color') {
    return map(colorValues.solid, (data, key) => {
      if (key === appearanceData.icon_background_value) return data;
    }).find(Boolean);
  }
};

export const getFontColor = (appearanceData, colorValues) => {
  if (appearanceData.font_color_value) {
    return map(colorValues.font_colours, (data, key) => {
      if (key === appearanceData.font_color_value) return data;
    }).find(Boolean);
  }
};

// Function to calculate the percentage of each reaction type
export const largestRemainderMethod = (values) => {
  // Calculate the total number of reactions
  const total = values.reduce((a, b) => a + b, 0);

  // If total is zero, return an array of "-" for each value
  if (total === 0) {
    return values.map(() => '-');
  }

  const percentages = values.map((v) => (v / total) * 100);
  const roundedDown = percentages.map(Math.floor);
  const remainders = percentages.map((p, i) => p - roundedDown[i]);

  // Calculate how many percentages need to be allocated
  let sum = roundedDown.reduce((a, b) => a + b, 0);
  const toAllocate = 100 - sum;

  // Determine which indices to allocate the remaining percentages to
  const indices = remainders
    .map((r, i) => [r, i])
    .sort((a, b) => b[0] - a[0])
    .slice(0, toAllocate)
    .map(([_, i]) => i);

  // Allocate the remaining percentages
  indices.forEach((i) => roundedDown[i]++);

  return roundedDown;
};

export function updateOnboardingTaskStatus(taskKey) {
  const { completedOnboardingTasks } = store.getState().Auth;
  const isCompleted = completedOnboardingTasks.includes(taskKey);

  const completeTask = () => {
    store.dispatch({
      type: authActions.UPDATE_GET_STARTED_DATA,
      data: {
        setup_guide: {
          completed_key : taskKey,
        },
      },
    });
  };

  if (!isCompleted) {
    completeTask();
  }

  return isCompleted;
}
