import { all, call, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
import ruleActions from '../automation/rules/actions';
import authActions from '../auth/actions';
import contactActions from '../contacts/actions';
import { message } from 'antd';
import settings from '../../settings';
import {
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  deletePromise,
  unAuthorisedRedirection,
  extractCurrentUserId,
  updateBrowserHistory,
  hexToRgbA,
  extractCurrentPersonId,
  trackEvent,
  unProcessableEntity,
  getQueryParamsValue,
  updateOnboardingTaskStatus,
} from '../../helpers/utility';
import { store, history } from '../store';
import _ from 'lodash';
import chatActions from '../chat/actions';
import { invalidContent } from '../../components/constants';
import workflowActions from '../automation/workflow/actions';
import sharedActions from '../sharedReducers/actions';
import inboxAutomationActions from '../inboxAutomation/actions';
import displayActions from '../displayRules/actions';
import builderActions from '../pageBuilder/actions';
import emailActions from '../email/actions';
import { COUNTRY_URL } from '../constants';
import axios from 'axios';
import settingActions from './actions';
import { newsCenterSettings } from '../../components/Settings/NewsCenter/constants';

export function* fetchGeneralInfo() {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/workspaces/general_info`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_GENERAL_INFO_SUCCESS,
      payload: payload.data,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch general info failed');
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  }
}

export function* patchGeneralInfo(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/workspaces/update_general_info`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.FETCH_GENERAL_INFO,
    });
    yield put({
      type: authActions.UPDATE_WORKSPACE_NAME,
      payload: params.payload.project_name,
    });
  } catch (error) {
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update general info failed');
    }
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  }
}

export function* fetchBrandingInfo() {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/workspaces/company_info`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_BRANDING_INFO_SUCCESS,
      brandingInfo: payload.data.project,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch branding info failed');
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  }
}

export function* patchBrandingInfo(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/workspaces/update_company_info`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.FETCH_BRANDING_INFO_SUCCESS,
      brandingInfo: data.data.project,
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update branding info failed');
    }
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  }
}

export function* fetchRoles(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    let searchText = store.getState().Settings.settingsRolesSearch;
    if (params.searchText) {
      searchText = params.searchText;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/project_roles?search_text=${searchText}&include_permissions=true`;
    let response = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ROLES_SUCCESS,
      payload: response.data.project_roles,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch roles info failed');
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* createOrUpdateRole(params) {
  try {
    if (params.id) {
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/settings/project_roles/${params.id}`;
      let payload = yield call(() => patchPromise(url, params.apiData));
      yield put({
        type: actions.FETCH_ROLES,
      });
      message.success(payload.message);
    } else {
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/settings/project_roles`;
      let payload = yield call(() => postPromise(url, params.apiData));
      yield put({
        type: actions.FETCH_ROLES,
      });
      message.success(payload.message);
    }
    store.dispatch({
      type: actions.CREATE_OR_UPDATE_ROLE_SUCCESS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Create role failed');
    }
    store.dispatch({
      type: actions.CREATE_OR_UPDATE_ROLE_FAILURE,
    });
  }
}

export function* deleteRole(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/project_roles/${params.id}`;
    let payload = yield call(() => deletePromise(url));
    yield put({
      type: actions.FETCH_ROLES,
    });
    message.success(payload.message);
  } catch (error) {
    if (error.response && error.response.status !== 404) {
      unAuthorisedRedirection(error);
    }
    // TODO: To change the code for 422 errors
    if (error.response && error.response.status === 404) {
      message.error(error.response.data.errors);
    } else {
      message.error('Delete role failed');
    }
  }
}

export function* fetchRoleList() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/project_roles?include_permissions=false`;
    let response = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ROLE_LIST_SUCCESS,
      payload: response.data.project_roles,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch roles info failed');
  }
}

export function* fetchTeams(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    let searchText = store.getState().Settings.settingsTeamSearch;
    if (params.searchText) {
      searchText = params.searchText;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/teams?search_text=${searchText}`;
    let payload = yield call(() => getPromise(url));

    yield put({
      type: actions.FETCH_TEAMS_SUCCESS,
      teamData: payload.data.teams,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch teams info failed');
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* fetchUsersList() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/teams/users_list`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_USERS_LIST_SUCCESS,
      userList: payload.data.users,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch users failed');
  }
}

export function* fetchTeamMatesList(params) {
  try {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/teams/${
      params.id
    }/team_users`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_TEAMMATES_LIST_SUCCESS,
      teamMatesList: payload.data.users,
      teamEmoji: params.teamEmoji,
    });
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch users failed');
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  }
}

export function* createTeam(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    if (params.id) {
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/settings/teams/${params.id}`;
      let payload = yield call(() => patchPromise(url, params.teamData));
      yield put({
        type: actions.FETCH_TEAMS,
      });
      yield put({
        type: actions.DRAWER_VISIBILITY,
        payload: false,
      });
      yield put({
        type: actions.SET_LOADER,
        loader: false,
      });
      message.success(payload.message);
    } else {
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/settings/teams`;
      let payload = yield call(() => postPromise(url, params.teamData));
      yield put({
        type: actions.FETCH_TEAMS,
      });
      yield put({
        type: actions.DRAWER_VISIBILITY,
        payload: false,
      });
      yield put({
        type: actions.SET_LOADER,
        loader: false,
      });
      message.success(payload.message);
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Create team failed');
    }
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  }
}

export function* deleteTeam(params) {
  try {
    yield put({
      type: actions.DELETING_TEAM_PROCESS,
      payload: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/teams/${params.id}`;
    let payload = yield call(() => deletePromise(url));
    yield put({
      type: actions.FETCH_TEAMS,
    });
    yield put({
      type: actions.DELETING_TEAM_PROCESS,
      payload: false,
    });
    message.success(payload.message);
  } catch (error) {
    if (error.response && error.response.status !== 404) {
      unAuthorisedRedirection(error);
    }
    // TODO: To change the code for 422 errors
    if (error.response && error.response.status === 404) {
      message.error(error.response.data.errors);
    } else {
      message.error('Delete team failed');
    }
    yield put({
      type: actions.DELETING_TEAM_PROCESS,
      payload: false,
    });
  }
}

export function* fetchUserData(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    let searchText = store.getState().Settings.settingsUserSearch;
    if (params.searchText) {
      searchText = params.searchText;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users?search_text=${searchText}`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_USER_DATA_SUCCESS,
      userData: payload.data,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch users failed');
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* fetchProjectPlanDetail() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users/project_plan_details`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_PROJECT_PLAN_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
  }
}

export function* changeStatusAndHide(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users/${params.id}`;
    let payload = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.FETCH_USER_DATA,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    message.success(payload.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Change status failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* inviteUser(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users/create_invite`;
    let payload = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.FETCH_USER_DATA,
    });
    yield put({
      type: actions.SET_DRAWER_STATUS,
      payload: false,
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    yield put({
      type: actions.DRAWER_VISIBILITY,
      payload: false,
    });
    message.success(payload.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Invite User failed');
    }
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  }
}

export function* updateUserPermissions(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users/update_project_role`;
    let payload = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.FETCH_USER_DATA,
    });
    yield put({
      type: actions.SET_DRAWER_STATUS,
      payload: false,
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    message.success(payload.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update user permission failed');
    }
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  }
}

export function* fetchActiveUser() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users/active_users_list`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ACTIVE_USER_SUCCESS,
      data: payload.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch active user failed');
  }
}
export function* transferOwnership(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users/transfer_ownership`;
    let payload = yield call(() => patchPromise(url, params.data));
    message.success(payload.message);
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    yield put({
      type: authActions.AUTHORIZATION_SUCCESS,
      payload,
    });
    yield put({
      type: actions.FETCH_USER_DATA,
    });
    yield put({
      type: actions.DRAWER_VISIBILITY_TRANSFER_OWNERSHIP,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('transfer ownership failed');
    }
  }
}

export function* fetchContactProperties(params) {
  const { isPageLoaderOff } = params;
  try {
    if (!isPageLoaderOff) {
      yield put({
        type: actions.SET_PAGE_LOADER,
        pageLoader: true,
      });
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_properties/property_lists`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CONTACT_PROPERTIES_SUCCESS,
      contactPropertiesData: payload.data.contact_properties,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('fetch contact properties failed');
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* deleteContactProperty(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_properties`;
    let payload = yield call(() => deletePromise(url, params.data));
    message.success(payload.message);
    yield put({
      type: actions.FETCH_CONTACT_PROPERTIES,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    if (error.response && error.response.status !== 404) {
      unAuthorisedRedirection(error);
    }
    if (error.response && error.response.status === 404) {
      message.error(error.response.data.errors);
    } else {
      message.error('delete contact properties failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* updateContactProperty(params) {
  try {
    if (params.isFromTable) {
      yield put({
        type: actions.SET_PAGE_LOADER,
        pageLoader: true,
      });
    } else {
      yield put({
        type: actions.SET_LOADER,
        loader: true,
      });
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_properties`;
    let payload = yield call(() => patchPromise(url, params.data));
    message.success(payload.message);
    yield put({
      type: actions.FETCH_CONTACT_PROPERTIES,
    });
    if (!params.isFromTable) {
      store.dispatch({
        type: actions.DRAWER_VISIBILITY_PROPERTY,
        payload: false,
      });
      yield put({
        type: actions.SET_LOADER,
        loader: false,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('update contact properties failed');
    }
    if (params.isFromTable) {
      yield put({
        type: actions.SET_PAGE_LOADER,
        pageLoader: false,
      });
    } else {
      store.dispatch({
        type: actions.DRAWER_VISIBILITY_PROPERTY,
        payload: true,
      });
      yield put({
        type: actions.SET_LOADER,
        loader: false,
      });
    }
  }
}

export function* createContactProperty(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_properties`;
    let payload = yield call(() => postPromise(url, params.data));
    message.success(payload.message);
    yield put({
      type: actions.FETCH_CONTACT_PROPERTIES,
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    store.dispatch({
      type: actions.DRAWER_VISIBILITY_PROPERTY,
      payload: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('create contact properties failed');
    }
    store.dispatch({
      type: actions.DRAWER_VISIBILITY_PROPERTY,
      payload: true,
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  }
}

export function* fetchContactTags(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    let searchText = store.getState().Settings.tagSearch;
    if (params.searchText) {
      searchText = params.searchText;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_tags?search_text=${searchText}`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CONTACT_TAGS_SUCCESS,
      contactTagsData: payload.data.tags,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch contact tags failed');
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* appendContactTags(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    let searchText = store.getState().Settings.tagSearch;
    let page = '';
    if (params.page) {
      page = params.page;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_tags?search_text=${searchText}&last_tag_id=${page}`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_CONTACT_TAGS_SUCCESS,
      contactTagsData: payload.data.tags,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('append contact tags failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* deleteContactTag(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_tags/${params.id}`;
    let payload = yield call(() => deletePromise(url));
    message.success(payload.message);
    yield put({
      type: actions.FETCH_CONTACT_TAGS,
    });
  } catch (error) {
    if (error.response && error.response.status !== 404) {
      unAuthorisedRedirection(error);
    }
    if (error.response && error.response.status === 404) {
      message.error(error.response.data.errors);
    } else {
      message.error('Delete contact tags failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* updateContactTag(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_tags/${params.id}`;
    let payload = yield call(() => patchPromise(url, params.data));
    yield put({
      type: actions.DRAWER_VISIBILITY,
      payload: false,
    });
    message.success(payload.message);
    yield put({
      type: actions.FETCH_CONTACT_TAGS,
    });
    yield put({
      type: workflowActions.FETCH_WORKFLOW_TAG_LIST,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update contact tags failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* createContactTag(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_tags`;
    let payload = yield call(() => postPromise(url, params.data));
    if (payload && payload.data && payload.data.tag) {
      if (params.fromRuleAction) {
        yield put({
          type: actions.FETCH_CURRENT_ACTION_CONTACT_TAGS,
          payload: payload.data.tag,
        });
      } else if (params.fromTriggerSelection) {
        yield put({
          type: actions.FETCH_CURRENT_TRIGGER_CONTACT_TAGS,
          payload: payload.data.tag,
        });
      } else if (params.fromContacts) {
        yield put({
          type: contactActions.APPEND_CONTACTS_TAGS,
          payload: payload.data.tag,
        });
        yield put({
          type: settingActions.CONTACTS_TAG_LOADER,
          payload: false,
        });
      } else if (params.fromWorkflow || params.fromChatBots) {
        yield put({
          type: actions.FETCH_CURRENT_CONTACT_TAGS,
          payload: payload.data.tag,
        });
      } else if (params.fromFroms) {
        yield put({
          type: builderActions.UPDATE_BUILDER_OBJECT_VALUE,
          payload: [
            {
              ...params.builderDataObject,
              value: payload.data.tag.tags,
            },
          ],
        });
        yield put({
          type: actions.CONTACTS_TAG_LOADER,
          payload: false,
        });
      } else {
        yield put({
          type: actions.FETCH_CONTACT_TAGS,
        });
        yield put({
          type: actions.DRAWER_VISIBILITY,
          payload: false,
        });
      }
      yield put({
        type: workflowActions.APPEND_WORKFLOW_TAG_LIST,
        payload: payload.data.tag,
      });
      yield put({
        type: ruleActions.APPEND_RULES_TAG_LIST,
        payload: payload.data.tag,
      });
      yield put({
        type: inboxAutomationActions.APPEND_RULE_CONTACT_TAGS,
        payload: payload.data.tag,
      });
      yield put({
        type: displayActions.APPEND_TAG_LIST,
        payload: payload.data.tag,
      });
      yield put({
        type: actions.ACTION_TAG_LOADER,
        payload: false,
      });
    }
    message.success(payload.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Create contact tags failed');
    }
    if (params.fromRuleAction) {
      yield put({
        type: actions.ACTION_TAG_LOADER,
        payload: false,
      });
    } else if (params.fromTriggerSelection) {
      yield put({
        type: actions.TRIGGER_TAG_LOADER,
        payload: false,
      });
    } else if (params.fromWorkflow || params.fromFroms) {
      yield put({
        type: actions.CONTACTS_TAG_LOADER,
        payload: false,
      });
    } else {
      yield put({
        type: actions.SET_PAGE_LOADER,
        pageLoader: false,
      });
    }
  }
}

export function* fetchContactSegments() {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_segments`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CONTACT_SEGMENTS_SUCCESS,
      contactSegmentsData: payload.data.segments,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch contact segments failed');
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* appendContactSegment(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    let page = '';
    if (params.page) {
      page = params.page;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_segments?last_segment_id=${page}`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_CONTACT_SEGMENT_SUCCESS,
      contactSegmentsData: payload.data.segments,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch contact segments failed');
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* updateContactSegment(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    let contactSegments = store.getState().Settings.contactSegmentsData;
    contactSegments = contactSegments.filter((data) => data.id === params.id);
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_segments/${
      params.id
    }`;
    let payload = yield call(() => patchPromise(url, params.data));
    message.success(payload.message);
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    yield put({
      type: actions.FETCH_CONTACT_SEGMENTS,
    });
    if (params.data.contact_segment.hidden !== contactSegments[0].hidden) {
      yield put({
        type: contactActions.FETCH_SEGMENTS,
        loadSegments: true,
      });
    }
    yield put({
      type: actions.DRAWER_VISIBILITY,
      payload: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update contact segments failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* deleteContactSegment(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_segments/${
      params.id
    }`;
    let payload = yield call(() => deletePromise(url));
    message.success(payload.message);
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    yield put({
      type: actions.FETCH_CONTACT_SEGMENTS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Delete contact segments failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* fetchPrimaryDomain() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/domains`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_DOMAINS_SUCCESS,
      domains: payload.data.domains,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch primary domain failed');
  }
}

export function* addDomain(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/domains`;
    let payload = yield call(() => postPromise(url, params.data));
    message.success(payload.message);
    yield put({
      type: actions.UPDATE_DOMAIN_SUCCESS,
      domains: payload.data.domain,
    });
    yield put({
      type: actions.SET_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SET_LOADER,
      pageLoader: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update primary domain failed');
    }
  }
}

export function* updatePrimaryDomain(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/domains/${params.id}`;
    let payload = yield call(() => patchPromise(url, params.data));
    message.success(payload.message);
    yield put({
      type: actions.SET_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SET_LOADER,
      pageLoader: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update primary domain failed');
    }
  }
}

export function* deleteDomain(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/domains/${params.id}`;
    let payload = yield call(() => deletePromise(url));
    message.success(payload.message);
    yield put({
      type: actions.DELETE_DOMAIN_SUCCESS,
      id: params.id,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    if (error.response && error.response.status !== 404) {
      unAuthorisedRedirection(error);
    }
    if (error.response && error.response.status === 404) {
      message.error(error.response.data.errors);
    } else {
      message.error('Delete domain failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* emailToDeveloper(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/data_tracking/send_mail_to_developer?to_email=${
      params.email
    }&email_content=${params.content}`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    yield put({
      type: actions.DRAWER_VISIBILITY,
      payload: false,
    });
    message.success(payload.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch primary domain failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* fetchExportsList(params) {
  try {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/exports`;
    let data = yield call(() => getPromise(url));
    yield put({ type: actions.FETCH_EXPORTS_LIST_SUCCESS, payload: data.data });
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  } catch (error) {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
    unAuthorisedRedirection(error);
    message.error('Fetch exports failed');
  }
}

export function* appendExports(params) {
  try {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    let page = '';
    if (params.page) {
      page = params.page;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/exports?last_export_id=${page}`;
    let data = yield call(() => getPromise(url));
    yield put({ type: actions.APPEND_EXPORTS_SUCCESS, exportsData: data.data });
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  } catch (error) {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
    unAuthorisedRedirection(error);
    message.error('append exports failed');
  }
}

export function* fetchImportsList() {
  try {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/imports`;
    let data = yield call(() => getPromise(url));
    yield put({ type: actions.FETCH_IMPORTS_LIST_SUCCESS, payload: data.data });
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  } catch (error) {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
    unAuthorisedRedirection(error);
    message.error('Fetch imports failed');
  }
}

export function* appendImports(params) {
  try {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    let page = '';
    if (params.page) {
      page = params.page;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/imports?last_import_id=${page}`;
    let data = yield call(() => getPromise(url));
    yield put({ type: actions.APPEND_IMPORTS_SUCCESS, importsData: data.data });
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  } catch (error) {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
    unAuthorisedRedirection(error);
    message.error('append imports failed');
  }
}

export function* fetchQualificationDataList() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/qualification_data`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_QUALIFICATION_DATA_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch qualification data failed');
    }
  }
}

export function* fetchQualificationDataModalList() {
  try {
    // yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/qualification_data/lists`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_QUALIFICATION_DATA_MODAL_LIST_SUCCESS,
      qualificationList: data.data.qualification_data,
    });
    // yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  } catch (error) {
    // yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch qualification data list failed');
    }
  }
}

export function* addQualificationData(params) {
  try {
    yield put({ type: actions.SET_LOADER, loader: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/qualification_data`;
    let data = yield call(() => patchPromise(url, params.data));
    message.success(data.message);
    yield put({ type: actions.SET_LOADER, loader: false });
    yield put({
      type: actions.FETCH_QUALIFICATION_DATA_LIST,
    });
    store.dispatch({
      type: actions.DRAWER_VISIBILITY,
      payload: false,
    });
  } catch (error) {
    yield put({ type: actions.SET_LOADER, loader: false });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Update qualification data failed');
    }
  }
}

export function* deleteQualificationData(params) {
  try {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/qualification_data`;
    let data = yield call(() => deletePromise(url, params.data));
    message.success(data.message);
    yield put({
      type: actions.FETCH_QUALIFICATION_DATA_LIST,
    });
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  } catch (error) {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('delete qualification data failed');
    }
  }
}

export function* deleteUser(params) {
  try {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users`;
    let data = yield call(() => deletePromise(url, params.data));
    message.success(data.message);
    yield put({
      type: actions.FETCH_USER_DATA,
    });
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  } catch (error) {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
    if (error.response && error.response.status !== 404) {
      unAuthorisedRedirection(error);
    }
    if (error.response && error.response.status === 404) {
      message.error(error.response.data.errors);
    } else {
      message.error('delete user failed');
    }
  }
}

export function* deleteInvite(params) {
  try {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users`;
    let data = yield call(() => deletePromise(url, params.data));
    message.success(data.message);
    yield put({
      type: actions.FETCH_USER_DATA,
    });
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  } catch (error) {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
    if (error.response && error.response.status !== 404) {
      unAuthorisedRedirection(error);
    }
    if (error.response && error.response.status === 404) {
      message.error(error.response.data.errors);
    } else {
      message.error('delete invite failed');
    }
  }
}

export function* resendInvite(params) {
  try {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users/resend_invite`;
    let data = yield call(() => postPromise(url, params.data));
    message.success(data.message);
    yield put({
      type: actions.FETCH_USER_DATA,
    });
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  } catch (error) {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('resend invite failed');
    }
  }
}

export function* fetchChatTags(params) {
  try {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    let searchText = store.getState().Settings.chatTagSearch;
    if (params.searchText) {
      searchText = params.searchText;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/conversation_tags?search_text=${searchText}&pagination=${
      params.noPagination ? 'false' : ''
    }`;
    let data = yield call(() => getPromise(url));
    if (params.fromChatBots) {
      yield put({
        type: actions.FETCH_CHAT_TAGS_SUCCESS,
        chatTagsData: data.data.chat_tags,
        fromChatBots: params.fromChatBots,
        currentConversationTagData: params.payload,
      });
    } else {
      yield put({
        type: actions.FETCH_CHAT_TAGS_SUCCESS,
        chatTagsData: data.data.chat_tags,
      });
    }
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('fetch conversation tags failed');
    }
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  }
}

export function* appendChatTags(params) {
  try {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    let searchText = store.getState().Settings.chatTagSearch;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/conversation_tags?search_text=${searchText}&last_chat_tag_id=${
      params.page
    }`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_CHAT_TAGS_SUCCESS,
      chatTagsData: data.data.chat_tags,
    });
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('append conversation tags failed');
    }
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  }
}

export function* updateChatTags(params) {
  try {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/conversation_tags/${
      params.id
    }`;
    let data = yield call(() => patchPromise(url, params.data));
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
    yield put({
      type: actions.FETCH_CHAT_TAGS,
    });
    message.success(data.message);
    yield put({ type: actions.DRAWER_VISIBILITY, payload: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('update conversation tags failed');
    }
  }
}

export function* createChatTags(params) {
  try {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/conversation_tags`;
    let data = yield call(() => postPromise(url, params.data));
    if (params.isFromInboxAutomation) {
      yield put({
        type: inboxAutomationActions.APPEND_RULE_CHAT_TAGS,
        payload: data.data.chat_tag,
      });
      yield put({
        type: actions.ACTION_TAG_LOADER,
        payload: false,
      });
    } else if (params.fromChatBots) {
      yield put({
        type: actions.FETCH_CHAT_TAGS,
        fromChatBots: params.fromChatBots,
        payload: data.data.chat_tag,
        noPagination: true,
      });
    } else if (params.isFromCustomView) {
      yield put({
        type: chatActions.APPEND_INBOX_CHAT_TAGS,
        payload: data.data.chat_tag,
      });
      yield put({
        type: actions.ACTION_TAG_LOADER,
        payload: false,
      });
    } else {
      yield put({
        type: actions.FETCH_CHAT_TAGS,
      });
    }
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
    message.success(data.message);
    yield put({ type: actions.DRAWER_VISIBILITY, payload: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('create conversation tags failed');
    }
    yield put({
      type: actions.ACTION_TAG_LOADER,
      payload: false,
    });
  }
}

export function* deleteChatTags(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/conversation_tags/${
      params.id
    }`;
    let data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({
      type: actions.FETCH_CHAT_TAGS,
    });
  } catch (error) {
    if (error.response && error.response.status !== 404) {
      unAuthorisedRedirection(error);
    }
    if (error.response && error.response.status === 404) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('delete conversation tags failed');
    }
  }
}

export function* fetchSnippetData(params) {
  try {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    let searchText = store.getState().Settings.snippetSearch;
    if (params.searchText) {
      searchText = params.searchText;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/snippets?pagination=false&with_snippet_category=true&with_message=false&search_text=${searchText}`;
    let data = yield call(() => getPromise(url));

    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
    yield put({
      type: actions.FETCH_SNIPPET_DATA_SUCCESS,
      snippetData: data.data.saved_replies,
      snippetCategories: data.snippet_categories,
    });
  } catch (error) {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('fetch snippets failed');
    }
    yield put({
      type: actions.FETCH_SNIPPET_DATA_FAILURE,
    });
  }
}

export function* fetchSingleSnippetData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/snippets/${
      params.id
    }?with_message=true`;
    let data = yield call(() => getPromise(url));

    yield put({
      type: actions.FETCH_SINGLE_SNIPPET_DATA_SUCCESS,
      snippetData: data.data.saved_reply,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('fetch snippet failed');
    }
  }
}

export function* appendSnippetData(params) {
  try {
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: true });
    let searchText = store.getState().Settings.chatTagSearch;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/snippets?search_text=${searchText}&last_saved_reply_id=${
      params.page
    }`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_SNIPPET_DATA_SUCCESS,
      snippetData: data.data.saved_replies,
    });
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('append conversation tags failed');
    }
    yield put({ type: actions.SET_PAGE_LOADER, pageLoader: false });
  }
}

export function* updateSnippetData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/snippets/${params.id}`;
    let data = yield call(() => patchPromise(url, params.data));
    message.success(data.message);
    yield put({ type: actions.FETCH_SNIPPET_DATA });
    params.closeDrawer && params.closeDrawer();
    yield put({
      type: actions.UPDATE_SNIPPET_DRAWER_DATA_LOADER,
      payload: false,
    });
    yield put({
      type: actions.UPDATE_SNIPPET_DATA_SUCCESS,
      payload: data.data.saved_reply,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('update snippets failed');
    }
    yield put({
      type: actions.UPDATE_SNIPPET_DRAWER_DATA_LOADER,
      payload: false,
    });
  }
}

export function* createSnippetFolderData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/snippet_categories`;
    const data = yield call(() => postPromise(url, params.data));
    yield put({ type: actions.FETCH_SNIPPET_DATA });
    yield put({
      type: actions.SET_SNIPPET_FOLDER_DRAWER_VISIBILITY,
      payload: false,
    });
    yield put({ type: actions.CREATE_SNIPPET_FOLDER_DATA_SUCCESS });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else if (error.response && error.response.status !== 403) {
      message.error('Create folder failed');
    }
    yield put({ type: actions.CREATE_SNIPPET_FOLDER_DATA_FAILURE });
  }
}

export function* updateSnippetFolderData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/snippet_categories/${
      params.id
    }`;
    let data = yield call(() => patchPromise(url, params.data));
    yield put({ type: actions.FETCH_SNIPPET_DATA });
    message.success(data.message);
    yield put({
      type: actions.SET_SNIPPET_FOLDER_DRAWER_VISIBILITY,
      payload: false,
    });
    yield put({ type: actions.UPDATE_SNIPPET_FOLDER_DATA_SUCCESS });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Update folder failed');
    }
    yield put({ type: actions.UPDATE_SNIPPET_FOLDER_DATA_FAILURE });
  }
}

export function* deleteSnippetFolderData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/snippet_categories/${
      params.id
    }`;
    let data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({ type: actions.FETCH_SNIPPET_DATA });
  } catch (error) {
    if (error.response && error.response.status !== 404) {
      unAuthorisedRedirection(error);
    }
    if (error.response && error.response.status === 404) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Delete folder failed');
    }
  }
}

export function* createSnippetData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/snippets`;
    let data = yield call(() => postPromise(url, params.data));
    trackEvent('Created saved reply');
    if (params.isFromConversations) {
      yield put({
        type: chatActions.FETCH_SNIPPETS,
        offset: '',
        personId: extractCurrentPersonId(),
      });
    } else {
      yield put({ type: actions.FETCH_SNIPPET_DATA });
    }
    message.success(data.message);
    yield put({
      type: actions.UPDATE_SNIPPET_DRAWER_DATA_LOADER,
      payload: false,
    });
    params.closeDrawer();
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('create snippets failed');
    }
    yield put({
      type: actions.UPDATE_SNIPPET_DRAWER_DATA_LOADER,
      payload: false,
    });
  }
}

export function* deleteSnippetData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/snippets/${params.id}`;
    let data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({ type: actions.FETCH_SNIPPET_DATA });
  } catch (error) {
    if (error.response && error.response.status !== 404) {
      unAuthorisedRedirection(error);
    }
    if (error.response && error.response.status === 404) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('delete snippets failed');
    }
  }
}

export function* fetchAppearanceData() {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/appearances`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_APPEARANCE_DATA_SUCCESS,
      appearanceData: data.data.setting,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('fetch appearance failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* updateAppearanceData(params) {
  try {
    let data;
    const { background_type, icon_background_type } =
      store.getState().Settings.appearanceData;
    let colorData;
    if (params.appearanceData.setting.background_value) {
      data = {
        setting: {
          background_type,
          background_value: params.appearanceData.setting.background_value,
          custom_value: null,
        },
      };
    } else if (params.appearanceData.setting.custom_value) {
      data = {
        setting: {
          background_type,
          background_value: null,
          custom_value: hexToRgbA(params.appearanceData.setting.custom_value),
        },
      };
    } else if (params.appearanceData.setting.icon_custom_color) {
      data = {
        setting: {
          icon_background_type,
          icon_background_value: null,
          icon_custom_color: hexToRgbA(
            params.appearanceData.setting.icon_custom_color,
          ),
        },
      };
    } else if (params.appearanceData.setting.icon_background_value) {
      data = {
        setting: {
          icon_background_type,
          icon_background_value:
            params.appearanceData.setting.icon_background_value,
          icon_custom_color: null,
        },
      };
    } else if (params.appearanceData.setting.iconColor) {
      let rgb = params.appearanceData.setting.iconColor.rgb;
      colorData = {
        setting: {
          icon_background_type,
          icon_custom_color: `rgba(${rgb.r},${rgb.g},${rgb.b},1)`,
          icon_background_value: null,
        },
      };
      data = colorData;
    } else if (params.appearanceData.setting.backgroundColor) {
      let rgb = params.appearanceData.setting.backgroundColor.rgb;
      colorData = {
        setting: {
          background_type,
          custom_value: `rgba(${rgb.r},${rgb.g},${rgb.b},1)`,
          background_value: null,
        },
      };
      data = colorData;
    } else if (params.appearanceData.setting.fontColor) {
      let rgb = params.appearanceData.setting.fontColor.rgb;
      colorData = {
        setting: {
          font_custom_color: `rgba(${rgb.r},${rgb.g},${rgb.b},1)`,
          font_color_value: null,
        },
      };
      data = colorData;
    } else if (params.appearanceData.setting.font_color_value) {
      data = {
        setting: {
          font_color_value: params.appearanceData.setting.font_color_value,
          font_custom_color: null,
        },
      };
    } else {
      data = params.appearanceData;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/appearances`;
    let payload = yield call(() => patchPromise(url, data));
    message.success(payload.message);
    yield put({
      type: actions.DRAWER_VISIBILITY,
      payload: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('update appearance failed');
    }
  }
}

export function* updateMessengerDefaults(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/messenger_defaults`;
    let data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_APPEARANCE_VALUES_SUCCESS,
      payload: params.payload,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
    }
  }
}

export function* fetchColorValues() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/appearances/background_color`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_COLOR_VALUES_SUCCESS,
      colorValues: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('fetch colors failed');
    }
  }
}

export function* fetchAvailabilityData() {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/availability`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_AVAILABILITY_DATA_SUCCESS,
      availabilityData: data.data.project,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('fetch availability data failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* updateAvailabilityData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/availability`;
    let data = yield call(() => patchPromise(url, params.data));
    message.success(data.message);
    yield put({
      type: actions.UPDATE_AVAILABILITY_DATA_SUCCESS,
      availabilityData: params.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
      yield put({
        type: actions.KB_CUSTOM_DOMAIN_UPDATE_ERROR_MESSAGE,
        payload: error.response.data.errors,
      });
    } else if (error.response && error.response.status !== 403) {
      message.error('update availability failed');
    }
  }
}

export function* fetchQualificationBotData() {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/qualification_bot`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_QUALIFICATION_BOT_DATA_SUCCESS,
      payload: data,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('fetch availability data failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* updateQualificationBotData(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/qualification_bot`;
    let data = yield call(() => patchPromise(url, params.data));
    message.success(data.message);
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  } catch (error) {
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('update qualification bot failed');
    }
  }
}

export function* fetchLanguageData() {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/languages`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_LANGUAGE_DATA_SUCCESS,
      languageData: data.data.project,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('fetch language data failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* fetchLanguages() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/languages/lists`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_LANGUAGES_SUCCESS,
      languages: data.data.languages,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('fetch languages failed');
    }
  }
}

export function* updateLanguageData(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/languages`;
    let data = yield call(() => patchPromise(url, params.data));
    message.success(data.message);
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('update language data failed');
    }
  }
}

export function* fetchVisibilityData() {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/visibility`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_VISIBILITY_DATA_SUCCESS,
      visibilityData: data.data.setting,
      messengerPath: data.data.messenger_path,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('fetch visibility data failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* fetchVisibilityDropdown() {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/messenger_path_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_VISIBILITY_DROPDOWN_SUCCESS,
      visibilityDropdown: data.data.message_path,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('fetch visibility dropdown failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* updateVisibilityData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/visibility`;
    let data = yield call(() => patchPromise(url, params.data));
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('update visibility data failed');
    }
  }
}

export function* updateVisibilityEnabledPaths(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/visibility/update_messenger_path`;
    let data = yield call(() => patchPromise(url, params.data));
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('update visibility data failed');
    }
  }
}

export function* fetchMeetingsProfileDetails() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users_profiles?user_id=${extractCurrentUserId()}`;
    let data = yield call(() => getPromise(url));
    if (_.isEmpty(data.data.users_profile.social_media)) {
      data.data.users_profile.social_media = [{}];
    }
    yield put({
      type: actions.FETCH_MEETINGS_PROFILE_DETAILS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch meetings profile failed');
    }
  }
}

export function* updateMeetingsProfileDetails(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users_profiles/${extractCurrentUserId()}`;
    yield call(() => patchPromise(url, params.payload));
    message.success('Profile updated successfully');
    updateOnboardingTaskStatus('setup_profile');
    if (params.imageUrl) {
      yield put({
        type: actions.SET_PROFILE_IMAGE_URL,
        imageUrl: params.imageUrl,
      });
    }
    if (params.coverImageUrl) {
      yield put({
        type: actions.SET_COVER_IMAGE_URL,
        coverImageUrl: params.coverImageUrl,
      });
    }
    if (params.isStyleChange) {
      yield put({
        type: actions.UPDATE_MEETINGS_PROFILE_DETAILS_SUCCESS,
        payload: params.payload,
      });
    }
  } catch (error) {
    if (params.imageUrl) {
      yield put({ type: actions.SET_PROFILE_IMAGE_URL, imageUrl: '' });
    }
    if (params.coverImageUrl) {
      yield put({
        type: actions.SET_COVER_IMAGE_URL,
        coverImageUrl: '',
      });
    }
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors.message[0]);
    } else if (error.response && error.response.status !== 403) {
      message.error('Profile update failed');
    }
  }
}

export function* availableCalendars() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/email_accounts`;
    let data = yield call(() => getPromise(url));
    if (data.data.primary_email_account_id) {
      yield put({
        type: actions.FETCH_CALENDAR_ACCOUNT_DETAILS,
        payload: data.data.primary_email_account_id,
      });
    }
    yield put({
      type: actions.AVAILABLE_CALENDAR_INTEGRATIONS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({ type: actions.AVAILABLE_CALENDAR_INTEGRATIONS_FAILURE });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch calendar integrations failed!');
    }
  }
}

export function* googleAccountOauth(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const { code, scope } = params.payload;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/google_calendar_integrations/oauth2callback?user_id=${extractCurrentUserId()}&code=${code}&scope=${scope}&new_page=true`;
    yield call(() => getPromise(url));
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    history.push('/projects/oauth2callback');
  } catch (error) {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    history.push('/projects/oauth2callback');
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Calendar integration failed!');
    }
  }
}

export function* disconnectCalendarAccount(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/email_accounts/${
      params.payload
    }/disconnect`;
    const data = yield call(() => deletePromise(url));
    yield put({
      type: actions.DISCONNECT_CALENDAR_ACCOUNT_SUCCESS,
      payload: params.payload,
    });
    yield put({ type: actions.AVAILABLE_CALENDAR_INTEGRATIONS });
    message.success(data.message);
  } catch (error) {
    yield put({
      type: actions.DISCONNECT_CALENDAR_ACCOUNT_FAILURE,
      payload: params.payload,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Account disconnect failed!');
    }
  }
}

export function* fetchUnavailabilities() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/unavailabilities?user_id=${extractCurrentUserId()}`;
    const data = yield call(() => getPromise(url));
    yield put({ type: actions.FETCH_UNAVAILABILITIES_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.FETCH_UNAVAILABILITIES_FAILURE });
    unAuthorisedRedirection(error);
  }
}

export function* updateUnavailabilities(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/unavailabilities/update_unavailability?user_id=${extractCurrentUserId()}`;
    yield call(() => patchPromise(url, params.payload));
    const { unavailability } = params.payload.users_project;
    yield put({
      type: actions.UPDATE_UNAVAILABILITIES_SUCCESS,
      payload: unavailability,
    });
    message.success('Unavailability updated');
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Unavailability update failed');
  }
}

export function* updateAvailableCalendars(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/email_accounts/${
      params.accountId
    }`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_AVAILABLE_CALENDARS_SUCCESS,
      payload: params.payload.email_account,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Available calendar not updated');
    }
  }
}

export function* updateDefaultMeetingLink(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users_profiles/update_default_meeting_link?user_id=${extractCurrentUserId()}`;
    yield call(() => patchPromise(url, params.payload));
    message.success('Default meeting link updated');
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Default meeting link not updated');
  }
}

export function* fetchKbSettings(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/knowledge_base`;
    let data = yield call(() => getPromise(url, params.data));
    yield put({
      type: actions.FETCH_KB_SETTINGS_SUCCESS,
      payload: data.data.knowledgebase_setting,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('fetch custom settings failed');
    }
  }
}

export function* updateKbSettings(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/knowledge_base`;
    let data = yield call(() => patchPromise(url, params.values));
    message.success(data.message);
    yield put({
      type: actions.FETCH_KB_SETTINGS_SUCCESS,
      payload: data.data.knowledgebase_setting,
    });
    yield put({
      type: actions.SET_CONNECT_DOMAIN_TAB_KEY,
      payload: 1,
    });
  } catch (error) {
    yield put({
      type: actions.KB_CUSTOM_DOMAIN_UPDATE_FAILURE,
      payload: params.values,
      error: error.response.data.errors,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (!params.connectDomain) {
        message.error(error.response.data.errors);
      }
    } else if (error.response && error.response.status !== 403) {
      message.error('Update knowledge base settings failed');
    }
  }
}

export function* fetchKbColorList() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/knowledge_base/background_color`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_KB_COLORS_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Fetch colors failed');
    }
  }
}

export function* fetchKbLanguageList() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/knowledge_base/language_list`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_KB_LANGUAGE_LIST_SUCCESS,
      payload: data.data.languages,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Fetch languages failed');
    }
  }
}
export function* kbCustomDomainUpdate() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/knowledge_base/validate_custom_domain`;
    let data = yield call(() => getPromise(url));
    yield put({ type: actions.KB_CUSTOM_DOMAIN_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      yield put({
        type: actions.KB_CUSTOM_DOMAIN_UPDATE_ERROR_MESSAGE,
        payload: error.response.data.errors,
      });
    } else if (error.response && error.response.status !== 403) {
      message.error('Update custom domain failed');
    }
  }
}

export function* fetchBlockedIP() {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/blocked_ips`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_BLOCKED_IP_SUCCESS,
      payload: data.data.blocked_ips,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('fetch blocked IPs failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* deleteBlockedIP(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/blocked_ips/${params.id}`;
    const data = yield call(() => deletePromise(url));
    message.success(data.message);
    if (!params.isFromInbox) {
      yield put({
        type: actions.FETCH_BLOCKED_IP,
      });
    }
  } catch (error) {
    if (error.response && error.response.status !== 404) {
      unAuthorisedRedirection(error);
    }
    if (error.response && error.response.status === 404) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Unblock IP failed');
    }
  }
}

export function* fetchBlockedUsers() {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/blocked_peoples`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_BLOCKED_USERS_SUCCESS,
      payload: data.data.blocked_people,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('fetch blocked users failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* deleteBlockedUser(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/blocked_peoples/${params.id}`;
    const data = yield call(() => deletePromise(url));
    message.success(data.message);
    if (!params.isFromInbox) {
      yield put({
        type: actions.FETCH_BLOCKED_USERS,
      });
    }
  } catch (error) {
    if (error.response && error.response.status !== 404) {
      unAuthorisedRedirection(error);
    }
    if (error.response && error.response.status === 404) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Unblock user failed');
    }
  }
}

export function* fetchAdvancedData() {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/advanced_settings`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ADVANCED_TAB_SUCCESS,
      payload: data.data.setting,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('fetch blocked users failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* updateAdvancedData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/advanced_settings`;
    let data = yield call(() => patchPromise(url, params.data));
    yield put({
      type: actions.UPDATE_ADVANCED_TAB_SUCCESS,
      payload: data.data,
    });
    yield put({
      type: actions.SET_SATISFACTION_RATINGS_SETTINGS_LOADER,
      payload: false,
    });
    yield put({
      type: actions.SET_ASK_EMAIL_BEFORE_START_LOADER,
      payload: false,
    });
    yield put({
      type: actions.SET_PREVENT_CONVERSATION_CLOSE_LOADER,
      payload: false,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SET_SATISFACTION_RATINGS_SETTINGS_LOADER,
      payload: false,
    });
    yield put({
      type: actions.SET_ASK_EMAIL_BEFORE_START_LOADER,
      payload: false,
    });
    yield put({
      type: actions.SET_PREVENT_CONVERSATION_CLOSE_LOADER,
      payload: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('update qualification bot failed');
    }
  }
}

export function* fetchTemplateList(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/email_templates${
      params.templateType ? `?template_type=${params.templateType}` : ''
    }`;
    const data = yield call(() => getPromise(url));
    if (params.templateType === 'visual_builder') {
      yield put({
        type: actions.FETCH_VISUAL_BUILDER_TEMPLATE_LIST_SUCCESS,
        payload: data,
      });
    } else {
      yield put({ type: actions.FETCH_TEMPLATE_LIST_SUCCESS, payload: data });
    }
  } catch (error) {
    if (params.templateType === 'visual_builder') {
      yield put({ type: actions.FETCH_VISUAL_BUILDER_TEMPLATE_LIST_FAILURE });
    } else {
      yield put({ type: actions.FETCH_TEMPLATE_LIST_FAILURE });
    }
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch template failed');
    }
  }
}

export function* updateDefaultTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/email_templates/update_default_template${
      params.templateType ? `?template_type=${params.templateType}` : ''
    }`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_DEFAULT_TEMPLATE_SUCCESS,
      defaultTemplateID: params.payload.email_template_id,
    });
    message.success(data.message);
  } catch (error) {
    yield put({ type: actions.UPDATE_DEFAULT_TEMPLATE_FAILURE });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update default template failed');
    }
  }
}

export function* fetchTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/email_templates/${
      params.payload
    }`;
    const data = yield call(() => getPromise(url));
    yield put({ type: actions.FETCH_TEMPLATE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.FETCH_TEMPLATE_FAILURE });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch template failed');
    }
  }
}

export function* createTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/email_templates${
      params.templateType ? `?template_type=${params.templateType}` : ''
    }`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({ type: actions.CREATE_EMAIL_TEMPLATE_SUCCESS });
    message.success(data.message, 5);
    if (params.isFromEmails) {
      yield put({
        type: emailActions.SET_CUSTOM_TEMPLATE_NAME_MODAL_VISIBILITY,
        payload: false,
      });
    } else {
      updateBrowserHistory('settings/email-templates');
    }
  } catch (error) {
    yield put({ type: actions.CREATE_EMAIL_TEMPLATE_FAILURE });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Create template failed');
    }
  }
}

export function* updateTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/email_templates/${params.id}`;
    const data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    yield put({ type: actions.UPDATE_EMAIL_TEMPLATE_SUCCESS });
  } catch (error) {
    yield put({ type: actions.UPDATE_EMAIL_TEMPLATE_FAILURE });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update template failed');
    }
  }
}

export function* deleteTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/email_templates/${
      params.payload
    }`;
    const data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({ type: actions.DELETE_EMAIL_TEMPLATE_SUCCESS });
    updateBrowserHistory('settings/email-templates');
  } catch (error) {
    yield put({ type: actions.DELETE_EMAIL_TEMPLATE_FAILURE });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors, 5);
    } else {
      message.error('Delete template failed');
    }
  }
}

export function* getInvoiceList(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/invoices`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_INVOICE_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actions.GET_INVOICE_DETAIL_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Invoice details fetch failed');
    }
  }
}

export function* getApiKeyDetail(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/access_token`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_APIKEY_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Get api key details failed');
    }
  }
}

export function* getPaymentCardList(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/billings/cards`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_PAYMENT_CARD_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Get payment card list failed');
    }
  }
}

export function* getCurrentPlanDetail(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/billings/project_subscription_plans`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_CURRENT_PLAN_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch current plan detail failed');
    }
  }
}

export function* getSharedTeamEmailDetail(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/inbound_email_address`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_SHARED_TEAM_EMAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    unAuthorisedRedirection(error, false);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Fetch shared team email detail failed');
    }
  }
}

export function* createShareTeamEmailAddress(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/inbound_email_address`;
    let data = yield call(() => postPromise(url, params.object));
    yield put({
      type: actions.CREATE_SHARED_EMAIL_ADDRESS_SUCCESS,
      payload: data.data,
    });
    if (params.fromOnboarding) {
      yield put({
        type: chatActions.CHANGE_CURRENT_CHANNEL_STEP,
        payload: {
          currentStep: 1,
        },
      });
    }
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    message.success('Create shared team email address successfully');
  } catch (error) {
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    unAuthorisedRedirection(error, false);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Create shared team email address failed');
    }
  }
}

export function* deleteSharedEmailAddress(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/inbound_email_address/${
      params.id
    }`;
    const data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({
      type: actions.DELETE_SHARED_EMAIL_ADDRESS_SUCCESS,
      payload: data.data,
      id: params.id,
    });
  } catch (error) {
    unAuthorisedRedirection(error, false);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Shared email address deleted failed');
    }
  }
}

export function* editSharedEmailAddress(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/inbound_email_address/${
      params.id
    }`;
    const data = yield call(() => patchPromise(url, params.object));
    message.success(data.message);
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    yield put({
      type: actions.EDIT_SHARED_EMAIL_ADDRESS_SUCCESS,
      payload: params.object.inbound_email_address,
      id: params.id,
    });
  } catch (error) {
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    unAuthorisedRedirection(error, false);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Shared email address update failed');
    }
  }
}

export function* fetchEmailSendingDomainList() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/custom_team_mates`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_EMAIL_SENDING_DOMAIN_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error, false);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('fetch email sending domains failed');
    }
  }
}

export function* resendEmailSendingDomain(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/custom_team_mates/${
      params.id
    }/resend`;
    const data = yield call(() => postPromise(url));
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error, false);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Resend email failed');
    }
  }
}

export function* createEmailSendDomain(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/custom_team_mates`;
    yield call(() => postPromise(url, params.formData));
    yield put({
      type: actions.OUT_BOUND_DRAWER_VISIBLE,
      payload: false,
    });
    yield put({ type: actions.FETCH_EMAIL_SENDING_DOMAIN_LIST });
    yield put({ type: actions.DISABLE_EMAIL_SEND_LOADING });
  } catch (error) {
    yield put({ type: actions.DISABLE_EMAIL_SEND_LOADING });
    unAuthorisedRedirection(error, false);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Create email failed');
    }
  }
}

export function* updateEmailSendDomain(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/custom_team_mates/${
      params.id
    }`;
    const data = yield call(() => patchPromise(url, params.formData));
    message.success(data.message);
    yield put({ type: actions.DISABLE_EMAIL_SEND_LOADING });
    yield put({
      type: actions.OUT_BOUND_DRAWER_VISIBLE,
      payload: false,
    });
    yield put({
      type: actions.UPDATE_EMAIL_SEND_DOMAIN_SUCCESS,
      payload: data.data.custom_team_mate,
    });
  } catch (error) {
    yield put({ type: actions.DISABLE_EMAIL_SEND_LOADING });
    unAuthorisedRedirection(error, false);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update email failed');
    }
  }
}

export function* deleteEmailSendDomain(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/custom_team_mates/${
      params.id
    }`;
    const data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({
      type: actions.DELETE_EMAIL_SENDING_DOMAIN_EMAIL_SUCCESS,
      id: params.id,
    });
  } catch (error) {
    unAuthorisedRedirection(error, false);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Delete email failed');
    }
  }
}

export function* verifyEmailSendingDomainPrelim(params) {
  try {
    let domain = params.domain;
    let dnsList =
      domain && Object.keys(domain).length !== 0 ? domain.dns_details : [];

    const responses = yield dnsList.map((dnsListItem) => {
      let url = `https://getgist.com/lookup/api/?domain=${
        dnsListItem.name
      }&type=${dnsListItem.type.toUpperCase()}`;
      return call(() => postPromise(url));
    });

    responses.map((response, key) => {
      let isPresent = false;
      if (dnsList[key].type === 'mx') {
        isPresent = response.mx.some(
          (data) => data.target === dnsList[key].value,
        );
      }
      if (dnsList[key].type === 'txt') {
        isPresent = response.txt.some(
          (data) => data.txt === dnsList[key].value,
        );
      }
      if (dnsList[key].type === 'cname') {
        isPresent = response.cname.some(
          (data) => data.target === dnsList[key].value,
        );
      }
      if (isPresent) {
        dnsList[key].status = true;
      }
      return true;
    });

    yield put({
      type: actions.VERIFY_EMAIL_SENDING_DOMAIN_PRELIM_SUCCESS,
      id: params.domain.id,
      payload: dnsList,
    });

    if (dnsList[0].status && dnsList[1].status && dnsList[2].status) {
      yield put({
        type: actions.VERIFY_EMAIL_SENDING_DOMAIN,
        id: params.domain.id,
      });
    } else {
      yield put({
        type: actions.VERIFY_EMAIL_SENDING_DOMAIN_FAILURE,
      });
    }
  } catch (error) {}
}

export function* verifyEmailSendingDomain(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/custom_team_mates/${
      params.id
    }/verify_outbound_domain`;
    const data = yield call(() => postPromise(url));
    trackEvent('Authenticated custom domain');
    message.success(data.message);
    yield put({
      type: actions.VERIFY_EMAIL_SENDING_DOMAIN_SUCCESS,
      id: params.id,
    });
  } catch (error) {
    yield put({ type: actions.VERIFY_EMAIL_SENDING_DOMAIN_FAILURE });
    unAuthorisedRedirection(error, false);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Verification failed');
    }
  }
}

export function* getBillingDetail(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/billings/billing_details`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_BILLING_DETAIL_SUCCESS,
      payload: payload.data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    unAuthorisedRedirection(error);
  }
}

export function* updateBillingDetail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/billings/billing_details`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
  } catch (error) {
    if (error.response && error.response === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update billing info failed');
    }
    unAuthorisedRedirection(error);
  }
}

export function* addCardDetail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/billings/cards`;
    let data = yield call(() => postPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.ADD_CARD_DETAIL_SUCCESS,
      payload: data.data,
    });
    yield put({
      type: actions.SET_ADD_CARD_DRAWER_STATUS,
      payload: false,
    });
  } catch (error) {
    if (error.response && error.response === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Add card detail failed');
    }
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  }
}

export function* removeCardDetail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/billings/cards/${params.id}`;
    const data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({ type: actions.REMOVE_CARD_DETAIL_SUCCESS, payload: params.id });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors, 5);
    } else {
      message.error('Card detail delete failed');
    }
  }
}

export function* makeDefaultCard(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/billings/cards/${
      params.id
    }/set_as_default`;
    let data = yield call(() => postPromise(url));
    message.success(data.message);
    yield put({
      type: actions.MAKE_DEFAULT_CARD_SUCCESS,
      payload: params.id,
    });
  } catch (error) {
    if (error.response && error.response === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('The card has been failed to set as the default card');
    }
    unAuthorisedRedirection(error);
  }
}

export function* payDueAmount(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/billings//project_subscription_plans/pay_due`;
    let data = yield call(() => postPromise(url));
    message.success(data.message);
    yield put({
      type: actions.PAY_DUE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    if (error.response && error.response === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Payment failed');
    }
    unAuthorisedRedirection(error);
  }
}

export function* deleteWorkSpace() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/workspaces`;
    let data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({ type: authActions.REMOVE_PROJECT_SECRET_KEY });
    history.push('/workspaces');
  } catch (error) {
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Workspace delete failed');
    }
    unAuthorisedRedirection(error);
  }
}

export function* getPlanDetail(params) {
  let queryParams = '';
  if (params.includeShopify) {
    queryParams = '?shopify_plans=true';
  }
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/billings/project_subscription_plans/eligible_plans${queryParams}`;
    const payload = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_ELIGIBLE_PLAN_DETAIL_SUCCESS,
      payload: payload.data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    message.error('Get plan detail failed');
    unAuthorisedRedirection(error);
  }
}

export function* upgradePlan({ payload }) {
  try {
    const shopifyStoreId = getQueryParamsValue('shopify_store');
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    if (
      !invalidContent.includes(shopifyStoreId) &&
      payload.canChargeWithShopify
    ) {
      yield put({
        type: authActions.CONNECT_SHOPIFY_FROM_APP,
        payload: {
          shopify_store: shopifyStoreId,
          slug_name: payload.slug_name,
        },
        secretKey: fetchProjectSecretKey(),
      });
    } else {
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/settings/billings/project_subscription_plans`;
      let data = yield call(() => patchPromise(url, payload));
      if (data.data.confirmation_url) {
        window.location.href = data.data.confirmation_url;
      } else {
        message.success(data.message);
        yield put({
          type: actions.UPGRATE_PLAN_SUCCESS,
        });
        if (!invalidContent.includes(shopifyStoreId)) {
          yield put({
            type: authActions.CONNECT_SHOPIFY_FROM_APP,
            payload: {
              shopify_store: shopifyStoreId,
            },
            secretKey: fetchProjectSecretKey(),
            isRedirect: true,
          });
        } else {
          window.location.href = 'subscription';
        }
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Upgrade plan failed');
    }
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  }
}

export function* getGdpr() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/data_and_privacy`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_GDPR_SUCCESS,
      gdprData: data.data.setting,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('fetch GDPR failed');
  }
}

export function* updateGdpr(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/data_and_privacy`;
    let data = yield call(() => patchPromise(url, params.data));
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('update qualification bot failed');
    }
  }
}

export function* fetchForms() {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/forms`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_FORMS_SUCCESS,
      formsData: data.data.setting,
    });
    yield put({
      type: sharedActions.SET_FORM_TEMPLATE_DETAILS_SETTINGS,
      payload: data.data.setting,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('fetch forms failed');
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* updateForms(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/forms`;
    let data = yield call(() => patchPromise(url, params.data));
    message.success(data.message);
    yield put({
      type: actions.UPDATE_FORMS_SUCCESS,
      payload: params.data.form_settings.capture_non_gist_forms,
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  } catch (error) {
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('update forms failed');
    }
  }
}

export function* getCustomFields() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/snippets/custom_properties`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CUSTOM_FIELDS_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Custom fields fetch failed');
    }
  }
}

export function* changeSupportAccess(params) {
  try {
    store.dispatch({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users/${params.id}`;
    let payload = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.FETCH_USER_DATA,
    });
    store.dispatch({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    message.success(payload.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Change support access failed');
    }
    store.dispatch({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* fetchBotIdentity() {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/bot`;
    let { data } = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_BOT_IDENTITY_SUCCESS,
      payload: data,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('fetch bots failed');
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* updateBotIdentity(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/bot`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.FETCH_BOT_IDENTITY,
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  } catch (error) {
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    unAuthorisedRedirection(error);
    if (error.response) {
      if (error.response.status === 422) {
        message.error(error.response.data.errors);
      } else if (error.response.status === 403) {
        message.error(error.response.data.error.message);
      }
    } else {
      message.error('update bot identity failed');
    }
  }
}

export function* createPropertyGroup(params) {
  const isUpdate = !invalidContent.includes(params.groupName.category_name);
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_properties/${
      isUpdate ? `update_category/${params.id}` : 'create_category'
    }?name=${params.name}`;
    let data;
    if (isUpdate) {
      data = yield call(() => patchPromise(url));
    } else {
      data = yield call(() => postPromise(url));
    }
    message.success(data.message);
    store.dispatch({
      type: actions.DRAWER_VISIBILITY_PROPERTY_GROUP,
      payload: false,
    });
    //TODO: check this is needed in future
    yield put({
      type: actions.CREATE_AND_UPDATE_PROPERTY_GROUP_SUCCESS,
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    yield put({ type: actions.FETCH_CONTACT_PROPERTIES });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update group failed');
    }
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    store.dispatch({
      type: actions.DRAWER_VISIBILITY_PROPERTY_GROUP,
      payload: true,
    });
  }
}

export function* deletePropertyGroup(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/contact_properties/delete_category/${
      params.id
    }`;
    const data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({ type: actions.FETCH_CONTACT_PROPERTIES });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Delete group failed');
    }
  }
}

export function* emailSettings() {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/email_settings`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_EMAIL_SETTINGS_POLICY_SUCCESS,
      payload: data.data.default_setting,
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  } catch (error) {
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    unAuthorisedRedirection(error);
    message.error('Fetch sunset policy failed');
  }
}
export function* updateEmailSettings(params) {
  const loaderType = `${params.currentType}Loader`;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/email_settings`;
    let data = yield call(() => patchPromise(url, params.data));
    message.success(data.message);
    yield put({
      type: actions.UPDATE_EMAIL_SETTINGS_IN_REDUCER,
      payload: params.data.email_settings,
    });
    yield put({
      type: actions.EMAIL_SETTINGS_SWITCH_LOADER,
      payload: {
        [loaderType]: false,
      },
    });
  } catch (error) {
    yield put({
      type: actions.EMAIL_SETTINGS_SWITCH_LOADER,
      payload: {
        [loaderType]: false,
      },
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update sunset policy failed');
    }
  }
}
export function* fetchReferrerID() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/grow_surf_participant_details`;
    let data = yield call(() => postPromise(url));
    yield put({
      type: actions.FETCH_REFERRER_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Referral fetch failed');
    }
  }
}

export function* getCustomFieldList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/custom_fields_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_FORM_CUSTOM_FIELD_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Custom fields fetch failed');
  }
}

export function* getTeammateList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/teammates_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_FORM_TEAMMATE_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Teammate details fetch failed');
  }
}

export function* settingsForm(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/forms`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.CONFIRMATION_SETTINGS_FORM_UPDATE_SUCCESS,
      payload: data.data.setting,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CONFIRMATION_SETTINGS_FORM_UPDATE_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Confirmation settings update failed');
    }
  }
}

export function* appendBlockedUsersOrIp(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    let query_param = '';
    if (params.payload.blockType === actions.APPEND_BLOCKED_IP) {
      query_param = `last_blocked_ip_id=${params.payload.lastBlockedIpId}`;
    } else {
      query_param = `last_blocked_people_id=${
        params.payload.lastBlockedUserId
      }`;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/blocked_peoples?${query_param}`;
    const data = yield call(() => getPromise(url));
    if (params.payload.blockType === actions.APPEND_BLOCKED_IP) {
      yield put({
        type: actions.APPEND_BLOCKED_IP_SUCCESS,
        payload: data.data.blocked_ips,
      });
    } else {
      yield put({
        type: actions.APPEND_BLOCKED_USERS_SUCCESS,
        payload: data.data.blocked_people,
      });
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error(
        `fetch blocked ${
          params.payload.blockType === actions.APPEND_BLOCKED_IP
            ? 'ip'
            : 'users'
        } failed`,
      );
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}
export function* getPipeLineList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.SETTINGS_GET_PIPELINE_DETAIL_SUCCESS,
      payload: data.data.pipelines,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch pipelines failed');
    }
    yield put({ type: actions.SETTINGS_GET_PIPELINE_DETAIL_FAILURE });
  }
}

export function* getStages(params) {
  const { currentPipeline } = store.getState().Settings;
  let pipelineID = currentPipeline.id;
  if (params.id) {
    pipelineID = params.id;
  }
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${pipelineID}/stages`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.SETTINGS_GET_STAGES_SUCCESS,
      payload: data.data.stages,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch stages failed');
    }
    yield put({ type: actions.SETTINGS_GET_STAGES_FAILURE });
  }
}

export function* createPipeline(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines`;
    let data = yield call(() => postPromise(url, params.data));
    message.success(data.message);
    yield put({
      type: actions.SETTINGS_CREATE_PIPELINE_SUCCESS,
      payload: data.data.pipeline,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Create pipeline failed ');
    }
    yield put({ type: actions.SETTINGS_CREATE_PIPELINE_FAILURE });
  }
}

export function* updatePipeline(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${params.id}`;
    const data = yield call(() => patchPromise(url, params.data));
    yield put({
      type: actions.SETTINGS_UPDATE_PIPELINE_SUCCESS,
      payload: { id: params.id, name: params.data.pipeline.name },
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update pipeline failed ');
    }
    yield put({ type: actions.SETTINGS_UPDATE_PIPELINE_FAILURE });
  }
}

export function* deletePipeline() {
  try {
    const { currentPipeline } = store.getState().Settings;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${currentPipeline.id}`;
    const deletePipelineResponse = yield call(() => deletePromise(url));
    message.success(deletePipelineResponse.message);
    yield put({
      type: actions.SETTINGS_DELETE_PIPELINE_SUCCESS,
      payload: { id: currentPipeline.id },
    });
    // Active the default pipeline
    yield put({
      type: actions.SETTINGS_GET_STAGES,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update pipeline failed ');
    }
  }
}

export function* createStage(params) {
  try {
    const { currentPipeline } = store.getState().Settings;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${
      currentPipeline.id
    }/stages`;
    let data = yield call(() => postPromise(url, params.data));
    yield put({
      type: actions.SETTINGS_CREATE_STAGE_SUCCESS,
      payload: data.data.stage,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Create stage failed');
    }
    yield put({ type: actions.SETTINGS_CREATE_STAGE_FAILURE });
  }
}

export function* updateStage(params) {
  try {
    const { currentPipeline } = store.getState().Settings;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${
      currentPipeline.id
    }/stages/${params.id}`;
    const data = yield call(() => patchPromise(url, params.data));
    yield put({
      type: actions.SETTINGS_UPDATE_STAGE_SUCCESS,
      id: params.id,
      ...params.data.stage,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update stage failed');
    }
    yield put({ type: actions.SETTINGS_UPDATE_STAGE_FAILURE });
  }
}

export function* deleteStage(params) {
  try {
    const { currentPipeline } = store.getState().Settings;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${
      currentPipeline.id
    }/stages/${params.id}`;
    const data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({ type: actions.SETTINGS_DELETE_STAGE_SUCCESS, id: params.id });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Stage delete failed');
    }
  }
}

export function* updateStagesPosition(params) {
  try {
    const { currentPipeline } = store.getState().Settings;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${
      currentPipeline.id
    }/update_stages_position`;
    const data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update stages position failed');
    }
  }
}

export function* fetchDealProperties() {
  try {
    let searchValue = store.getState().Settings.dealPropertiesSearchValue;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/deal_properties?search_text=${
      searchValue ? searchValue : ''
    }`;
    const dataResponse = yield call(() => getPromise(url));
    yield put({
      type: actions.SETTINGS_GET_DEAL_PROPERTIES_SUCCESS,
      payload: dataResponse.data.deal_properties,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch deal properties failed');
    }
    yield put({ type: actions.SETTINGS_GET_DEAL_PROPERTIES_FAILURE });
  }
}

export function* createDealProperty(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/deal_properties`;
    const data = yield call(() => postPromise(url, params.data));
    message.success(data.message);
    yield put({ type: actions.SETTINGS_GET_DEAL_PROPERTIES });
    yield put({ type: actions.SETTINGS_CREATE_DEAL_PROPERTY_SUCCESS });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Create deal property failed');
    }
    yield put({ type: actions.SETTINGS_CREATE_DEAL_PROPERTY_FAILURE });
  }
}

export function* updateDealProperty(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/deal_properties/${params.id}`;
    const data = yield call(() => patchPromise(url, params.data));
    message.success(data.message);
    yield put({ type: actions.SETTINGS_UPDATE_DEAL_PROPERTY_SUCCESS });
    yield put({ type: actions.SETTINGS_GET_DEAL_PROPERTIES });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update deal property failed');
    }
    yield put({ type: actions.SETTINGS_UPDATE_DEAL_PROPERTY_FAILURE });
  }
}

export function* deleteDealProperty(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/deal_properties/${params.id}`;
    const data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({
      type: actions.SETTINGS_DELETE_DEAL_PROPERTY_SUCCESS,
      id: params.id,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Delete deal property failed');
    }
    yield put({ type: actions.SETTINGS_DELETE_DEAL_PROPERTY_FAILURE });
  }
}

export function* appendDealProperties(params) {
  try {
    let searchValue = store.getState().Settings.dealPropertiesSearchValue;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/deal_properties?search_text=${
      searchValue ? searchValue : ''
    }&last_deal_property_id=${params.lastId}`;
    const dataResponse = yield call(() =>
      getPromise(url, { last_deal_property_id: params.lastId }),
    );
    yield put({
      type: actions.SETTINGS_APPEND_DEAL_PROPERTIES_SUCCESS,
      payload: dataResponse.data.deal_properties,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch deal properties failed');
    }
    yield put({ type: actions.SETTINGS_APPEND_DEAL_PROPERTIES_FAILURE });
  }
}

export function* getCompanyTrackingStatus(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/company_properties/company_tracking_status`;
    const dataResponse = yield call(() => getPromise(url, params.payload));
    yield put({
      type: actions.SETTINGS_GET_COMPANY_TRACKING_STATUS_SUCCESS,
      payload: dataResponse.data.track_company_for_person,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.SETTINGS_GET_COMPANY_TRACKING_STATUS_FAILURE });
    if (error.response && error.response.status !== 403) {
      message.error('Fetch company tracking status failed');
    }
  }
}

export function* updateCompanyTrackingStatus(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/company_properties/company_tracking`;
    const dataResponse = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.SETTINGS_UPDATE_COMPANY_TRACKING_STATUS_SUCCESS,
      payload: params.payload.track_company_for_person,
    });
    message.success(dataResponse.data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update company tracking status failed');
    }
    yield put({
      type: actions.SETTINGS_UPDATE_COMPANY_TRACKING_STATUS_SUCCESS,
    });
  }
}
export function* updateTheme(params) {
  try {
    if (params.payload.user_preference?.hidden_info_widgets) {
      yield put({
        type: authActions.SET_INFO_BANNER_VISIBILITY,
        payload: params.payload.user_preference?.hidden_info_widgets,
      });
    } else {
      yield put({
        type: authActions.UPDATE_DISPLAY_PREFERENCE,
        payload: params.payload.user_preference.theme,
      });
    }

    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/user_preferences/`;
    const dataResponse = yield call(() => patchPromise(url, params.payload));
    message.success(dataResponse.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Theme update failed');
    }
  }
}

export function* updateSupportBotFeedback(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/bot/update_support_bot_settings`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.SETTINGS_UPDATE_SUPPORT_BOT_FEEDBACK_SUCCESS,
      payload: data.data,
    });
    if (params.updateLocalIntroMessages) {
      params.updateLocalIntroMessages();
    }
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Emma feedback details update failed');
    }
  }
}

export function* getWebhooksList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/gist_webhooks`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_WEBHOOKS_LIST_SUCCESS,
      payload: data.data.gist_webhooks,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Get webhooks list failed');
    }
  }
}

export function* getSingleWebookData(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/gist_webhooks/${
      params.payload
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_SINGLE_WEBHOOK_DATA_SUCCESS,
      payload: data.data.gist_webhook,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Get webhooks list failed');
    }
  }
}

export function* getWebhooksSubscriptionTemplateData() {
  try {
    let url = `${settings.S3_BUCKET_URL}webhooks/webhook.txt`;
    const data = yield call(() => getPromise(url, false));

    yield put({
      type: actions.GET_WEBHOOKS_SUBSCRIPTIONS_TEMPLATE_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Get webhooks template data failed');
    }
  }
}

export function* updateSingleWebhookData(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });

    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/gist_webhooks/${params.id}`;
    let payload = yield call(() => patchPromise(url, params.data));
    message.success('Webhook updated successfully');
    yield put({
      type: actions.UPDATE_SINGLE_WEBHOOK_DATA_SUCCESS,
      payload: payload.data.gist_webhook,
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SET_LOADER,
      pageLoader: false,
    });
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Update webhook failed');
    }
  }
}

export function* updateSingleWebhookStatus(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/gist_webhooks/${params.id}`;
    let data = yield call(() => patchPromise(url, params.data));
    if (params.fromTable) {
      yield put({
        type: actions.GET_WEBHOOKS_LIST,
      });
    } else {
      yield put({
        type: actions.UPDATE_SINGLE_WEBHOOK_DATA_SUCCESS,
        payload: data.data.gist_webhook,
      });
    }
    message.success('Webhook updated successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SET_LOADER,
      pageLoader: false,
    });
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Update webhook failed');
    }
  }
}

export function* createSingleWebhook(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });

    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/gist_webhooks`;
    let data = yield call(() => postPromise(url, params.data));
    message.success('Webhook created successfully');
    yield put({
      type: actions.CREATE_SINGLE_WEBHOOK_SUCCESS,
      payload: data.data.gist_webhook,
    });

    updateBrowserHistory(`settings/webhooks/${data.data.gist_webhook.id}`);

    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Webhook create failed');
    }
  }
}

export function* deleteSingleWebhook(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/gist_webhooks/${params.id}`;
    let payload = yield call(() => deletePromise(url));
    message.success(payload.data);
    yield put({
      type: actions.GET_WEBHOOKS_LIST,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Deleting webhook failed');
    }
  }
}

export function* sendSampleWebhook(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/gist_webhooks/send_test_webhook`;
    let payload = yield call(() => postPromise(url, params.data));
    message.success(payload.data);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Send sample webhook failed');
    }
  }
}

export function* getMailSubscription() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_subscriptions`;
    let response = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_MAIL_SUBSCRIPTION_DATA_SUCCESS,
      payload: response.data.mail_subscriptions,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Get subscription types data failed');
    }
  }
}

export function* createMailSubscription(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_subscriptions`;
    let response = yield call(() => postPromise(url, params.data));
    yield put({
      type: actions.MAIL_SUBSCRIPTION_DRAWER_VISIBLE,
      payload: false,
    });
    yield put({
      type: actions.GET_MAIL_SUBSCRIPTION_DATA,
    });
    message.success(response.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Create subscription type failed');
    }
  }
}

export function* deleteMailSubscription(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_subscriptions/${params.id}`;
    let response = yield call(() => deletePromise(url));
    yield put({
      type: actions.GET_MAIL_SUBSCRIPTION_DATA,
    });
    message.success(response.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Delete subscription type failed');
    }
  }
}

export function* updateMailSubscription(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_subscriptions/${params.id}`;
    let response = yield call(() => patchPromise(url, params.data));
    yield put({
      type: actions.MAIL_SUBSCRIPTION_DRAWER_VISIBLE,
      payload: false,
    });
    yield put({
      type: actions.SET_MAIL_SUBSCRIPTION_DETAILS,
      payload: {},
    });
    yield put({
      type: actions.GET_MAIL_SUBSCRIPTION_DATA,
    });
    message.success(response.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Update subscription type failed');
    }
  }
}

export function* changeSubscriptionPosition(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_subscriptions/update_display_order`;
    let response = yield call(() => postPromise(url, params.data));
    if (response && response.message) {
      message.success(response.message);
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Change subscription type position failed');
    }
  }
}

export function* fetchConversationProperties(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const { with_options, with_hidden } = params.payload;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/conversation_properties?with_options=${with_options}&with_hidden=${with_hidden}`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CONVERSATION_PROPERTIES_SUCCESS,
      conversationPropertiesData: payload.data.conversation_properties,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch conversation properties failed');
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* createConversationProperty(params) {
  try {
    yield put({
      type: actions.SET_LOADER,
      loader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/conversation_properties`;
    let payload = yield call(() => postPromise(url, params.data));
    message.success(payload.message);
    yield put({
      type: actions.FETCH_CONVERSATION_PROPERTIES,
      payload: {
        with_options: false,
        with_hidden: true,
      },
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
    store.dispatch({
      type: actions.DRAWER_VISIBILITY_CONVERSATION_PROPERTY,
      payload: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('create conversation properties failed');
    }
    store.dispatch({
      type: actions.DRAWER_VISIBILITY_CONVERSATION_PROPERTY,
      payload: true,
      isCreate: true,
    });
    yield put({
      type: actions.SET_LOADER,
      loader: false,
    });
  }
}

export function* updateConversationProperty(params) {
  try {
    if (params.isFromTable) {
      yield put({
        type: actions.SET_PAGE_LOADER,
        pageLoader: true,
      });
    } else {
      yield put({
        type: actions.SET_LOADER,
        loader: true,
      });
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/conversation_properties/${
      params.data.conversation_property.id
    }`;
    let payload = yield call(() => patchPromise(url, params.data));
    message.success(payload.message);
    yield put({
      type: actions.FETCH_CONVERSATION_PROPERTIES,
      payload: {
        with_options: false,
        with_hidden: true,
      },
    });
    if (!params.isFromTable) {
      store.dispatch({
        type: actions.DRAWER_VISIBILITY_CONVERSATION_PROPERTY,
        payload: false,
      });
      yield put({
        type: actions.SET_LOADER,
        loader: false,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('update conversation properties failed');
    }
    if (params.isFromTable) {
      yield put({
        type: actions.SET_PAGE_LOADER,
        pageLoader: false,
      });
    } else {
      store.dispatch({
        type: actions.DRAWER_VISIBILITY_CONVERSATION_PROPERTY,
        payload: true,
      });
      yield put({
        type: actions.SET_LOADER,
        loader: false,
      });
    }
  }
}

export function* deleteConversationProperty(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/conversation_properties/${
      params.data.conversation_property.id
    }`;
    let payload = yield call(() => deletePromise(url));
    message.success(payload.message);
    yield put({
      type: actions.FETCH_CONVERSATION_PROPERTIES,
      payload: {
        with_options: false,
        with_hidden: true,
      },
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    if (error.response && error.response.status !== 404) {
      unAuthorisedRedirection(error);
    }
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('delete contact properties failed');
    }
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* getIndividualProperty(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/conversation_properties/${
      params.payload.id
    }`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_INDIVIDUAL_PROPERTY_SUCCESS,
      conversationProperty: payload.data.conversation_property,
    });
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch conversation properties failed');
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  }
}

export function* getIndividualConversationProperty(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations/${
      params.payload
    }/conversation_properties`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_INDIVIDUAL_CONVERSATION_PROPERTIES_SUCCESS,
      payload: payload.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch conversation properties failed');
  }
}

export function* updateIndividualConversationProperty(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations/${
      params.payload
    }/update_conversation_property`;
    let payload = yield call(() => patchPromise(url, params.object));
    yield put({
      type: actions.UPDATE_INDIVIDUAL_CONVERSATION_PROPERTY_SUCCESS,
    });
    message.success(payload.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch conversation properties failed');
  }
}

export function* fetchConversationPropertyWithOptions(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/conversation_properties/${
      params.payload
    }`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CONTACT_CONVERSATION_PROPERTY_WITH_OPTIONS_SUCCESS,
      payload: payload.data.conversation_property,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch conversation properties failed');
  }
}

export function* outlookAccountOauth(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const { payload } = params;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/email_accounts/authorize`;
    yield call(() => postPromise(url, payload));
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    history.push('/projects/microsoft-callback');
  } catch (error) {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    history.push('/projects/microsoft-callback');
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Calendar integration failed!');
    }
  }
}

export function* updatePrimaryAccount(params) {
  try {
    const { payload } = params;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/email_accounts/update_primary_account`;
    const data = yield call(() => patchPromise(url, payload));
    yield put({
      type: actions.UPDATE_PRIMARY_CALENDAR_ACCOUNT_SUCCESS,
      payload: payload.primary_email_account_id,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update primary calendar failed!');
    }
  }
}

export function* fetchAccountDetails(params) {
  try {
    const { payload } = params;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/email_accounts/${
      params.payload
    }`;
    const data = yield call(() => getPromise(url, payload));
    yield put({
      type: actions.FETCH_CALENDAR_ACCOUNT_DETAILS_SUCCESS,
      payload: data.data,
      accountId: params.payload,
    });
  } catch (error) {
    yield put({ type: actions.AVAILABLE_CALENDAR_INTEGRATIONS_FAILURE });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch calendar details failed!');
    }
  }
}

export function* fetchHupspotProperties() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/hubspot_app/mapping_details`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_HUBSPOT_PROPERTIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_HUBSPOT_PROPERTIES_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch calendar details failed!');
    }
  }
}

export function* saveHubspotMappingProperties(params) {
  try {
    const { payload } = params;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/hubspot_app/save_mapping_details`;
    const data = yield call(() => postPromise(url, payload));
    message.success(data.data);
    yield put({
      type: actions.SAVE_HUBSPOT_MAPPING_PROPERITIES_SUCCESS,
      payload: payload.hubspot_app.hubspot_properties_mapping,
    });
  } catch (error) {
    yield put({
      type: actions.SAVE_HUBSPOT_MAPPING_PROPERITIES_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch calendar details failed!');
    }
  }
}

export function* syncHubspotHistoryData() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/hubspot_app/sync_history_data`;
    const response = yield call(() => postPromise(url));
    message.success(response.data);
    yield put({ type: actions.SYNC_HUBSPOT_HISTORY_DATA_SUCCESS });
  } catch (error) {
    yield put({ type: actions.SYNC_HUBSPOT_HISTORY_DATA_FAILURE });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Sync Hubspot history data failed!');
    }
  }
}

export function* getMessengerAppPreferences() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/app_preferences`;
    let { data } = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_MESSENGER_APP_PREFERENCES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: actions.GET_MESSENGER_APP_PREFERENCES_FAILURE });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch messenger app preferences failed!');
    }
  }
}

export function* updateMessengerAppPreferences(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/messenger/app_preferences/update_app_preference_setting`;
    yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_MESSENGER_APP_PREFERENCES_SUCCESS,
    });
    message.success('App preferences updated successfully');
  } catch (error) {
    yield put({ type: actions.UPDATE_MESSENGER_APP_PREFERENCES_FAILURE });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update messenger app preferences failed!');
    }
  }
}

export function* settingsCountry() {
  try {
    const data = yield call(() => getPromise(COUNTRY_URL));
    yield put({
      type: actions.SETTINGS_COUNTRY_SUCCESS,
      countryList: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('fetch countries failed');
    }
  }
}

export function* getSpamFilters() {
  try {
    const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/settings/get_spam_filter_settings`,
      data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_SPAM_FILTERS_DATA_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.GET_SPAM_FILTERS_DATA_FAILURE });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch spam filters failed');
    }
  }
}

export function* updateSpamFilters(params) {
  const { payload, updateBlockedDomains } = params;
  try {
    const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/settings/update_spam_filter_settings`,
      data = yield call(() => patchPromise(url, payload));
    yield put({
      type: actions.UPDATE_SPAM_FILTERS_DATA_SUCCESS,
      payload: data.data,
    });
    if (updateBlockedDomains) {
      yield updateBlockedDomains();
    }
    message.success(data.message || 'Spam filters updated successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.UPDATE_SPAM_FILTERS_DATA_FAILURE });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update spam filters failed');
    }
  }
}

export function* getLiveBotList() {
  try {
    const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/assistants/bots?bot_type=instant_bot&status=live&search_text=&sort_key=priority&sort_type=asc&tag_ids=&from_setting=true`,
      data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_LIVE_BOT_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.FETCH_LIVE_BOT_LIST_FAILURE });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch live bots list failed');
    }
  }
}

export function* updateSupportBot(params) {
  try {
    const { payload, deleteIntroMessage } = params,
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/settings/messenger/bot/update_support_bot_setting`,
      response = yield call(() => patchPromise(url, payload));
    message.success(
      response.message || 'Emma details are updated successfully.',
    );
    if (deleteIntroMessage) {
      yield deleteIntroMessage();
    }
    yield put({
      type: actions.UPDATE_SETTINGS_SUPPORT_BOT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.UPDATE_SETTINGS_SUPPORT_BOT_FAILURE });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Emma details update failed');
    }
  }
}

// KB themes
export function* fetchKbThemesList() {
  try {
    const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/kb_themes`,
      data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_KB_THEMES_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch themes list failed');
    yield put({
      type: actions.FETCH_KB_THEMES_LIST_FAILURE,
    });
  }
}

export function* deleteKbTheme(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/kb_themes/${params.themeId}`;
    let data = yield call(() => deletePromise(url));
    yield put({
      type: actions.FETCH_KB_THEMES_LIST,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Delete theme failed');
  }
}

export function* duplicateKbTheme(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/kb_themes/duplicate`;
    let data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.DUPLICATE_KB_THEME_SUCCESS,
      payload: data.data,
    });
    updateBrowserHistory(`settings/kb-themes/${data.data.id}`);
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Duplicate theme failed');
    yield put({
      type: actions.DUPLICATE_KB_THEME_FAILURE,
    });
  }
}

export function* activeKbTheme(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/kb_themes/set_as_active`;
    let data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.SET_ACTIVE_KB_THEME_SUCCESS,
      payload: params.payload.kb_theme.id,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Set active theme failed');
  }
}

export function* fetchKbThemeDetails(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/kb_themes/${params.themeId}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_KB_THEME_DETAILS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch theme details failed');
    yield put({
      type: actions.FETCH_KB_THEME_DETAILS_FAILURE,
    });
  }
}

export function* fetchKbLiquidFileData(params) {
  const { fileName, themeId, extension } = params.payload;
  try {
    let url = `${settings.KB_PREVIEW_THEMES_CDN}themes/${themeId}/${fileName}${extension}`;
    const data = yield call(() => axios.get(url));
    yield put({
      type: actions.FETCH_KB_LIQUID_FILE_DATA_SUCCESS,
      payload: data.data,
      fileName,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch liquid file failed');
    yield put({
      type: actions.FETCH_KB_LIQUID_FILE_DATA_FAILURE,
    });
  }
}

export function* saveDraftEditorLiquid(params) {
  try {
    const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/kb_themes/${
        params.themeId
      }/save_draft`,
      data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.SAVE_DRAFT_KB_THEME_EDITOR_LIQUID_SUCCESS,
      payload: data.data,
      isForNameUpdate: params.isForNameUpdate,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Save draft liquid file failed');
  }
}

export function* publishKbTheme(params) {
  try {
    const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/kb_themes/${params.themeId}/publish`,
      data = yield call(() => postPromise(url, params.payload));
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Publish KB theme failed');
  }
}

export function* redeemCouponsSubmit(params) {
  try {
    const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/redeem_coupon`,
      data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_PLAN_SUBSCRIPTION_USAGES,
      payload: data,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Redeem coupons submit failed');
  }
}

export function* fetchSlackNotifications() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/slack_notifications`;
    const response = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_SLACK_NOTIFICATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_SLACK_NOTIFICATIONS_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch slack notifications failed!');
    }
  }
}

export function* fetchSlackChannelsAndEvents() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/slack_channels_and_events`;
    const response = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_SLACK_CHANNELS_AND_EVENTS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_SLACK_CHANNELS_AND_EVENTS_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch slack channels and events failed!');
    }
  }
}

export function* saveSlackNotification(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/slack_notifications`;
    const response = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.SAVE_SLACK_NOTIFICATION_SUCCESS,
      payload: response.data.slack_notification,
    });
    message.success(response.message || 'Slack notification add successfully');
    yield put({
      type: actions.SET_SLACK_NOTIFICATION_ADD_EDIT_FORM_ENABLE,
      payload: {
        visible: false,
      },
    });
    if (params.isCreateNewChannel) {
      yield put({
        type: actions.APPEND_SLACK_CHANNEL,
        payload: {
          id: response.data.slack_notification.channel,
          name: params.payload.slack_notification['channel_name'],
        },
      });
    }
  } catch (error) {
    yield put({
      type: actions.SAVE_SLACK_NOTIFICATION_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Add slack notification is failed!');
    }
  }
}

export function* updateSlackNotification(params) {
  const { payload, notificationId, isCreateNewChannel } = params;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/slack_notification/${notificationId}`;
    const response = yield call(() => patchPromise(url, payload));
    yield put({
      type: actions.UPDATE_SLACK_NOTIFICATION_SUCCESS,
      payload: response.data.slack_notification,
    });
    message.success(
      response.message || 'Slack notification updated successfully',
    );
    yield put({
      type: actions.SET_SLACK_NOTIFICATION_ADD_EDIT_FORM_ENABLE,
      payload: {
        visible: false,
      },
    });
    if (isCreateNewChannel) {
      yield put({
        type: actions.APPEND_SLACK_CHANNEL,
        payload: {
          id: response.data.slack_notification.channel,
          name: payload.slack_notification['channel_name'],
        },
      });
    }
  } catch (error) {
    yield put({
      type: actions.UPDATE_SLACK_NOTIFICATION_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update slack notification is failed!');
    }
  }
}

export function* deleteSlackNotification(params) {
  const { notificationId } = params;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/slack_notification/${notificationId}`;
    const response = yield call(() => deletePromise(url));
    yield put({
      type: actions.DELETE_SLACK_NOTIFICATION_SUCCESS,
      notificationId,
    });
    message.success(response.data || 'Slack notification deleted successfully');
    yield put({
      type: actions.SET_SLACK_NOTIFICATION_ADD_EDIT_FORM_ENABLE,
      payload: {
        visible: false,
      },
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_SLACK_NOTIFICATION_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Delete slack notification is failed!');
    }
  }
}

export function* fetchSlackAuthUrl(params) {
  const { projectId } = params;
  try {
    const url = `${settings.ROOT_URL}projects/${projectId}/slack_auth`;
    const response = yield call(() => getPromise(url));
    if (response.action === 'redirect') {
      window.open(response.url, '_self');
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors, 3000);
    } else {
      message.error('Fetch slack channels and events failed!');
    }
  }
}
export function* fetchIdentityVerification() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/identity_token`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_IDENTITY_VERIFICATION_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_IDENTITY_VERIFICATION_FAILED,
    });
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch identify verification failed!');
  }
}

export function* updateIdentityVerification(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/identity_token`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_IDENTITY_VERIFICATION_SUCCESS,
      payload: data.data,
    });
    message.success('Update identify verification success');
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Update identify verification failed!');
  }
}

export function* generateUserHash(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/generate_user_hash`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.GENERATE_USER_HASH_SUCCESS,
      payload: data.data,
    });
  } catch (e) {
    unAuthorisedRedirection(e);
    unProcessableEntity(e, 'User hash generation failed!');
  }
}

export function* fetchPushNotificationsList() {
  try {
    const url = `${
        settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/app_credentials`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_PUSH_NOTIFICATIONS_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error, false);
    yield put({
      type: actions.FETCH_PUSH_NOTIFICATIONS_LIST_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('fetch push notifications failed');
    }
  }
}

export function* deletePushNotification(params) {
  try {
    const url = `${
        settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/app_credentials/${params.id}`;
    const data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({
      type: actions.FETCH_PUSH_NOTIFICATIONS_LIST,
    });
  } catch (error) {
    unAuthorisedRedirection(error, false);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Delete push notification failed');
    }
  }
}

export function* createPushNotifications(params) {
  try {
    const url = `${
        settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/app_credentials`;
    const data = yield call(() => postPromise(url, params.formData));
    yield put({
      type: actions.PUSH_NOTIFICATIONS_DRAWER_VISIBLE,
      payload: false,
    });
    yield put({
      type: actions.REMOVE_CERTIFICATE_DETAILS,
    });
    yield put({
      type: actions.SET_AUTHENTICATION_TYPE,
      payload: '',
    });
    yield put({
      type: actions.SET_APP_TYPE,
      payload: undefined,
    });
    yield put({ type: actions.FETCH_PUSH_NOTIFICATIONS_LIST });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error, false);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Create push notification failed');
    }
  }
}

export function* updatePushNotifications(params) {
  try {
    const url = `${
        settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/app_credentials/${params.id}`;
    const data = yield call(() => patchPromise(url, params.formData));
    yield put({
      type: actions.PUSH_NOTIFICATIONS_DRAWER_VISIBLE,
      payload: false,
    });
    yield put({
      type: actions.FETCH_PUSH_NOTIFICATIONS_LIST,
    });
    yield put({
      type: actions.REMOVE_CERTIFICATE_DETAILS,
    });
    yield put({
      type: actions.SET_AUTHENTICATION_TYPE,
      payload: '',
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error, false);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Update push notification failed');
    }
  }
}

// NewsCenter Web Portal
export function* updateNewsCenterPortalSettings(params) {
  try {
    let data = { ...newsCenterSettings.portal, ...params.values };
    yield put({
      type: actions.UPDATE_NEWSCENTER_PORTAL_SETTINGS_SUCCESS,
      payload: data,
    });

    // TODO Jitta - Need to connect with backend API and update reducer
    // const url = `${
    //   settings.ROOT_URL
    // }projects/${fetchProjectSecretKey()}/settings/newscenter_portal`;
    // let data = yield call(() => patchPromise(url, params.values));
    // message.success(data.message);
    // yield put({
    //   type: actions.UPDATE_NEWSCENTER_PORTAL_SETTINGS_SUCCESS,
    //   payload: data.data.newscenter_portal_settings,
    // });

    // TODO Jitta - Need to set the domain connected status
    // yield put({
    //   type: actions.SET_NEWSCENTER_PORTAL_DOMAIN_TAB_KEY,
    //   payload: 1,
    // });
  } catch (error) {
    // TODO Jitta - Need to handle domain connect failure
    // yield put({
    //   type: actions.NEWSCENTER_PORTAL_CUSTOM_DOMAIN_UPDATE_FAILURE,
    //   payload: params.values,
    //   error: error.response.data.errors,
    // });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (!params.connectDomain) {
        message.error(error.response.data.errors);
      }
    } else if (error.response && error.response.status !== 403) {
      message.error('Update portal settings failed');
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_WEBHOOKS_LIST, getWebhooksList),
    takeEvery(actions.GET_SINGLE_WEBHOOK_DATA, getSingleWebookData),
    takeEvery(
      actions.GET_WEBHOOKS_SUBSCRIPTIONS_TEMPLATE_DATA,
      getWebhooksSubscriptionTemplateData,
    ),
    takeEvery(actions.UPDATE_SINGLE_WEBHOOK_DATA, updateSingleWebhookData),
    takeEvery(actions.UPDATE_SINGLE_WEBHOOK_STATUS, updateSingleWebhookStatus),
    takeEvery(actions.CREATE_SINGLE_WEBHOOK, createSingleWebhook),
    takeEvery(actions.DELETE_SINGLE_WEBHOOK, deleteSingleWebhook),
    takeEvery(actions.SEND_SAMPLE_WEBHOOOK, sendSampleWebhook),
    takeEvery(actions.FETCH_GENERAL_INFO, fetchGeneralInfo),
    takeEvery(actions.PATCH_GENERAL_INFO, patchGeneralInfo),
    takeEvery(actions.FETCH_BRANDING_INFO, fetchBrandingInfo),
    takeEvery(actions.PATCH_BRANDING_INFO, patchBrandingInfo),
    takeEvery(actions.FETCH_TEAMS, fetchTeams),
    takeEvery(actions.FETCH_USERS_LIST, fetchUsersList),
    takeEvery(actions.CREATE_TEAM, createTeam),
    takeEvery(actions.DELETE_TEAM, deleteTeam),
    takeEvery(actions.CREATE_OR_UPDATE_ROLE, createOrUpdateRole),
    takeEvery(actions.DELETE_ROLE, deleteRole),
    takeEvery(actions.FETCH_ROLES, fetchRoles),
    takeEvery(actions.FETCH_ROLE_LIST, fetchRoleList),
    takeEvery(actions.FETCH_TEAMMATES_LIST, fetchTeamMatesList),
    takeEvery(actions.FETCH_USER_DATA, fetchUserData),
    takeEvery(actions.FETCH_PROJECT_PLAN_DETAIL, fetchProjectPlanDetail),
    takeEvery(actions.CHANGE_STATUS_AND_HIDE, changeStatusAndHide),
    takeEvery(actions.INVITE_USER, inviteUser),
    takeEvery(actions.UPDATE_USER_PERMISSIONS, updateUserPermissions),
    takeEvery(actions.DELETE_USER, deleteUser),
    takeEvery(actions.DELETE_INVITE, deleteInvite),
    takeEvery(actions.RESEND_INVITE, resendInvite),
    takeEvery(actions.FETCH_ACTIVE_USER, fetchActiveUser),
    takeEvery(actions.TRANSFER_OWNERSHIP, transferOwnership),
    takeEvery(actions.FETCH_CONTACT_PROPERTIES, fetchContactProperties),
    takeEvery(
      actions.FETCH_CONVERSATION_PROPERTIES,
      fetchConversationProperties,
    ),
    takeEvery(actions.DELETE_CONTACT_PROPERTY, deleteContactProperty),
    takeEvery(actions.UPDATE_CONTACT_PROPERTY, updateContactProperty),
    takeEvery(actions.CREATE_CONTACT_PROPERTY, createContactProperty),
    takeEvery(actions.FETCH_CONTACT_TAGS, fetchContactTags),
    takeEvery(actions.APPEND_CONTACT_TAGS, appendContactTags),
    takeEvery(actions.DELETE_CONTACT_TAG, deleteContactTag),
    takeEvery(actions.UPDATE_CONTACT_TAG, updateContactTag),
    takeEvery(actions.CREATE_CONTACT_TAG, createContactTag),
    takeEvery(actions.UPDATE_CONTACT_SEGMENT, updateContactSegment),
    takeEvery(actions.FETCH_CONTACT_SEGMENTS, fetchContactSegments),
    takeEvery(actions.APPEND_CONTACT_SEGMENT, appendContactSegment),
    takeEvery(actions.DELETE_CONTACT_SEGMENT, deleteContactSegment),
    takeEvery(actions.FETCH_DOMAINS, fetchPrimaryDomain),
    takeEvery(actions.UPDATE_DOMAIN, updatePrimaryDomain),
    takeEvery(actions.DELETE_DOMAIN, deleteDomain),
    takeEvery(actions.ADD_DOMAIN, addDomain),
    takeEvery(actions.EMAIL_TO_DEVELOPER, emailToDeveloper),
    takeEvery(actions.FETCH_EXPORTS_LIST, fetchExportsList),
    takeEvery(actions.APPEND_EXPORTS, appendExports),
    takeEvery(actions.FETCH_IMPORTS_LIST, fetchImportsList),
    takeEvery(actions.APPEND_IMPORTS, appendImports),
    takeEvery(
      actions.FETCH_QUALIFICATION_DATA_LIST,
      fetchQualificationDataList,
    ),
    takeEvery(
      actions.FETCH_QUALIFICATION_DATA_MODAL_LIST,
      fetchQualificationDataModalList,
    ),
    takeEvery(actions.ADD_QUALIFICATION_DATA, addQualificationData),
    takeEvery(actions.DELETE_QUALIFICATION_DATA, deleteQualificationData),
    takeEvery(actions.FETCH_CHAT_TAGS, fetchChatTags),
    takeEvery(actions.APPEND_CHAT_TAGS, appendChatTags),
    takeEvery(actions.UPDATE_CHAT_TAGS, updateChatTags),
    takeEvery(actions.CREATE_CHAT_TAGS, createChatTags),
    takeEvery(actions.DELETE_CHAT_TAGS, deleteChatTags),
    takeEvery(actions.CREATE_SNIPPET_FOLDER_DATA, createSnippetFolderData),
    takeEvery(actions.UPDATE_SNIPPET_FOLDER_DATA, updateSnippetFolderData),
    takeEvery(actions.DELETE_SNIPPET_FOLDER_DATA, deleteSnippetFolderData),
    takeEvery(actions.FETCH_SNIPPET_DATA, fetchSnippetData),
    takeEvery(actions.FETCH_SINGLE_SNIPPET_DATA, fetchSingleSnippetData),
    takeEvery(actions.APPEND_SNIPPET_DATA, appendSnippetData),
    takeEvery(actions.UPDATE_SNIPPET_DATA, updateSnippetData),
    takeEvery(actions.CREATE_SNIPPET_DATA, createSnippetData),
    takeEvery(actions.DELETE_SNIPPET_DATA, deleteSnippetData),
    takeEvery(actions.FETCH_APPEARANCE_DATA, fetchAppearanceData),
    takeEvery(actions.UPDATE_APPEARANCE_VALUES, updateAppearanceData),
    takeEvery(actions.UPDATE_MESSENGER_DEFAULTS, updateMessengerDefaults),
    takeEvery(actions.FETCH_COLOR_VALUES, fetchColorValues),
    takeEvery(actions.FETCH_AVAILABILITY_DATA, fetchAvailabilityData),
    takeEvery(actions.UPDATE_AVAILABILITY_DATA, updateAvailabilityData),
    takeEvery(
      actions.UPDATE_QUALIFICATION_BOT_DATA,
      updateQualificationBotData,
    ),
    takeEvery(actions.FETCH_QUALIFICATION_BOT_DATA, fetchQualificationBotData),
    takeEvery(actions.FETCH_LANGUAGE_DATA, fetchLanguageData),
    takeEvery(actions.FETCH_LANGUAGES, fetchLanguages),
    takeEvery(actions.UPDATE_LANGUAGE_DATA, updateLanguageData),
    takeEvery(actions.FETCH_VISIBILITY_DATA, fetchVisibilityData),
    takeEvery(actions.FETCH_VISIBILITY_DROPDOWN, fetchVisibilityDropdown),
    takeEvery(actions.UPDATE_VISIBILITY_DATA, updateVisibilityData),
    takeEvery(
      actions.UPDATE_VISIBILITY_ENABLED_PATHS,
      updateVisibilityEnabledPaths,
    ),
    takeEvery(actions.FETCH_BLOCKED_IP, fetchBlockedIP),
    takeEvery(actions.DELETE_BLOCKED_IP, deleteBlockedIP),
    takeEvery(actions.FETCH_BLOCKED_USERS, fetchBlockedUsers),
    takeEvery(actions.DELETE_BLOCKED_USER, deleteBlockedUser),
    takeEvery(actions.FETCH_ADVANCED_TAB, fetchAdvancedData),
    takeEvery(actions.UPDATE_ADVANCED_TAB, updateAdvancedData),
    takeEvery(
      actions.FETCH_MEETINGS_PROFILE_DETAILS,
      fetchMeetingsProfileDetails,
    ),
    takeEvery(
      actions.UPDATE_MEETINGS_PROFILE_DETAILS,
      updateMeetingsProfileDetails,
    ),
    takeEvery(actions.AVAILABLE_CALENDAR_INTEGRATIONS, availableCalendars),
    takeEvery(actions.GOOGLE_ACCOUNT_OAUTH, googleAccountOauth),
    takeEvery(actions.DISCONNECT_CALENDAR_ACCOUNT, disconnectCalendarAccount),
    takeEvery(actions.OUTLOOK_ACCOUNT_OAUTH, outlookAccountOauth),
    takeEvery(actions.UPDATE_PRIMARY_CALENDAR_ACCOUNT, updatePrimaryAccount),
    takeEvery(actions.FETCH_CALENDAR_ACCOUNT_DETAILS, fetchAccountDetails),
    takeEvery(actions.FETCH_UNAVAILABILITIES, fetchUnavailabilities),
    takeEvery(actions.UPDATE_UNAVAILABILITIES, updateUnavailabilities),
    takeEvery(actions.UPDATE_AVAILABLE_CALENDARS, updateAvailableCalendars),
    takeEvery(actions.UPDATE_DEFAULT_MEETING_LINK, updateDefaultMeetingLink),
    takeEvery(actions.FETCH_TEMPLATE_LIST, fetchTemplateList),
    takeEvery(actions.UPDATE_DEFAULT_TEMPLATE, updateDefaultTemplate),
    takeEvery(actions.FETCH_TEMPLATE, fetchTemplate),
    takeEvery(actions.CREATE_EMAIL_TEMPLATE, createTemplate),
    takeEvery(actions.UPDATE_EMAIL_TEMPLATE, updateTemplate),
    takeEvery(actions.DELETE_EMAIL_TEMPLATE, deleteTemplate),
    takeEvery(actions.GET_INVOICE_DETAIL, getInvoiceList),
    takeEvery(actions.FETCH_KB_SETTINGS, fetchKbSettings),
    takeEvery(actions.UPDATE_KB_SETTINGS, updateKbSettings),
    takeEvery(actions.FETCH_KB_COLORS_LIST, fetchKbColorList),
    takeEvery(actions.FETCH_KB_LANGUAGE_LIST, fetchKbLanguageList),
    takeEvery(actions.KB_CUSTOM_DOMAIN_UPDATE, kbCustomDomainUpdate),
    takeEvery(actions.GET_APIKEY_DETAIL, getApiKeyDetail),

    takeEvery(actions.GET_PAYMENT_CARD_LIST, getPaymentCardList),
    takeEvery(actions.GET_CURRENT_PLAN_DETAIL, getCurrentPlanDetail),
    takeEvery(actions.GET_SHARED_TEAM_EMAIL, getSharedTeamEmailDetail),
    takeEvery(actions.CREATE_SHARED_EMAIL_ADDRESS, createShareTeamEmailAddress),
    takeEvery(actions.DELETE_SHARED_EMAIL_ADDRESS, deleteSharedEmailAddress),
    takeEvery(actions.RESEND_EMAIL_SENDING_DOMAIN, resendEmailSendingDomain),
    takeEvery(
      actions.FETCH_EMAIL_SENDING_DOMAIN_LIST,
      fetchEmailSendingDomainList,
    ),
    takeEvery(actions.CREATE_EMAIL_SEND_DOMAIN, createEmailSendDomain),
    takeEvery(actions.UPDATE_EMAIL_SEND_DOMAIN, updateEmailSendDomain),
    takeEvery(actions.EDIT_SHARED_EMAIL_ADDRESS, editSharedEmailAddress),
    takeEvery(actions.DELETE_EMAIL_SENDING_DOMAIN_EMAIL, deleteEmailSendDomain),
    takeEvery(
      actions.VERIFY_EMAIL_SENDING_DOMAIN_PRELIM,
      verifyEmailSendingDomainPrelim,
    ),
    takeEvery(actions.VERIFY_EMAIL_SENDING_DOMAIN, verifyEmailSendingDomain),
    takeEvery(actions.GET_BILLING_DETAIL, getBillingDetail),
    takeEvery(actions.UPDATE_BILLING_DETAIL, updateBillingDetail),
    takeEvery(actions.DELETE_WORKSPACE, deleteWorkSpace),
    takeEvery(actions.ADD_CARD_DETAIL, addCardDetail),
    takeEvery(actions.REMOVE_CARD_DETAIL, removeCardDetail),
    takeEvery(actions.MAKE_DEFAULT_CARD, makeDefaultCard),
    takeEvery(actions.PAY_DUE, payDueAmount),

    takeEvery(actions.GET_ELIGIBLE_PLAN_DETAIL, getPlanDetail),
    takeEvery(actions.UPGRATE_PLAN, upgradePlan),
    takeEvery(actions.GET_GDPR, getGdpr),
    takeEvery(actions.UPDATE_GDPR, updateGdpr),
    takeEvery(actions.FETCH_FORMS, fetchForms),
    takeEvery(actions.UPDATE_FORMS, updateForms),
    takeEvery(actions.FETCH_CUSTOM_FIELDS_LIST, getCustomFields),
    takeEvery(actions.CHANGE_SUPPORT_ACCESS, changeSupportAccess),
    takeEvery(actions.FETCH_BOT_IDENTITY, fetchBotIdentity),
    takeEvery(actions.UPDATE_BOT_IDENTITY, updateBotIdentity),
    takeEvery(actions.CREATE_AND_UPDATE_PROPERTY_GROUP, createPropertyGroup),
    takeEvery(actions.DELETE_PROPERTY_GROUP, deletePropertyGroup),

    takeEvery(actions.EMAIL_SETTINGS, emailSettings),
    takeEvery(actions.UPDATE_EMAIL_SETTINGS, updateEmailSettings),
    takeEvery(actions.FETCH_REFERRER_ID, fetchReferrerID),
    takeEvery(actions.CONFIRMATION_SETTINGS_FORM_UPDATE, settingsForm),
    takeEvery(actions.FETCH_REFERRER_ID, fetchReferrerID),
    takeEvery(actions.APPEND_BLOCKED_USERS_OR_IP, appendBlockedUsersOrIp),
    takeEvery(actions.SETTINGS_GET_PIPELINE_DETAIL, getPipeLineList),
    takeEvery(actions.SETTINGS_GET_STAGES, getStages),
    takeEvery(actions.SETTINGS_CREATE_PIPELINE, createPipeline),
    takeEvery(actions.SETTINGS_UPDATE_PIPELINE, updatePipeline),
    takeEvery(actions.SETTINGS_CREATE_STAGE, createStage),
    takeEvery(actions.SETTINGS_UPDATE_STAGE, updateStage),
    takeEvery(actions.SETTINGS_DELETE_STAGE, deleteStage),
    takeEvery(actions.SETTINGS_UPDATE_STAGES_POSITION, updateStagesPosition),
    takeEvery(actions.SETTINGS_DELETE_PIPELINE, deletePipeline),
    takeEvery(actions.SETTINGS_GET_DEAL_PROPERTIES, fetchDealProperties),
    takeEvery(actions.SETTINGS_CREATE_DEAL_PROPERTY, createDealProperty),
    takeEvery(actions.SETTINGS_UPDATE_DEAL_PROPERTY, updateDealProperty),
    takeEvery(actions.SETTINGS_DELETE_DEAL_PROPERTY, deleteDealProperty),
    takeEvery(actions.SETTINGS_APPEND_DEAL_PROPERTIES, appendDealProperties),
    takeEvery(
      actions.SETTINGS_GET_COMPANY_TRACKING_STATUS,
      getCompanyTrackingStatus,
    ),
    takeEvery(
      actions.SETTINGS_UPDATE_COMPANY_TRACKING_STATUS,
      updateCompanyTrackingStatus,
    ),
    takeEvery(actions.SETTINGS_UPDATE_THEME, updateTheme),
    takeEvery(
      actions.SETTINGS_UPDATE_SUPPORT_BOT_FEEDBACK,
      updateSupportBotFeedback,
    ),
    takeEvery(actions.GET_MAIL_SUBSCRIPTION_DATA, getMailSubscription),
    takeEvery(actions.CREATE_MAIL_SUBSCRIPTION, createMailSubscription),
    takeEvery(actions.DELETE_MAIL_SUBSCRIPTION, deleteMailSubscription),
    takeEvery(actions.UPDATE_MAIL_SUBSCRIPTION, updateMailSubscription),
    takeEvery(
      actions.CHANGE_SUBSCRIPTION_TYPE_POSITION,
      changeSubscriptionPosition,
    ),
    takeEvery(actions.CREATE_CONVERSATION_PROPERTY, createConversationProperty),
    takeEvery(actions.UPDATE_CONVERSATION_PROPERTY, updateConversationProperty),
    takeEvery(actions.DELETE_CONVERSATION_PROPERTY, deleteConversationProperty),
    takeEvery(
      actions.GET_INDIVIDUAL_CONVERSATION_PROPERTIES,
      getIndividualConversationProperty,
    ),
    takeEvery(
      actions.UPDATE_INDIVIDUAL_CONVERSATION_PROPERTY,
      updateIndividualConversationProperty,
    ),
    takeEvery(
      actions.FETCH_CONTACT_CONVERSATION_PROPERTY_WITH_OPTIONS,
      fetchConversationPropertyWithOptions,
    ),
    takeEvery(actions.FETCH_HUBSPOT_PROPERTIES, fetchHupspotProperties),
    takeEvery(
      actions.SAVE_HUBSPOT_MAPPING_PROPERITIES,
      saveHubspotMappingProperties,
    ),
    takeEvery(actions.SYNC_HUBSPOT_HISTORY_DATA, syncHubspotHistoryData),
    takeEvery(
      actions.GET_MESSENGER_APP_PREFERENCES,
      getMessengerAppPreferences,
    ),
    takeEvery(
      actions.UPDATE_MESSENGER_APP_PREFERENCES,
      updateMessengerAppPreferences,
    ),
    takeEvery(actions.SETTINGS_COUNTRY, settingsCountry),
    takeEvery(actions.GET_SPAM_FILTERS_DATA, getSpamFilters),
    takeEvery(actions.UPDATE_SPAM_FILTERS_DATA, updateSpamFilters),
    takeEvery(actions.FETCH_LIVE_BOT_LIST, getLiveBotList),
    takeEvery(actions.UPDATE_SETTINGS_SUPPORT_BOT, updateSupportBot),
    takeEvery(actions.FETCH_KB_THEMES_LIST, fetchKbThemesList),
    takeEvery(actions.DELETE_KB_THEME, deleteKbTheme),
    takeEvery(actions.DUPLICATE_KB_THEME, duplicateKbTheme),
    takeEvery(actions.SET_ACTIVE_KB_THEME, activeKbTheme),
    takeEvery(actions.FETCH_KB_THEME_DETAILS, fetchKbThemeDetails),
    takeEvery(actions.FETCH_KB_LIQUID_FILE_DATA, fetchKbLiquidFileData),
    takeEvery(actions.SAVE_DRAFT_KB_THEME_EDITOR_LIQUID, saveDraftEditorLiquid),
    takeEvery(actions.PUBLISH_KB_THEME, publishKbTheme),
    takeEvery(actions.REDEEM_COUPONS_SUBMIT, redeemCouponsSubmit),
    takeEvery(
      actions.FETCH_SLACK_CHANNELS_AND_EVENTS,
      fetchSlackChannelsAndEvents,
    ),
    takeEvery(actions.FETCH_SLACK_NOTIFICATIONS, fetchSlackNotifications),
    takeEvery(actions.SAVE_SLACK_NOTIFICATION, saveSlackNotification),
    takeEvery(actions.UPDATE_SLACK_NOTIFICATION, updateSlackNotification),
    takeEvery(actions.DELETE_SLACK_NOTIFICATION, deleteSlackNotification),
    takeEvery(actions.FETCH_SLACK_AUTH_URL, fetchSlackAuthUrl),
    takeEvery(actions.FETCH_PUSH_NOTIFICATIONS_LIST, fetchPushNotificationsList),
    takeEvery(actions.PUSH_NOTIFICATION_DELETE, deletePushNotification),
    takeEvery(actions.CREATE_PUSH_NOTIFICATION_DETAILS, createPushNotifications),
    takeEvery(actions.UPDATE_PUSH_NOTIFICATION_DETAILS, updatePushNotifications),
    takeEvery(actions.FETCH_IDENTITY_VERIFICATION, fetchIdentityVerification),
    takeEvery(actions.UPDATE_IDENTITY_VERIFICATION, updateIdentityVerification),
    takeEvery(actions.GENERATE_USER_HASH, generateUserHash),
    takeEvery(
      actions.UPDATE_NEWSCENTER_PORTAL_SETTINGS,
      updateNewsCenterPortalSettings,
    ),
  ]);
}
