function getDomainName() {
  const { location } = window;
  const host = location.hostname.split('.');
  if (host.length >= 2) {
    return host.splice(-2).join('.');
  } else {
    return 'getgist.com';
  }
}

export default {
  ROOT_URL: `https://web-api-testing.${getDomainName()}/api/v1/web/`,
  REACT_DOMAIN_URL: 'https://web-testing.getgist.com/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  GIF_URL: 'https://api.tenor.com/v1/',
  TENOR_API_KEY: 'TPO4BJ8VSTBU',
  SOCKET_URL: 'https://aws-testing-chat.getgist.com',
  API_DOMAIN: `https://web-api-testing.${getDomainName()}`,
  WORKFLOW_SKIP_DELAY_INTERVAL: 5000,
  PING_API_INTERVAL: 30000,
  CUSTOM_INBOX_VIEW_PING_API_INTERVAL: 15000,
  TIME_BASED_POLLING_API_INTERVAL: 300000,
  ALLOW_CREDENTIALS: true,
  BUG_SNAG_KEY: '9cbeb66717c6b90b033e0b095e52a113',
  MEETING_DOMAIN_URL: 'https://testing.getgist.me/',
  STRIPE_PAYMENT_KEY: 'pk_test_bSotHLINhZbKZ8dBg28Ml6G8',
  FB_INTEGRATION_CONFIG: {
    appId: 221578118538050,
    cookie: true,
    xfbml: true,
    version: 'v18.0',
    status: true,
  },
  FB_INTEGRATION_SCOPE:
    'manage_pages,pages_messaging,pages_read_engagement,business_management',
  FB_AD_INTEGRATION_SCOPE: 'ads_management',
  STRIPE_INTEGRATION_ID: 'ca_BOMNkGD9RiF1xYr4YzyyKh39HfRmfpni',
  GIST_SNIPPET_URL: 'https://widget-testing.getgist.com',
  ZOOM_INTEGRATION_ID: 'TQLrv3QJR1azUpVPmi0cbg',
  FIREBASE_VAPID_KEY:
    'BJrpZCUgCMnVnvp33a7kHwfLjff4YLUk64hgt9-0RhctL12Fi30DlSrevHUbvg3nU517IbyNGGMs7w_k54LEzvY',
  FIRE_BASE_CONFIG: {
    apiKey: 'AIzaSyB1NT-_lNGtTQDoS5Fv3dfxKNsGClPZZWc',
    authDomain: 'gist-web-push-testing-9f1d0.firebaseapp.com',
    databaseURL: 'https://gist-web-push-testing-9f1d0.firebaseio.com',
    projectId: 'gist-web-push-testing-9f1d0',
    storageBucket: 'gist-web-push-testing-9f1d0.appspot.com',
    messagingSenderId: '37026891204',
    appId: '1:37026891204:web:9b96719058f11b3c275091',
    measurementId: 'G-43BH4NCB5N',
  },
  FROALA_EDITOR_KEY:
    'wFE7nE5H5I4C2C11B5C5fLUQZf1ASFb1EFRNh1Hb1BCCQDUHnA8B6E5A4B1D3F3A1B8D8==',
  PRE_LAUNCH_PRODUCTION_PROJECTS: [
    'nmjkgjw6',
    '9bipx36y',
    'zfsjsd2r',
    'vuksawk9',
    'lkxodqji',
    'wgvs3vhp',
  ],
  TWITTER_OAUTH_LINK: 'https://oauth-testing.getgist.com/twitter',
  DOMAIN_AllOW: ['nmjkgjw6', 'wgvs3vhp'],
  USER_FEEDBACK: 'https://static.userback.io/widget/v1.js',
  EMBED_MEETING_LINK: 'https://meeting-widget-testing.getgist.com/',
  FORMS_CONFIRMATION_API: 'https://new-forms-testing.getgist.com/',
  FORM_PROJECTS: [
    'nmjkgjw6',
    'siymudyd',
    'bdxgkgtn',
    '3m37jt4s',
    'knjnfjk9',
    'uquxn6wm',
  ],
  EMOJI_URL:
    'https://web-api-testing.getgist.com/images/satisfaction_rating/png/',
  TEMPLATE_PROJECT_KEY: 'drs6hmg2',
  DEFAULT_PRODUCT_IMAGES:
    'https://d258lu9myqkejp.cloudfront.net/ecommerce/product.jpeg',
  EMBEDDED_FORMS: 'https://events-testing.getgist.com/',
  RESTRICTED_PROJECTS_BASED_ON_TEAMS: [
    { projectName: 'nmjkgjw6', teamId: 1601 },
  ],
  RESTRICTED_FEATURES_BASED_ON_TEAMS: [
    { projectName: 'nmjkgjw6', teamId: 1645 },
  ],
  EMBEDDED_FORMS_SCRIPT_URL: 'https://forms-widget-testing.getgist.com/',
  STATIC_HOST: 'https://gist-testing.b-cdn.net',
  BEE_EMAIL_TEMPLATE: 'https://gist-testing.b-cdn.net/email_templates/',
  SHOPIFY_URL: 'https://ecommerce-integration-testing.getgist.com/login?shop=',
  PRICING_PAGE: 'https://getgist.com/pricing',
  DISABLE_TYPING_SOCKET: ['nmjkgjw6'],
  QUICK_LINK_PROJECT: ['nmjkgjw6'],
  BOT_PREVIEW_URL:
    'https://gist-analytics-testing.b-cdn.net/bot-preview/index.html',
  S3_BUCKET_URL: 'https://gist-testing.s3.amazonaws.com/',
  RECAPTCHA_KEY: '6LfSpb4dAAAAAIzmv_MYmA9sGGU7AC5iT1rQZQwW',
  MEETING_ROOM_URL: 'https://getgist.daily.co/',
  OUTLOOK_CALENDAR_CLIENT_ID: 'ada45e6c-3144-493e-8d1a-044d1edf5971',
  GOOGLE_CALENDAR_CLIENT_ID:
    '769051448902-qgmvcisorb7nrbqhr90f9kpglpm13avs.apps.googleusercontent.com',
  GOOGLE_WEB_FONTS_URL:
    'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCpfnm5kVng8hhP_jnAnnTXVP7MEUM89-k',
  EMBEDDED_SURVEY_SCRIPTS_URL: 'https://survey-widget-testing.getgist.com/',
  SURVEY_STAND_ALONE_URL: 'https://survey-testing.getgist.com',
  GROWSURF_ID: 'vv52aj',
  BEAMER_ID: 'hpSQBHeV47579',
  USER_BACK_TOKEN: '37057|72213|ujpPa3UBPWXu5SiWSV4KeGFxP',
  USEITFUL_TOKEN: '0c3b902fc56345076a7b2c75816e4c93',
  EXTERNAL_API_ENDPOINT: 'https://aws-api-testing.getgist.com/',
  TOUR_EXTENSION_CHROME_URL:
    'https://chrome.google.com/webstore/detail/gist-tours/kieddkdogcdckopacpjblgdabanmjjpb',
  KB_PREVIEW_THEMES_CDN: 'https://kb-preview-themes-testing.b-cdn.net/',
  PAYPAL_CLIENT_ID:
    'AZhBhafrWe5Ze_qBvwfXIWTgHgFhEZ4ran5G4_wW3w4-LsIJRsSoQfkwOOlYJh6VFVaYnAUzCJr0nORs',
};
