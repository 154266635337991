import actions from './actions';
import * as _ from 'lodash';
import {
  folderIcon,
  articleDetail,
} from '../../components/KnowledgeBase/constants';
import {
  isValidUrl,
} from '../../components/KnowledgeBase/helpers/utility';
import { cloneDeep } from 'lodash';

export const initState = {
  collectionList: [],
  authorList: [],
  author: 'all',
  sections: [],
  selectedCollection: 'all',
  editSection: null,
  selectedSection: '',
  sectionSortKey: '',
  sectionSortValue: '',
  articleStatus: 'all',
  articles: [],
  statusList: [],
  articlesSortKey: '',
  articlesSortValue: '',
  articlesLoading: false,
  collectionLoading: false,
  selectedArticlesIds: [],
  articleSearchText: '',
  selectedCollectionName: '',
  selectedSectionName: '',
  articleDetail: articleDetail,
  articleUrl: '',
  articleContent: '',
  changedUrlContent: '',
  articleId: '',
  selectedIcon: folderIcon,
  hasDomain: true,
  isDuplicateArticle: false,
  isFailed: false,
  articleLoader: {
    saveDraft: false,
    update: false,
    publish: false,
  },
  fetchArticleLoader: false,
  allArticleCount: null,
  openKeys: [],
  loading: false,
  modalVisibility: false,
  articleInnerDetail: articleDetail,
  isRestoreDraftContent: false,
  defaultArticleDetails: {},
  supportedLanguages: [],
  articleTranslations: {},
  selectedLanguage: {},
  collectionHierarchy: {},
  collectionHierarchyDetails: {},
  articlesAndCollectionsLoading: false,
  articlesAndCollectionsList: {
    categories: [],
    articles: [],
  },
  showCollectionModal: false,
  parentCollectionId: null,
  editCollection: {},
  defaultLanguage: {},
  collectionModalVisibility: false,
  isArticleSelected: null,
  urlDetails: null,
  activeLanguageCode: '',
  isEditCollection: false,
  articleFetchStatus: true,
  isUploading: false,
};

export default function knowledgeBaseReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_COLLECTION_LIST_SUCCESS:
      return {
        ...state,
        collectionList: action.payload.categories,
        hasDomain: action.payload.has_domain,
        allArticleCount: action.payload.total_articles_count,
      };
    case actions.CHANGE_COLLECTIONS_LOADER:
      return {
        ...state,
        collectionLoading: action.payload,
      };
    case actions.EDIT_COLLECTION_SUCCESS: {
      const { category } = action.payload,
        { articles, categories } = state.articlesAndCollectionsList,
        { name, description, category_icon, custom_icon_url } =
          category.translations[state.defaultLanguage.code];
      const updatedCategories = categories.map((item) =>
        item.secret_key === category.category_secret
          ? { ...item, name, description, category_icon, custom_icon_url }
          : item,
      );
      return {
        ...state,
        articlesAndCollectionsList: {
          articles,
          categories: updatedCategories,
        },
        collectionLoading: false,
      };
    }
    case actions.FETCH_AUTHOR_LIST_SUCCESS:
      return {
        ...state,
        authorList: action.payload,
      };
    case actions.CHANGE_AUTHOR_NAME:
      return {
        ...state,
        author: action.payload,
      };
    case actions.ON_COLLECTION_CHANGE:
      return {
        ...state,
        selectedCollection: action.key,
        selectedSection: '',
        selectedCollectionName: action.name,
        selectedSectionName: '',
        selectedArticlesIds: [],
        sections: [],
        articleStatus:
          state.articleStatus === 'unassigned' ? 'all' : state.articleStatus,
      };
    case actions.SHOW_SECTION_MODAL:
      return {
        ...state,
        showSectionModal: action.isShow,
        editSection: action.isShow ? action.section : null,
      };
    case actions.SET_SECTIONS_SORT_KEY_AND_VALUE:
      return {
        ...state,
        sectionSortKey: action.sortKey,
        sectionSortValue: action.sortOrder,
      };
    case actions.FETCH_ARTICLE_LIST_SUCCESS: {
      const {
        has_domain,
        total_articles_count,
        url_details,
        articles,
        articles_count,
      } = action.payload;
      return {
        ...state,
        statusList: articles_count,
        articles,
        urlDetails: url_details,
        hasDomain: has_domain,
        allArticleCount: total_articles_count,
      };
    }
    case actions.CHANGE_ARTICLES_STATUS:
      return {
        ...state,
        articleStatus: action.payload,
      };
    case actions.SET_ARTICLES_SORT_KEY_AND_VALUE:
      return {
        ...state,
        articlesSortKey: action.sortKey,
        articlesSortValue: action.sortOrder,
      };
    case actions.SET_ARTICLE_SEARCH_TEXT:
      return {
        ...state,
        articleSearchText: action.payload,
      };
    case actions.FETCH_ARTICLE_LIST:
      return {
        ...state,
        articlesLoading: true,
      };
    case actions.SET_ARTICLES_LOADER:
      return {
        ...state,
        articlesLoading: action.payload,
      };
    case actions.SET_COLLECTIONS_LOADER:
      return {
        ...state,
        collectionLoading: action.payload,
      };
    case actions.DELETE_ARTICLE_SUCCESS:
      let articleIds = action.payload.map((id) => {
        id = { article_secret: id };
        return id;
      });
      let matchedArticles = _.unionBy(
          articleIds,
          state.articles,
          'article_secret',
        ),
        filteredArticles = matchedArticles.filter(
          (article) => Object.keys(article).length > 1,
        );
      let statusList = state.statusList;
      if (state.articleStatus !== 'all') {
        let statusIndex = _.findIndex(statusList, {
          type: state.articleStatus,
        });
        statusList[statusIndex].count =
          statusList[statusIndex].count - parseInt(action.payload.length);
      }
      let index = _.findIndex(statusList, { type: 'all' });
      statusList[index].count =
        statusList[index].count - parseInt(action.payload.length);
      return {
        ...state,
        articles: filteredArticles,
        statusList: statusList,
        allArticleCount:
          parseInt(state.allArticleCount) - parseInt(action.payload.length),
      };
    case actions.SET_ARTICLES_SELECTED_ROWS:
      return {
        ...state,
        selectedArticlesIds: action.selectedRowKeys,
      };
    case actions.FETCH_ARTICLE_SUCCESS:
      const { article, translations, category_secret, secondary_category } =
          action.payload,
        { isFromRestore, isDefaultLanguage } = action;
      const collectionHierarchy = {
          categorySecret: category_secret,
          secondaryCategory: secondary_category,
        },
        currentArticle =
          isFromRestore && !isDefaultLanguage
            ? { ...translations[action.code] }
            : { ...article },
        defaultArticleDetails = !isFromRestore
          ? { ...article }
          : { ...state.defaultArticleDetails };

      return {
        ...state,
        articleDetail: currentArticle,
        articleInnerDetail: currentArticle,
        defaultArticleDetails,
        articleTranslations:
          !isDefaultLanguage && !isFromRestore
            ? { ...state.articleTranslations, ...translations }
            : { ...state.articleTranslations },
        collectionHierarchy,
        collectionHierarchyDetails: collectionHierarchy,
        articleFetchStatus: true,
      };
    case actions.FETCH_ARTICLE_FAILURE:
      return {
        ...state,
        articleFetchStatus: false,
      };
    case actions.SET_SELECTED_LANGUAGE_ARTICLE_DATA: {
      const { code, default: isDefault } = action.payload;
      const getArticle = isDefault
        ? state.defaultArticleDetails
        : state.articleTranslations[code] || {
            ...articleDetail,
            user_id: state.authorList[0].id,
          };
      return {
        ...state,
        articleDetail: {
          ...getArticle,
          html_text: getArticle.html_text,
        },
        articleInnerDetail: {
          ...getArticle,
          html_text: getArticle.html_text,
        },
        selectedLanguage: action.payload,
      };
    }
    case actions.SET_ARTICLE_TEXT:
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          html_text: action.payload,
        },
      };
    case actions.SET_ARTICLES_TITLE:
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          title: action.payload,
        },
      };
    case actions.FETCH_ARTICLE_URL_SUCCESS: {
      let { articleUrl, langCode } = action.payload,
        lastContent,
        changedUrlContent,
        splittedUrl;
      const selectedLangugae = state.selectedLanguage.code,
        langugeToChange = langCode ?? selectedLangugae;
      if (!isValidUrl(articleUrl)) {
        if (articleUrl === '') {
          articleUrl = 'new-article';
          changedUrlContent = 'new-article';
        } else {
          changedUrlContent = action.payload;
        }
        splittedUrl = '';
      } else {
        lastContent = articleUrl.substring(articleUrl.lastIndexOf('/'));
        changedUrlContent = lastContent.substring(lastContent.indexOf('-') + 1);
        splittedUrl = articleUrl?.replace(changedUrlContent, '');
        articleUrl = articleUrl
          ?.replace(changedUrlContent, 'new-article')
          .replace(`/${selectedLangugae}/`, `/${langugeToChange}/`);
      }
      return {
        ...state,
        articleUrl,
        changedUrlContent: splittedUrl,
        articleContent: changedUrlContent,
        fetchArticleLoader: state.isDuplicateArticle,
      };
    }
    case actions.SET_CHANGED_ARTICLE_URL:
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          url: action.changedUrl,
          urlContent: action.changedContent,
        },
      };
    case actions.ON_SAVE_SECONDARY:
      return {
        ...state,
        collectionHierarchy: {
          ...state.collectionHierarchy,
          secondaryCategory: action.payload,
        },
      };
    case actions.ON_SAVE_AUTHOR:
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          user_id: action.payload,
        },
      };
    case actions.ON_SAVE_PRIMARY:
      return {
        ...state,
        collectionHierarchy: {
          ...state.collectionHierarchy,
          categorySecret: action.payload,
        },
      };
    case actions.SET_ARTICLE_ID:
      return {
        ...state,
        articleId: action.payload,
      };
    case actions.RESET_SELECTED_ARTICLES:
      return {
        ...state,
        selectedArticlesIds: [],
      };
    case actions.HANDLE_DOMAIN_NOTIFICATION:
      return {
        ...state,
        hasDomain: action.payload,
      };
    case actions.SET_DUPLICATE_DETAILS:
      return {
        ...state,
        isDuplicateArticle: action.payload,
      };
    case actions.COLLECTION_POSITION_CHANGE_FAILURE:
      return {
        ...state,
        isFailed: true,
      };
    case actions.UPDATE_ARTICLES_COUNT:
      let collectionsList = state.collectionList,
        filterdCollection,
        filterdSection,
        sectionsList = [];
      collectionsList = collectionsList.map((collection) => {
        filterdCollection = action.payload.filter(
          (key) => key.secret_key === collection.category_secret,
        );
        if (filterdCollection.length !== 0) {
          collection = {
            ...collection,
            articles_count: filterdCollection[0].articles_count,
          };
        }
        collection.sections = collection.sections.map((section) => {
          filterdSection = action.payload.filter(
            (key) => key.secret_key === section.section_secret,
          );
          if (filterdSection.length !== 0) {
            section = {
              ...section,
              articles_count: filterdSection[0].articles_count,
            };
            sectionsList = state.sections.map((section) => {
              if (section.section_secret === filterdSection[0].secret_key) {
                section.articles_count = filterdSection[0].articles_count;
              }
              return section;
            });
          }
          return section;
        });
        return collection;
      });
      return {
        ...state,
        collectionList: collectionsList,
        section: sectionsList,
      };
    case actions.SET_OPEN_GRAPH_DESCRIPTION:
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          og_description: action.payload,
        },
      };
    case actions.SET_OPEN_GRAPH_TITLE:
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          og_title: action.payload,
        },
      };
    case actions.SET_PAGE_TITLE:
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          seo_title: action.payload,
        },
      };
    case actions.SET_META_DESCRIPTION:
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          seo_description: action.payload,
        },
      };
    case actions.ON_SAVE_OG_IMAGE:
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          og_image: action.payload,
        },
      };
    case actions.ON_SAVE_OG_URL:
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          og_image_url: action.payload,
        },
      };
    case actions.SET_ARTICLE_DETAILS:
      return {
        ...state,
        articleDetail,
        articleInnerDetail: articleDetail,
      };
    case actions.SET_FETCH_ARTICLE_LOADER:
      return {
        ...state,
        fetchArticleLoader: action.payload,
      };
    case actions.UPDATE_PUBLISH_STATUS:
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          published_status: action.status,
        },
        articleInnerDetail: {
          ...state.articleInnerDetail,
          published_status: action.status,
        },
        articleLoader: {
          ...state.articleLoader,
          publish: true,
        },
      };
    case actions.UPDATE_PUBLISH_STATUS_SUCCESS: {
      const { isDefaultLanguage, status, language } = action.payload;
      return {
        ...state,
        articleLoader: {
          ...state.articleLoader,
          publish: false,
        },
        articleDetail: {
          ...state.articleDetail,
          published_status: status,
        },
        articleInnerDetail: {
          ...state.articleInnerDetail,
          published_status: status,
        },
        defaultArticleDetails: isDefaultLanguage
          ? { ...state.defaultArticleDetails, published_status: status }
          : state.defaultArticleDetails,
        articleTranslations: !isDefaultLanguage
          ? {
              ...state.articleTranslations,
              [language]: {
                ...state.articleTranslations[language],
                published_status: status,
              },
            }
          : state.articleTranslations,
      };
    }
    case actions.UPDATE_PUBLISH_STATUS_FAILURE:
      return {
        ...state,
        articleLoader: {
          ...state.articleLoader,
          publish: false,
        },
        articleDetail: {
          ...state.articleDetail,
          published_status: action.payload,
        },
        articleInnerDetail: {
          ...state.articleInnerDetail,
          published_status: action.payload,
        },
      };
    case actions.UPDATE_OPEN_KEY:
      return {
        ...state,
        openKeys: action.openedKey,
      };
    case actions.SET_SEO_INDEX:
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          seo_index: action.payload,
        },
      };
    case actions.IMPORT_ARTICLE:
      return {
        ...state,
        loading: true,
      };
    case actions.IMPORT_KB_UPDATE_MODAL:
      return {
        ...state,
        ...action.payload,
      };
    case actions.SET_ARTICLES_DESCRIPTION:
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          description: action.payload,
        },
      };
    case actions.CREATE_ARTICLE:
      return {
        ...state,
        articleLoader: {
          ...state.articleLoader,
          saveDraft: !action.isUpdate,
          update: !!action.isUpdate,
        },
      };
    case actions.CREATE_ARTICLE_SUCCESS: {
      // To update the restorable key only on save draft
      const { language } = action.payload,
        defalutLanguage = state.defaultLanguage.code === language;
      return {
        ...state,
        articleDetail: action.payload,
        articleInnerDetail: action.payload,
        articleLoader: {
          ...state.articleLoader,
          saveDraft: false,
        },
        defaultArticleDetails:
          action.isForCreateArticle || !defalutLanguage
            ? { ...state.defaultArticleDetails }
            : action.isUpdate
            ? action.payload
            : { ...state.defaultArticleDetails, restorable: true },
        articleTranslations: {
          ...state.articleTranslations,
          ...(action.isForCreateArticle
            ? { [language]: action.payload }
            : action.isUpdate
            ? { [language]: action.payload }
            : {
                [language]: {
                  ...state.articleTranslations[language],
                  restorable: true,
                },
              }),
        },
      };
    }
    case actions.CREATE_ARTICLE_FAILURE:
      return {
        ...state,
        articleLoader: {
          ...state.articleLoader,
          saveDraft: false,
          update: false,
        },
      };
    case actions.CHANGE_RESTORABLE_KEY: {
      const { language, isDefaultLanguage } = action.payload;
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          restorable: false,
        },
        articleInnerDetail: {
          ...state.articleInnerDetail,
          restorable: false,
        },
        defaultArticleDetails: isDefaultLanguage
          ? {
              ...state.defaultArticleDetails,
              restorable: false,
            }
          : state.defaultArticleDetails,
        articleTranslations: {
          ...state.articleTranslations,
          [language]: {
            ...state.articleTranslations[language],
            restorable: false,
          },
        },
      };
    }
    case actions.UPDATE_ARTICLE_LIVE_DETAILS_LOADER:
      return {
        ...state,
        articleLoader: {
          ...state.articleLoader,
          update: action.payload,
        },
      };
    case actions.RESET_ARTICLE_DETAILS:
      return {
        ...state,
        articleDetail: articleDetail,
        articleInnerDetail: articleDetail,
        isRestoreDraftContent: false,
        defaultArticleDetails: {},
        articleTranslations: {},
        selectedLanguage: {},
        articleId: '',
        collectionHierarchy: {},
        collectionHierarchyDetails: {},
      };
    case actions.SET_ARTICLE_RESTORE_DETAIL:
      return {
        ...state,
        isRestoreDraftContent: false,
      };
    case actions.RESET_SUPPORTED_LANGUAGES:
      return {
        ...state,
        supportedLanguages: [],
      };
    case actions.FETCH_SUPPORTED_LANGUAGES:
      return {
        ...state,
        loading: true
      }
    case actions.FETCH_SUPPORTED_LANGUAGES_SUCCESS:
      const { supported_languages } = action.payload;
      const default_language =
        supported_languages.length >= 1
          ? supported_languages.find((lang) => lang.default)
          : {};
      return {
        ...state,
        supportedLanguages: action.payload.supported_languages,
        selectedLanguage: default_language,
        defaultLanguage: default_language,
        activeLanguageCode: default_language?.code,
        loading: false
      };
    case actions.FETCH_ARTICLES_AND_COLLECTIONS_LIST:
      return {
        ...state,
        articlesAndCollectionsLoading: true,
      };
    case actions.FETCH_ARTICLES_AND_COLLECTIONS_LIST_SUCCESS:
      return {
        ...state,
        articlesAndCollectionsList: action.payload,
        urlDetails: action.payload.url_details,
        allArticleCount: action.payload.total_articles_count,
        articlesAndCollectionsLoading: false,
      };
    case actions.FETCH_ARTICLES_AND_COLLECTIONS_LIST_FAILURE:
      return {
        ...state,
        articlesAndCollectionsLoading: false,
      };
    case actions.CHANGE_COLLECTIONS_MODAL_DETAIL:
      const { editCollection, visibility, id, isEditCollection } = action;
      return {
        ...state,
        editCollection,
        isEditCollection,
        showCollectionModal: visibility,
        parentCollectionId: id,
      };
    case actions.SET_PARENT_COLLECTION_SECRET:
      return {
        ...state,
        parentCollectionId: action.payload,
      };
    case actions.UPDATE_COLLECTIONS_MODAL_DETAILS:
      const { isVisible, isArticle, collectionLoading } = action.payload;
      return {
        ...state,
        collectionModalVisibility: isVisible,
        isArticleSelected: isArticle,
        collectionLoading: collectionLoading ?? state.collectionLoading,
      };
    case actions.SET_ACTIVE_LANGUAGE:
      return {
        ...state,
        activeLanguageCode: action.payload,
      };
    case actions.UPDATE_MODAL_FORM_DETAILS: {
      const {
        activeLanguageCode: code,
        defaultLanguage,
        editCollection,
      } = state;
      let updatedPayload = action.payload;
      const defaultLanguageCode = defaultLanguage.code;
      const defaultLanguageDetails = editCollection?.[defaultLanguageCode];

      if (defaultLanguageDetails) {
        const { apply_to_all, custom_icon_url, category_icon } =
          defaultLanguageDetails;

        //enabling apply_to_all for new languages
        if (apply_to_all && !editCollection[code]) {
          updatedPayload.apply_to_all = true;
          updatedPayload.custom_icon_url = custom_icon_url;
          updatedPayload.category_icon = category_icon;
        }
      }

      return {
        ...state,
        editCollection: {
          ...editCollection,
          ...(code
            ? { [code]: { ...editCollection?.[code], ...updatedPayload } }
            : {}),
        },
      };
    }
    case actions.REMOVE_LANGUAGE_FROM_EDIT_COLLECTIONS:
      let editCollections = cloneDeep(state.editCollection);
      delete editCollections[action.payload];
      return {
        ...state,
        editCollection: editCollections,
      };
    case actions.DELETE_FROM_ARTICLE_AND_COLLECTIONS: {
      const { articles, categories } = state.articlesAndCollectionsList;
      return {
        ...state,
        articlesAndCollectionsList: {
          categories,
          articles: articles.filter(
            (article) => article.secret_key !== action.payload[0],
          ),
        },
      };
    }
    case actions.UPLOAD_CUSTOM_ICON:
      return {
        ...state,
        isUploading: true,
      };
    case actions.UPLOAD_CUSTOM_ICON_FAILURE:
      return {
        ...state,
        isUploading: false,
      };
    case actions.UPLOAD_CUSTOM_ICON_SUCCESS: {
      const { activeLanguageCode, editCollection } = state;
      return {
        ...state,
        isUploading: false,
        editCollection: {
          ...editCollection,
          [activeLanguageCode]: {
            ...editCollection[activeLanguageCode],
            ...action.payload,
          },
        },
      };
    }
    case actions.REMOVE_CUSTOM_ICON_IN_REDUCER: {
      const { activeLanguageCode, editCollection } = state;
      const { isApplyToAllEnabled } = action;

      const updatedEditCollection = Object.fromEntries(
        Object.entries(editCollection).map(([key, value]) => {
          if (isApplyToAllEnabled || activeLanguageCode === key) {
            value = { ...value, custom_icon_url: '' };
          }
          return [key, value];
        }),
      );
      return {
        ...state,
        editCollection: updatedEditCollection,
      };
    }
    case actions.APPLY_ICON_TO_ALL_LANGUAGE: {
      const { category_icon, custom_icon_url, apply_to_all } = action.payload;
      const { editCollection, activeLanguageCode } = state;
      const updatedEditCollection = Object.fromEntries(
        Object.entries(editCollection).map(([key, value]) => {
          if (apply_to_all || activeLanguageCode === key) {
            value.category_icon =
              category_icon ?? editCollection[key].category_icon;
            value.custom_icon_url = custom_icon_url;
            value.apply_to_all = apply_to_all;
          }
          return [key, value];
        }),
      );
      return {
        ...state,
        isUploading: false,
        editCollection: updatedEditCollection,
      };
    }
    case actions.DISABLE_APPLY_TO_ALL_LANGUAGES: {
      const { activeLanguageCode, editCollection } = state;
      return {
        ...state,
        editCollection: {
          ...editCollection,
          [activeLanguageCode]: {
            ...editCollection[activeLanguageCode],
            apply_to_all: false,
          },
        },
      };
    }
    default:
      return state;
  }
}
