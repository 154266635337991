import { Layout, Menu } from 'antd';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import {
  isRestricted,
  getCouponsCount,
  isTemplateEnabled,
  fetchProjectSecretKey,
  isPrimeCouponWorkspace,
  isPrimePremiumEquivalent,
} from '../../../../helpers/utility';
import { store } from '../../../../redux/store';
import actions from '../../../../redux/sharedReducers/actions';
import { restrictedPage } from '../../../../components/TopNavBar/constants';

const { Sider } = Layout;
const { SubMenu } = Menu;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    const showPrimeRestrictedFeature =
        !isPrimeCouponWorkspace() ||
        getCouponsCount('aio_prime') >= 5 ||
        isPrimePremiumEquivalent(),
      primeRestrictedFeature = [
        ...(!isRestricted(restrictedPage.conversation) && !isTemplateEnabled()
          ? [{ label: 'Surveys', url: 'surveys' }]
          : []),
        { label: 'Tours', url: 'tours' },
      ];
    this.messageMenu = [
      {
        title: 'Messages',
        key: 'messages',
        subMenu: [
          { label: 'Emails', url: 'email' },
          {
            label: 'Chats',
            url: 'chat',
            isTemplateEnabled: isTemplateEnabled(),
          },
          {
            label: 'Posts',
            url: 'post',
            isTemplateEnabled: isTemplateEnabled(),
          },
          {
            label: 'Bots',
            url: 'bots',
            isTemplateEnabled: isTemplateEnabled(),
          },
          {
            label: 'Forms',
            url: 'forms',
            isTemplateEnabled: isTemplateEnabled(),
          },
          {
            label: 'News Items',
            url: 'newscenter',
            isTemplateEnabled: isTemplateEnabled(),
          },
          ...(showPrimeRestrictedFeature ? primeRestrictedFeature : []),
        ],
      },
      {
        title: 'Automation',
        key: 'automation',
        subMenu: [
          { label: 'Workflows', url: 'workflows' },
          { label: 'Rules', url: 'rules' },
        ],
      },
    ];

    this.state = {
      defaultOpenKeys: ['messages', 'automation'],
    };
  }

  menuClick = (e) => {
    store.dispatch({
      type: actions.SET_MESSAGE_SIDEBAR_ACTIVE,
      key: e.keyPath,
    });
    localStorage.setItem('currentMessageUrl', e.key);
  };

  render() {
    const { messageSideBarActiveKey } = this.props;
    let activeKey = messageSideBarActiveKey;
    if (activeKey[0].includes('/')) {
      // Extract the part before the first '/'
      activeKey[0] = activeKey[0].split('/')[0];
    }
    return (
      <Sider className="message-sider">
        <div className="heading">Outbound</div>
        <Menu
          mode="inline"
          selectedKeys={activeKey}
          style={{ height: '100%', borderRight: 0 }}
          defaultOpenKeys={this.state.defaultOpenKeys}
        >
          {this.messageMenu.map((data) =>
            data.subMenu ? (
              <SubMenu key={data.key} title={<span> {data.title}</span>}>
                {data.subMenu &&
                  data.subMenu.map((data) =>
                    !data.isTemplateEnabled ? (
                      <Menu.Item key={data.url} onClick={this.menuClick}>
                        {data.url === 'builder' ? (
                              <NavLink
                                to={`/projects/${fetchProjectSecretKey()}/${
                                  data.url
                                }`}
                              />
                            ) : (
                              <NavLink
                                to={`/projects/${fetchProjectSecretKey()}/messages/${
                                  data.url
                                }`}
                          />
                        )}
                        {data.label}
                        {data.tag && (
                          <span>
                            <sup className="top-menu">{data.tag}</sup>
                          </span>
                        )}
                      </Menu.Item>
                    ) : null,
                  )}
              </SubMenu>
            ) : (
              <Menu.Item key={data.url} onClick={this.menuClick}>
                <NavLink
                  to={`/projects/${fetchProjectSecretKey()}/messages/${
                    data.url
                  }`}
                />
                {data.title}
              </Menu.Item>
            ),
          )}
        </Menu>
      </Sider>
    );
  }
}

export default Sidebar;
