import actions from './actions';

export const initState = {
  // News Center
  newsCenters: [],
  selectedNewsCenter: {},
  centerLoader: false,
  supportedLanguages: [],
  newsCenterLabels: [],
  authors: [],
  sslStatus: '',
  customDomainVerifyLoader: false,
  customDomainErrorMessage: '',
  domainVerified: false,
  connectDomainCurrentTabKey: 0,

  // News Items
  newsItems: [],
  selectedNewsItem: {},
  coverImage: null,
  newsItemsStatusCounts: [],
  newItemLoader: false,
  newsCenterSaveLoader: false,
  newsItemSaveLoader: false,
  labelSaveLoader: false,
  duplicateNewsItem: {},
  tableLoader: false,
  labelDrawerVisibility: false,
  connectDomainModalVisibility: false,
  importNewsItemsLoader: false,
  importModalVisible: false,
};

export default function newsCenterReducer(state = initState, action) {
  switch (action.type) {
    // News Centers

    case actions.FETCH_NEWS_CENTERS:
      return {
        ...state,
        centerLoader: true,
      };

    case actions.FETCH_NEWS_CENTERS_SUCCESS:
      return {
        ...state,
        newsCenters: action.payload,
        centerLoader: false,
      };

    case actions.FETCH_NEWS_CENTERS_FAILURE:
      return {
        ...state,
        centerLoader: false,
      };

    // News Center
    case actions.CREATE_NEWS_CENTER:
      return {
        ...state,
        newsCenterSaveLoader: true,
      };

    case actions.CREATE_NEWS_CENTER_SUCCESS:
      return {
        ...state,
        newsCenters: [...state.newsCenters, action.payload],
        newsCenterSaveLoader: false,
      };
    case actions.CREATE_NEWS_CENTER_FAILURE:
      return {
        ...state,
        newsCenterSaveLoader: false,
      };

    case actions.UPDATE_NEWS_CENTER:
      return {
        ...state,
        newsCenterSaveLoader: true,
      };
    case actions.UPDATE_NEWS_CENTER_SUCCESS:
      return {
        ...state,
        selectedNewsCenter: action.payload,
        newsCenters: state.newsCenters.map((newsCenter) =>
          newsCenter.id === action.payload.id ? action.payload : newsCenter,
        ),
        newsCenterSaveLoader: false,
      };
    case actions.UPDATE_NEWS_CENTER_FAILURE:
      return {
        ...state,
        newsCenterSaveLoader: false,
      };
    case actions.FETCH_NEWS_CENTER_SUCCESS:
      return {
        ...state,
        selectedNewsCenter: action.payload,
      };
    case actions.CUSTOM_DOMAIN_UPDATE_SUCCESS:
      return {
        ...state,
        sslStatus: action.payload.message,
        customDomainVerifyLoader: false,
        customDomainErrorMessage: '',
        connectDomainModalVisibility: false,
        domainVerified: true,
      };
    case actions.CUSTOM_DOMAIN_UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        customDomainErrorMessage: action.payload,
        customDomainVerifyLoader: false,
      };
    case actions.SET_CONNECT_DOMAIN_TAB_KEY:
      return {
        ...state,
        connectDomainCurrentTabKey: action.payload,
      };
    case actions.DELETE_NEWS_CENTER_SUCCESS:
      return {
        ...state,
        newsCenters: state.newsCenters.filter(
          (newsCenter) => newsCenter.id !== action.payload,
        ),
        selectedNewsCenter: undefined,
      };
    case actions.FETCH_NEWS_CENTER_LANGUAGES_SUCCESS:
      return {
        ...state,
        supportedLanguages: action.payload,
      };
    case actions.SET_SELECTED_NEWS_CENTER:
      return {
        ...state,
        selectedNewsCenter: action.payload,
      };

    // News Items
    case actions.FETCH_NEWS_ITEMS:
      return {
        ...state,
        tableLoader: true,
      };
    case actions.FETCH_NEWS_ITEMS_SUCCESS:
      return {
        ...state,
        newsItems: action.payload,
        tableLoader: false,
      };
    case actions.FETCH_NEWS_ITEMS_FAILURE:
      return {
        ...state,
        tableLoader: false,
      };
    case actions.APPEND_NEWS_ITEMS:
      return {
        ...state,
        tableLoader: true,
      };
    case actions.FETCH_APPEND_NEWS_ITEMS_SUCCESS:
      return {
        ...state,
        newsItems: [...state.newsItems, ...action.payload],
        tableLoader: false,
      };
    case actions.FETCH_NEWS_ITEM_SUCCESS:
      return {
        ...state,
        selectedNewsItem: action.payload,
        newItemLoader: false,
      };
    case actions.FETCH_NEWS_ITEM:
      return {
        ...state,
        newItemLoader: true,
      };
    case actions.CREATE_NEWS_ITEM:
      return {
        ...state,
        newsItemSaveLoader: true,
      };
    case actions.CREATE_NEWS_ITEM_SUCCESS:
      return {
        ...state,
        selectedNewsItem: action.payload,
        newsItemSaveLoader: false,
      };
    case actions.CREATE_NEWS_ITEM_FAILURE:
      return {
        ...state,
        newsItemSaveLoader: false,
      };
    case actions.UPDATE_NEWS_ITEM:
      return {
        ...state,
        newsItemSaveLoader: true,
      };
    case actions.UPDATE_NEWS_ITEM_SUCCESS:
      return {
        ...state,
        selectedNewsItem: action.payload,
        newsItemSaveLoader: false,
      };
    case actions.UPDATE_NEWS_ITEM_FAILURE:
      return {
        ...state,
        newsItemSaveLoader: false,
      };
    case actions.DELETE_NEWS_ITEM:
      return {
        ...state,
        tableLoader: true,
      };
    case actions.DELETE_NEWS_ITEM_SUCCESS:
      return {
        ...state,
        newsItems: state.newsItems.filter((item) => item.id !== action.payload),
        tableLoader: false,
      };
    case actions.DELETE_NEWS_ITEM_FAILURE:
      return {
        ...state,
        tableLoader: false,
      };
    case actions.DUPLICATE_NEWS_ITEM:
      return {
        ...state,
        newsItemSaveLoader: true,
      };
    case actions.SET_DUPLICATE_NEWS_ITEM:
      return {
        ...state,
        duplicateNewsItem: action.payload,
      };
    case actions.DUPLICATE_NEWS_ITEM_SUCCESS:
      return {
        ...state,
        newsItems: [...state.newsItems, action.payload],
        selectedNewsItem: action.payload,
        newsItemSaveLoader: false,
        duplicateNewsItem: {},
      };
    case actions.DUPLICATE_NEWS_ITEM_FAILURE:
      return {
        ...state,
        newsItemSaveLoader: false,
        duplicateNewsItem: {},
      };
    case actions.FETCH_NEWS_ITEMS_STATUS_COUNTS_SUCCESS:
      return {
        ...state,
        newsItemsStatusCounts: action.payload,
      };
    case actions.SET_COVER_IMAGE:
      return {
        ...state,
        coverImage: action.payload,
      };

    // News Center Labels
    case actions.FETCH_NEWS_CENTER_LABELS_SUCCESS:
      return {
        ...state,
        newsCenterLabels: action.payload,
      };
    case actions.CREATE_NEWS_CENTER_LABEL:
      return {
        ...state,
        labelSaveLoader: true,
      };
    case actions.CREATE_NEWS_CENTER_LABEL_SUCCESS:
      return {
        ...state,
        newsCenterLabels: [...state.newsCenterLabels, action.payload],
        labelSaveLoader: false,
      };
    case actions.SAVE_NEWS_CENTER_LABEL_FAILURE:
      return {
        ...state,
        labelSaveLoader: false,
      };
    case actions.UPDATE_NEWS_CENTER_LABEL:
      return {
        ...state,
        labelSaveLoader: true,
      };
    case actions.UPDATE_NEWS_CENTER_LABEL_SUCCESS:
      return {
        ...state,
        newsCenterLabels: state.newsCenterLabels.map((label) =>
          label.id === action.payload.id ? action.payload : label,
        ),
        labelSaveLoader: false,
      };
    case actions.DELETE_NEWS_CENTER_LABEL_SUCCESS:
      return {
        ...state,
        newsCenterLabels: state.newsCenterLabels.filter(
          (label) => label.id !== action.payload,
        ),
      };
    case actions.UPDATE_LABEL_DRAWER_STATUS:
      return {
        ...state,
        labelDrawerVisibility: action.payload,
      };
    case actions.FETCH_AUTHORS_SUCCESS:
      return {
        ...state,
        authors: action.payload,
      };
    case actions.SET_CONNECT_DOMAIN_MODAL_VISIBLE:
      return {
        ...state,
        connectDomainModalVisibility: action.payload,
      };

    case actions.IMPORT_NEWS_ITEMS:
      return {
        ...state,
        importNewsItemsLoader: true,
      };
    case actions.SET_IMPORT_NEWS_ITEMS_LOADER:
      return {
        ...state,
        importNewsItemsLoader: action.payload,
      };
    case actions.TOGGLE_IMPORT_MODAL_VISIBILITY:
      return {
        ...state,
        importModalVisible: action.payload,
      };
    default:
      return state;
  }
}
