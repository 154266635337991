import Settings from '../../settings';
import { getAppDetail } from '../../helpers/utility';
import HomeLight from '../../image/messenger/HomeLight';
import MessagesLight from '../../image/messenger/MessagesLight';
import HelpLight from '../../image/messenger/HelpLight';
import NewsLight from '../../image/messenger/NewsLight';
import HomeDark from '../../image/messenger/HomeDark';
import MessagesDark from '../../image/messenger/MessagesDark';
import HelpDark from '../../image/messenger/HelpDark';
import NewsDark from '../../image/messenger/NewsDark';

export const colorPickerClasses = [
  'saturation-black',
  'saturation-white',
  'color-picker custom-color-picker',
  'hue-horizontal',
  'flexbox-fix',
  'appearance-color-picker',
  'sketch-picker ',
  'color-picker',
];

export const dateFormat = 'll';


export const wooCommerceContent =
  `Sync live customer, product, cart and order data from your WooCommerce store into ${getAppDetail().name}.`;

export const shopifyContent =
  'Sync live customer, product, cart and order data from your Shopify store into Gist.';

export const defaultAckText =
  "<p>Hi {{contact.name | default: ''}}!</p>\n" +
  "<p>Thank you for your message. Our team will reply to you very shortly and we'll update you by email.</p>\n" +
  '<p>Thank you!</p>';

export const googleCalendarLink = `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?access_type=offline&approval_prompt=force&client_id=${Settings.GOOGLE_CALENDAR_CLIENT_ID}&include_granted_scopes=true&redirect_uri=${Settings.REACT_DOMAIN_URL}oauth2callback&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar&flowName=GeneralOAuthFlow`;

export const outlookCalendarLink = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${Settings.OUTLOOK_CALENDAR_CLIENT_ID}&response_type=code&prompt=consent&redirect_uri=${Settings.REACT_DOMAIN_URL}microsoft-callback&scope=openid%20email%20profile%20offline_access%20https://graph.microsoft.com/User.Read%20https://graph.microsoft.com/Calendars.ReadWrite`;

export const freeTrial = 'free_trial';

export const twitterLink = `${Settings.TWITTER_OAUTH_LINK}?secret_key=`;

export const freePlan = ['free_pro', 'free'];

export const gistBasePlan = [
  { name: 'All-in-One', slug_name: 'all_in_one' },
  { name: 'Support', slug_name: 'support' },
  { name: 'Marketing', slug_name: 'marketing' },
];

export const whiteLabelBasePlan = [
  { name: 'Marketing', slug_name: 'marketing' },
];

export const proMonthly = [
  'pro',
  'ultimate_monthly_pro',
  'lifetime_monthly_pro',
  'discounted_monthly_pro',
  'pro_monthly',
];

export const proAnnual = [
  'discounted_pro',
  'ultimate_discounted_pro_revised',
  'lifetime_discounted_pro_revised',
  'pro_annual',
];

export const standardPlan = ['standard', 'standard_monthly', 'standard_annual'];

export const proPlan = [
  'pro',
  'ultimate_monthly_pro',
  'lifetime_monthly_pro',
  'discounted_monthly_pro',
  'pro_monthly',
  'discounted_pro',
  'ultimate_discounted_pro_revised',
  'lifetime_discounted_pro_revised',
  'pro_annual',
];

export const standardPlanFeatures = [
  'Live chat',
  'Chat prompts',
  'Meetings & profiles',
  'Ticketing',
  'Event tracking',
  'Email marketing',
  'Knowledge base',
  'Mobile apps',
];
export const proPlanFeatures = [
  'All features in standard plan',
  'Bots',
  'Automated meetings',
  'Routing rules',
  'Whitelist domains',
  'Marketing automation',
  'Event visualizer',
  'Workflows',
];

export const toolbarButtons = [
  'bold',
  'italic',
  'underline',
  'insertLink',
  'personalize',
  'insertImage',
];

export const liveViewToolbarButtons = [
  'bold',
  'italic',
  'underline',
  'insertLink',
  'insertImage',
  'SnippetBtn',
];

export const aiAssistToolbarButtons = [
  'expand',
  'rephrase',
  'friendly',
  'professional',
];

export const newMessageAiAssistToolbarButtons = [
  'expandNewMessage',
  'rephraseNewMessage',
  'friendlyNewMessage',
  'professionalNewMessage',
];

export const proactiveToolbarButtons = [
  'bold',
  'italic',
  'underline',
  'insertLink',
  'personalize',
  'SnippetBtn',
  'insertImage',
  'insertVideo',
  'insertButton',
];

export const newMessageToolbarButtons = [
  'bold',
  'italic',
  'underline',
  'insertLink',
  'SnippetBtn',
  'insertImage',
  'insertVideo',
];

// Todo: proactiveComposer hide snippet. In future this constant should be removed
export const proactiveMessageToolbarButtons = [
  'bold',
  'italic',
  'underline',
  'insertLink',
  'personalize',
  // 'SnippetBtn',
  'insertImage',
  'insertVideo',
  'insertButton',
];

export const postMessageToolbarButtons = [
  'bold',
  'italic',
  'underline',
  'h1',
  'h2',
  'align',
  'personalize',
  'insertLink',
  // 'SnippetBtn',
  'insertImage',
  'insertVideo',
  'insertButton',
];
export const botToolbarButtons = [
  'bold',
  'italic',
  'underline',
  'insertLink',
  'insertImage',
  'insertVideo',
  'personalize',
];

export const imageEditButtons = [
  'imageStyle',
  'imageLink',
  'imageRemove',
  'linkEdit',
  'linkRemove',
];

export const imageEditButtonsPost = [
  'imageLink',
  'imageRemove',
  'linkEdit',
  'linkRemove',
];

export const videoInsertButtons = ['videoByURL'];

export const pluginsEnabled = ['link', 'image'];

export const contactPropertyDescriptionLength = 147;

export const botNameLength = 30;

export const defaultColor = '#37a1f6';
export const fontDefaultColor = 'rgba(0, 0, 0, 0.847)';
export const defaultGradientColorCode = [
  '#f54654',
  '#2e4ae5',
  '#483baa',
  '#58ceb6',
  '#801f8d',
  '#1db8ab',
];

export const contactPropertyTypes = [
  {
    name: 'Text',
    value: 'string',
  },
  {
    name: 'Number',
    value: 'number',
  },
  {
    name: 'Date',
    value: 'date',
  },
  {
    name: 'True or False',
    value: 'boolean',
  },
  {
    name: 'List',
    value: 'list',
  },
];
export const contactPropertyListType = [
  {
    name: 'Single select',
    value: 'single_dropdown',
  },
  {
    name: 'Multi select',
    value: 'multi_dropdown',
  },
];

export const snippetVisibilityOptions = [
  {
    name: 'Everyone',
    key: 'everyone',
  },
  {
    name: 'Specific teams',
    key: 'me_and_team',
  },
  {
    name: 'Myself',
    key: 'only_me',
  },
];

export const displayConditions = [
  {
    criteria: [
      {
        key: 'current_url_path',
        condition: 'is_any_page',
      },
    ],
    criteria_operator: 'AND',
  },
];

export const allTimezones = {
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Honolulu': 'Hawaii',
  'America/Juneau': 'Alaska',
  'America/Boise': 'Mountain Time',
  'America/Dawson': 'Dawson, Yukon',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'America/Phoenix': 'Arizona',
  'America/Chicago': 'Central Time',
  'America/Regina': 'Saskatchewan',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Belize': 'Central America',
  'America/Detroit': 'Eastern Time',
  'America/Bogota': 'Bogota, Lima, Quito',
  'America/Caracas': 'Caracas, La Paz',
  'America/Santiago': 'Santiago',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/Sao_Paulo': 'Brasilia',
  'America/Tijuana': 'Tijuana',
  'America/Montevideo': 'Montevideo',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Godthab': 'Greenland',
  'America/Los_Angeles': 'Pacific Time',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  GMT: 'UTC',
  'Europe/London': 'Edinburgh, London',
  'Europe/Dublin': 'Dublin',
  'Europe/Lisbon': 'Lisbon',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Atlantic/Canary': 'Canary Islands',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Algiers': 'West Central Africa',
  'Europe/Bucharest': 'Bucharest',
  'Africa/Cairo': 'Cairo',
  'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Athens': 'Athens, Minsk',
  'Asia/Jerusalem': 'Jerusalem',
  'Africa/Harare': 'Harare, Pretoria',
  'Europe/Moscow': 'Istanbul, Moscow, St. Petersburg, Volgograd',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Africa/Nairobi': 'Nairobi',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Tehran': 'Tehran',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Taipei': 'Taipei',
  'Australia/Perth': 'Perth',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Yakutsk': 'Yakutsk',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Hobart': 'Hobart',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Guam': 'Guam, Port Moresby',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Asia/Kamchatka': 'Kamchatka, Marshall Islands',
  'Pacific/Fiji': 'Fiji Islands',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Tongatapu': "Nuku'alofa",
};

export const spamFiltersContent = {
  default_check: {
    name: 'General checks',
    description:
      'Mark emails which fail spam detection filters or sender/DMARC authentication as spam.',
  },
  marketing_mail: {
    name: 'Marketing emails',
    description:
      'Mark marketing email from known email service providers as spam.',
  },
  role_based: {
    name: 'Role-based emails',
    description:
      'Mark emails from role based address such as info@, admin@ as spam .',
  },
  subscription_mail: {
    name: 'Subscription link emails',
    description: 'Mark emails with an unsubscribe link or header as spam.',
  },
  automated_response: {
    name: 'Automated response emails',
    description: 'Mark auto-responder emails (incl. out of office) as spam.',
  },
  blocked_list: {
    name: 'Block specific domains',
    description: 'Always mark conversations from specified domains as spam.',
  },
};

export const followupKeys = { 1: 'has_answered', 2: 'has_not_answered' };

export const followupItemLabels = {
  helped: 'Helpful',
  talk_to_person: 'Talk to a person',
  ask_another_question: 'Ask another question',
};

export const colorGradientKey = 'gradient';
export const fileTypes = '.jpg, .jpeg, .png';
export const titleLength = 80;
export const descriptionLength = 160;
export const masterColorsList = {
  gradient: [
    'linear-gradient(296deg, #f54654, #fd8966)',
    'linear-gradient(296deg, #2e4ae5, #5da9ff)',
    'linear-gradient(296deg, #7b75ed, #483baa)',
    'linear-gradient(326deg, #e7ff8e, #58ceb6)',
    'linear-gradient(219deg, #801f8d, #6f1b7a, #731c7f, #6a1a74 31%, #f0487f)',
    'linear-gradient(296deg, #1db8ab, #3eccce)',
  ],
  solid: ['#0154E4', '#408bef', '#6d53dd', '#83e181', '#941093', '#3bc7ab'],
};

export const supportedLanguages = {
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  de: 'German',
  ru: 'Russian',
  pl: 'Polish',
  he: 'Hebrew',
  el: 'Greek',
  tr: 'Turkish',
  it: 'Italian',
  id: 'Bahasa (Indonesian)',
  nn: 'Norwegian',
  da: 'Danish',
  nl: 'Dutch',
  bg: 'Bulgarian',
  cs: 'Czech',
  sk: 'Slovak',
  pt: 'Portuguese',
  sv: 'Swedish',
  hr: 'Croatian',
  bs: 'Bosnian',
  'zh-hans': 'Chinese (Simplified)',
  'zh-hant': 'Chinese (Traditional)',
  ar: 'Arabic',
  et: 'Estonian',
  fi: 'Finnish',
  hu: 'Hungarian',
  lv: 'Latvian',
  lt: 'Lithuanian',
  mn: 'Mongolian',
  sr: 'Serbian',
  sl: 'Slovenian',
  ja: 'Japanese',
  ko: 'Korean',
  vi: 'Vietnamese',
  th: 'Thai',
  ms: 'Malay',
  ca: 'Catalan',
  af: 'Afrikaans',
  hi: 'Hindi',
  sq: 'Albanian',
  bn: 'Bengali',
  is: 'Icelandic',
  ge: 'Georgian',
};

export const defaultSpacesMetaData = [
  {
    key: 'home',
    name: 'Home',
    description: 'Make your Messenger personal',
    icon: HomeLight,
    iconActive: HomeDark,
  },
  {
    key: 'messages',
    name: 'Messages',
    description: 'An Inbox for your customers',
    icon: MessagesLight,
    iconActive: MessagesDark,
  },
  {
    key: 'help',
    name: 'Help',
    description: 'In-Messenger Help Center',
    icon: HelpLight,
    iconActive: HelpDark,
  },
  {
    key: 'news',
    name: 'News',
    description: 'Targeted newsfeed for announcements',
    icon: NewsLight,
    iconActive: NewsDark,
  },
];

export const defaultSpacesTabData = {
  visitors: [
    {
      key: 'home',
      isEnabled: true,
    },
    {
      key: 'messages',
      isEnabled: false,
    },
    {
      key: 'help',
      isEnabled: false,
    },
    {
      key: 'news',
      isEnabled: false,
    },
  ],
  contacts: [
    {
      key: 'home',
      isEnabled: true,
    },
    {
      key: 'messages',
      isEnabled: false,
    },
    {
      key: 'help',
      isEnabled: false,
    },
    {
      key: 'news',
      isEnabled: false,
    },
  ],
};

export const EMAIL_SETTINGS_TYPES = {
  subscriptionTypes: 'include_default_people_mail_subscription',
  sunsetPolicy: 'global_sunset_policy',
  openTracking: 'open_tracking_enabled',
  clickTracking: 'click_tracking_enabled',
};
