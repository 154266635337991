import React, { Component } from 'react';
import { Card, Form, Radio, Tooltip } from 'antd';
import './style.scss';
import { store } from '../../../redux/store';
import broadCastActions from '../../../redux/email/broadcast/actions';
import {
  fetchProjectSecretKey,
  fetchRestrictedFeatures,
} from '../../../helpers/utility';
import { menu } from '../../TopNavBar/constants';
import { NavLink } from 'react-router-dom';
import VisualBuilder from '../../../image/visual_builder.svg';
import TextEditor from '../../../image/text_editor.svg';
import BeeTemplate from '../../Email/Shared/BeeTemplate';
import SelectTemplateContent from '../../Email/Shared/SelectTemplateContent';
const { Meta } = Card;

class EmailTemplateContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      builderType: 'visual_builder',
    };
  }
  componentDidMount() {
    store.dispatch({
      type: broadCastActions.FETCH_TEMPLATES,
      templateType: this.state.builderType,
    });
  }

  onChange = (e) => {
    this.setState({
      builderType: e.target.value,
    });
    store.dispatch({
      type: broadCastActions.FETCH_TEMPLATES,
      templateType: e.target.value,
    });
  };

  render() {
    const {
        emailTemplates,
        emailTemplatesLoader,
        selectedTemplateId,
        restrictedFeatures,
        templateType,
      } = this.props,
      { getFieldDecorator } = this.props.form,
      { builderType } = this.state;

    return (
      <>
        <Form.Item label="Which email builder would you like to use?">
          {getFieldDecorator('broadcast_type', {
            initialValue: builderType,
            rules: [{ required: true, message: 'choose an option' }],
          })(
            <Radio.Group
              className={'radio-card-button grid grid-cols-2 gap-4'}
              onChange={this.onChange}
            >
              <Tooltip
                title={
                  fetchRestrictedFeatures().includes(
                    menu.emailVisualBuilder,
                  ) ? (
                    <>
                      Please upgrade your plan{' '}
                      <NavLink
                        to={`/projects/${fetchProjectSecretKey()}/settings/subscription`}
                        target="_blank"
                      >
                        here
                      </NavLink>{' '}
                      to access this feature.
                    </>
                  ) : (
                    ''
                  )
                }
                placement="top"
              >
                <Radio
                  value="visual_builder"
                  disabled={fetchRestrictedFeatures().includes(
                    menu.emailVisualBuilder,
                  )}
                >
                  <Card
                    hoverable
                    cover={<img alt="example" src={VisualBuilder} />}
                  >
                    <Meta
                      title="Visual Builder"
                      description="Design emails using drag and drop editor to create engaging visual content without touching code"
                    />
                  </Card>
                </Radio>
              </Tooltip>
              <Radio value="text_editor">
                <Card hoverable cover={<img alt="example" src={TextEditor} />}>
                  <Meta
                    title="Text/HTML Builder"
                    description="Create plain text-based emails focused on written communication and for full control of your email"
                  />
                </Card>
              </Radio>
            </Radio.Group>,
          )}
        </Form.Item>
        <SelectTemplateContent
          isfromSelectTemplateContainer={true}
          emailTemplates={emailTemplates}
          emailTemplatesLoader={emailTemplatesLoader}
          selectedTemplateId={selectedTemplateId}
          restrictedFeatures={restrictedFeatures}
          templateType={templateType}
          emailType={builderType}
        />
      </>
    );
  }
}

export default EmailTemplateContainer;
