export const newsCenterSettings = {
  is_reactions_enabled: true,
  is_feedback_enabled: true,
  open_links_in_new_tab: true,
  enable_social_sharing: true,
  send_push: true,
  show_read_more: true,
  link_logo_to_home: true,
  add_utm_params_to_links: true,
  enable_multi_line_comments: true,
  appearance: {
    header_bg_color: '#0050d8',
    header_text_color: '#ffffff',
    body_bg_color: '#f7f7f7',
    post_bg_color: '#ffffff',
    post_text_color: '#424242',
    post_link_color: '#4b63af',
    button_bg_color: '#3d50e0',
    button_text_color: '#ffffff',
    date_text_color: '#8da2b5',
    launcher_bg_color: '#00d8a6',
    launcher_counter_bg_color: '#ff5a5f',
    post_title_font_size: 20,
    post_content_font_size: 16,
    custom_css: 'body {\n  /* code goes here */\n}',
  },
  languages: {
    primary_language: 'en',
    additional_languages: ['es', 'fi', 'is'],
  },
  portal: {
    site_visibility: true,
    sub_domain: 'updates',
    custom_domain: '',
    show_messenger: true,
    google_analytics: '12345dfg876sd5',
    homepage_title: 'Gist News Portal',
    seo_description: '',
    sub_description: null,
    seo_index: true,
    og_title: 'Gist News Portal',
    og_description: '',
    og_image: '',
    favicon:
      'https://d3iahwxie98s37.cloudfront.net/projects/2/knowledge_base/original/chat-bubble.png?1573121830',
    logo: 'https://d3iahwxie98s37.cloudfront.net/projects/2/knowledge_base/original/04EAB89B-D90E-0570-0E0C-4FB9F1B4743E__PortfolioLandscape_logo.png?1589376145',
    site_name: 'Gist News Portal',
    homepage_url: 'https://getgist.com',
    text_link: 'Go to Gist Home',
    site_description: 'How can we help?',
    header_bg_color: '#6d53dd',
    header_bg_color_type: 'solid',
    bg_image: '',
    footer_links: [
      { title: 'Home', link: 'google.com' },
      { title: 'Features', link: 'features.com' },
      { title: 'Pricing', link: 'pricing.com' },
    ],
    social_links: {
      fb_link: 'fb.com',
      twitter_link: 'te.com',
      linkedin_link: 'li.in',
    },
    copyright_text: '2021 Copyright. Gist.',
    ssl_status: 'failed',
  },
  widget: {
    visibility: true,
    show_widget_launcher: true,
    show_on_mobile_devices: false,
    add_link_to_portal: false,
    unread_count_favicon: true,
    play_sound_for_new_posts: true,
    prevent_auto_open: false,
    prevent_auto_open_mobile: true,
    display_mode: 'right',
    launcher_position: 'bottom-right',
    launcher_bg_color: '#00d8a6',
    launcher_icon: 'launcher-icon-2',
  },
};

// TODO No longer used in FE - Backend to make these default labels for new news centers
export const newsCenterLabelsList = [
  {
    id: 1,
    name: 'New',
    url_slug: 'new',
    bg_color: '#FDE6DD',
    text_color: '#FF854B',
    created_at: 1603442045,
    updated_at: 1627064486,
  },
  {
    id: 2,
    name: 'Improvement',
    url_slug: 'improvement',
    bg_color: '#e3e7fa',
    text_color: '#334bfa',
    created_at: 1603442045,
    updated_at: 1627064486,
  },
  {
    id: 3,
    name: 'Fix',
    url_slug: 'fix',
    bg_color: '#f7e5fc',
    text_color: '#c701f1',
    created_at: 1603442045,
    updated_at: 1627064486,
  },
  {
    id: 4,
    name: 'Coming soon',
    url_slug: 'coming-soon',
    bg_color: '#d7efdc',
    text_color: '#0f8a40',
    created_at: 1603442045,
    updated_at: 1627064486,
  },
  {
    id: 5,
    name: 'Announcement',
    url_slug: 'announcement',
    bg_color: '#feedaf',
    text_color: '#6f5502',
    created_at: 1603442045,
    updated_at: 1627064486,
  },
];
