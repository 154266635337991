import InboxBanner from '../../image/onboarding/features-inbox-1.png';

export const infoCardData = [
  {
    title: 'Invite your teammates',
    description:
      'Invite them in bulk or individually, set their teams, roles, and permissions.',
    iconClass: 'fas fa-users',
    action: {
      type: 'navigate',
      url: 'settings/users',
    },
  },
  {
    title: 'Customize your Messenger',
    description: 'Set your colors, logo and more to match your brand.',
    iconClass: 'fas fa-paint-brush',
    action: {
      type: 'navigate',
      url: 'settings/appearance',
    },
  },
  {
    title: 'Install Messenger',
    description:
      'It only takes a few minutes with our examples and no-code integrations.',
    iconClass: 'fas fa-code',
    action: {
      type: 'navigate',
      url: 'settings/tracking-code',
    },
  },
  {
    title: 'Set up omni-channel',
    description: 'Centralize all channels — chat, email, facebook and more.',
    iconClass: 'fas fa-envelope',
    action: {
      type: 'navigate',
      url: 'connect-channel',
    },
  },
  {
    title: 'Import contact data',
    description: 'Target and personalize your customer support experience.',
    iconClass: 'fas fa-user',
    action: {
      type: 'navigate',
      url: 'import_csv',
    },
  },
  {
    title: 'Integrate with other tools',
    description: 'Integrate with HubSpot, Jira, Segment, Slack, and more.',
    iconClass: 'fas fa-boxes',
    action: {
      type: 'navigate',
      url: 'apps',
    },
  },
];

export const helpDeskStepsData = {
  title: 'Help Desk',
  description:
    'Connect your inbox, bots, and help center into one central workspace',
  bannerImg: InboxBanner,
  key: 'help_desk',
  color_class: 'blue',
  steps: [
    {
      title: 'Set up your profile',
      key: 'setup_profile',
      description:
        'Add personal touch to each conversation by showing your name and profile picture',
      iconClass: 'fas fa-user',
      action: {
        type: 'navigate',
        url: 'settings/profile',
      },
    },
    {
      title: 'Set up teams',
      key: 'create_teams',
      description:
        'Create teams so you can assign conversations to the right people',
      iconClass: 'fas fa-users',
      action: {
        type: 'navigate',
        url: 'settings/teams',
      },
    },
    {
      title: 'Set up office hours',
      key: 'setup_office_hours',
      description:
        'Add office hours so users always know when to expect a response.',
      iconClass: 'fas fa-clock',
      action: {
        type: 'navigate',
        url: 'settings/availability',
      },
    },
    {
      title: 'Track logged-in users',
      key: 'track_users',
      description: 'Personalize support by tracking relevant business data',
      iconClass: 'fas fa-address-card',
      action: {
        type: 'navigate',
        url: 'settings/tracking-code',
      },
    },
    {
      title: 'Create snippets',
      key: 'create_snippets',
      description:
        'Define time-saving actions like saved replies, or tag a conversation',
      iconClass: 'fas fa-bookmark',
      action: {
        type: 'navigate',
        url: 'settings/snippets',
      },
    },
    {
      title: 'Create inbox rules',
      key: 'create_inbox_rules',
      description: 'Automatically route and triage conversations using rules',
      iconClass: 'fas fa-bolt',
      action: {
        type: 'navigate',
        url: 'conversations/rules',
      },
    },
    {
      title: 'Add apps to Messenger',
      key: 'add_messenger_apps',
      description:
        'Enable users to find solutions before starting a conversation',
      iconClass: 'fas fa-boxes',
      action: {
        type: 'navigate',
        url: 'settings/messenger-apps',
      },
    },
    // {
    //   title: 'Secure your Messenger',
    //   key: 'secure_messenger',
    //   description:
    //     'Protect your Messenger from unauthorized access and impersonation',
    //   iconClass: 'fas fa-lock',
    //   action: {
    //     type: 'navigate',
    //     url: 'settings/security',
    //   },
    // },
  ],
};

export const KBStepsData = {
  title: 'Knowledge Base',
  description:
    'Create multilingual articles for your global customers for faster self-serve answers',
  bannerImg: InboxBanner,
  key: 'knowledge_base',
  color_class: 'yellow',
  steps: [
    {
      title: 'Design your help center',
      key: 'setup_kb',
      description:
        'Customize look and feel with brand-specific logo, colors, and fonts.',
      iconClass: 'fas fa-paint-brush',
      action: {
        type: 'navigate',
        url: 'settings/knowledge-base',
      },
    },
    {
      title: 'Import existing articles',
      key: 'import_articles',
      description:
        'Use the importer tool to transfer articles from your current knowledge base.',
      iconClass: 'fas fa-file-import',
      action: {
        type: 'navigate',
        url: 'knowledge-base/all-articles',
      },
    },
    {
      title: 'Create your first article',
      key: 'create_articles',
      description:
        'Use the article editor to format and add your first article.',
      iconClass: 'fas fa-file',
      action: {
        type: 'navigate',
        url: 'knowledge-base/article',
      },
    },
    {
      title: 'Organize articles in collections',
      key: 'organize_collections',
      description: 'Group articles using collections for better navigation.',
      iconClass: 'fas fa-book-open',
      action: {
        type: 'navigate',
        url: 'knowledge-base/collections',
      },
    },
    {
      title: 'Add your custom domain',
      key: 'add_kb_custom_domain',
      description:
        'Set up a custom URL for your knowledge base for a more branded experience.',
      iconClass: 'fas fa-link',
      action: {
        type: 'navigate',
        url: 'settings/knowledge-base',
      },
    },
    {
      title: 'Publish your knowledge base',
      key: 'publish_kb',
      description: 'Make the help center publicly accessible for users.',
      iconClass: 'fas fa-toggle-on',
      action: {
        type: 'navigate',
        url: 'settings/knowledge-base',
      },
    },
  ],
};

export const EmmaStepsData = {
  title: 'AI Chatbot',
  description:
    'Resolve customer questions instantly with Emma, our breakthrough AI Chatbot',
  bannerImg: InboxBanner,
  key: 'ai_chatbot',
  color_class: 'pink',
  steps: [
    {
      title: 'Give it some content',
      key: 'add_emma_content',
      description: 'Import your existing support content or create new.',
      iconClass: 'fas fa-file-alt',
      action: {
        type: 'navigate',
        url: 'support/bots/contents',
      },
      isCompleted: false,
    },
    {
      title: 'Set up custom answers',
      key: 'create_custom_answers',
      description: 'Create bespoke answers to the most important questions',
      iconClass: 'fas fa-comment-dots',
      action: {
        type: 'navigate',
        url: 'support/bots/answers',
      },
    },
    {
      title: 'Set up and go live',
      key: 'setup_emma',
      description: 'Test Emma with your content, configure it, and go live.',
      iconClass: 'fas fa-play',
      action: {
        type: 'navigate',
        url: 'support/bots/setup',
      },
    },
    {
      title: "Follow Emma's conversations",
      key: 'review_emma_conversations',
      description:
        'Monitor how it responds to your customers and improve its content.',
      iconClass: 'fas fa-inbox',
      action: {
        type: 'navigate',
        url: 'support/bots/unanswered',
      },
    },
    {
      title: "Track Emma's performance",
      key: 'track_emma_performance',
      description: 'Measure how it impacts your support metrics.',
      iconClass: 'fas fa-chart-bar',
      action: {
        type: 'navigate',
        url: 'reports/support/emma',
      },
    },
  ],
};

export const emailStepsData = {
  title: 'Email Marketing',
  description:
    'Create and send targeted, personal emails your customers will want to read',
  bannerImg: InboxBanner,
  key: 'email_marketing',
  color_class: 'red',
  steps: [
    // {
    //   title: 'Organize contacts with tags',
    //   key: 'contact_tags',
    //   description:
    //     'Efficiently segment your contacts by applying relevant tags.',
    //   iconClass: 'fas fa-tags',
    //   action: {
    //     type: 'navigate',
    //     url: 'settings/contact-tags',
    //   },
    // },
    // {
    //   title: 'Create contact segments',
    //   key: 'contact_segments',
    //   description:
    //     'Create targeted groups of contacts for more focused marketing efforts.',
    //   iconClass: 'fas fa-users',
    //   action: {
    //     type: 'navigate',
    //     url: 'contacts',
    //   },
    // },
    {
      title: 'Get email approved',
      key: 'submit_email_approval',
      description:
        'Streamline approval processes with essential campaign documentation.',
      iconClass: 'fas fa-users',
      action: {
        type: 'navigate',
        url: 'messages/email',
      },
    },
    {
      title: 'Authenticate your sending domain',
      key: 'authenticate_sending_domain',
      description:
        'Boost email deliverability by configuring SPF and DKIM for your domain.',
      iconClass: 'fas fa-users',
      action: {
        type: 'navigate',
        url: 'settings/email-sending-domain',
      },
    },
    {
      title: 'Create your first email',
      key: 'create_email',
      description:
        "Create captivating emails using Gist's intuitive builder or custom HTML.",
      iconClass: 'fas fa-users',
      action: {
        type: 'navigate',
        url: 'messages/email',
      },
    },
    {
      title: 'Create a lead form',
      key: 'create_form',
      description:
        'Grow your lists, drive sales, upsell, cross-sell, recover carts & more',
      iconClass: 'fas fa-columns',
      action: {
        type: 'navigate',
        url: 'messages/forms',
      },
    },
    {
      title: 'Set up workflows',
      key: 'create_workflow',
      description:
        'Craft a better experience across customer journey with omnichannel messaging',
      iconClass: 'fas fa-sparkles',
      action: {
        type: 'navigate',
        url: 'messages/workflows',
      },
    },
    // {
    //   title: 'Set up a preference center',
    //   key: 'setup_preference',
    //   description:
    //     'Enhance customer engagement by offering tailored email content choices.',
    //   iconClass: 'fas fa-users',
    //   action: {
    //     type: 'navigate',
    //     url: 'contacts',
    //   },
    // },
    // {
    //   title: 'Set up a sunset policy',
    //   key: 'setup_sunset_policy',
    //   description:
    //     'Maintain domain reputation by focusing on engaged email subscribers.',
    //   iconClass: 'fas fa-users',
    //   action: {
    //     type: 'navigate',
    //     url: 'contacts',
    //   },
    // },
    {
      title: 'Analyse email insights',
      key: 'review_email_health',
      description:
        "Optimize email content by understanding your email campaign's performance.",
      iconClass: 'fas fa-users',
      action: {
        type: 'navigate',
        url: 'reports/marketing/email-health',
      },
    },
  ],
};

export const outboundStepsData = {
  title: 'Outbound Messages',
  description:
    'Onboard and educate your customers with automated in-context messages',
  bannerImg: InboxBanner,
  key: 'outbound_messages',
  color_class: 'green',
  steps: [
    {
      title: 'Set a chat message live',
      key: 'set_chat_live',
      description:
        'Offer support in areas where your customers may need more help',
      iconClass: 'fas fa-comment-dots',
      action: {
        type: 'navigate',
        url: 'messages/chat',
      },
    },
    {
      title: 'Set a post message live',
      key: 'set_post_live',
      description:
        'Make announcements, showcase offers & nudge visitors into action',
      iconClass: 'fas fa-bullhorn',
      action: {
        type: 'navigate',
        url: 'messages/post',
      },
    },
    {
      title: 'Set a survey live',
      key: 'set_survey_live',
      description:
        'Collect feedback to uncover customer needs & personalize campaigns',
      iconClass: 'fas fa-bullseye',
      action: {
        type: 'navigate',
        url: 'messages/surveys',
      },
    },
    {
      title: 'Set a product tour live',
      key: 'set_tour_live',
      description:
        'Introduce customers to your product with guided experiences that drive adoption',
      iconClass: 'fas fa-info',
      action: {
        type: 'navigate',
        url: 'messages/tours',
      },
    },
  ],
};

export const supportCardData = [helpDeskStepsData, KBStepsData, EmmaStepsData];

export const marketingCardData = [emailStepsData, outboundStepsData];

export const footerCardData = [
  {
    title: 'Gist Help Center',
    description: 'Get advice and quick answers from the Gist team',
    iconClass: 'fas fa-book-open',
    action: {
      type: 'navigate',
      url: 'https://docs.getgist.com/en/',
    },
  },
  {
    title: 'Switch from Intercom',
    description: 'Learn how Intercom concepts map to Gist and how to migrate',
    iconClass: 'fab fa-intercom',
    action: {
      type: 'navigate',
      url: 'https://docs.getgist.com/en/article/188-moving-to-gist-from-intercom',
    },
  },
  {
    title: 'Developer Hub',
    description: 'Integrate with or build apps on top of Gist',
    iconClass: 'fas fa-code',
    action: {
      type: 'navigate',
      url: 'https://developers.getgist.com',
    },
  },
  {
    title: 'Ask a question',
    description: 'Get advice and help from our expert team',
    iconClass: 'fas fa-question',
    action: {
      type: 'chat_new_conversation',
    },
  },
];
