import React, { useMemo, useCallback } from 'react';
import {
  inboxShortcutGroupId,
  shortcutsList,
  shortcutsListGroups,
} from '../../../containers/App/constants';
import JungleSelect from 'react-jungle-select';
import { extractConversationIdentifier } from '../../../helpers/utility';
import { getCommandItem, getGroupItem } from './Shared';

// Default OmniSearch component for displaying and selecting shortcuts
const DefaultWidget = (props) => {
  const {
    selectedCommand,
    activeConversation,
    handleCommandSelect,
    showNewConversation,
  } = props;

  // Determine if the current view is the inbox
  let isInbox = false;
  const pathName = window.location.pathname.split('/');
  if (
    pathName[3] === 'conversations' &&
    (!['rules', 'rule', 'table', 'search', 'latest'].includes(pathName[4]) ||
      showNewConversation ||
      extractConversationIdentifier())
  ) {
    isInbox = true;
  }

  // List of shortcuts filtered based on visibility
  const shortcutsListLocal = useMemo(() => {
    let filteredShortcuts = shortcutsList.filter(
      ({ show_in_omni_search: showInOmniSearch }) => {
        // Check if the shortcut should be shown in the omni search
        if (typeof showInOmniSearch === 'function') {
          return showInOmniSearch();
        }
        return showInOmniSearch;
      },
    );

    // Filter out inbox shortcuts if not in inbox view
    if (!isInbox) {
      filteredShortcuts = filteredShortcuts.filter(
        (item) =>
          !inboxShortcutGroupId.includes(item.groupId) &&
          !(pathName[4] === 'search' && item.groupId === 6),
      );
    } else {
      // Further filter shortcuts based on the active conversation status
      filteredShortcuts = filteredShortcuts.filter((item) => {
        if (activeConversation && activeConversation.status === 'closed') {
          return item.action !== 'close_conversation';
        } else if (activeConversation && activeConversation.status === 'open') {
          return item.action !== 'reopen_conversation';
        } else if (
          activeConversation &&
          activeConversation.status === 'pending'
        ) {
          return item.action !== 'snooze_conversation';
        }
        return true;
      });
    }
    return filteredShortcuts;
  }, [activeConversation, isInbox]);

  // List of shortcut groups filtered based on visibility
  const shortcutsListGroupsLocal = useMemo(() => {
    let filteredGroups = shortcutsListGroups.filter(
      ({ show_in_omni_search: showInOmniSearch }) => {
        // Check if the group should be shown in the omni search
        if (typeof showInOmniSearch === 'function') {
          return showInOmniSearch();
        }
        return showInOmniSearch;
      },
    );

    // Exclude groups based on inboxShortcutGroupId only if not in inbox
    if (!isInbox) {
      filteredGroups = filteredGroups.filter(
        (item) => !inboxShortcutGroupId.includes(item.id),
      );
    }

    return filteredGroups;
  }, [inboxShortcutGroupId, isInbox]);

  // Handle selected command
  const handleSelectChange = useCallback(
    (value) => {
      handleCommandSelect(value);
    },
    [handleCommandSelect],
  );

  return (
    <JungleSelect
      items={shortcutsListLocal}
      groups={shortcutsListGroupsLocal}
      renderGroup={(group, items) => getGroupItem(group, items)}
      renderItem={(item) => getCommandItem(item)}
      placeholder="Type a command or search..."
      searchable={true}
      searchableAttributes={['name']}
      listWrapper={(list) => <div className="command-list">{list}</div>}
      onChange={handleSelectChange}
      mode="list"
      selectFirstItem={true}
      selected={selectedCommand}
      autofocus={true}
    />
  );
};

export default DefaultWidget;
