import React, { useEffect, useState, useCallback, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openLinkInNewTab } from '../../helpers/utility';
import sharedActions from '../../redux/sharedReducers/actions';
import chatActions from '../../redux/chat/actions';
import AskEmmaModal from '../Chat/Conversations/AskEmmaModal';
import DefaultWidget from './OmniSearch/DefaultWidget';
import { lazy } from 'react';

// Lazy load widgets
const ArticleWidget = lazy(() => import('./OmniSearch/ArticleWidget'));
const AIAssistWidget = lazy(() => import('./OmniSearch/AIAssistWidget'));
const SnoozeWidget = lazy(() => import('./OmniSearch/SnoozeWidget'));
const AssignWidget = lazy(() => import('./OmniSearch/AssignWidget'));
const TagConversationWidget = lazy(() =>
  import('./OmniSearch/TagConversationWidget'),
);
const TagContactWidget = lazy(() => import('./OmniSearch/TagContactWidget'));
const SnippetWidget = lazy(() => import('./OmniSearch/SnippetWidget'));
const MeetingWidget = lazy(() => import('./OmniSearch/MeetingWidget'));
const GoToWidget = lazy(() => import('./OmniSearch/GoToWidget'));
const CreateNewWidget = lazy(() => import('./OmniSearch/CreateNewWidget'));
const skeletons = (
  <Skeleton active title={false} paragraph={{ rows: 4, width: '100%' }} />
);

import '../Chat/Messages/style.scss';
import { Skeleton } from 'antd';
import CustomSnoozeModal from './OmniSearch/CustomSnoozeModal';

const OmniSearch = React.memo((props) => {
  const dispatch = useDispatch();

  const currentConversationId = useSelector(
    ({ Chat }) => Chat.currentConversationId,
  );
  const displayOmniSearch = useSelector(
    ({ SharedReducers }) => SharedReducers.displayOmniSearch,
  );
  const currentOmniSearchState = useSelector(
    ({ SharedReducers }) => SharedReducers.currentOmniSearchState,
  );
  const showNewConversation = useSelector(
    ({ Chat }) => Chat.showNewConversation,
  );
  const currentReplyEditor = useSelector(
    ({ SharedReducers }) => SharedReducers.replyEditor,
  );
  const currentNoteEditor = useSelector(
    ({ SharedReducers }) => SharedReducers.noteEditor,
  );
  const replyEditor =
    currentReplyEditor &&
    currentReplyEditor[
      currentConversationId || (showNewConversation ? 'new_message' : null)
    ];
  const noteEditor =
    currentNoteEditor && currentNoteEditor[currentConversationId];
  const isComposerUndo = useSelector(
    ({ SharedReducers }) => SharedReducers.isComposerUndo,
  );
  const tabActiveKey = useSelector(({ Chat }) => Chat.activeComposerTab);
  const activeComposerTab = useSelector(
    ({ Chat }) =>
      Chat.activeComposerTabList[currentConversationId] ||
      tabActiveKey ||
      'reply',
  );
  const currentConversation = useSelector(({ Chat }) =>
    Chat.chatRooms.find(
      (conversation) =>
        conversation.conversation_identifier === currentConversationId,
    ),
  );
  const isFromPreviousConversation = useSelector(
    ({ Chat }) => Chat.isFromPreviousConversation,
  );
  const selectedPreviousConversation = useSelector(
    ({ Chat }) => Chat.selectedPreviousConversation,
  );

  const [selectedCommand, setSelectedCommand] = useState(undefined);

  // Determine if active conversation if previous conversation or default one
  const activeConversation = isFromPreviousConversation
    ? selectedPreviousConversation
    : currentConversation || {};

  // Effect to handle clicks outside the modal
  const handleClickOutside = useCallback((e) => {
    const modal = document.querySelector('.omnisearch-modal');
    if (modal && !modal.contains(e.target)) {
      hideOmniSearch();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  // Effect to handle closing the modal on pressing escape key inside the JungleSelect search input
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentOmniSearchState]);

  // Close the modal if the active element is the JungleSelect search input and the escape key is pressed
  const handleKeyDown = (e) => {
    const activeElement = document.activeElement;
    if (
      activeElement.matches('.jungle-select-controls input') &&
      (e.key === 'Escape' || e.keyCode === 27)
    ) {
      e.preventDefault();
      hideOmniSearch();
    }
  };

  // Hide the OmniSearch modal
  const hideOmniSearch = () => {
    dispatch({ type: sharedActions.SHOW_HIDE_OMNISEARCH, payload: false });
  };

  // Update the value in the appropriate editor
  const onValueChange = (value, type) => {
    dispatch({
      type:
        type === 'reply'
          ? chatActions.SET_REPLY_COMPOSERS_VALUE
          : chatActions.SET_NOTE_COMPOSERS_VALUE,
      payload: value,
      conversationId: currentConversationId,
    });
  };

  // Push content into the composer
  const pushContentIntoComposer = (value, withOutWhitespace = false) => {
    // Get the appropriate editor based on the active composer tab
    const tabKeyBasedEditor =
      activeComposerTab === 'reply' ? replyEditor : noteEditor;

    // Enable editing on the selected editor
    tabKeyBasedEditor.edit.on();
    tabKeyBasedEditor.selection.restore();

    // Handle undo state
    if (isComposerUndo) {
      tabKeyBasedEditor.commands.undo();
      dispatch({
        type: sharedActions.CHANGE_COMPOSER_UNDO_STATUS,
        payload: false,
      });
    }

    // Insert the value into the editor and update the appropriate redux state
    value = value.replace(/\r?\n/g, '<br>'); // Replace new lines with <br> for HTML
    tabKeyBasedEditor.events.focus(); // Focus on the editor
    tabKeyBasedEditor.html.insert(`${value}${withOutWhitespace ? '' : ' '}`); // Insert the value into the editor
    tabKeyBasedEditor.undo.saveStep(); // Save the undo step
    tabKeyBasedEditor.selection.save();
    tabKeyBasedEditor.selection.restore();
    onValueChange(tabKeyBasedEditor.html.get(true), activeComposerTab); // Update the appropriate redux state
  };

  // Handle command selection
  const handleCommandSelect = (command) => {
    // Open the link in a new tab if the command group ID is 5
    if (command.groupId === 5) {
      openLinkInNewTab(command.url);
    } else if (
      command.groupId !== 1 ||
      (command.groupId === 1 &&
        (activeConversation.id || command.action === 'ask_emma'))
    ) {
      // Execute the shortcut command and set the selected command
      props.executeShortcutCommand(command.action);
      setSelectedCommand(command);
    }

    // Hide OmniSearch for commands with no secondary screen
    if (
      ![
        'snooze_conversation',
        'assign_to',
        'snippet',
        'tag_conversation',
        'article',
        'meeting',
        'tag_contact',
        'ai_assist',
      ].includes(command.action)
    ) {
      hideOmniSearch();
    }
  };

  return (
    <>
      <AskEmmaModal
        pushContentIntoComposer={pushContentIntoComposer}
        currentConversationId={currentConversationId}
        showNewConversation={showNewConversation}
      />
      {displayOmniSearch && (
        <div className="omnisearch-component">
          <div className="omnisearch-modal">
            <div className="command-list-container">
              <div className="command-list-inner">
                <Suspense
                  fallback={<div style={{ padding: '16px' }}>{skeletons}</div>}
                >
                  {currentOmniSearchState === 'default' && (
                    <DefaultWidget
                      selectedCommand={selectedCommand}
                      activeConversation={activeConversation}
                      handleCommandSelect={handleCommandSelect}
                      showNewConversation={showNewConversation}
                    />
                  )}
                  {currentOmniSearchState === 'go_to' && (
                    <GoToWidget handleCommandSelect={handleCommandSelect} />
                  )}
                  {currentOmniSearchState === 'create_new' && (
                    <CreateNewWidget
                      handleCommandSelect={handleCommandSelect}
                    />
                  )}
                  {currentOmniSearchState === 'snooze' && (
                    <SnoozeWidget
                      activeConversationStatus={activeConversation.status}
                      currentConversationId={currentConversationId}
                      isFromPreviousConversation={isFromPreviousConversation}
                    />
                  )}
                  {currentOmniSearchState === 'assign_to' && <AssignWidget />}
                  {currentOmniSearchState === 'tag_conversation' && (
                    <TagConversationWidget />
                  )}
                  {currentOmniSearchState === 'tag_contact' && (
                    <TagContactWidget />
                  )}
                  {currentOmniSearchState === 'snippet' && (
                    <SnippetWidget
                      pushContentIntoComposer={pushContentIntoComposer}
                    />
                  )}
                  {currentOmniSearchState === 'article' && (
                    <ArticleWidget
                      pushContentIntoComposer={pushContentIntoComposer}
                    />
                  )}
                  {currentOmniSearchState === 'meeting' && <MeetingWidget />}
                  {currentOmniSearchState === 'ai_assist' && <AIAssistWidget />}
                </Suspense>
                </div>
              </div>
            </div>
          </div>
      )}
      <CustomSnoozeModal
        activeConversationStatus={activeConversation.status}
        isFromPreviousConversation={isFromPreviousConversation}
        currentConversationId={currentConversationId}
      />
    </>
  );
});

export default OmniSearch;
