import actions from './actions';
import ProactiveMessage from '../proactiveMessage/actions';
import {
  fetchProjectSecretKey,
  removeUnderscore,
  extractAvatarLetter,
  findIndexOfAllUsersSegment,
  updatePropertyPreference,
  canWeAccess,
  handleNotAuthorizedModal,
  subscriptionStatus,
  subscriptionStatusUpdate,
  avatarImage,
} from '../../helpers/utility';
import React from 'react';
import { Avatar, Tooltip, Tag } from 'antd';
import * as _ from 'lodash';
import {
  defaultVisibleColumns,
  orderOfColumns,
} from '../../components/Contacts/UsersListTable/constants';
import { invalidContent } from '../../components/constants';
import { store } from '../store';
import { userRestriction } from '../../components/TopNavBar/constants';
import { MOST_USED_TAGS_LIMIT } from '../../containers/App/constants';
import { cloneDeep } from 'lodash';
import LiveTimeAgo from '../../components/Chat/LiveTimeAgo';
import moment from 'moment';
import CustomTimeAgo from "../../components/Contacts/CustomTimeAgo";

export const initState = {
  contactActionEdit: [],
  showFilterOption: false,
  filterOptionIndex: [],
  contactsData: [],
  contactsColumns: [],
  contactSearchValue: '',
  sortOrder: '',
  sortKey: '',
  userProperties: [],
  customProperties: [],
  segments: [],
  selectedSegment: {},
  contactsLoading: true,
  contactProfileData: null,
  allKeyList: [],
  allContactsColumns: [],
  allPropertyKeys: [],
  contactActivitiesData: [],
  propertiesMetaData: {},
  eventsMetaData: [],
  events: [],
  addedPropertyAndEvent: {
    name: '',
    new_filters: [],
  },
  tags: [],
  selectedRowKeys: [],
  filters: {},
  activitiesLoading: true,
  contactsLoadingSpinner: false,
  activitiesFilterList: [],
  isFilterApplied: false,
  filteredContactCount: null,
  isShowSaveSegment: false,
  profileLoading: true,
  recipientsLoader: false,
  personID: '',
  importCsvCurrentStepKey: 0,
  importedCsvFile: null,
  importCSVLoader: false,
  mapUserProperties: [],
  CSVHeaders: [],
  importedFilePreview: [],
  dateFormats: [],
  mappedData: {},
  importCsvName: null,
  importTerms: false,
  importCsvActionData: {},
  importCsvCondition: 'update_or_new',
  createPropertyDrawerVisible: false,
  selectedProperties: [],
  tableData: [],
  exportDrawerVisible: false,
  exportCsvLoader: false,
  teamMates: [],
  isOpenSaveSegment: false,
  segmentUpdateType: 'update',
  contactNotes: {},
  isFetchingContactNotes: false,
  noteAddLoading: false,
  contactModalVisible: false,
  contactModalLoading: false,
  isSegmentBtnLoader: false,
  fbCustomAudienceLoading: false,
  fbCustomAudienceDrawerVisible: false,
  takeFilterParams: false,
  accountId: '',
  wooCommerceLoader: false,
  productDetails: null,
  contactMeetingList: [],
  contactMeetingLoader: false,
  contactMeetingModalStatus: false,
  contactMeetingModalLoader: false,
  emailList: [],
  emailLoader: false,
  emailListLoader: false,
  emailModalStatus: false,
  companyDrawerStatus: false,
  companyBtnLoader: false,
  ownerDrawerStatus: false,
  ownerBtnLoader: false,
  recentSegments: [],
  favouriteSegments: [],
  mostUsedContactTags: [],
  personMailSubscription: [],
  contactSubscriptions: [],
  subscriptionLoader: false,
  handledSubscriptions: [],
  pageCount: 0,
  modalContactId: null,
  segmentProcessing: false,
  dealsList: [],
  dealsLoader: false,
};

/**
 * Sort array of objects based on another array
 */

function mapOrder(array, order, key) {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
}

function selectedColumns(allKeyArray, propertyPreference) {
  const hasActionIndex = _.findIndex(allKeyArray, {
    dataIndex: 'gist_contact_action',
  });
  if (hasActionIndex === -1) {
    allKeyArray.splice(
      1,
      0,
      constructContactsTable('icon', 'gist_contact_action'),
    );
  }
  let userPropertiesColumns = propertyPreference;
  if (
    userPropertiesColumns &&
    userPropertiesColumns !== '' &&
    userPropertiesColumns.length > 0
  ) {
    let NewuserPropertiesColumns = userPropertiesColumns.split(',');
    if (
      NewuserPropertiesColumns.length > 0 &&
      !NewuserPropertiesColumns.includes('gist_contact_action')
    ) {
      NewuserPropertiesColumns.splice(1, 0, 'gist_contact_action');
      userPropertiesColumns = NewuserPropertiesColumns.toLocaleString();
      updatePropertyPreference(userPropertiesColumns, 'contacts_reducer');
    }
  }
  if (String(userPropertiesColumns).startsWith('name')) {
    userPropertiesColumns = userPropertiesColumns.replace('name', 'full_name');
    updatePropertyPreference(userPropertiesColumns, 'contacts_reducer');
  }
  let columns = defaultVisibleColumns;
  if (!['', undefined, null].includes(userPropertiesColumns)) {
    columns = [...userPropertiesColumns.split(',')];
  } else {
    updatePropertyPreference(defaultVisibleColumns, 'contacts_reducer');
  }
  allKeyArray = allKeyArray.filter((contactsColumn) =>
    columns.includes(contactsColumn.dataIndex),
  );

  let correctOrder = userPropertiesColumns.split(',');
  var newOrderedColumns = mapOrder(allKeyArray, correctOrder, 'dataIndex');
  return newOrderedColumns;
}

function openProactiveModal(record) {
  if (!canWeAccess(userRestriction.communicateWithContacts)) {
    handleNotAuthorizedModal(true);
  } else {
    store.dispatch({
      type: ProactiveMessage.PROACTIVE_OPEN_MESSAGE_MODAL,
      payload: { personId: [record.id] },
    });
  }
}

function constructContactsTable(value, key) {
  let contentWidth = '',
    alignContent = '';

  switch (key) {
    case 'full_name':
      contentWidth = '350px';
      alignContent = 'left';
      break;
    case 'company_name':
      contentWidth = '350px';
      alignContent = 'center';
      break;
    case 'company':
    case 'email_list':
    case 'campaign':
    case 'email_url':
    case 'date':
    case 'tags':
    case 'city':
    case 'contact_owner':
      contentWidth = '300px';
      alignContent = 'center';
      break;
    case 'landing_page':
    case 'signup_source':
      contentWidth = '250px';
      alignContent = 'center';
      break;
    case 'email':
      contentWidth = '350px';
      alignContent = 'left';
      break;
    case 'user_id':
    case 'gist_contact_action':
    case 'user_type':
      contentWidth = '150px';
      alignContent = 'center';
      break;
    default:
      contentWidth = '250px';
      alignContent = 'center';
  }
  let columns = {
    title: () => {
      const title =
        key === 'gist_contact_action' ? 'Action' : removeUnderscore(key);
      return (
        <Tooltip title={title} placement={'bottom'}>
          {title}
        </Tooltip>
      );
    },
    dataIndex: key,
    sorter: key !== 'gist_contact_action',
    width: contentWidth,
    align: alignContent,
    ellipsis: true,
    render: (data, record) => {
      if (key === 'gist_contact_action') {
        return (
          <div className="visitors-cell" style={{ justifyContent: 'center' }}>
            <div className="chat" onClick={() => openProactiveModal(record)}>
              <i className={'fal fa-comment-lines'} />
            </div>
          </div>
        );
      }
      if (key === 'full_name') {
        let avatar = <Avatar size="medium" src={record.avatar_url} />;
        if (
          record.avatar_url === null ||
          record.avatar_url.includes('assets.getgist.com/avatar')
        ) {
          avatar = (
            <div className="contacts-profile">
              {record.avatar_letter
                ? record.avatar_letter
                : extractAvatarLetter(record.full_name)}
            </div>
          );
        }
        return (
          <div className="contacts-cell">
            {avatar}
            <a
              href={`/projects/${fetchProjectSecretKey()}/contacts/${
                record.id
              }`}
              className="link"
              onClick={(e) => {
                e.preventDefault();
                store.dispatch({
                  type: actions.UPDATE_MODAL_CONTACT_ID,
                  payload: {
                    contact_id: record.id,
                  },
                });
              }}
            >
              {data ? data : 'Site Visitor'}
            </a>
          </div>
        );
      }
      if (['email_list', 'email_url', 'campaign', 'date'].includes(value)) {
        return (
          <div className="contacts-cells">
            {data ? <CustomTimeAgo data={data} /> : '-'}
          </div>
        );
      }
      if (['landing_page', 'signup_source'].includes(key)) {
        return (
          <div className="contacts-cells">
            {data ? (
              <Tooltip title={data} placement={'bottom'}>
                {data}
              </Tooltip>
            ) : (
              '-'
            )}
          </div>
        );
      }
      if (key === 'tags' && data !== null) {
        return (
          <div className="contacts-cells">
            {data.split(',').map((tag, index) => (
              <Tooltip title={tag} placement={'bottom'} key={index}>
                <Tag>{tag}</Tag>
              </Tooltip>
            ))}
          </div>
        );
      }
      if (key === 'contact_owner') {
        return (
          <div className="contacts-cell" style={{ justifyContent: 'center' }}>
            {Object.keys(data).length ? (
                <>
                  {avatarImage(data)}
                  <span>{data.full_name}</span>
                </>
            ) : (
              '-'
            )}
          </div>
        );
      }
      if (value === 'number') {
        return (
          <div className="contacts-cells">
            {!invalidContent.includes(data) ? data : '-'}
          </div>
        );
      }
      return (
        <div className="contacts-cells">
          <Tooltip title={data ? data : '-'} placement={'bottom'}>
            {data ? data : '-'}
          </Tooltip>
        </div>
      );
    },
  };
  if (key === 'last_seen') {
    columns.defaultSortOrder = 'ascend';
  }
  return columns;
}

function constructProperties(value) {
  return {
    key: removeUnderscore(value.property_name),
    value: value.property_name,
  };
}

export default function contactReducer(state = initState, action) {
  switch (action.type) {
    case actions.EDIT_CONTACT:
      return {
        ...state,
        contactActionEdit: action.selectedRows,
        selectedRowKeys: action.selectedRowKeys,
      };
    case actions.SHOW_FILTER_OPTION:
      return {
        ...state,
        showFilterOption: !state.showFilterOption,
      };
    case actions.ADD_CONDITION:
      return {
        ...state,
        filterOptionIndex: action.filterOptionIndex,
      };
    case actions.FETCH_CONTACTS:
      return {
        ...state,
        contactsLoadingSpinner: true,
      };
    case actions.FETCH_CONTACTS_SUCCESS:
      let allKeyArray = _.map(state.allPropertyKeys, (value) =>
        constructContactsTable(value.property_type, value.property_name),
      );

      // ADD RECENT SEGMENTS
      return {
        ...state,
        contactsData: action.payload.data,
        contactsColumns: selectedColumns(
          allKeyArray,
          action.propertyPreference,
        ),
        contactsLoading: false,
        contactsLoadingSpinner: false,
        allContactsColumns: allKeyArray,
        filteredContactsCount: action.payload.meta_data.filtered_people_count,
        matchedContactsCount: action.payload.meta_data.matched_people_count,
        isFilterApplied: action.searchValue
          ? true
          : state.isFilterApplied
            ? true
            : false,
        recentSegments: action.payload.most_recent_segments,
        segmentProcessing: action.payload.meta_data.segment_processing,
      };
    case actions.APPEND_CONTACTS_SUCCESS:
      const allContactsColumns = _.map(state.allPropertyKeys, (value) =>
        constructContactsTable(value.property_type, value.property_name),
      );
      return {
        ...state,
        contactsData: state.contactsData.concat(action.payload.data),
        contactsColumns: selectedColumns(
          allContactsColumns,
          action.propertyPreference,
        ),
        contactsLoading: false,
        contactsLoadingSpinner: false,
        allContactsColumns,
      };
    case actions.UPDATE_CONTACT_COLUMNS:
      return {
        ...state,
        contactsColumns: selectedColumns(
          state.allContactsColumns,
          action.propertyPreference,
        ),
      };
    case actions.FETCH_PROPERTIES_SUCCESS:
      let properties = action.payload.data,
        userProperties = _.map(properties.user_properties, (value) =>
          constructProperties(value),
        ),
        customProperties = _.map(properties.custom_properties, (value) =>
          constructProperties(value),
        );
      let allProperties = [];
      for (let category of action.payload.data.user_properties) {
        allProperties = _.concat(allProperties, category.properties);
      }
      allProperties = _.reverse(_.sortBy(allProperties, orderOfColumns));
      let allKeyArrayList = _.map(allProperties, (value) =>
        constructContactsTable(value.property_type, value.property_name),
      );
      return {
        ...state,
        userProperties: userProperties,
        customProperties: customProperties,
        eventFilters: properties.event_filters,
        allPropertyKeys: allProperties,
        allContactsColumns: allKeyArrayList,
        contactsColumns: selectedColumns(
          allKeyArrayList,
          action.propertyPreference,
        ),
      };
    case actions.ON_CONTACTS_SEARCH:
      return {
        ...state,
        contactSearchValue: action.payload,
      };
    case actions.SET_SORTER:
      return {
        ...state,
        sortOrder: action.payload.data.sortOrder,
        sortKey: action.payload.data.sortKey,
      };
    case actions.EMPTY_CONTACTS:
      let { isFromSideBar } = action.payload;
      return {
        ...state,
        contactsLoading: true,
        contactsLoadingSpinner: true,
        contactsData: [],
        sortOrder: isFromSideBar ? state.sortOrder : '',
        sortKey: isFromSideBar ? state.sortKey : '',
        contactSearchValue: isFromSideBar ? state.contactSearchValue : '',
      };
    case actions.FETCH_SEGMENTS_SUCCESS:
      const segments = action.payload.data;
      const most_recent_segments = action.payload.most_recent_segments;
      const favourite_segments = action.payload.favourite_segments;

      let selectedSegment = state.selectedSegment;
      if (_.isEmpty(selectedSegment)) {
        const segmentIndex = findIndexOfAllUsersSegment(action.payload.data);
        selectedSegment = segmentIndex >= 0 ? segments[segmentIndex] : {};
      }

      return {
        ...state,
        segments,
        selectedSegment,
        recentSegments: most_recent_segments,
        favouriteSegments: favourite_segments,
      };
    case actions.SET_SEGMENT_SELECTION:
      return {
        ...state,
        selectedSegment: action.payload,
        addedPropertyAndEvent: {
          ...action.payload,
          name:
            String(action.payload.name).toLowerCase() === 'all users'
              ? ''
              : action.payload.name,
        },
        isShowSaveSegment: false,
        isFilterApplied: false,
        recipientsCount: 0,
      };
    case actions.SET_SELECTED_SEGMENT_COUNT:
      return {
        ...state,
        segments: !invalidContent.includes(action.matchedContactsCount) && state.segments.length ? state.segments.map(segmentData => {
          if (state.selectedSegment.id === segmentData.id) {
            segmentData.people_count = action.matchedContactsCount;
          }
          return segmentData;
        }) : state.segments,
      };
    case actions.SET_SEGMENT_FROM_SETTINGS:
      let activeSegment = state.segments.filter(
        (data) => data.id === parseInt(action.id),
      );
      return {
        ...state,
        selectedSegment: activeSegment[0]
          ? activeSegment[0]
          : state.segments[findIndexOfAllUsersSegment(state.segments)],
        addedPropertyAndEvent: activeSegment[0]
          ? activeSegment[0]
          : state.addedPropertyAndEvent,
        isFilterApplied: false,
      };
    case actions.FETCH_CONTACT_PROFILE_SUCCESS:
      let allKeyList = _.map(state.allPropertyKeys, (value) => ({
        key: value.property_name,
        value: value.property_type,
      })),
        { starred_keys } = action.payload;
      allKeyList = allKeyList.filter(
        (data) => !['subscription_types', 'tags'].includes(data.key),
      );
      if (starred_keys && starred_keys.length) {
        let updatedAllKeyList = _.cloneDeep(allKeyList);
        updatedAllKeyList.sort((compareElement1, compareElement2) => {
          let compareElement1Index = starred_keys.indexOf(compareElement1.key),
            compareElement2Index = starred_keys.indexOf(compareElement2.key);
          if (compareElement1Index === -1) {
            compareElement1Index = starred_keys.length;
          }
          if (compareElement2Index === -1) {
            compareElement2Index = starred_keys.length;
          }
          return compareElement1Index - compareElement2Index;
        });
        allKeyList = updatedAllKeyList;
      }
      return {
        ...state,
        contactProfileData: action.payload,
        allKeyList: allKeyList,
        profileLoading: false,
      };
    case actions.UPDATE_CONTACT_PROFILE_TAG_SUCCESS: {
      if (state.contactProfileData && state.contactProfileData.person_data) {
        return {
          ...state,
          contactProfileData: {
            ...state.contactProfileData,
            person_data: {
              ...state.contactProfileData.person_data,
              tags: state.contactProfileData.person_data.tags.concat(
                action.payload.data.tags,
              ),
            },
          },
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case actions.REMOVE_CONTACT_PROFILE_TAG_SUCCESS:
      return {
        ...state,
        contactProfileData: {
          ...state.contactProfileData,
          person_data: {
            ...state.contactProfileData.person_data,
            tags: state.contactProfileData.person_data.tags.filter(
              (tag) => tag.id !== action.tagId,
            ),
          },
        },
      };
    case actions.UPDATE_CONTACT_PROFILE_SUCCESS:
      let updateProperties = cloneDeep(state.contactProfileData.properties);
      let updateProperty = action.payload.person;
      Object.keys(action.payload.person).forEach(function eachKey(updatedKey) {
        Object.keys(action.payload.person).forEach(function eachKey(actualKey) {
          if (updatedKey === actualKey) {
            updateProperties = updateProperties.map((property) => {
              if (property.key === actualKey) {
                property.value = updateProperty[actualKey];
              }
              return property;
            });
          }
        });
      });
      let updateProfile = cloneDeep(state.contactProfileData.profile);
      if (action.profile) {
        updateProfile = {
          ...updateProfile,
          ...action.profile,
          ...action.payload.person,
        };
        if (action.payload.owner !== undefined) {
          updateProfile.owner = action.payload.owner;
        }
      }
      return {
        ...state,
        contactProfileData: {
          ...state.contactProfileData,
          profile: updateProfile,
          properties: updateProperties,
        },
        reset: false,
      };
    case actions.DELETE_CONTACT_PROFILE_SUCCESS:
      // INFO: Commented because this will be needed after soft delete implemented in backend
      // let personIds = action.payload.person_ids.map(personId => {
      //   personId = { id: personId };
      //   return personId;
      // });
      // let matchedPerson = _.unionBy(personIds, state.contactsData, 'id'),
      //   filteredContacts = matchedPerson.filter(
      //     person => Object.keys(person).length > 1,
      //   );
      return {
        ...state,
        // contactsData: filteredContacts,
        contactActionEdit: [],
        selectedRowKeys: [],
      };
    case actions.FETCH_CONTACT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activitiesLoading: false,
        contactActivitiesData: state.contactActivitiesData.concat(
          action.payload.data,
        ),
      };
    case actions.ENABLE_CONTACT_ACTIVITIES_SKELETON:
      return {
        ...state,
        activitiesLoading: true,
        profileLoading: true,
      };
    case actions.EDIT_CONTACT_PROPERTIES_SUCCESS:
      const contactsData = state.contactsData;
      action.payload.person_ids.forEach((id) => {
        const contactIndex = state.contactsData.findIndex(
          (datum) => datum.id === parseInt(id),
        );
        _.merge(contactsData[contactIndex], action.payload.person);
      });
      return {
        ...state,
        contactsData,
        contactActionEdit: [],
        selectedRowKeys: [],
      };
    case actions.CLEAR_CONTACT_ACTIVITIES:
      return {
        ...state,
        contactActivitiesData: [],
      };
    case actions.FETCH_TAGS_SUCCESS:
      let mostUsedContactTagIds = action.payload.data.most_used_tags,
        allTagsList = action.payload.data.tags;
      let mostUsedContactTags = [];

      if (mostUsedContactTagIds.length) {
        for (let i = 0; i < mostUsedContactTagIds.length; i++) {
          mostUsedContactTags.push(
            allTagsList.find((tag) => tag.id === mostUsedContactTagIds[i]),
          );
        }
      }

      return {
        ...state,
        tags: allTagsList,
        mostUsedContactTags: mostUsedContactTags.slice(0, MOST_USED_TAGS_LIMIT),
      };
    case actions.APPLY_TAG_SUCCESS:
      const ids = action.payload.data.person_ids.map((id) => parseInt(id));
      state.contactsData = state.contactsData.map((contactsDatum) => {
        if (ids.includes(contactsDatum.id)) {
          if (contactsDatum.tags) {
            const contactTags = new Set(contactsDatum.tags.split(', '));
            contactTags.add(action.payload.data.tag);
            contactsDatum.tags = [...contactTags].join(', ');
          } else {
            contactsDatum.tags = action.payload.data.tag;
          }
          return contactsDatum;
        }
        return contactsDatum;
      });
      return {
        ...state,
        contactsData: state.contactsData,
        contactActionEdit: [],
        selectedRowKeys: [],
      };
    case actions.FILTERS_DROP_DOWN_SUCCESS:
      return {
        ...state,
        filters: action.payload.data,
      };
    case actions.CREATE_SEGMENT:
      return {
        ...state,
        isSegmentBtnLoader: true,
      };
    case actions.CREATE_SEGMENT_SUCCESS:
      return {
        ...state,
        segments: state.segments.concat(action.payload.data),
        selectedSegment: action.payload.data,
        isShowSaveSegment: false,
        isOpenSaveSegment: false,
        isSegmentBtnLoader: false,
      };
    case actions.CREATE_SEGMENT_FAILURE:
      return {
        ...state,
        isSegmentBtnLoader: false,
        isOpenSaveSegment: true,
      };
    case actions.ENABLE_CONTACTS_TABLE_LOADING_SPINNER:
      return {
        ...state,
        contactsLoadingSpinner: true,
      };
    case actions.UNSUBSCRIBE_CONTACT_PROFILE_SUCCESS:
      return {
        ...state,
        contactProfileData: {
          ...state.contactProfileData,
          profile: {
            ...state.contactProfileData.profile,
            unsubscribed_from_all_email: action.payload,
          },
        },
      };
    case actions.UPDATE_SEGMENT:
      return {
        ...state,
        isSegmentBtnLoader: true,
      };
    case actions.UPDATE_SEGMENT_SUCCESS:
      let segmentsList = state.segments.map((segment) => {
        if (segment.id === action.payload.data.id) {
          segment = action.payload.data;
        }
        return segment;
      });
      return {
        ...state,
        segments: segmentsList,
        selectedSegment: action.payload.data,
        isShowSaveSegment: false,
        isSegmentBtnLoader: false,
        isOpenSaveSegment: false,
      };
    case actions.UPDATE_SEGMENT_FAILURE:
      return {
        ...state,
        isOpenSaveSegment: true,
        isSegmentBtnLoader: false,
      };
    case actions.APPLY_FILTERS:
      return {
        ...state,
        selectedSegment: {
          ...state.selectedSegment,
          new_filters: action.filters,
        },
        isShowSaveSegment: true,
        isFilterApplied: true,
      };
    case actions.SET_ACTIVITIES_FILTER:
      return {
        ...state,
        activitiesFilterList: action.payload.activityTypes,
      };
    case actions.SET_RECIPIENT_LOADER:
      return {
        ...state,
        recipientsLoader: action.payload,
      };
    case actions.DISABLE_CONTACTS_LOADER:
      return {
        ...state,
        contactsLoadingSpinner: false,
        contactsLoading: false,
      };
    case actions.CONTACTS_SET_FILTER:
      return {
        ...state,
        addedPropertyAndEvent: state.selectedSegment,
      };
    case actions.SET_PERSON_ID:
      return {
        ...state,
        personID: action.payload,
      };
    case actions.SET_IMPORT_CSV_CURRENT_STEP_KEY:
      return {
        ...state,
        importCsvCurrentStepKey: action.payload,
      };
    case actions.SET_IMPORT_CSV_FILE:
      return {
        ...state,
        importedCsvFile: action.payload.file,
        importCsvName: action.payload.name,
      };
    case actions.VALIDATE_IMPORTED_CSV:
      return {
        ...state,
        importCSVLoader: true,
      };
    case actions.VALIDATE_IMPORTED_CSV_SUCCESS:
      let hideProperty = action.payload.data.csv_headers.map((value) => {
        action.payload.data.user_properties.map((userValue) => {
          value.hideProperty = false;
          if (value.name === userValue.name) {
            value.type = userValue.type;
          }
          return userValue;
        });
        return value;
      });
      let mapped;
      let mappedData = {};
      action.payload.data.csv_headers.map((headerValue) => {
        mapped = false;
        action.payload.data.user_properties.map((userValue) => {
          if (headerValue.name === userValue.name) {
            mapped = true;
            mappedData[headerValue.name] = { value: headerValue.name };
          }
          return userValue;
        });
        if (!mapped) {
          mappedData[headerValue.name] = { value: null };
        }
        return headerValue;
      });
      let selectedProperties = [];
      action.payload.data.user_properties.map((value) => {
        action.payload.data.csv_headers.map((header, index) => {
          if (header.name === value.name) {
            selectedProperties.push({ id: index, value: value.name });
          }
          return header;
        });
        return value;
      });
      return {
        ...state,
        importCSVLoader: false,
        mapUserProperties: action.payload.data.user_properties,
        CSVHeaders: hideProperty,
        importedFilePreview: action.payload.data.preview,
        dateFormats: action.payload.data.date_formats,
        mappedData: mappedData,
        selectedProperties: selectedProperties,
        tableData: hideProperty,
      };
    case actions.VALIDATE_IMPORTED_CSV_FAILURE:
      return {
        ...state,
        importCSVLoader: false,
      };
    case actions.SET_MAPPED_DATA:
      return {
        ...state,
        mappedData: {
          ...state.mappedData,
          [action.key]:
            action.dataType === 'date'
              ? { value: action.value, date_format: action.dateFormat }
              : { value: action.value },
        },
      };
    case actions.SET_IMPORT_NAME_AND_ACCEPT:
      return {
        ...state,
        importCsvName: action.name,
        importTerms: action.terms,
      };
    case actions.IMPORT_CSV_ACTIONS_DATA:
      return {
        ...state,
        importCsvActionData: action.payload,
      };
    case actions.IMPORT_CSV:
      return {
        ...state,
        importCSVLoader: true,
      };
    case actions.IMPORT_CSV_SUCCESS:
      return {
        ...state,
        importCSVLoader: false,
      };
    case actions.IMPORT_CSV_FAILURE:
      return {
        ...state,
        importCSVLoader: false,
      };
    case actions.SET_IMPORT_CSV_IMPORT_CONDITION:
      return {
        ...state,
        importCsvCondition: action.payload,
      };
    case actions.CREATE_CONTACT_PROPERTIES_FOR_CSV_SUCCESS:
      return {
        ...state,
        mapUserProperties: state.mapUserProperties.concat(
          action.payload.data.contact_property,
        ),
      };
    case actions.CREATE_PROPERTY_DRAWER_VISIBLE:
      return {
        ...state,
        createPropertyDrawerVisible: action.payload,
        newPropertyDetails: { ...action.propertyDetails },
      };
    case actions.RESET_IMPORT_CSV_DATA:
      return {
        ...state,
        importCSVLoader: false,
        mapUserProperties: [],
        CSVHeaders: [],
        importedFilePreview: [],
        dateFormats: [],
        mappedData: [],
        importedCsvFile: null,
        selectedProperties: [],
        importCsvActionData: {},
        importTerms: false,
      };
    case actions.UPDATE_TABLE_DATA:
      return {
        ...state,
        tableData: action.tableData,
      };
    case actions.UPDATE_TABLE_HIDE:
      let data = [...state.tableData];
      if (action.hide) {
        data.map((value) => {
          if (value.name === action.key) {
            value.hideProperty = true;
          }
          return value;
        });
      } else {
        data.map((value) => {
          if (value.name === action.key) {
            value.hideProperty = false;
          }
          return value;
        });
      }
      return {
        ...state,
        tableData: data,
      };
    case actions.UPDATE_TABLE_DATA_PROPERTY:
      let tableData = [...state.tableData];
      if (action.payload.dataType === 'date') {
        tableData.map((value) => {
          if (value.name === action.payload.key) {
            value.type = 'date';
          }
          return value;
        });
      }
      if (action.payload.dataType === 'string') {
        tableData.map((value) => {
          if (value.name === action.payload.key) {
            value.type = 'string';
          }
          return value;
        });
      }
      return {
        ...state,
        tableData,
      };
    case actions.DISABLE_SELECTED_PROPERTY:
      let propertyIndex = _.findIndex(
        state.selectedProperties,
        function (customProperty) {
          return customProperty.id === action.id;
        },
      );
      if (propertyIndex >= 0) {
        state.selectedProperties[propertyIndex] = {
          id: action.id,
          value: action.payload,
        };
      } else {
        state.selectedProperties.push({
          id: action.id,
          value: action.payload,
        });
      }
      return {
        ...state,
        selectedProperties: state.selectedProperties,
      };
    case actions.EXPORT_CONTACTS_DRAWER_VISIBLE:
      return {
        ...state,
        exportDrawerVisible: action.payload,
      };
    case actions.EXPORT_CONTACTS:
      return {
        ...state,
        exportCsvLoader: true,
      };
    case actions.EXPORT_CONTACTS_SUCCESS:
      return {
        ...state,
        exportDrawerVisible: false,
        exportCsvLoader: false,
      };
    case actions.EXPORT_CONTACTS_FAILURE:
      return {
        ...state,
        exportCsvLoader: false,
      };
    case actions.FETCH_TEAM_MATES_SUCCESS:
      return {
        ...state,
        teamMates: action.payload.team_mates,
      };
    case actions.SET_SAVE_SEGMENT_DRAWER:
      return {
        ...state,
        isOpenSaveSegment: action.payload,
        segmentUpdateType: 'update',
        addedPropertyAndEvent: {
          ...state.addedPropertyAndEvent,
          name: state.selectedSegment.name,
        },
      };
    case actions.SET_SEGMENT_UPDATE_TYPE:
      return {
        ...state,
        segmentUpdateType: action.payload,
        addedPropertyAndEvent: {
          ...state.addedPropertyAndEvent,
          name: state.selectedSegment.name,
        },
      };
    case actions.FETCH_CONTACT_NOTES_SUCCESS:
      return {
        ...state,
        contactNotes: action.payload,
        isFetchingContactNotes: false,
      };
    case actions.FETCH_CONTACT_NOTES_FAILURE:
      return {
        ...state,
        isFetchingContactNotes: false,
      };
    case actions.ACTIVATE_NOTES_LOADER:
      return {
        ...state,
        isFetchingContactNotes: true,
      };
    case actions.CREATE_CONTACT_NOTES:
      return {
        ...state,
        noteAddLoading: true,
      };
    case actions.CREATE_CONTACT_NOTES_SUCCESS:
      let peopleNotes = state.contactNotes.people_notes;
      peopleNotes.unshift(action.payload.people_note);
      return {
        ...state,
        contactNotes:
          parseInt(action.params.personID) ===
          parseInt(action.payload.people_note.person_id)
            ? {
                ...state.contactNotes,
                total_count: state.contactNotes.total_count + 1,
                people_notes: peopleNotes,
              }
            : state.contactNotes,
        noteAddLoading: false,
      };
    case actions.EDIT_CONTACT_NOTES_SUCCESS:
      let updatedPeopleNotes = state.contactNotes.people_notes.map((note) => {
        if (note.id === action.payload.id) {
          note = action.payload;
        }
        return note;
      });
      return {
        ...state,
        contactNotes: {
          ...state.contactNotes,
          people_notes: updatedPeopleNotes,
        },
      };
    case actions.DELETE_CONTACT_NOTES_SUCCESS:
      return {
        ...state,
        contactNotes:
          parseInt(action.params.personID) ===
          parseInt(action.payload.people_note.person_id)
            ? {
                ...state.contactNotes,
                total_count: state.contactNotes.total_count - 1,
                people_notes: state.contactNotes.people_notes.filter(
                  (note) => note.id !== action.params.noteID,
                ),
              }
            : state.contactNotes,
      };
    case actions.UPDATE_SEGMENT_NAME:
      return {
        ...state,
        addedPropertyAndEvent: {
          ...state.addedPropertyAndEvent,
          name: action.payload,
        },
      };
    case actions.CONTACT_CREATE_MODAL:
      return {
        ...state,
        contactModalVisible: action.payload,
      };
    case actions.CREATE_CONTACT:
      return{
        ...state,
        contactModalLoading: true,
      };
    case actions.CREATE_CONTACT_SUCCESS:
      return{
        ...state,
        contactModalLoading: false,
        contactModalVisible: false,
      };
    case actions.CREATE_CONTACT_FAILURE:
      return{
        ...state,
        contactModalLoading: false,
      };
    case actions.FB_CUSTOM_AUDIENCE_DRAWER_VISIBLE:
      return {
        ...state,
        fbCustomAudienceDrawerVisible: action.payload,
        accountId: '',
      };
    case actions.FB_CUSTOM_AUDIENCE:
      return {
        ...state,
        fbCustomAudienceLoading: true,
      };
    case actions.FB_CUSTOM_AUDIENCE_SUCCESS:
      return {
        ...state,
        fbCustomAudienceLoading: false,
        fbCustomAudienceDrawerVisible: false,
        accountId: '',
      };
    case actions.FB_CUSTOM_AUDIENCE_FAILURE:
      return {
        ...state,
        fbCustomAudienceLoading: false,
      };
    case actions.UPDATE_FILTER_OR_SEGMENT:
      return{
        ...state,
        takeFilterParams: action.takeFilterParams,
      };
    case actions.SET_ACCOUNT_ID:
      return {
        ...state,
        accountId: action.payload,
      };
    case actions.FETCH_WOOCOMMERCE_PRODUCTS_DETAILS:
      return {
        ...state,
        wooCommerceLoader: true,
      };
    case actions.FETCH_WOOCOMMERCE_PRODUCTS_DETAILS_SUCCESS:
      return {
        ...state,
        wooCommerceLoader: false,
        productDetails: action.payload,
      };
    case actions.FETCH_WOOCOMMERCE_PRODUCTS_DETAILS_FAILURE:
      return {
        ...state,
        wooCommerceLoader: false,
      };
    case actions.GET_CONTACT_MEETINGS:
      return {
        ...state,
        contactMeetingLoader: true,
      };
    case actions.GET_CONTACT_MEETINGS_SUCCESS:
      return {
        ...state,
        contactMeetingList: action.payload,
        contactMeetingLoader: false,
      };
    case actions.GET_CONTACT_MEETINGS_FAILURE:
      return {
        ...state,
        contactMeetingLoader: false,
      };
    case actions.CONTACT_MEETING_MODAL_STATUS: {
      return {
        ...state,
        contactMeetingModalStatus: action.payload,
      };
    }
    case actions.ADD_CONTACT_MEETING:
      return {
        ...state,
        contactMeetingModalLoader: true,
      };
    case actions.ADD_CONTACT_MEETING_SUCCESS:
      const meetingList = _.cloneDeep(state.contactMeetingList);
      meetingList.unshift(action.payload);
      return {
        ...state,
        contactMeetingList: meetingList,
        contactMeetingModalLoader: false,
        contactMeetingModalStatus: false,
      };
    case actions.ADD_CONTACT_MEETING_FAILURE:
      return {
        ...state,
        contactMeetingModalLoader: false,
      };
    case actions.CANCEL_CONTACT_MEETING:
      return {
        ...state,
        contactMeetingModalLoader: true,
      };
    case actions.CANCEL_CONTACT_MEETING_SUCCESS:
      return {
        ...state,
        contactMeetingList: state.contactMeetingList.filter(
          (data) => data.id !== action.meetingID,
        ),
        contactMeetingModalLoader: false,
      };
    case actions.CANCEL_CONTACT_MEETING_FAILURE:
      return {
        ...state,
        contactMeetingModalLoader: false,
      };
    case actions.ADD_CONTACT_ACTIVITIES:
      const activities = _.cloneDeep(state.contactActivitiesData);
      activities.unshift(action.payload);
      return {
        ...state,
        contactActivitiesData: activities,
      };
    case actions.CONTACT_EMAIL_MODEL_STATUS:
      return {
        ...state,
        emailModalStatus: action.payload,
      };
    case actions.GET_CONTACT_EMAIL_LIST:
      return {
        ...state,
        emailListLoader: true,
      };
    case actions.GET_CONTACT_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        emailList: action.payload,
        emailListLoader: false,
      };
    case actions.GET_CONTACT_EMAIL_LIST_FAILURE:
      return {
        ...state,
        emailListLoader: false,
      };
    case actions.CREATE_CONTACT_EMAIL:
      return {
        ...state,
        emailLoader: true,
      };
    case actions.CREATE_CONTACT_EMAIL_SUCCESS:
      let email = _.cloneDeep(state.emailList);
      email.unshift(action.payload);
      return {
        ...state,
        emailList: email,
        emailLoader: false,
        emailModalStatus: false,
      };
    case actions.CREATE_CONTACT_EMAIL_FAILURE:
      return {
        ...state,
        emailLoader: false,
      };
    case actions.SET_CONTACT_COMPANY_DRAWER:
      return {
        ...state,
        companyDrawerStatus: action.payload,
      };
    case actions.UPDATE_CONTACT_COMPANY:
      return {
        ...state,
        companyBtnLoader: true,
      };
    case actions.UPDATE_CONTACT_COMPANY_FAILURE:
      return {
        ...state,
        companyBtnLoader: false,
      };
    case actions.UPDATE_CONTACT_COMPANY_SUCCESS:
      return {
        ...state,
        contactProfileData: {
          ...state.contactProfileData,
          company: action.payload,
        },
        companyDrawerStatus: false,
        companyBtnLoader: false,
      };
    case actions.UPDATE_CONTACT_OWNER:
      return {
        ...state,
        ownerBtnLoader: true,
      };
    case actions.UPDATE_CONTACT_OWNER_SUCCESS:
      let updatedProperties = cloneDeep(state.contactProfileData.properties).map(
        property => {
          if (property.key === 'contact_owner') {
            return { ...property, value: action.payload ? action.payload.id : null };
          }
          return property;
          },
      );
      return {
        ...state,
        contactProfileData: {
          ...state.contactProfileData,
          profile: {
            ...state.contactProfileData.profile,
            owner: action.payload,
            contact_owner: action.payload && action.payload.id.toString(),
          },
          properties: updatedProperties,
        },
        ownerDrawerStatus: false,
        ownerBtnLoader: false,
      };
    case actions.UPDATE_CONTACT_OWNER_FAILURE:
      return {
        ...state,
        ownerBtnLoader: false,
      };
    case actions.SET_CONTACT_OWNER_DRAWER:
      return {
        ...state,
        ownerDrawerStatus: action.payload,
      };

    case actions.ADD_FAVOURITE_SEGMENT_SUCCESS:
      return {
        ...state,
        favouriteSegments: action.payload,
      };
    case actions.REMOVE_FAVOURITE_SEGMENT_SUCCESS:
      return {
        ...state,
        favouriteSegments: action.payload,
      };
    case actions.GET_PERSON_MAIL_SUBSCRIPTION:
      return {
        ...state,
        subscriptionLoader: !!action.isFromInitialized,
      };
    case actions.GET_PERSON_MAIL_SUBSCRIPTION_SUCCESS:
      let activeSubscriptions = action.payload.mail_subscriptions.filter(
        (item) => item.status === 'published',
      );
      return {
        ...state,
        personMailSubscription: activeSubscriptions,
        contactSubscriptions: subscriptionStatus(
          activeSubscriptions,
          action.payload.mail_subscriptions_person,
        ),
        subscriptionLoader: false,
        handledSubscriptions: action.payload.mail_subscriptions_person,
      };
    case actions.UPDATE_PERSON_MAIL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        contactSubscriptions: subscriptionStatusUpdate(
          state.contactSubscriptions,
          action.payload.subscribed_status,
          action.payload.mail_subscription_id,
        ),
      };
    case actions.ACTIVE_MAIL_SUBSCRIPTIONS_LOADER:
      return {
        ...state,
        subscriptionLoader: action.payload,
      };
    case actions.INCREMENT_PAGE_COUNT:
      return {
        ...state,
        pageCount: state.pageCount + 1,
      };
    case actions.RESET_PAGE_COUNT:
      return {
        ...state,
        pageCount: 0,
      };
    case actions.UPDATE_MODAL_CONTACT_ID:
      return {
        ...state,
        modalContactId: action.payload.contact_id,
      };
    case actions.RESET_CONTACT_NOTES:
      return {
        ...state,
        contactNotes: {},
      };
    case actions.FETCH_CONTACT_DEALS:
      return {
        ...state,
        dealsLoader: true,
      };
    case actions.FETCH_CONTACT_DEALS_SUCCESS:
      return {
        ...state,
        dealsList: action.payload?.deals || [],
        dealsLoader: false,
      };
    case actions.APPEND_CONTACTS_TAGS:
      return {
        ...state,
        tags: [...state.tags, action.payload],
      };
    default:
      return state;
  }
}
