import sharedActions from '../../../redux/sharedReducers/actions';
import chatActions from '../../../redux/chat/actions';
import { setSnoozeCalculation } from '../../../helpers/utility';
import { store } from '../../../redux/store';

const getSVG = (name) => {
  // Mapping icons to the corresponding names for a more scalable approach.
  const iconMap = {
    AskEmma: 'fal fa-info',
    WriteReply: 'fal fa-reply',
    AddNote: 'fal fa-sticky-note',
    InsertSnippet: 'fal fa-bookmark',
    InsertArticle: 'fal fa-file-alt',
    Attachment: 'fal fa-paperclip',
    Image: 'fal fa-image',
    Calendar: 'fal fa-calendar',
    AssignTo: 'fal fa-users-medical',
    AssignToMe: 'fal fa-user-plus',
    SearchConversation: 'fal fa-search',
    Close: 'fal fa-inbox-in',
    Snooze: 'fal fa-clock',
    Reopen: 'fal fa-inbox-out',
    Priority: 'fal fa-star',
    TagMessage: 'fal fa-tag',
    TagContact: 'fal fa-user-tag',
    SwitchInboxLayout: 'fal fa-line-columns',
    GoTo: 'fal fa-arrow-right',
    New: 'fal fa-plus',
    Help: 'fal fa-info-circle',
    API: 'fal fa-book',
    Keyboard: 'fal fa-keyboard',
    NewMessage: 'fal fa-keyboard',
    AiAssist: null,
  };

  if (name === 'AiAssist') {
    return <span className="ai-icon">AI</span>;
  }
  return <i className={iconMap[name] || ''} />;
};

const ShortcutKeys = ({ shortcuts }) => (
  <span className="shortcut-indicator">
    {shortcuts.map((shortcut, key) => (
      <span
        className={shortcut !== '' ? 'shortcut-key' : 'no-shortcut-key'}
        key={key}
      >
        {shortcut !== '' ? shortcut : 'then'}
      </span>
    ))}
  </span>
);

export const getGroupItem = (group, items) =>
  items.length > 0 &&
  group.id !== 'nogroup' && (
    <div>
      <div className="command-separator-container">
        <div className="command-separator" />
      </div>
      <div className="list-heading-container">
        <span className="list-heading">{group.label}</span>
      </div>
    </div>
  );

export const getCommandItem = (item) => (
  <div className="command-item">
    <div className="command-item-inner">
      <div className="icon-container">{getSVG(item.icon)}</div>
      <span className="command-content">
        <span className="command-title">{item.name}</span>
        {item.shortcut_key && <ShortcutKeys shortcuts={item.shortcut_key} />}
        {item.custom_text && (
          <span className="custom-text">{item.custom_text}</span>
        )}
      </span>
    </div>
  </div>
);

export const snoozeConversation = (
  value,
  activeConversationStatus,
  isFromPreviousConversation,
  currentConversationId,
  customSnoozeTime,
) => {
  let selectedTime =
    value !== 'custom'
      ? setSnoozeCalculation(value)
      : { format: 'custom', time: customSnoozeTime };
  store.dispatch({
    type: chatActions.SNOOZE_CONVERSATION,
    conversation_identifiers: [currentConversationId],
    select_all: false,
    time_key: selectedTime.format,
    time_value: selectedTime.time,
    currentStatus: activeConversationStatus,
    isFromPreviousConversation: isFromPreviousConversation,
  });

  store.dispatch({
    type: chatActions.UPDATE_CHAT_ROOMS_CONVERSATION_STATUS,
    payload: {
      conversation_identifier: currentConversationId,
      status: 'pending',
      isFromPreviousConversation: isFromPreviousConversation,
    },
  });
  store.dispatch({
    type: sharedActions.SHOW_HIDE_CUSTOM_SNOOZE_MODAL,
    payload: false,
  });
  store.dispatch({
    type: sharedActions.SHOW_HIDE_OMNISEARCH,
    payload: false,
  });
};
