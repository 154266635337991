import actions from './actions';

export const initState = {
  sortKey: 'priority',
  sortOrder: 'asc',
  searchValue: '',
  chatOnGoingTabKey: '1',
  chatOnGoingTagId: '',
  chatOnGoingStatus: 'all',
  chatOnGoingScheduleTime: false,
  chatPromptDetails: {},
  scheduleDetails: {},
  isReOrderMessageChats: false,
  messagesChatLoader: false,
  editDrawerMessageTemplate: {},
  sendChatLoader: false,
};

export default function chatOngoingReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_CHAT_ONGOING_SORT_VALUES:
      return {
        ...state,
        sortKey: action.sortKey,
        sortOrder: action.sortOrder,
      };
    case actions.SET_CHAT_ONGOING_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case actions.SET_CHAT_ONGOING_STATUS:
      return {
        ...state,
        chatOnGoingStatus: action.payload,
      };
    case actions.GET_CHAT_PROMPT_DETAILS_SUCCESS:
      if (!action.payload.template) {
        action.payload.template = 'notification'; // Template is empty while creating a chat
      }
      return {
        ...state,
        chatPromptDetails: action.payload,
        chatOnGoingScheduleTime:
          action.payload &&
          action.payload.schedule_details &&
          Object.keys(action.payload.schedule_details).length
            ? true
            : false,
        scheduleDetails:
          action.payload && action.payload.schedule_details
            ? action.payload.schedule_details
            : {},
        chatOnGoingTabKey: action.chatTabKey
          ? action.chatTabKey
          : state.chatOnGoingTabKey,
        sendChatLoader: false,
      };
    case actions.UPDATE_CHAT_PROMPT_DETAILS:
      return {
        ...state,
        chatPromptDetails: {
          ...state.chatPromptDetails,
          ...action.payload,
        },
      };
    case actions.GET_CHAT_PROMPT_DETAILS_FAILURE:
      return {
        ...state,
        sendChatLoader: false,
      };
    case actions.SET_CHAT_ONGOING_TAB_KEY:
      return {
        ...state,
        chatOnGoingTabKey: action.payload,
      };
    case actions.SET_CHAT_ONGOING_TAG_ID:
      return {
        ...state,
        chatOnGoingTagId: action.payload,
      };
    case actions.SET_CHAT_ONGOING_SCHEDULE_TIME:
      return {
        ...state,
        chatOnGoingScheduleTime: action.payload,
      };
    case actions.CHAT_ONGOING_SCHEDULE_DETAILS:
      return {
        ...state,
        scheduleDetails: action.payload,
        sendChatLoader: true,
      };
    case actions.CHAT_ONGOING_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
      };
    case actions.REORDER_MESSAGE_CHATS:
      return {
        ...state,
        isReOrderMessageChats: action.payload,
      };
    case actions.SET_MESSAGE_CHAT_LOADER:
      return {
        ...state,
        messagesChatLoader: action.payload,
      };
    default:
      return state;
  }
}
