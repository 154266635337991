import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Modal } from 'antd';
import { snoozeConversation } from './Shared';
import sharedActions from '../../../redux/sharedReducers/actions';
const CustomSnoozeModal = ({
  activeConversationStatus,
  isFromPreviousConversation,
  currentConversationId,
}) => {
  const showHideCustomSnoozeModal = useSelector(
    ({ SharedReducers }) => SharedReducers.displaySnoozeModal,
  );
  const [customSnoozeTime, setCustomSnoozeTime] = useState(null),
    [snoozeButtonStatus, setSnoozeButtonStatus] = useState(false);
  const dispatch = useDispatch();

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const setSnoozeTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    if (selectedDate >= currentDate) {
      setSnoozeButtonStatus(false);
    } else {
      setSnoozeButtonStatus(true);
    }
    setCustomSnoozeTime(time);
  };
  const hideCustomSnoozeModal = () => {
    dispatch({
      type: sharedActions.SHOW_HIDE_CUSTOM_SNOOZE_MODAL,
      payload: false,
    });
  };
  return (
    <Modal
      visible={showHideCustomSnoozeModal}
      wrapClassName={'custom-snooze-modal'}
      destroyOnClose={true}
      onCancel={hideCustomSnoozeModal}
      okText="Snooze"
      onOk={() =>
        snoozeConversation(
          'custom',
          activeConversationStatus,
          isFromPreviousConversation,
          currentConversationId,
          customSnoozeTime,
        )
      }
      okButtonProps={{ disabled: snoozeButtonStatus }}
    >
      <DatePicker
        selected={customSnoozeTime}
        onChange={(date) => setSnoozeTime(date)}
        filterTime={filterPassedTime}
        dateFormat="DD/MM/YYYY HH:mm Z"
        showTimeSelect
        inline
      />
    </Modal>
  );
};

export default CustomSnoozeModal;
