const actions = {
  EDIT_CONTACT: 'EDIT_CONTACT',
  SHOW_FILTER_OPTION: 'SHOW_FILTER_OPTION',
  ADD_CONDITION: 'ADD_CONDITION',
  FETCH_SEGMENTS: 'FETCH_SEGMENTS',
  FETCH_CONTACTS: 'FETCH_CONTACTS',
  FETCH_CONTACTS_SUCCESS: 'FETCH_CONTACTS_SUCCESS',
  APPEND_CONTACTS: 'APPEND_CONTACTS',
  APPEND_CONTACTS_SUCCESS: 'APPEND_CONTACTS_SUCCESS',
  ON_CONTACTS_SEARCH: 'ON_CONTACTS_SEARCH',
  EMPTY_CONTACTS: 'EMPTY_CONTACTS',
  FETCH_PROPERTIES: 'FETCH_PROPERTIES',
  FETCH_PROPERTIES_SUCCESS: 'FETCH_PROPERTIES_SUCCESS',
  FETCH_SEGMENTS_SUCCESS: 'FETCH_SEGMENTS_SUCCESS',
  FETCH_CONTACT_PROFILE: 'FETCH_CONTACT_PROFILE',
  FETCH_CONTACT_PROFILE_SUCCESS: 'FETCH_CONTACT_PROFILE_SUCCESS',
  UPDATE_CONTACT_PROFILE_PROPERTIES: 'UPDATE_CONTACT_PROFILE_PROPERTIES',
  UPDATE_CONTACT_PROFILE_SUCCESS: 'UPDATE_CONTACT_PROFILE_SUCCESS',
  UPDATE_CONTACT_COLUMNS: 'UPDATE_CONTACT_COLUMNS',
  DELETE_CONTACT_PROFILE: 'DELETE_CONTACT_PROFILE',
  UNSUBSCRIBE_CONTACT_PROFILE: 'UNSUBSCRIBE_CONTACT_PROFILE',
  UNSUBSCRIBE_CONTACT_PROFILE_SUCCESS: 'UNSUBSCRIBE_CONTACT_PROFILE_SUCCESS',
  CREATE_CONTACT: 'CREATE_CONTACT',
  CREATE_CONTACT_SUCCESS: 'CREATE_CONTACT_SUCCESS',
  CREATE_CONTACT_FAILURE: 'CREATE_CONTACT_FAILURE',
  DELETE_CONTACT_PROFILE_SUCCESS: 'DELETE_CONTACT_PROFILE_SUCCESS',
  EDIT_CONTACT_PROPERTIES: 'EDIT_CONTACT_PROPERTIES',
  EDIT_CONTACT_PROPERTIES_SUCCESS: 'EDIT_CONTACT_PROPERTIES_SUCCESS',
  FETCH_CONTACT_ACTIVITIES: 'FETCH_CONTACT_ACTIVITIES',
  FETCH_CONTACT_ACTIVITIES_SUCCESS: 'FETCH_CONTACT_ACTIVITIES_SUCCESS',
  FETCH_TAGS: 'FETCH_TAGS',
  FETCH_TAGS_SUCCESS: 'FETCH_TAGS_SUCCESS',
  APPLY_TAG: 'APPLY_TAG',
  APPLY_TAG_SUCCESS: 'APPLY_TAG_SUCCESS',
  CREATE_SEGMENT: 'CREATE_SEGMENT',
  CREATE_SEGMENT_SUCCESS: 'CREATE_SEGMENT_SUCCESS',
  CREATE_SEGMENT_FAILURE: 'CREATE_SEGMENT_FAILURE',
  CLEAR_CONTACT_ACTIVITIES: 'CLEAR_CONTACT_ACTIVITIES',
  SET_SORTER: 'SET_SORTER',
  ENABLE_CONTACT_ACTIVITIES_SKELETON: 'ENABLE_CONTACT_ACTIVITIES_SKELETON',
  UPDATE_SEGMENT: 'UPDATE_SEGMENT',
  UPDATE_SEGMENT_SUCCESS: 'UPDATE_SEGMENT_SUCCESS',
  UPDATE_SEGMENT_FAILURE: 'UPDATE_SEGMENT_FAILURE',
  SET_SEGMENT_SELECTION: 'SET_SEGMENT_SELECTION',
  SET_SEGMENT_FROM_SETTINGS: 'SET_SEGMENT_FROM_SETTINGS',
  SET_SELECTED_SEGMENT_COUNT: 'SET_SELECTED_SEGMENT_COUNT',
  ENABLE_CONTACTS_TABLE_LOADING_SPINNER:
    'ENABLE_CONTACTS_TABLE_LOADING_SPINNER',
  UPDATE_CONTACT_PROFILE_TAG_SUCCESS: 'UPDATE_CONTACT_PROFILE_TAG_SUCCESS',
  REMOVE_CONTACT_PROFILE_TAG_SUCCESS: 'REMOVE_CONTACT_PROFILE_TAG_SUCCESS',
  APPLY_FILTERS: 'APPLY_FILTERS',
  SET_ACTIVITIES_FILTER: 'SET_ACTIVITIES_FILTER',
  SET_RECIPIENT_LOADER: 'SET_RECIPIENT_LOADER',
  DISABLE_CONTACTS_LOADER: 'DISABLE_CONTACTS_LOADER',
  CONTACTS_SET_FILTER: 'CONTACTS_SET_FILTER',
  SET_PERSON_ID: 'SET_PERSON_ID',
  fetchContacts: (params, tagsValue, segmentId, eventId) => ({
    type: actions.FETCH_CONTACTS,
    ...params,
    tagsValue,
    segmentId,
    eventId,
  }),
  SET_IMPORT_CSV_CURRENT_STEP_KEY: 'SET_IMPORT_CSV_CURRENT_STEP_KEY',
  SET_IMPORT_CSV_FILE: 'SET_IMPORT_CSV_FILE',
  VALIDATE_IMPORTED_CSV: 'VALIDATE_IMPORTED_CSV',
  VALIDATE_IMPORTED_CSV_SUCCESS: 'VALIDATE_IMPORTED_CSV_SUCCESS',
  VALIDATE_IMPORTED_CSV_FAILURE: 'VALIDATE_IMPORTED_CSV_FAILURE',
  SET_MAPPED_DATA: 'SET_MAPPED_DATA',
  SET_IMPORT_NAME_AND_ACCEPT: 'SET_IMPORT_NAME_AND_ACCEPT',
  IMPORT_CSV_ACTIONS_DATA: 'IMPORT_CSV_ACTIONS_DATA',
  IMPORT_CSV: 'IMPORT_CSV',
  IMPORT_CSV_SUCCESS: 'IMPORT_CSV_SUCCESS',
  IMPORT_CSV_FAILURE: 'IMPORT_CSV_FAILURE',
  SET_IMPORT_CSV_IMPORT_CONDITION: 'SET_IMPORT_CSV_IMPORT_CONDITION',
  CREATE_CONTACT_PROPERTIES_FOR_CSV: 'CREATE_CONTACT_PROPERTIES_FOR_CSV',
  CREATE_CONTACT_PROPERTIES_FOR_CSV_SUCCESS:
    'CREATE_CONTACT_PROPERTIES_FOR_CSV_SUCCESS',
  CREATE_PROPERTY_DRAWER_VISIBLE: 'CREATE_PROPERTY_DRAWER_VISIBLE',
  RESET_IMPORT_CSV_DATA: 'RESET_IMPORT_CSV_DATA',
  DISABLE_SELECTED_PROPERTY: 'DISABLE_SELECTED_PROPERTY',
  EXPORT_CONTACTS: 'EXPORT_CONTACTS',
  EXPORT_CONTACTS_SUCCESS: 'EXPORT_CONTACTS_SUCCESS',
  EXPORT_CONTACTS_FAILURE: 'EXPORT_CONTACTS_FAILURE',
  EXPORT_CONTACTS_DRAWER_VISIBLE: 'EXPORT_CONTACTS_DRAWER_VISIBLE',
  UPDATE_TABLE_DATA: 'UPDATE_TABLE_DATA',
  UPDATE_TABLE_DATA_PROPERTY: 'UPDATE_TABLE_DATA_PROPERTY',
  UPDATE_TABLE_HIDE: 'UPDATE_TABLE_HIDE',
  FETCH_TEAM_MATES: 'FETCH_TEAM_MATES',
  FETCH_TEAM_MATES_SUCCESS: 'FETCH_TEAM_MATES_SUCCESS',
  SET_SAVE_SEGMENT_DRAWER: 'SET_SAVE_SEGMENT_DRAWER',
  SET_SEGMENT_UPDATE_TYPE: 'SET_SEGMENT_UPDATE_TYPE',
  FETCH_CONTACT_NOTES: 'FETCH_CONTACT_NOTES',
  RESET_CONTACT_NOTES: 'RESET_CONTACT_NOTES',
  FETCH_CONTACT_NOTES_SUCCESS: 'FETCH_CONTACT_NOTES_SUCCESS',
  CREATE_CONTACT_NOTES: 'CREATE_CONTACT_NOTES',
  EDIT_CONTACT_NOTES: 'EDIT_CONTACT_NOTES',
  DELETE_CONTACT_NOTES: 'DELETE_CONTACT_NOTES',
  FETCH_CONTACT_NOTES_FAILURE: 'FETCH_CONTACT_NOTES_FAILURE',
  ACTIVATE_NOTES_LOADER: 'ACTIVATE_NOTES_LOADER',
  CREATE_CONTACT_NOTES_SUCCESS: 'CREATE_CONTACT_NOTES_SUCCESS',
  EDIT_CONTACT_NOTES_SUCCESS: 'EDIT_CONTACT_NOTES_SUCCESS',
  DELETE_CONTACT_NOTES_SUCCESS: 'DELETE_CONTACT_NOTES_SUCCESS',
  UPDATE_SEGMENT_NAME: 'UPDATE_SEGMENT_NAME',
  CONTACT_CREATE_MODAL: 'CONTACT_CREATE_MODAL',
  FB_CUSTOM_AUDIENCE_DRAWER_VISIBLE: 'FB_CUSTOM_AUDIENCE_DRAWER_VISIBLE',
  FB_CUSTOM_AUDIENCE: 'FB_CUSTOM_AUDIENCE',
  FB_CUSTOM_AUDIENCE_SUCCESS: 'FB_CUSTOM_AUDIENCE_SUCCESS',
  FB_CUSTOM_AUDIENCE_FAILURE: 'FB_CUSTOM_AUDIENCE_FAILURE',
  UPDATE_FILTER_OR_SEGMENT: 'UPDATE_FILTER_OR_SEGMENT',
  SET_ACCOUNT_ID: 'SET_ACCOUNT_ID',
  FETCH_WOOCOMMERCE_PRODUCTS_DETAILS: 'FETCH_WOOCOMMERCE_PRODUCTS_DETAILS',
  FETCH_WOOCOMMERCE_PRODUCTS_DETAILS_SUCCESS:
    'FETCH_WOOCOMMERCE_PRODUCTS_DETAILS_SUCCESS',
  FETCH_WOOCOMMERCE_PRODUCTS_DETAILS_FAILURE:
    'FETCH_WOOCOMMERCE_PRODUCTS_DETAILS_FAILURE',
  FETCH_CONTACT_ID: 'FETCH_CONTACT_ID',
  GET_CONTACT_MEETINGS: 'GET_CONTACT_MEETINGS',
  GET_CONTACT_MEETINGS_SUCCESS: 'GET_CONTACT_MEETINGS_SUCCESS',
  GET_CONTACT_MEETINGS_FAILURE: 'GET_CONTACT_MEETINGS_FAILURE',
  CONTACT_MEETING_MODAL_STATUS: 'CONTACT_MEETING_MODAL_STATUS',
  ADD_CONTACT_MEETING: 'ADD_CONTACT_MEETING',
  ADD_CONTACT_MEETING_SUCCESS: 'ADD_CONTACT_MEETING_SUCCESS',
  ADD_CONTACT_MEETING_FAILURE: 'ADD_CONTACT_MEETING_FAILURE',
  CANCEL_CONTACT_MEETING: 'CANCEL_CONTACT_MEETING',
  CANCEL_CONTACT_MEETING_SUCCESS: 'CANCEL_CONTACT_MEETING_SUCCESS',
  CANCEL_CONTACT_MEETING_FAILURE: 'CANCEL_CONTACT_MEETING_FAILURE',
  ADD_CONTACT_ACTIVITIES: 'ADD_CONTACT_ACTIVITIES',
  GET_CONTACT_EMAIL_LIST: 'GET_CONTACT_EMAIL_LIST',
  GET_CONTACT_EMAIL_LIST_SUCCESS: 'GET_CONTACT_EMAIL_LIST_SUCCESS',
  GET_CONTACT_EMAIL_LIST_FAILURE: 'GET_CONTACT_EMAIL_LIST_FAILURE',
  CREATE_CONTACT_EMAIL: 'CREATE_CONTACT_EMAIL',
  CREATE_CONTACT_EMAIL_SUCCESS: 'CREATE_CONTACT_EMAIL_SUCCESS',
  CREATE_CONTACT_EMAIL_FAILURE: 'CREATE_CONTACT_EMAIL_FAILURE',
  CONTACT_EMAIL_MODEL_STATUS: 'CONTACT_EMAIL_MODEL_STATUS',
  UPDATE_CONTACT_COMPANY: 'UPDATE_CONTACT_COMPANY',
  UPDATE_CONTACT_COMPANY_SUCCESS: 'UPDATE_CONTACT_COMPANY_SUCCESS',
  UPDATE_CONTACT_COMPANY_FAILURE: 'UPDATE_CONTACT_COMPANY_FAILURE',
  SET_CONTACT_COMPANY_DRAWER: 'SET_CONTACT_COMPANY_DRAWER',
  UPDATE_CONTACT_OWNER: 'UPDATE_CONTACT_OWNER',
  UPDATE_CONTACT_OWNER_SUCCESS: 'UPDATE_CONTACT_OWNER_SUCCESS',
  UPDATE_CONTACT_OWNER_FAILURE: 'UPDATE_CONTACT_OWNER_FAILURE',
  SET_CONTACT_OWNER_DRAWER: 'SET_CONTACT_OWNER_DRAWER',
  ADD_FAVOURITE_SEGMENT: 'ADD_FAVOURITE_SEGMENT',
  ADD_FAVOURITE_SEGMENT_SUCCESS: 'ADD_FAVOURITE_SEGMENT_SUCCESS',
  REMOVE_FAVOURITE_SEGMENT: 'REMOVE_FAVOURITE_SEGMENT',
  REMOVE_FAVOURITE_SEGMENT_SUCCESS: 'REMOVE_FAVOURITE_SEGMENT_SUCCESS',
  DELETE_SINGLE_CONTACT_SEGMENT: 'DELETE_SINGLE_CONTACT_SEGMENT',
  RENAME_SINGLE_CONTACT_SEGMENT: 'RENAME_SINGLE_CONTACT_SEGMENT',
  GET_PERSON_MAIL_SUBSCRIPTION: 'GET_PERSON_MAIL_SUBSCRIPTION',
  GET_PERSON_MAIL_SUBSCRIPTION_SUCCESS: 'GET_PERSON_MAIL_SUBSCRIPTION_SUCCESS',
  UPDATE_PERSON_MAIL_SUBSCRIPTION: 'UPDATE_PERSON_MAIL_SUBSCRIPTION',
  UPDATE_PERSON_MAIL_SUBSCRIPTION_SUCCESS:
    'UPDATE_PERSON_MAIL_SUBSCRIPTION_SUCCESS',
  ACTIVE_MAIL_SUBSCRIPTIONS_LOADER: 'ACTIVE_MAIL_SUBSCRIPTIONS_LOADER',
  INCREMENT_PAGE_COUNT: 'INCREMENT_PAGE_COUNT',
  RESET_PAGE_COUNT: 'RESET_PAGE_COUNT',
  UPDATE_MODAL_CONTACT_ID: 'UPDATE_MODAL_CONTACT_ID',
  FETCH_CONTACT_DEALS: 'FETCH_CONTACT_DEALS',
  FETCH_CONTACT_DEALS_SUCCESS: 'FETCH_CONTACT_DEALS_SUCCESS',
  APPEND_CONTACTS_TAGS: 'APPEND_CONTACTS_TAGS',
};

export default actions;
